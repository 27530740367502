<template>
  <v-row class="ma-0">
    <v-col
      class="pa-0 pb-2"
      cols="12"
    >
      <block-item-card
        v-ripple
        :min-height="44"
        style="border-radius: 8px !important; border: none;cursor: pointer"
      >
        <v-container
          class="synthesis-ui-grey-06"
          fill-height
          pa-0
          style="height: 44px;"
          @click="$emit('live-prices-list-card-click', item)"
        >
          <v-row class="ma-0 py-2">
            <v-col
              class="pa-0 px-3"
              cols="6"
            >
              <div
                class="d-inline-block text-no-wrap"
                style="padding: 5px 0"
              >
                <div class="text-size-14 font-weight-medium synthesis-brand-dark-blue--text">
                  {{ item.Name }}
                </div>
              </div>
            </v-col>
            <v-col
              class="pa-0 px-3 text-right"
              cols="6"
            >
              <div class="d-inline-block text-no-wrap">
                <div
                  ref="live-price-text"
                  class="text-size-14 font-weight-medium synthesis-brand-dark-blue--text"
                >
                  {{ item.PriceFmt }}
                </div>
                <div :class="`synthesis-inter-text text-size-10 font-weight-regular ${item.DailyChangeColor}--text`">
                  {{ item.DailyChangeFmt }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </block-item-card>
    </v-col>
  </v-row>
</template>

<script>
import BlockItemCard from '@/theme/default/components/common/BlockItemCard'

export default {
  name      : 'LivePricesListCard',
  components: { BlockItemCard },
  directives: {},
  mixins    : [],
  props     : {
    item: {
      type    : Object,
      required: true
    },
    showPriceAnimation: {
      type   : Boolean,
      default: true
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {
      animatePriceTimeout: null
    }
  },
  computed: {},
  watch   : {
    'item.PriceFmt': {
      handler () {
        this.animatePriceText()
      },
      deep: true
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    animatePriceText () {
      if (this.showPriceAnimation) {
        const ref = this.$refs['live-price-text']
        const all = ['blinking', ...this.item.DailyChangeBgColors]
        const cls = ['blinking', this.item.DailyChangeBgColor]

        ref.classList.add(...cls)

        clearTimeout(this.animatePriceTimeout)
        this.animatePriceTimeout = setTimeout(() => {
          ref.classList.remove(...all)
        }, 400)
      }
    }
  }
}
</script>

<style scoped>
.blinking {
  -webkit-animation: 1s blink ease-in-out infinite;
  -moz-animation: 1s blink ease-in-out infinite;
  -ms-animation: 1s blink ease-in-out infinite;
  -o-animation: 1s blink ease-in-out infinite;
  animation: 1s blink ease-in-out infinite;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  50% {
    opacity: 1;
  }
}

</style>
