import BaseModel                 from '@/lib/data/model/BaseModel'
import UserProfileInputValidator from '@/api/validations/account/UserProfileInputValidator'
import UserProfileInputResource  from '@/api/resources/account/UserProfileInputResource'
import API                       from '@/api/Api'

export default class UserProfileInputModel extends BaseModel {
  constructor (payload = {}, resource = UserProfileInputResource, validator = UserProfileInputValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
  async UpdateUserProfile () {
    const data = this.clone()
    if (!data?.passwordUpdate?.oldPassword || !data?.passwordUpdate?.newPassword || !data?.passwordUpdate?.newPasswordConfirmation) {
      data.passwordUpdate = null
    }
    return await API.Resource.User.UpdateMyUserProfile(data)
  }
}
