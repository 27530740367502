import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class RiskDebtAmortisationValidator extends BaseValidator {
  validatorRules = {
    amortisation: {
      required       : true,
      type           : BaseValidator.Type.Array,
      decrementValues: (v) => {
        const arr = [...[{ amount: this.model?.notional || 0 }], ...v]
        return arr.every((item, index) => {
          const nextIndex = index + 1
          return nextIndex < arr.length ? item.amount >= arr[nextIndex].amount : true
        })
      },
      minLen: 0
    }
  }

  validatorMessages = {
    el: {
      decrementValues: 'Amortisation values should be in descending order and smaller or equal to notional'
    },
    en: {
      decrementValues: 'Amortisation values should be in descending order and smaller or equal to notional'
    }
  }

  constructor (data = {}) {
    super(data)
  }
}
