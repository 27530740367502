import { API }                                                                                                             from '@/api/Api'
import { DebtProfileExposureQuery, HistoricalDailyQuotesQuery, LatestQuoteQuery, LatestQuotesQuery, SupportedAssetsQuery } from '@/api/query/Assets.graphql'

export default {

  /**
   * SupportedAssets
   *
   * @returns {Promise<AxiosResponse<*>>}
   * @constructor
   */
  async SupportedAssets () {
    const Data = {}
    return await API.GQLRequest(SupportedAssetsQuery, Data)
  },

  /**
   * LatestQuote
   *
   * @param assetPair {Object}
   * @param featureFilter {FeatureFilterEnum}
   * @returns {Promise<AxiosResponse<*>>}
   * @constructor
   */
  async LatestQuote (assetPair, featureFilter) {
    const Data = {
      featureFilter: featureFilter,
      ...assetPair
    }
    return await API.GQLRequest(LatestQuoteQuery, Data)
  },

  /**
   * LatestQuotes
   *
   * @param assetPairs {Array<Object>}
   * @param featureFilter {FeatureFilterEnum}
   * @returns {Promise<AxiosResponse<*>>}
   * @constructor
   */
  async LatestQuotes (assetPairs, featureFilter) {
    const Data = {
      featureFilter: featureFilter,
      pairs        : assetPairs
    }
    return await API.GQLRequest(LatestQuotesQuery, Data)
  },

  /**
   * DebtProfileExposure
   *
   * @param accountId {String}
   * @param assetPairs {Array<Object>}
   * @param featureFilter {FeatureFilterEnum}
   * @returns {Promise<AxiosResponse<*>>}
   * @constructor
   */
  async DebtProfileExposure (accountId, assetPairs, featureFilter) {
    const Data = {
      accountId    : accountId,
      featureFilter: featureFilter,
      pairs        : assetPairs
    }
    return await API.GQLRequest(DebtProfileExposureQuery, Data)
  },

  /**
   * HistoricalDailyQuotes
   *
   * @param assetPairs {Array<Object>}
   * @param fromPeriod {String || Date}
   * @returns {Promise<AxiosResponse<*>>}
   * @constructor
   */
  async HistoricalDailyQuotes (assetPairs, fromPeriod) {
    const Data = {
      pairs: assetPairs,
      from : fromPeriod
    }
    return await API.GQLRequest(HistoricalDailyQuotesQuery, Data)
  }

}
