<template>
  <v-card
    v-if="asset"
    :min-height="180"
    class="synthesis-card-border"
    flat
  >
    <v-card-title
      class="pb-0 text-size-20 font-weight-medium synthesis-brand-dark-blue--text"
      v-text="asset.Name"
    />
    <v-row class="ma-0">
      <v-col
        class="pb-0"
        cols="6"
        xl="3"
      >
        <v-card-text class="pl-1 pb-0 synthesis-inter-text text-size-10 synthesis-ui-grey-01--text">
          <div class="text-no-wrap">
            {{ $t('Hedging.Card.Instrument.Title') }}
            <infotip
              v-if="$t('Hedging.Card.Instrument.InfoTip')"
              icon-style="margin-top: -6px"
            >
              {{ $t('Hedging.Card.Instrument.InfoTip') }}
            </infotip>
          </div>
          <div
            class="pt-1 text-no-wrap text-size-18 font-weight-medium synthesis-brand-dark-blue--text"
            v-text="asset.TypeDisplay"
          />
        </v-card-text>
      </v-col>
      <v-col
        class="pb-0"
        cols="6"
        xl="3"
      >
        <v-card-text class="pb-0 synthesis-inter-text text-size-10 synthesis-ui-grey-01--text">
          <div class="text-no-wrap">
            {{ $t('Hedging.Card.HedgeRatio.Title') }}
            <infotip
              v-if="$t('Hedging.Card.HedgeRatio.InfoTip')"
              icon-style="margin-top: -6px"
            >
              {{ $t('Hedging.Card.HedgeRatio.InfoTip') }}
            </infotip>
          </div>
          <div
            class="pt-1 text-no-wrap text-size-18 font-weight-medium synthesis-brand-dark-blue--text"
            v-text="asset.HedgeRatio"
          />
        </v-card-text>
      </v-col>
      <v-col
        class="pb-0 pl-0 pl-xl-4"
        cols="6"
        xl="3"
      >
        <v-card-text class="synthesis-inter-text text-size-10 synthesis-ui-grey-01--text">
          <div class="text-no-wrap">
            {{ $t('Hedging.Card.Cost.Title') }}
            <infotip
              v-if="asset.CostBreakdown && !!asset.CostBreakdown"
              icon-style="margin-top: -6px"
            >
              <template v-for="(item, i) in asset.CostBreakdown">
                {{ item.Label }}<span
                  :key="`cost-breakdown-${i}`"
                  class="pl-2"
                ><span
                  v-if="asset.BaseCurrency && !isBaseCurrencySymbolSingle(asset.BaseCurrency)"
                  :class="`pt-4 pr-1 text-size-14 font-weight-medium synthesis-brand-white--text`"
                  v-text="asset.BaseCurrency"
                />{{ baseCurrencySymbolSingle(asset.BaseCurrency) + item.CostFmt }}</span><br :key="`cost-breakdown-br-${i}`">
              </template>
            </infotip>
          </div>
          <div
            class="pt-1 text-no-wrap text-size-18 font-weight-medium synthesis-brand-dark-blue--text"
          >
            <span
              v-if="asset.BaseCurrency && !isBaseCurrencySymbolSingle(asset.BaseCurrency)"
              :class="`pt-4 pr-1 text-no-wrap text-size-14 font-weight-medium synthesis-brand-dark-blue--text`"
              v-text="asset.BaseCurrency"
            />{{ baseCurrencySymbolSingle(asset.BaseCurrency) + asset.Cost }}
          </div>
        </v-card-text>
      </v-col>
      <v-col
        cols="6"
        xl="3"
      >
        <v-card-text class="synthesis-inter-text text-size-10 synthesis-ui-grey-01--text">
          <div class="text-no-wrap">
            {{ $t('Hedging.Card.PotentialSaving.Title') }}
            <infotip
              v-if="$t('Hedging.Card.PotentialSaving.InfoTip')"
              icon-style="margin-top: -6px"
            >
              {{ $t('Hedging.Card.PotentialSaving.InfoTip') }}
            </infotip>
          </div>
          <div
            :class="`pt-1 text-no-wrap text-size-18 font-weight-medium ${asset.PotentialSavingTextColor || 'synthesis-brand-dark-blue'}--text`"
          >
            <span
              v-if="asset.BaseCurrency && !isBaseCurrencySymbolSingle(asset.BaseCurrency)"
              :class="`pt-4 pr-1 text-no-wrap text-size-14 font-weight-medium ${asset.PotentialSavingTextColor || 'synthesis-brand-dark-blue'}--text`"
              v-text="asset.BaseCurrency"
            />{{ baseCurrencySymbolSingle(asset.BaseCurrency) + asset.PotentialSaving }}
          </div>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col class="pa-0">
        <v-card-text class="pt-0">
          <v-lazy
            :options="{threshold: .5}"
            :value="disableLazy"
            transition="fade-transition"
          >
            <e-chart-hedging-instrument-bar
              :axis-y-tick-prefix="asset.BaseCurrency"
              :bar-background-color="themeColorRGBA('synthesis-ui-grey-04', 0.5)"
              :bar-color="themeColorRGBA('synthesis-ui-orange-02')"
              :bar-title="$t('Hedging.Card.UnhedgedRisk.Title')"
              :chart-animation="chartAnimation"
              :chart-data-options="asset.BarValuesUnhedgedRisk"
              :chart-height="30"
              chart-animation-easing="quinticInOut"
            />
          </v-lazy>
          <v-lazy
            :options="{threshold: .5}"
            :value="disableLazy"
            transition="fade-transition"
          >
            <e-chart-hedging-instrument-bar
              :axis-y-tick-prefix="asset.BaseCurrency"
              :bar-background-color="themeColorRGBA('synthesis-ui-grey-04', 0.5)"
              :bar-color="themeColorRGBA('synthesis-ui-blue-04')"
              :bar-title="$t('Hedging.Card.HedgedRisk.Title')"
              :chart-animation="chartAnimation"
              :chart-data-options="asset.BarValuesHedgedRisk"
              :chart-height="30"
              :show-background="true"
              chart-animation-easing="quinticInOut"
            />
          </v-lazy>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import chartsColorMixin           from '@/theme/default/components/charts/mixins/chartsColorMixin'
import EChartHedgingInstrumentBar from '@/theme/default/components/charts/echarts/EChartHedgingInstrumentBar'
import Infotip                    from '@/theme/default/components/common/Infotip'
import useExportAdditional        from '@/mixins/useExportAdditional'

export default {
  name      : 'HedgingInstrumentCard',
  components: {
    Infotip,
    EChartHedgingInstrumentBar
  },
  directives: {},
  mixins    : [chartsColorMixin, useExportAdditional],
  props     : {
    asset: {
      type   : Object,
      default: () => {}
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    isBaseCurrencySymbolSingle (baseCurrency) {
      return baseCurrency.length === 1
    },

    baseCurrencySymbolSingle (baseCurrency) {
      return this.isBaseCurrencySymbolSingle(baseCurrency) ? baseCurrency : ''
    }
  }
}
</script>

<style scoped>

</style>
