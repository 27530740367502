<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :persistent="options.persistent"
    @keydown.esc="cancel"
  >
    <v-card
      class="pa-3 pa-sm-6"
      flat
    >
      <v-card-title
        class="justify-center text-center text-size-20 font-weight-medium synthesis-brand-dark-blue--text"
        v-html="title"
      />

      <v-card-text
        class="justify-center text-center text-size-16 font-weight-medium synthesis-ui-grey-01--text"
        v-html="message"
      />

      <v-card-actions>
        <v-btn
          v-if="options.button.no"
          class="d-inline-block synthesis-brand-dark-blue--text"
          color="synthesis-ui-grey-04"
          depressed
          height="42"
          width="50%"
          x-large
          @click.native="cancel"
        >
          {{ options.button.no.title }}
        </v-btn>

        <v-btn
          v-if="options.button.yes"
          :color="options.color"
          class="d-inline-block white--text"
          depressed
          height="42"
          width="50%"
          x-large
          @click.native="confirm"
        >
          {{ options.button.yes.title }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm-dialog ref="confirmDialog"></confirm-dialog>
 *
 * Call it:
 * this.$refs.confirmDialog.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirmDialog.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirmDialog
 * <template>
 *   <v-app>
 *     ...
 *     <confirm-dialog ref="confirmDialog"></confirm-dialog>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirmDialog = this.$refs.confirmDialog.open
 * }
 */

export default {
  name      : 'ConfirmDialog',
  components: {},
  directives: {},
  mixins    : [],
  props     : [],
  data () {
    return {
      dialog        : false,
      resolve       : null,
      reject        : null,
      title         : '',
      message       : '',
      defaultOptions: {
        color     : 'synthesis-ui-red-02',
        width     : 425,
        persistent: true,
        button    : {
          no : { title: this.$t('Common.Button.No') },
          yes: { title: this.$t('Common.Button.Yes') }
        }
      },
      options: {}
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {
    this.options = { ...this.defaultOptions }
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    open (title, message, options = {}) {
      this.title = title
      this.message = message
      this.options = { ...this.defaultOptions, ...options }
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    confirm () {
      this.resolve && this.resolve(true)
      this.dialog = false
    },

    cancel () {
      this.resolve && this.resolve(false)
      this.dialog = false
    }
  }
}
</script>

<style scoped>
/deep/ .v-dialog {
  box-shadow : none;
}
</style>
