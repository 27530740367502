import BaseValidator      from '@/lib/data/validator/BaseValidator'
import ValidatorTypesEnum from '@/lib/data/validator/ValidatorTypesEnum'

export default class ResetPasswordValidator extends BaseValidator {
  validatorRules = {
    Password: {
      required       : true,
      type           : ValidatorTypesEnum.String,
      minLen         : 8,
      isValidPassword: this.isValidPassword
    },
    ConfirmPassword: {
      required       : true,
      type           : ValidatorTypesEnum.String,
      equals         : 'Password',
      minLen         : 8,
      isValidPassword: this.isValidPassword
    }
  }

  validatorMessages = {
    el: {
      equals         : 'Must be same as "New Password"',
      isValidPassword: 'Should contain at least one lowercase, uppercase, number, and symbol character'
    },
    en: {
      equals         : 'Must be same as "New Password"',
      isValidPassword: 'Should contain at least one lowercase, uppercase, number, and symbol character'
    }
  }

  constructor (data = {}) {
    super(data)
  }

  isValidPassword (password) {
    // The password should be at least 8 characters long and contain at least one lowercase, uppercase, number, and symbol character
    return /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/.test(password)
  }
}
