<template>
  <v-container
    class="align-content-start"
    fill-height
    fluid
  >
    <v-row>
      <section-header
        :breadcrumb-items="breadcrumb"
        :button="viewHasData"
        :button-title="$t('Industry.Button.New.Title')"
        button-to="IndustryComparisonNew"
      />
    </v-row>

    <v-row>
      <v-col cols="12">
        <view-data-container
          :button-title="$t('Industry.Button.New.Title')"
          :loading="isLoadingData"
          :no-data-title="$t('Industry.Message.NoData.Title')"
          button
          button-to="IndustryComparisonNew"
          class="pa-0"
        >
          <template v-if="viewHasData">
            <v-row>
              <v-col cols="12">
                <v-tabs
                  v-model="activeTab"
                  active-class="active-tab"
                  background-color="transparent"
                  height="30"
                  hide-slider
                  show-arrows
                >
                  <v-tab>{{ $t('Industry.Tab.Archive.Title') }}</v-tab>
                </v-tabs>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="pa-0"
                cols="12"
              >
                <v-tabs-items v-model="activeTab">
                  <v-tab-item>
                    <v-container fluid>
                      <v-row>
                        <template v-for="(item, index) in items">
                          <v-col
                            :key="`tab-${activeTab}-items-${index}`"
                            cols="12"
                            lg="4"
                            sm="6"
                            style="padding: 10px"
                          >
                            <v-lazy
                              :key="`lazy-industry-archive-tab-${activeTab}-items-${index}`"
                              :options="{threshold: .5}"
                              transition="fade-transition"
                            >
                              <archive-item-card
                                :card-event="true"
                                :date-created="item.CreatedAtFmt"
                                :menu-items="$t('Industry.Tab.Archive.Card.MenuItems')"
                                :tags="item.Tags"
                                :title="item.Name"
                                @card-click="onItemCardClick(item)"
                                @archive-item-delete-button-click="onItemCardDeleteClick(item)"
                              />
                            </v-lazy>
                          </v-col>
                        </template>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </template>
        </view-data-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta      from '@/mixins/i18n/i18nRouteMeta'
import viewHasData        from '@/mixins/viewHasData'
import routerTabState     from '@/mixins/routerTabState'
import SectionHeader      from '@/theme/default/components/common/SectionHeader'
import ViewDataContainer  from '@/theme/default/components/common/ViewDataContainer'
import ArchiveItemCard    from '@/theme/default/components/common/ArchiveItemCard'
import API                from '@/api/Api'
import { clone }          from '@/lib/utils/helper'
import { collect }        from '@/lib/utils/array'
import ComparisonResource from '@/api/resources/industry/ComparisonResource'

export default {
  name      : 'Industry',
  components: {
    ArchiveItemCard,
    ViewDataContainer,
    SectionHeader
  },
  directives: {},
  mixins    : [i18nRouteMeta, viewHasData, routerTabState],
  props     : {},
  dataStore : {
    items              : 'Industry.Archive.Data',
    industrySummaryData: 'Industry.Summary.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    breadcrumb () {
      return [{
        text    : this.$t('Industry.Title'),
        disabled: true
      }]
    },

    accountId () {
      return this.user?.selectedAccountId || null
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      this.isLoadingData = true

      API.Resource.Industry.Archive(this.accountId)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {
          this.isLoadingData = false
        })
    },
    handleResponse (response) {
      const data = API.responseData(response)?.listCompanyComparisons || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const o = clone(data)

        this.items = collect(o, ComparisonResource)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    onItemCardDeleteClick (item) {
      if (!item || !item.Id) return

      this.$root.$confirmDialog(
        this.$t('Industry.Tab.Archive.Confirm.Delete.Title'),
        this.$t('Industry.Tab.Archive.Confirm.Delete.Body', { Name: item.Name }),
        {
          color : 'synthesis-ui-red-02',
          button: {
            no : { title: this.$t('Common.Button.Cancel') },
            yes: { title: this.$t('Common.Button.Delete') }
          }
        }
      ).then(response => {
        if (response) {
          API.Resource.Industry.DeleteComparison(item.Id)
            .then(response => {
              this.handleDeleteIndustryResponse(response)
            })
            .catch(e => {
              this.handleDeleteIndustryResponse(e.response)
            })
            .finally(() => {})
        }
      })
    },
    handleDeleteIndustryResponse (response) {
      const comparisonId = API.responseData(response)?.deleteCompanyComparison || ''
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && comparisonId) {
        this.deleteStorageDataById(comparisonId)
        this.getData()
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },
    deleteStorageDataById (comparisonId) {
      if (this.comparisonId === comparisonId) {
        this.comparisonId = null
      }

      this.$delete(this.industrySummaryData, comparisonId)
      this.$delete(this.items, comparisonId)
    },

    onItemCardClick (item) {
      this.$router.push({
        name  : 'IndustryComparisonShow',
        params: {
          id          : item.Id,
          comparisonId: item.Id
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
