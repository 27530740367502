<template>
  <v-col cols="12">
    <block-item-card
      v-if="creditMetrics"
      ref="creditMetricsCard"
      :export-element="elementCreditMetrics"
      :title="$t('Risks.Section.DebtProfile.Block.CreditMetrics.Title')"
      :tooltip="$t('Risks.Section.DebtProfile.Block.CreditMetrics.InfoTip')"
    >
      <v-row class="ma-0">
        <v-col
          class="ma-0 pa-1"
          cols="12"
        >
          <v-row
            class="ma-0"
          >
            <v-col
              cols="12"
              md="6"
            >
              <item-chart-legend
                :background-color="themeColorRGBA('synthesis-ui-green-08')"
                :title="$t('Risks.Section.DebtProfile.Block.CreditMetrics.Pie.DebtToEquityRatio.Title')"
                :title-size="16"
                class="pa-5 pt-4"
              />
              <v-lazy
                :options="{threshold: .5}"
                :value="disableLazy"
                transition="fade-transition"
              >
                <base-e-chart-pie
                  axis-y-tick-prefix=""
                  axis-y-tick-suffix="%"
                  :chart-data-options="pieValues"
                  :pie-radius="150"
                  :chart-height="360"
                  :tooltip-show="true"
                  chart-animation-easing="elasticOut"
                  tooltip-confine
                  :label-line-show="false"
                  :label-show="true"
                  :chart-animation="chartAnimation"
                  legend-show
                  label-position="inside"
                  :chart-grid-margin="{left: 5, right: 0, top: 0, bottom: 0, containLabel: false}"
                  :label-pie-asset="false"
                  :pie-asset-value-font-color="themeColorRGBA('synthesis-brand-white')"
                  :pie-asset-value-font-size="28"
                  :pie-asset-value-font-weight="300"
                  :pie-legend-font-size="18"
                  :pie-legend-font-weight="300"
                  pie-asset-value-align="center"
                  legend-left="center"
                  legend-top="bottom"
                  :legend-data="pieLegendValues"
                />
              </v-lazy>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-lazy
                :options="{threshold: .5}"
                :value="disableLazy"
                transition="fade-transition"
              >
                <debt-credit-metrics-table-card
                  :headers="tableHeaders"
                  :items="tableValues"
                />
              </v-lazy>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </block-item-card>
  </v-col>
</template>

<script>
import chartsColorMixin           from '@/theme/default/components/charts/mixins/chartsColorMixin'
import BlockItemCard              from '@/theme/default/components/common/BlockItemCard'
import riskAnalysisId             from '@/theme/default/views/risks/mixins/riskAnalysisId'
import riskBaseCurrency           from '@/theme/default/views/risks/mixins/riskBaseCurrency'
import BaseEChartPie              from '@/theme/default/components/charts/echarts/BaseEChartPie.vue'
import ItemChartLegend            from '@/theme/default/components/common/ItemChartLegend.vue'
import DebtCreditMetricsTableCard from '@/theme/default/components/common/DebtCreditMetricsTableCard.vue'
import useExportAdditional        from '@/mixins/useExportAdditional'
import { clone }                  from '@/lib/utils/helper'

export default {
  name      : 'CreditMetricsBlock',
  components: {
    DebtCreditMetricsTableCard,
    ItemChartLegend,
    BaseEChartPie,
    BlockItemCard
  },
  directives: {},
  mixins    : [riskBaseCurrency, chartsColorMixin, riskAnalysisId, useExportAdditional],
  props     : {},
  dataStore : {
    riskDebtData        : 'Risks.Debt.Data',
    riskBaseCurrencyData: 'Risks.BaseCurrency.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      elementCreditMetrics: null
    }
  },
  computed: {
    debt () {
      return this.riskDebtData[this.id] || null
    },

    creditMetrics () {
      return this.debt?.CreditMetrics || null
    },

    pieValues () {
      return this.creditMetrics?.PieValues || []
    },

    pieLegendValues () {
      return clone(this.creditMetrics?.PieValues).reverse()
    },

    tableHeaders () {
      return this.creditMetrics?.TableHeaders || []
    },

    tableValues () {
      return this.creditMetrics?.TableValues || []
    }

  },
  watch: {
    debt: {
      handler () {
        this.setExportElements()
      },
      immediate: true
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    setExportElements () {
      this.$nextTick(() => {
        this.elementCreditMetrics = this.$refs?.creditMetricsCard?.$el
      })
    }
  }
}
</script>

<style scoped>

</style>
