import BaseValidator from '@/lib/data/validator/BaseValidator'
import { sum }       from '@/lib/utils/array'

export default class HedgingPolicyValidator extends BaseValidator {
  validatorRules = {
    name: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 3
    },
    baseCurrencyCode: {
      required: true,
      type    : BaseValidator.Type.String
    },
    mode: {
      required: true,
      type    : BaseValidator.Type.String
    },
    exposures: {
      required: true,
      type    : BaseValidator.Type.Array,
      minLen  : 1
    },
    carry: {
      requiredIf: ['marketRatesUsed', false],
      type      : BaseValidator.Type.Array
    }
  }

  validatorMessages = {
    el: {
      notExcludedLengthValid: 'You must have at least 1 valid exposure.'
    },
    en: {
      notExcludedLengthValid: 'You must have at least 1 valid exposure.'
    }
  }

  constructor (data = {}) {
    super(data)

    this.validatorRules.exposures = {
      required              : true,
      type                  : BaseValidator.Type.Array,
      minLen                : 1,
      notExcludedLengthValid: (v) => v.length - v.filter(asset => asset.userProvidedSeasonality && sum(asset.seasonality) === 0).length > 0
    }

    this.validatorRules.carry = {
      requiredIf: ['marketRatesUsed', false],
      type      : BaseValidator.Type.Array,
      minLen    : (v, model = this.model) => model.marketRatesUsed ? v && v.length === 0 : v && v.length > 0
    }
  }
}
