import BaseResource                    from '@/lib/data/resource/BaseResource'
import { toNumberFormatNotationShort } from '@/lib/utils/helper'
import dayjs                           from 'dayjs'

export default class DashboardHedgingPositionItemResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const trade = payload?.trade || null

    this.Id = payload.position.id ?? ''
    this.Name = payload.name ?? ''
    this.AssetPair = trade?.assetPair || null
    this.InstrumentType = trade.instrument ?? ''
    this.DisplayInstrument = trade.displayInstrument ?? ''
    this.MaturityDate = trade.maturityDate ?? ''
    this.MtmValue = trade.mtmValue ?? ''
    this.MtmValueColor = this.MtmValue > 0 ? 'green' : 'red'
    this.DailyChangeValue = trade.dailyChangeAbsolute ?? ''
    this.DailyChangeValueColor = this.DailyChangeValue > 0 ? 'green' : 'red'
    this.HistoricalMTM = payload.historicalMTM ?? null
  }

  /* PROPERTIES */
  get BaseCurrencyDisplayName () {
    return this.AssetPair?.quote?.displayName || ''
  }

  get BaseCurrencyDisplaySymbol () {
    return this.AssetPair?.quote?.displaySymbol || ''
  }

  get Asset () {
    const base = this.AssetPair?.base?.displayName || ''
    const quote = this.BaseCurrencyDisplayName || ''

    return quote + '-' + base
  }

  get Date () {
    return this.getDateFmt(this.MaturityDate)
  }

  get TitleValueFmt () {
    return this.MtmValue ? this.getBaseCurrencySymbolSingle() + toNumberFormatNotationShort(this.MtmValue) : ''
  }

  get TitleValueChangeFmt () {
    const prefix = this.getBaseCurrencySymbolSingle() + (this.DailyChangeValue > 0 ? '+' : '')

    return this.DailyChangeValue ? prefix + toNumberFormatNotationShort(this.DailyChangeValue) : ''
  }

  get ChartValues () {
    return [{
      x: this.HistoricalMTM?.dates || [],
      y: this.HistoricalMTM?.prices || []
    }]
  }

  /* METHODS */
  baseCurrencySymbolSingle () {
    return this.BaseCurrencyDisplaySymbol.length === 1
  }

  getBaseCurrencySymbolSingle () {
    return this.baseCurrencySymbolSingle() ? this.BaseCurrencyDisplaySymbol : this.BaseCurrencyDisplayName + ' '
  }

  getDateFmt (date) {
    return date ? dayjs(date).format('MMM YYYY') : ''
  }
}
