import BaseResource                 from '@/lib/data/resource/BaseResource'
import { collect }                  from '@/lib/utils/array'
import AssetNameResource            from '@/api/resources/asset/AssetNameResource'
import RiskSeasonalityAssetResource from '@/api/resources/risks/RiskSeasonalityAssetResource'

export default class RiskSeasonalityResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const o = payload?.result?.seasonality || null

    this.Id = payload?.id ?? ''
    this.Name = payload?.name ?? ''
    this.Assets = collect(o?.assets || [], AssetNameResource)
    this.Data = collect(o?.perAsset || [], RiskSeasonalityAssetResource)
  }

  /* PROPERTIES */

  /* METHODS */
}
