export default {
  Title: 'Knowledge Center',

  Article: {
    ReadMore: {
      Title: 'Read more'
    }
  },

  Button: {
    LoadMore: {
      Title: 'Load More'
    }
  }
}
