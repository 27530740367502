import BaseResource                        from '@/lib/data/resource/BaseResource'
import HedgingSummaryAssetStrategyResource from '@/api/resources/hedging/HedgingSummaryAssetStrategyResource'
import AssetNameResource                   from '@/api/resources/asset/AssetNameResource'
import { collect }                         from '@/lib/utils/array'
import InstrumentTypeEnum                  from '@/api/enums/InstrumentTypeEnum'
import HedgingPolicyParametersResource     from '@/api/resources/hedging/HedgingPolicyParametersResource'

export default class HedgingSummaryResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const o = payload?.result?.strategySummary || null

    this.Id = payload.id ?? ''
    this.Name = payload.name ?? ''
    this.Parameters = payload.parameters ? new HedgingPolicyParametersResource(payload.parameters) : null
    this.Assets = collect(o?.assets || [], AssetNameResource)
    this.AssetsStrategy = collect(o?.assetStrategy || [], HedgingSummaryAssetStrategyResource)
  }

  /* PROPERTIES */
  get Mode () {
    return this.Parameters?.Mode ?? ''
  }

  get TableValues () {
    if (this.AssetsStrategy && this.AssetsStrategy.length) {
      return this.AssetsStrategy.map(item => {
        return {
          Asset                  : item.Name,
          Forwards               : item.ForwardsRiskFmt,
          HasForwards            : item.ForwardsRiskFmt !== '',
          Options                : item.OptionsRiskFmt,
          HasOptions             : item.OptionsRiskFmt !== '',
          Collars                : item.CollarsRiskFmt,
          HasCollars             : item.CollarsRiskFmt !== '',
          BaseCurrency           : item.BaseCurrencyDisplayName,
          BestInstrument         : item.PreferredInstrumentDisplay,
          BestInstrumentColor    : this.getBestInstrumentColor(item.PreferredInstrument),
          BestInstrumentTextColor: 'synthesis-brand-white'
        }
      })
    }
    return []
  }

  get ChartValues () {
    if (this.AssetsStrategy && this.AssetsStrategy.length) {
      return [{
        x         : this.AssetsStrategy.map(item => item.Name),
        y         : this.AssetsStrategy.map(item => item.PreferredOptimalHedgeRatio),
        instrument: this.AssetsStrategy.map(item => item.PreferredInstrumentDisplay)
      }]
    }

    return []
  }

  get Instruments () {
    if (this.AssetsStrategy && this.AssetsStrategy.length) {
      return this.AssetsStrategy.map(item => item.Card)
    }

    return []
  }

  /* METHODS */
  getBestInstrumentColor (instrument) {
    if (instrument === InstrumentTypeEnum.FORWARD) return 'synthesis-ui-purple-02'
    if (instrument === InstrumentTypeEnum.OPTION) return 'synthesis-ui-yellow-01'
    if (instrument === InstrumentTypeEnum.COLLAR) return 'synthesis-ui-red-02'
    if (instrument === InstrumentTypeEnum.INTEREST_RATE_SWAP) return 'synthesis-ui-orange-02'
    return ''
  }
}
