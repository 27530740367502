<template>
  <v-col
    v-if="details"
    cols="12"
    md="6"
  >
    <v-toolbar
      color="transparent"
      dense
      flat
    >
      <v-toolbar-title
        class="pl-1 synthesis-text text-size-24 font-weight-medium synthesis-brand-dark-blue--text"
        v-text="$t('Alerts.Section.Summary.Details.Title')"
      />
    </v-toolbar>
    <block-item-card class="pa-2">
      <v-container fluid>
        <v-row class="mt-0">
          <v-col
            class="pt-0"
            cols="12"
          >
            <label class="field-label">
              {{ $t('Alerts.Section.Summary.Details.Fields.Description.Label') }}
            </label>
            <v-textarea
              :value="details.Description"
              filled
              flat
              hide-details
              outlined
              readonly
              rows="2"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <label class="field-label">
              {{ $t('Alerts.Section.Summary.Details.Fields.Priority.Label') }}
            </label>
            <v-text-field
              :value="details.Priority"
              filled
              flat
              hide-details
              outlined
              readonly
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <label class="field-label">
              {{ $t('Alerts.Section.Summary.Details.Fields.Currency.Label') }}
            </label>
            <v-text-field
              :value="details.Currency"
              filled
              flat
              hide-details
              outlined
              readonly
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <label class="field-label">
              {{ $t('Alerts.Section.Summary.Details.Fields.ExpiryDate.Label') }}
            </label>
            <v-text-field
              :value="details.ExpiryDate"
              filled
              flat
              hide-details
              outlined
              readonly
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <label class="field-label">
              {{ $t('Alerts.Section.Summary.Details.Fields.Notifications.Label') }}
            </label>
            <v-text-field
              :value="details.Notifications"
              filled
              flat
              hide-details
              outlined
              readonly
            />
          </v-col>
        </v-row>
      </v-container>
    </block-item-card>
  </v-col>
</template>

<script>

import BlockItemCard from '@/theme/default/components/common/BlockItemCard'
import alertId       from '@/theme/default/views/alerts/mixins/alertId'

export default {
  name      : 'AlertDetails',
  components: {
    BlockItemCard
  },
  directives: {},
  mixins    : [alertId],
  props     : {},
  dataStore : {
    alertsSummaryData: 'Alerts.Summary.Data'
  },
  data () {
    return {}
  },
  computed: {
    alert () {
      return this.alertsSummaryData[this.id] || null
    },

    details () {
      return this.alert?.Details
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
