<template>
  <v-container
    v-if="summary"
    fluid
  >
    <v-row>
      <section-header
        :breadcrumb-items="breadcrumb"
      />
    </v-row>

    <v-row>
      <v-col cols="12">
        <hedging-strategy-show-base
          :policy-id="id"
          class="pa-0"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta             from '@/mixins/i18n/i18nRouteMeta'
import SectionHeader             from '@/theme/default/components/common/SectionHeader'
import policyId                  from '@/theme/default/views/hedging/mixins/policyId'
import HedgingStrategyShowBase   from '@/theme/default/views/hedging/HedgingStrategyShowBase'
import API                       from '@/api/Api'
import { clone }                 from '@/lib/utils/helper'
import HedgingSummaryResource    from '@/api/resources/hedging/HedgingSummaryResource'
import HedgingInstrumentResource from '@/api/resources/hedging/HedgingInstrumentResource'

export default {
  name      : 'HedgingStrategyShow',
  components: {
    HedgingStrategyShowBase,
    SectionHeader
  },
  directives: {},
  mixins    : [i18nRouteMeta, policyId],
  props     : {
    policyName: {
      type   : String,
      default: ''
    }
  },
  dataStore: {
    hedgingSummaryData: 'Hedging.Summary.Data',
    hedgingInstrument : 'Hedging.Instrument'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    summary () {
      return this.hedgingSummaryData[this.id] || null
    },

    title () {
      return this.summary?.Name || ''
    },

    breadcrumb () {
      return [
        {
          text    : this.$t('Hedging.Title'),
          disabled: false,
          exact   : true,
          to      : { name: 'Hedging' }
        },
        {
          text    : this.title,
          disabled: true
        }
      ]
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Hedging.Summary(this.id)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },
    handleResponse (response) {
      const data = API.responseData(response)?.findPolicy || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const o = clone(data)
        const v = new HedgingSummaryResource(o)

        // Store data to DataStore
        this.$set(this.hedgingSummaryData, this.id, v)

        // Store instrument types to DataStore
        o?.result?.forwards && this.$set(this.hedgingInstrument.Forward.Data, this.id, new HedgingInstrumentResource(o?.result?.forwards))
        o?.result?.options && this.$set(this.hedgingInstrument.Option.Data, this.id, new HedgingInstrumentResource(o?.result?.options))
        o?.result?.riskReversal && this.$set(this.hedgingInstrument.Collar.Data, this.id, new HedgingInstrumentResource(o?.result?.riskReversal))
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>

</style>
