import BaseResource from '@/lib/data/resource/BaseResource'

export default class RiskDebtOptimalFixedRatioRatesViewAssetResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Title = payload?.label || payload || ''
    this.Value = payload?.value || payload || ''
  }

  /* PROPERTIES */

  /* METHODS */
}
