<template>
  <block-item-card
    :min-height="minHeight"
    :title="title"
    :title-large-size="$vuetify.breakpoint.mdAndUp ? 52 : 40"
    :title-value="titleValue"
    :title-value-color="titleValueColor"
  >
    <v-card-text
      v-if="items && items.length"
      :style="`height: ${minHeight}px`"
      class="pb-2 mt-0 overflow-y-auto"
    >
      <template
        v-for="(item, i) in items"
      >
        <v-lazy
          :key="`lazy-forecast-flow-rugl-summary-card-${i}`"
          :options="{threshold: .5}"
          transition="fade-transition"
        >
          <forecast-flow-rugl-summary-card-item
            :key="`forecast-flow-rugl-summary-card-item-${i}`"
            :item="item"
          />
        </v-lazy>
      </template>
    </v-card-text>
  </block-item-card>
</template>

<script>

import BlockItemCard                   from '@/theme/default/components/common/BlockItemCard.vue'
import ForecastFlowRuglSummaryCardItem from '@/theme/default/views/risks/blocks/forecastFlow/ForecastFlowRuglSummaryCardItem.vue'

export default {
  name      : 'ForecastFlowRuglSummaryCard',
  components: {
    ForecastFlowRuglSummaryCardItem,
    BlockItemCard
  },
  directives: {},
  mixins    : [],
  props     : {
    title: {
      type   : String,
      default: ''
    },
    titleValue: {
      type   : String,
      default: ''
    },
    titleValueColor: {
      type   : String,
      default: undefined
    },
    items: {
      type   : Array,
      default: () => []
    },
    minHeight: {
      type   : [Number, String],
      default: 270
    }
  },
  dataStore: {
    appToolbarExtended  : 'App.Config.AppToolbarExtended',
    riskForecastFlowData: 'Risks.ForecastFlow.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>
