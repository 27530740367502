export default {
  name: 'i18nRouteMeta',
  data () {
    return {}
  },
  metaInfo () {
    const returnObj = {
      title        : this.meta.title,
      titleTemplate: this.meta.name, // '%s | ' + this.meta.name,
      link         : [
        {
          vmid: 'canonical',
          rel : 'canonical',
          href: this.meta.url
        }
      ],
      meta: [
        {
          vmid   : 'application-name',
          name   : 'application-name',
          content: this.meta.name
        },
        {
          vmid    : 'og:url',
          property: 'og:url',
          content : this.meta.url
        },

        // Facebook / Open Graph
        {
          vmid    : 'og:title',
          property: 'og:title',
          content : this.meta.title + ' | ' + this.meta.name
        },
        {
          vmid    : 'og:site_name',
          property: 'og:site_name',
          content : this.meta.name
        },
        {
          vmid    : 'og:type',
          property: 'og:type',
          content : 'website'
        },
        {
          vmid    : 'og:url',
          property: 'og:url',
          content : this.meta.url
        },

        // Twitter Card
        {
          vmid   : 'twitter:card',
          name   : 'twitter:card',
          content: 'summary'
        },
        {
          vmid   : 'twitter:site',
          name   : 'twitter:site',
          content: this.meta.url
        },
        {
          vmid   : 'twitter:title',
          name   : 'twitter:title',
          content: this.meta.title + ' | ' + this.meta.name
        },

        // Google / Schema.org markup:
        {
          vmid    : 'google:name',
          itemprop: 'name',
          content : this.meta.title + ' | ' + this.meta.name
        }
      ]
    }

    if (this.meta.desc) {
      returnObj.meta.push({
        vmid   : 'description',
        name   : 'description',
        content: this.meta.desc
      })
      returnObj.meta.push({
        vmid    : 'og:description',
        property: 'og:description',
        content : this.meta.desc
      })
      returnObj.meta.push({
        vmid   : 'twitter:description',
        name   : 'twitter:description',
        content: this.meta.desc
      })
      returnObj.meta.push({
        vmid    : 'google:description',
        itemprop: 'description',
        content : this.meta.desc
      })
    }

    if (this.meta.img) {
      returnObj.meta.push({
        vmid    : 'og:image',
        property: 'og:image',
        content : this.meta.img
      })
      returnObj.meta.push({
        vmid   : 'twitter:image:src',
        name   : 'twitter:image:src',
        content: this.meta.img
      })
      returnObj.meta.push({
        vmid    : 'google:image',
        itemprop: 'image',
        content : this.meta.img
      })
    }

    return returnObj
  },
  computed: {
    meta () {
      return {
        name : this.$te('Common.App.Title') ? this.$t('Common.App.Title') : 'Maven Synthesis',
        title: this.$te(`Route.${ this.$route.name }.Meta.Title`) ? this.$t(`Route.${ this.$route.name }.Meta.Title`) : this.title ? this.title : '',
        desc : this.$te(`Route.${ this.$route.name }.Meta.Desc`) ? this.$t(`Route.${ this.$route.name }.Meta.Desc`) : this.desc ? this.desc : '',
        url  : process.env.VUE_APP_DOMAIN + this.$route.fullPath,
        img  : this.$te(`Route.${ this.$route.name }.Meta.Img`) ? this.$t(`Route.${ this.$route.name }.Meta.Img`) : this.img ? this.img : ''
      }
    }
  }
}
