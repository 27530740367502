import BaseModel        from '@/lib/data/model/BaseModel'
import CompanyValidator from '@/api/validations/user/CompanyValidator'
import CompanyResource  from '@/api/resources/user/CompanyResource'

export default class CompanyModel extends BaseModel {
  constructor (payload = {}, resource = CompanyResource, validator = CompanyValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}
