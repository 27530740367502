const InstrumentTypeEnum = {
  FORWARD           : 'Forward',
  OPTION            : 'Option',
  COLLAR            : 'Collar',
  INTEREST_RATE_SWAP: 'InterestRateSwap'
}

export const InstrumentTypeEnumHelper = {
  all () {
    return Object.values(InstrumentTypeEnum)
  },

  getTranslationChoice (type) {
    switch (type) {
    case InstrumentTypeEnum.FORWARD:
      return 0
    case InstrumentTypeEnum.OPTION:
      return 1
    case InstrumentTypeEnum.COLLAR:
      return 2
    case InstrumentTypeEnum.INTEREST_RATE_SWAP:
      return 3
    }
  }
}

export default InstrumentTypeEnum
