import BaseResource          from '@/lib/data/resource/BaseResource'
import { collect }           from '@/lib/utils/array'
import AlertIncidentResource from '@/api/resources/alerts/AlertIncidentResource'

export default class AlertsArchiveResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Items = payload.incidents ? collect(payload.incidents, AlertIncidentResource) : null
    this.Total = payload.total ?? 0
    this.Page = payload.page ?? 1
    this.ItemsPerPage = payload.itemsPerPage ?? 10
  }

  /* PROPERTIES */
  get TableValues () {
    if (this.Items && !!this.Items.length) {
      return this.Items.map(item => item.TableRow)
    }
    return []
  }

  get Options () {
    return {
      itemsPerPage: this.ItemsPerPage,
      page        : this.Page,
      total       : this.Total
    }
  }

  /* METHODS */
}
