<template>
  <v-navigation-drawer
    v-model="navigationDrawerVisible"
    app
    color="synthesis"
    dark
    floating
    right
    temporary
    width="320"
  >
    <template #prepend>
      <v-list
        dark
        dense
      >
        <v-list-item class="pa-0 pl-4 pr-1">
          <img
            class="my-4"
            height="20"
            src="/img/logo/logo-light.png"
          >

          <v-spacer />

          <v-btn
            class="pa-0"
            icon
            plain
            @click.stop="toggleNavigationDrawer"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
    </template>

    <v-list dense>
      <template v-for="(item, i) in topItems">
        <v-row
          v-if="item.heading"
          :key="`heading-${i}`"
          align="center"
        >
          <v-col
            class="text-left py-0 my-0"
            cols="6"
          >
            <v-subheader>
              {{ item.heading }}
            </v-subheader>
          </v-col>
          <v-col
            class="text-right py-0 my-0"
            cols="6"
          >
            <v-btn
              v-if="item.text && item.route"
              :exact="item.route.exact"
              :to="item.route"
              small
              text
            >
              {{ item.text }}
            </v-btn>
          </v-col>
        </v-row>

        <v-divider
          v-else-if="item.divider"
          :key="`divider-${i}`"
          class="my-2"
        />

        <v-list-group
          v-else-if="item.children"
          :key="i"
          v-model="item.model"
          no-action
        >
          <template #appendIcon>
            <div style="opacity: 0.6;">
              <v-icon color="#ffffff">
                mdi-chevron-down
              </v-icon>
            </div>
          </template>

          <template #activator>
            <v-list-item
              active-class="active-list-item"
              class="pl-0 normal-list-item"
              dense
              style="height: 40px"
            >
              <template #default="{active}">
                <v-list-item-action
                  v-if="item.icon"
                  class="mr-5"
                >
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-action
                  v-else-if="item.synthesisIcon"
                  class="mr-5"
                >
                  <synthesis-icon
                    :active="active || item.model"
                    :name="item.synthesisIcon"
                  />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>

          <v-list-item
            v-for="(child, c) in item.children"
            :key="`child-${i}-${c}`"
            :exact="child.route.exact"
            :to="child.route"
            active-class="active-list-item"
            class="normal-list-item"
            dense
            style="height: 40px; padding-left: 70px;"
          >
            <template #default="{active}">
              <v-list-item-action
                v-if="child.icon"
                class="mr-5"
              >
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-action
                v-else-if="child.synthesisIcon"
                class="mr-5"
              >
                <synthesis-icon
                  :active="active || child.model"
                  :name="child.synthesisIcon"
                />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-else
          :key="`item-${i}`"
          :exact="item.route.exact"
          :to="item.route"
          active-class="active-list-item"
          class="normal-list-item"
          dense
          style="height: 40px"
        >
          <template #default="{active}">
            <v-list-item-action
              v-if="item.icon"
              class="mr-5"
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-action
              v-else-if="item.synthesisIcon"
              class="mr-5"
            >
              <synthesis-icon
                :active="active || item.model"
                :name="item.synthesisIcon"
              />
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </template>
    </v-list>

    <template #append>
      <v-divider />

      <v-list
        class="pb-0"
        dark
        dense
      >
        <v-list-item
          dense
          style="height: 40px"
        >
          <v-list-item-avatar
            color="orange"
            size="25"
          >
            <span class="text-size-16 font-weight-bold">
              {{ userAvatarLetter }}
            </span>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="normal-list-item">
              {{ userCompanyName }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list
        class="py-0"
        dense
      >
        <template v-for="(item, i) in bottomItems">
          <v-row
            v-if="item.heading"
            :key="`heading-${i}`"
            align="center"
          >
            <v-col
              class="text-left py-0 my-0"
              cols="6"
            >
              <v-subheader>
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col
              class="text-right py-0 my-0"
              cols="6"
            >
              <v-btn
                v-if="item.text && item.route"
                :exact="item.route.exact"
                :to="item.route"
                small
                text
              >
                {{ item.text }}
              </v-btn>
            </v-col>
          </v-row>

          <v-divider
            v-else-if="item.divider"
            :key="`divider-${i}`"
            class="my-2"
          />

          <v-list-group
            v-else-if="item.children"
            :key="i"
            v-model="item.model"
            no-action
          >
            <template #appendIcon>
              <div style="opacity: 0.6;">
                <v-icon color="#ffffff">
                  mdi-chevron-down
                </v-icon>
              </div>
            </template>

            <template #activator>
              <v-list-item
                active-class="active-list-item"
                class="pl-0 normal-list-item"
                dense
                style="height: 40px"
              >
                <template #default="{active}">
                  <v-list-item-action
                    v-if="item.icon"
                    class="mr-5"
                  >
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-action
                    v-else-if="item.synthesisIcon"
                    class="mr-5"
                  >
                    <synthesis-icon
                      :active="active || item.model"
                      :name="item.synthesisIcon"
                    />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </template>

            <v-list-item
              v-for="(child, c) in item.children"
              :key="`child-${i}-${c}`"
              :exact="child.route.exact"
              :to="child.route"
              active-class="active-list-item"
              class="normal-list-item"
              dense
              style="height: 40px; padding-left: 70px;"
            >
              <template #default="{active}">
                <v-list-item-action
                  v-if="child.icon"
                  class="mr-5"
                >
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-action
                  v-else-if="child.synthesisIcon"
                  class="mr-5"
                >
                  <synthesis-icon
                    :active="active || child.model"
                    :name="child.synthesisIcon"
                  />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-else
            :key="`item-${i}`"
            :exact="item.route.exact"
            :to="item.route"
            active-class="active-list-item"
            class="normal-list-item"
            dense
            style="height: 40px"
          >
            <template #default="{active}">
              <v-list-item-action
                v-if="item.icon"
                class="mr-5"
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-action
                v-else-if="item.synthesisIcon"
                class="mr-5"
              >
                <synthesis-icon
                  :active="active || item.model"
                  :name="item.synthesisIcon"
                />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </template>
      </v-list>

      <div class="px-4 py-2">
        <v-btn
          block
          class="white--text rounded-sm"
          color="synthesis-ui-red-02"
          depressed
          @click="onLogoutBtnClick"
        >
          {{ $t('Common.Button.Logout') }}
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>

import appNavigationDrawerCommon from '@/theme/default/components/app/mixins/appNavigationDrawerCommon'
import SynthesisIcon             from '@/theme/default/components/common/icons/SynthesisIcon'

export default {
  name      : 'AppNavigationDrawerMobile',
  components: { SynthesisIcon },
  directives: {},
  mixins    : [appNavigationDrawerCommon],
  props     : {},

  data () {
    return {}
  },

  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    toggleNavigationDrawer () {
      this.navigationDrawerVisible = !this.navigationDrawerVisible
    }
  }
}
</script>

<style scoped>
/deep/ .v-list-item__title {
  font-size   : 14px !important;
  font-weight : 400;
}

/deep/ .normal-list-item {
  color   : #FFFFFF;
  opacity : 0.6;
}

/deep/ .active-list-item {
  color   : #FFFFFF;
  opacity : 1;
}
</style>
