export default {
  Title: 'Help',

  MavenAdvisory: {
    Title : 'How can we help?',
    Fields: {
      Email: {
        Label      : 'Email',
        Placeholder: 'Enter your email'
      },
      ContactNumber: {
        Label      : 'Contact Number',
        Placeholder: 'Enter your contact number'
      },
      Subject: {
        Label      : 'Subject',
        Placeholder: 'Enter subject'
      },
      Message: {
        Label      : 'Message',
        Placeholder: 'What can we help you with?'
      }
    },
    Success: {
      Title: 'Message sent!',
      Body : `
        <p>Thanks for your message. A member of our team will get back to you shortly!</p>
        <p>In the meantime, why not check out our Knowledge Centre for all the latest Whitepapers, Tutorials and market information.</p>
      `
    }
  },

  SynthesisSupport: {
    Title : 'Feedback Form',
    Fields: {
      Email: {
        Label      : 'Email',
        Placeholder: 'Enter your email'
      },
      ContactNumber: {
        Label      : 'Contact Number',
        Placeholder: 'Enter your contact number'
      },
      Subject: {
        Label      : 'Subject',
        Placeholder: 'Enter subject'
      },
      Message: {
        Label      : 'Message',
        Placeholder: 'Add your feedback message here'
      }
    },
    Success: {
      Title: 'Feedback submitted!',
      Body : `
        <p>Thanks for your message. We’re always looking for ways to improve our platform so we appreciate feedback of all kinds!</p>
        <p>We regularly push updates to the platform so please keep an eye out for new changes.</p>
      `
    }
  },

  HelpSynthesisSupport: {
    Title: 'Synthesis Support'
  },

  HelpMavenAdvisory: {
    Title: 'Maven Advisory'
  }
}
