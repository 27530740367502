var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{staticClass:"field-label"},[_vm._v(_vm._s(_vm.baseTitle))]),_c('v-item-group',{attrs:{"disabled":_vm.disabled,"mandatory":_vm.groupModel !== 'Other'},model:{value:(_vm.groupModel),callback:function ($$v) {_vm.groupModel=$$v},expression:"groupModel"}},[_c('v-container',{attrs:{"fluid":"","pa-0":""}},[(_vm.showSelectOnly)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"disabled":_vm.disabled,"error-messages":_vm.errorMessages,"items":_vm.BaseCurrencies,"placeholder":_vm.$t('Risks.Edit.Form.Fields.BaseCurrency.Placeholder'),"rules":_vm.rules,"hide-details":"auto","item-text":"symbol","item-value":"symbol","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',{staticClass:"caption",domProps:{"textContent":_vm._s(item.symbol)}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.name)+" "),_c('span',{staticClass:"caption grey--text"},[_vm._v(_vm._s(item.symbol))])])]}}],null,false,3731445273),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1)]:[_c('v-row',[_vm._l((_vm.commonCurrenciesOther),function(item,index){return [_c('v-col',{key:("base-currency-" + index + "-" + (item.symbol)),attrs:{"cols":_vm.cols}},[_c('v-item',{attrs:{"disabled":_vm.disabled,"value":item.symbol},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"item-group-card d-flex align-center",class:[{'item-group-card-active': active}],attrs:{"disabled":_vm.disabled,"flat":"","height":"80"},on:{"click":toggle}},[_c('v-avatar',{staticClass:"ma-3",attrs:{"color":"synthesis-ui-purple-07","size":"35"}},[_c('span',{staticClass:"synthesis-text font-weight-medium text-size-20 synthesis-ui-purple-01--text"},[_vm._v(" "+_vm._s(item.symbol === 'Other' ? '¤' : item.displaySymbol)+" ")])]),_c('v-card-title',{staticClass:"synthesis-text synthesis-brand-dark-blue--text text-size-14 font-weight-regular pl-0"},[_vm._v(" "+_vm._s(item.symbol)+" ")])],1)]}}],null,true)})],1)]})],2),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.groupModel === 'Other'),expression:"groupModel === 'Other'"}]},[_c('v-col',{attrs:{"cols":"12"}},[_c('label',{staticClass:"field-label"},[_vm._v(_vm._s(_vm.selectTitle))]),_c('v-select',{attrs:{"disabled":_vm.disabled,"error-messages":_vm.errorMessages,"items":_vm.baseCurrenciesExcludeCommon,"placeholder":_vm.$t('Risks.Edit.Form.Fields.BaseCurrency.Placeholder'),"rules":_vm.rules,"hide-details":"auto","item-text":"symbol","item-value":"symbol","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',{staticClass:"caption",domProps:{"textContent":_vm._s(item.symbol)}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.name)+" "),_c('span',{staticClass:"caption grey--text"},[_vm._v(_vm._s(item.symbol))])])]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }