import BaseResource                           from '@/lib/data/resource/BaseResource'
import AlertTriggerConditionThresholdTypeEnum from '@/api/enums/AlertTriggerConditionThresholdTypeEnum'
import AlertTypeEnum                          from '@/api/enums/AlertTypeEnum'
import { isEmpty }                            from '@/lib/utils/type'

export default class TriggerEditInputResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.id = payload.Id ?? ''
    this.tradeId = payload.TradeId ?? ''
    this.portfolioId = payload.PortfolioId ?? ''
    this.assetCode = payload.Asset?.symbol ?? ''
    this.conditionType = payload.ConditionType ?? AlertTriggerConditionThresholdTypeEnum.UpperLimit
    this.snapshotValue = payload.SnapshotValue ?? payload.Threshold
    this.threshold = payload.Threshold ?? ''
    this.displayThreshold = payload.DisplayThreshold ?? ''
    this.selectedItem = payload.selectedItem ?? {
      id         : '',
      title      : payload.PositionName || payload.PortfolioName || '',
      description: payload.Instrument ?? '',
      value      : 0,
      pair       : {
        base  : payload.Asset,
        quote : payload.BaseCurrency,
        symbol: this.getSymbol(payload)
      },
      baseCurrency: payload.BaseCurrency ?? null,
      assets      : payload.Assets,
      tags        : payload.Tags
    }
  }

  /* PROPERTIES */

  /* METHODS */
  IsPortfolio () {
    return this.Type === AlertTypeEnum.PortfolioMTM
  }

  Type () {
    if (!isEmpty(this.PortfolioId)) {
      return AlertTypeEnum.PortfolioMTM
    } else if (!isEmpty(this.TradeId)) {
      return AlertTypeEnum.PositionMTM
    } else {
      return AlertTypeEnum.AssetPrice
    }
  }

  getSymbol (payload) {
    return this.IsPortfolio() ? payload?.BaseCurrency?.symbol || '' : `${ this.assetCode }_${ payload?.BaseCurrency?.symbol || '' }`
  }
}
