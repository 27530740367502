import BaseResource                    from '@/lib/data/resource/BaseResource'
import { toNumberFormatNotationShort } from '@/lib/utils/helper'

export default class HedgingStrategySummaryCostItemResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Label = payload.label ?? ''
    this.Cost = payload.cost ?? ''
    this.CostFmt = payload.cost ? toNumberFormatNotationShort(payload.cost) : ''
  }

  /* PROPERTIES */

  /* METHODS */
}
