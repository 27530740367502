import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class AssetStrategyInputValidator extends BaseValidator {
  validatorRules = {
    assetCode: {
      required: true,
      type    : BaseValidator.Type.String
    },
    currentHedgeRatio: {
      required: false,
      type    : BaseValidator.Type.Numeric
    },
    currentInstrument: {
      required: false,
      type    : BaseValidator.Type.String
      // isIn    : InstrumentTypeEnumHelper.all()
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
