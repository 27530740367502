<template>
  <v-container fluid>
    <v-form
      ref="form"
      v-model="isFormValid"
      @submit.prevent
    >
      <v-row>
        <v-col
          cols="12"
          style="min-height: 66px"
        >
          <v-breadcrumbs
            :items="breadcrumb"
            class="pa-0 d-inline-block"
          />
          <div class="d-inline-block float-right">
            <v-btn
              :disabled="!isFormValid || isFormSaving"
              :loading="isFormSaving"
              block
              class="synthesis-text text-size-14 white--text action-button"
              color="synthesis-ui-green-01"
              depressed
              height="42"
              large
              @click="submitForm"
              v-text="$t('MyDetails.Button.Save')"
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-toolbar
            color="transparent"
            dense
            flat
          >
            <v-toolbar-title
              class="synthesis-text text-size-24 font-weight-medium synthesis-brand-dark-blue--text"
              v-text="$t('MyDetails.Section.PersonalDetails.Title')"
            />
          </v-toolbar>

          <block-item-card
            class="pa-2"
            min-height="380"
          >
            <v-container fluid>
              <v-row class="mt-0">
                <v-col
                  class="mb-4"
                  cols="12"
                >
                  <v-avatar
                    color="orange"
                    size="48"
                  >
                    <span class="synthesis-text text-size-28 font-weight-medium synthesis-brand-white--text">
                      {{ userAvatarLetter }}
                    </span>
                  </v-avatar>

                  <span class="pa-4 synthesis-text text-size-16 font-weight-medium synthesis-brand-dark-blue--text">{{ userCompanyName }}</span>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                >
                  <label class="field-label">
                    {{ $t('MyDetails.Section.PersonalDetails.Fields.Name.Label') }}
                  </label>
                  <v-text-field
                    v-model="model.personalDetailsUpdate.name"
                    :error-messages="serverErrorMessages['personalDetailsUpdate.name']"
                    :placeholder="$t('MyDetails.Section.PersonalDetails.Fields.Name.Placeholder')"
                    :rules="model.validator.vuetifyFormFieldRules('personalDetailsUpdate.name')"
                    autocomplete="name"
                    name="name"
                    outlined
                    type="text"
                  />
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                >
                  <label class="field-label">
                    {{ $t('MyDetails.Section.PersonalDetails.Fields.Email.Label') }}
                  </label>
                  <v-text-field
                    :placeholder="$t('MyDetails.Section.PersonalDetails.Fields.Email.Placeholder')"
                    :value="userEmail"
                    autocomplete="email"
                    disabled
                    name="email"
                    outlined
                    type="email"
                  />
                </v-col>
              </v-row>
            </v-container>
          </block-item-card>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-toolbar
            color="transparent"
            dense
            flat
          >
            <v-toolbar-title
              class="synthesis-text text-size-24 font-weight-medium synthesis-brand-dark-blue--text"
              v-text="$t('MyDetails.Section.Password.Title')"
            />
          </v-toolbar>
          <block-item-card
            class="pa-2"
            min-height="380"
          >
            <v-container fluid>
              <v-row class="mt-0">
                <v-col
                  class="py-0"
                  cols="12"
                >
                  <label class="field-label">
                    {{ $t('MyDetails.Section.Password.Fields.OldPassword.Label') }}
                  </label>
                  <v-text-field
                    v-model="model.passwordUpdate.oldPassword"
                    :append-icon="passwordOldVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                    :error-messages="serverErrorMessages['passwordUpdate.oldPassword']"
                    :placeholder="$t('MyDetails.Section.Password.Fields.OldPassword.Placeholder')"
                    :rules="model.validator.vuetifyFormFieldRules('passwordUpdate.oldPassword')"
                    :type="passwordOldVisible ? 'text' : 'password'"
                    autocomplete="password"
                    name="password"
                    outlined
                    @click:append="toggleOldPassword"
                  />
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                >
                  <label class="field-label">
                    {{ $t('MyDetails.Section.Password.Fields.NewPassword.Label') }}
                  </label>
                  <v-text-field
                    v-model="model.passwordUpdate.newPassword"
                    :append-icon="passwordNewVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                    :error-messages="serverErrorMessages['passwordUpdate.newPassword']"
                    :placeholder="$t('MyDetails.Section.Password.Fields.NewPassword.Placeholder')"
                    :rules="model.validator.vuetifyFormFieldRules('passwordUpdate.newPassword')"
                    :type="passwordNewVisible ? 'text' : 'password'"
                    autocomplete="new-password"
                    name="new-password"
                    outlined
                    @click:append="toggleNewPassword"
                  />
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                >
                  <label class="field-label">
                    {{ $t('MyDetails.Section.Password.Fields.ConfirmPassword.Label') }}
                  </label>
                  <v-text-field
                    v-model="model.passwordUpdate.newPasswordConfirmation"
                    :append-icon="passwordConfirmVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                    :error-messages="serverErrorMessages['passwordUpdate.newPasswordConfirmation']"
                    :placeholder="$t('MyDetails.Section.Password.Fields.ConfirmPassword.Placeholder')"
                    :rules="model.validator.vuetifyFormFieldRules('passwordUpdate.newPasswordConfirmation')"
                    :type="passwordConfirmVisible ? 'text' : 'password'"
                    autocomplete="confirm-password"
                    name="confirm-password"
                    outlined
                    @click:append="toggleConfirmPassword"
                  />
                </v-col>
              </v-row>
            </v-container>
          </block-item-card>
        </v-col>

        <v-col cols="12">
          <v-toolbar
            color="transparent"
            dense
            flat
          >
            <v-toolbar-title
              class="synthesis-text text-size-24 font-weight-medium synthesis-brand-dark-blue--text"
              v-text="$t('MyDetails.Section.Notifications.Title')"
            />
          </v-toolbar>
          <block-item-card
            class="pa-2"
            min-height="220"
          >
            <v-container fluid>
              <v-row class="mt-0">
                <v-col
                  class="py-0"
                  cols="12"
                >
                  <label class="field-label">
                    {{ $t('MyDetails.Section.Notifications.Fields.Title') }}
                  </label>
                  <v-checkbox
                    v-model="model.userPreferencesUpdate.emailOnAlertIncident"
                    :label="$t('MyDetails.Section.Notifications.Fields.AlertIsTriggered.Label')"
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col
                  class="py-0"
                  cols="12"
                >
                  <v-checkbox
                    v-model="model.userPreferencesUpdate.emailOnNewArticles"
                    :label="$t('MyDetails.Section.Notifications.Fields.NewTutorialsAdded.Label')"
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col
                  class="py-0"
                  cols="12"
                >
                  <v-checkbox
                    v-model="model.userPreferencesUpdate.emailOnCompanyUpdates"
                    :label="$t('MyDetails.Section.Notifications.Fields.CompanyNewsAndUpdates.Label')"
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col
                  class="py-0"
                  cols="12"
                >
                  <v-checkbox
                    v-model="model.userPreferencesUpdate.emailOnNewAssets"
                    :label="$t('MyDetails.Section.Notifications.Fields.NewQuotesAdded.Label')"
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col
                  class="py-0"
                  cols="12"
                >
                  <v-checkbox
                    v-model="model.userPreferencesUpdate.emailOnRiskSummaryUpdates"
                    :label="$t('MyDetails.Section.Notifications.Fields.WeeklyUpdatesRiskPositions.Label')"
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>
          </block-item-card>
        </v-col>
      </v-row>
    </v-form>

    <overlay-loader :value="isFormSaving" />
  </v-container>
</template>

<script>
import i18nRouteMeta         from '@/mixins/i18n/i18nRouteMeta'
import BlockItemCard         from '@/theme/default/components/common/BlockItemCard'
import formData              from '@/mixins/form/formData'
import UserProfileInputModel from '@/api/models/account/UserProfileInputModel'
import API                   from '@/api/Api'
import OverlayLoader         from '@/theme/default/components/common/OverlayLoader'
import UserModel             from '@/api/models/user/UserModel'

export default {
  name      : 'AccountSettings',
  components: {
    OverlayLoader,
    BlockItemCard
  },
  directives: {},
  mixins    : [i18nRouteMeta, formData],
  props     : {},
  dataModel : UserProfileInputModel,
  data () {
    return {
      passwordOldVisible    : false,
      passwordNewVisible    : false,
      passwordConfirmVisible: false
    }
  },
  computed: {
    breadcrumb () {
      return [{
        text    : this.$t('MyDetails.Title'),
        disabled: true,
        exact   : true
      }]
    },

    userCompanyName () {
      return this.user?.selectedAccount?.company?.shortName || ''
    },

    userAvatarLetter () {
      return this.userCompanyName?.charAt(0) || ''
    },

    userEmail () {
      return this.user?.email || ''
    }
  },
  watch: {
    'model.passwordUpdate': {
      handler (newVal) {
        if (newVal?.oldPassword || newVal?.newPassword || newVal?.newPasswordConfirmation) {
          this.model.validator.validatorRules['passwordUpdate.oldPassword'].required = true
          this.model.validator.validatorRules['passwordUpdate.newPassword'].required = true
          this.model.validator.validatorRules['passwordUpdate.newPasswordConfirmation'].required = true
        } else {
          this.model.validator.validatorRules['passwordUpdate.oldPassword'].required = false
          this.model.validator.validatorRules['passwordUpdate.newPassword'].required = false
          this.model.validator.validatorRules['passwordUpdate.newPasswordConfirmation'].required = false
        }
      },
      deep: true
    }
  },
  beforeCreate () {},
  created () {
    this.initData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    submitForm () {
      if (this.isFormSaving || !this.validateForm()) return
      this.isFormSaving = true

      this.model.UpdateUserProfile()
        .then(response => {
          this.handleResponse(response)
        })
        .catch(error => {
          this.handleResponse(error.response)
        })
        .finally(() => {})
    },

    handleResponse (response) {
      const data = API.responseData(response)?.updateMyProfile || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.user = new UserModel(data)
        this.resetFormValidation()
        this.resetForm()

        this.$nextTick(() => {
          this.initData()
        })
        this.isFormSaving = false
      } else {
        this.$bus.$emit('app:error', errors)
        this.isFormSaving = false
      }
    },

    initData () {
      this.model.personalDetailsUpdate.name = this.user?.name || ''
      this.model.userPreferencesUpdate.emailOnAlertIncident = this.user?.preferences?.emailOnAlertIncident ?? false
      this.model.userPreferencesUpdate.emailOnNewAssets = this.user?.preferences?.emailOnNewAssets ?? false
      this.model.userPreferencesUpdate.emailOnCompanyUpdates = this.user?.preferences?.emailOnCompanyUpdates ?? false
      this.model.userPreferencesUpdate.emailOnNewArticles = this.user?.preferences?.emailOnNewArticles ?? false
      this.model.userPreferencesUpdate.emailOnRiskSummaryUpdates = this.user?.preferences?.emailOnRiskSummaryUpdates ?? false
    },

    toggleOldPassword () {
      this.passwordOldVisible = !this.passwordOldVisible
    },
    toggleNewPassword () {
      this.passwordNewVisible = !this.passwordNewVisible
    },
    toggleConfirmPassword () {
      this.passwordConfirmVisible = !this.passwordConfirmVisible
    }
  }
}

</script>

<style scoped>
/deep/ .v-toolbar__content, .v-toolbar__extension {
  padding: 4px 0 !important;
}

/deep/ .v-breadcrumbs li {
  color: var(--v-synthesis-ui-grey-01-base);
  font-weight: 500;
  font-size: 24px;
}

/deep/ .v-breadcrumbs li a, /deep/ .v-breadcrumbs__item--disabled li a {
  color: var(--v-synthesis-ui-grey-01-base) !important;
}

/deep/ .v-breadcrumbs__item--disabled {
  color: var(--v-synthesis-brand-dark-blue-base) !important;
}

/deep/ .v-breadcrumbs li:nth-child(even) {
  padding: 0 8px;
}
</style>
