import BaseModel                 from '@/lib/data/model/BaseModel'
import UserValidator             from '@/api/validations/user/UserValidator'
import UserResource              from '@/api/resources/user/UserResource'
import { first, uniqueElements } from '@/lib/utils/array'
import API                       from '@/api/Api'
import PreferencesResource       from '@/api/resources/user/PreferencesResource'
import SubscriptionTierEnum      from '@/api/enums/SubscriptionTierEnum'

export default class UserModel extends BaseModel {
  constructor (payload = {}, resource = UserResource, validator = UserValidator) {
    super(payload, resource, validator)
  }

  get defaultAccount () {
    return first(this.accounts)
  }

  /* PROPERTIES */
  get selectedAccount () {
    return this.defaultAccount
  }

  get selectedAccountId () {
    return this.selectedAccount?.id || null
  }

  get selectedAccountCompany () {
    return this.selectedAccount?.company || null
  }

  get selectedAccountCompanyId () {
    return this.selectedAccountCompany?.id || null
  }

  get selectedAccountSubsidiaryIds () {
    return this.selectedAccountCompany?.corporateGroup?.subsidiaries?.map(subsidiary => subsidiary.accountId) ?? []
  }

  get selectedAccountBaseCurrency () {
    return this.selectedAccountCompany?.baseCurrency || null
  }

  get livePricesPairs () {
    return this.preferences?.livePricesPairs ?? []
  }

  get livePricesAssets () {
    const assets = []
    this.livePricesPairs.forEach(pair => {
      assets.push(pair.baseSymbol)
      assets.push(pair.quoteSymbol)
    })
    return uniqueElements(assets)
  }

  get subscriptionTier () {
    return this.selectedAccount?.subscriptionTier ?? SubscriptionTierEnum.STANDARD
  }

  get hasDebtProfile () {
    return this.selectedAccount?.hasDebtProfile ?? false
  }

  get hasForecastFlow () {
    return this.selectedAccount?.hasForecastFlow ?? false
  }

  /* METHODS */
  boot () {
    super.boot()

    this.guarded = []
  }

  async Get () {
    return await API.Resource.User.Get(this.id)
  }

  async MyUser () {
    return await API.Resource.User.MyUser()
  }

  async UpdateFromMyUser () {
    return await this.MyUser()
      .then(response => {
        this.handleUpdateMyUserResponse(response)
      })
      .catch(error => {
        this.handleUpdateMyUserResponse(error.response)
      })
  }

  handleUpdateMyUserResponse (response) {
    const data = API.responseData(response)?.myUser || null
    const errors = API.responseErrors(response) || []

    if (API.isResponseSuccess(response) && data) {
      this.setData(data)
      if (window && window.Vue) window.Vue.user = this
    } else {
      this.$bus.$emit('app:error', errors)
    }
  }

  async MyPreferences () {
    return await API.Resource.User.MyPreferences()
  }

  async UpdateFromMyPreferences () {
    return await this.MyPreferences()
      .then(response => {
        this.handleUpdateMyPreferencesResponse(response)
      })
      .catch(error => {
        this.handleUpdateMyPreferencesResponse(error.response)
      })
  }

  handleUpdateMyPreferencesResponse (response) {
    const data = API.responseData(response)?.myUser || null
    const errors = API.responseErrors(response) || []

    if (API.isResponseSuccess(response) && data) {
      this.preferences = new PreferencesResource(data.preferences)
      if (window && window.Vue) window.Vue.user = this
    } else {
      this.$bus.$emit('app:error', errors)
    }
  }

  getLivePricesAssetPairs (baseCurrencyCode) {
    if (!baseCurrencyCode) return []

    return this.livePricesAssets.map(asset => ({
      baseSymbol : asset,
      quoteSymbol: baseCurrencyCode
    }))
  }
}
