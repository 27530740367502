/**
 * Websocket instance
 * @type {null}
 */
let ws = null

/**
 * Timeout
 * @type {null}
 */
let wsTimeoutId = null

/**
 * Whether the websocket should attempt to connect immediately upon instantiation.
 * @type {boolean}
 */
const wsAutomaticOpen = true

/**
 * The number of milliseconds to delay before attempting to reconnect. Accepts integer. Default: 1000.
 * @type {number}
 */
const wsReconnectInterval = 1000

/**
 * The maximum number of milliseconds to delay a reconnection attempt. Accepts integer. Default: 30000.
 * @type {number}
 */
const wsMaxReconnectInterval = 30000

/**
 * The rate of increase of the reconnected delay. Allows reconnecting attempts to back off when problems persist. Accepts integer or float. Default: 1.5.
 * @type {number}
 */
const wsReconnectDecay = 0.5

/**
 * The maximum number of reconnection attempts to make. Unlimited if null.
 * @type {null}
 */
const wsMaxReconnectAttempts = null

/**
 * The number of reconnect attempts.
 * @type {number}
 */
let wsReconnectAttempts = 0

/**
 * The binary type, possible values 'blob' or 'arraybuffer', default 'blob'.
 * @type {string}
 */
const wsBinaryType = 'blob'

export const Socket = {
  init () {
    /**
     * Create or not a websocket upon instantiation.
     */
    if (wsAutomaticOpen) {
      this.open()
    }
  },

  close () {
    ws && ws.close()
    ws = null

    wsTimeoutId && clearTimeout(wsTimeoutId)
    wsTimeoutId = null
  },

  send: (data) => {
    // eslint-disable-next-line no-console
    // console.log('Socket send.')

    ws && ws.readyState === WebSocket.OPEN && ws.send(JSON.stringify(data))
  },

  sendLivePricesPairs () {
    if (window && window?.Vue) {
      const pairs = {
        pairs: window.Vue.user?.livePricesPairs,
        token: window.Vue.authorizationToken
      }

      if (pairs) {
        Socket.send(pairs)
      }
    }
  },

  refresh () {
    // eslint-disable-next-line no-console
    // console.log('Socket refresh.', ws)

    ws && ws.close()
  },

  open: (reconnectAttempt = false) => {
    ws = new WebSocket(`${ process.env.VUE_APP_WEBSOCKET_URL }${ process.env.VUE_APP_WEBSOCKET_LIVE_QUOTES_PATH }`)
    ws.binaryType = wsBinaryType

    if (reconnectAttempt) {
      if (wsMaxReconnectAttempts && wsReconnectAttempts > wsMaxReconnectAttempts) {
        return
      }
    } else {
      wsReconnectAttempts = 0
    }

    ws.onopen = e => {
      // eslint-disable-next-line no-console
      // console.log('Socket started.')

      clearTimeout(wsTimeoutId)

      Socket.sendLivePricesPairs()
    }

    // On socket message received
    ws.onmessage = e => {
      window.Vue.$bus.$emit('ws-onmessage', JSON.parse(e.data))
    }

    // On closed socket
    ws.onclose = e => {
      if (ws) {
        const timeout = wsReconnectInterval * Math.pow(wsReconnectDecay, wsReconnectAttempts)
        const delay = (timeout > wsMaxReconnectInterval ? wsMaxReconnectInterval : timeout)

        wsTimeoutId = setTimeout(() => {
          wsReconnectAttempts++
          Socket.open(true)
        }, delay)

        // eslint-disable-next-line no-console
        console.warn(`Socket closed. Reconnect will be attempted in ${ delay / 1000 } second(s).`)
      }

      // eslint-disable-next-line no-console
      // console.log('Socket closed.')
    }

    // Socket error
    ws.onerror = e => {
      // eslint-disable-next-line no-console
      console.error('Socket error.', e)
    }
  }
}

export default Socket
