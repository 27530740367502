import BaseResource                                   from '@/lib/data/resource/BaseResource'
import i18n                                           from '@/lang/lang'
import AlertPriorityEnum, { AlertPriorityEnumHelper } from '@/api/enums/AlertPriorityEnum'
import AlertTriggerConditionThresholdTypeEnum         from '@/api/enums/AlertTriggerConditionThresholdTypeEnum'

export default class DashboardAlertsListItemResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.IsDummy = payload.dummy ?? false
    this.Id = payload.id ?? ''
    this.Name = payload.name ?? ''
    this.Type = payload.alertType ? i18n.t(`Alerts.Section.History.AlertType.Type.${ payload.alertType }`) : ''
    this.Priority = payload.priority ?? AlertPriorityEnum.Low
    this.Threshold = payload.threshold
    this.DisplayThreshold = payload.displayThreshold ?? ''
    this.CurrentDistance = payload.currentDistance ?? ''
    this.ConditionType = payload.conditionType ?? ''
  }

  /* PROPERTIES */
  get Title () {
    return this.Name
  }

  get Value () {
    return this.DisplayThreshold || '0'
  }

  get Color () {
    return AlertPriorityEnumHelper.getColor(this.Priority)
  }

  get ChangeValue () {
    return `${ this.CurrentDistance }%`
  }

  get ChangeValueColor () {
    return this.ConditionType === AlertTriggerConditionThresholdTypeEnum.LowerLimit ? 'red' : 'green'
  }

  /* METHODS */
}
