<template>
  <v-card
    :class="{'active': activeAnalysis}"
    :height="210"
    class="pa-2 synthesis-card-border"
    flat
    v-on="enableCardClick ? {click: onCardClick} : {}"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="synthesis-inter-text text-size-12 synthesis-ui-grey-01--text pb-0">
          {{ title }}
        </div>
        <v-list-item-title class="synthesis-text text-size-32 synthesis-brand-dark-blue--text pt-0 pb-0">
          <span
            v-if="titlePrefix"
            class="pt-md-6 pt-3 pr-2 text-size-20 font-weight-medium synthesis-brand-dark-blue--text"
          >
            {{ titlePrefix }}
            <infotip
              v-if="titlePrefixTooltip"
              icon-style="margin-top: -26px"
            >{{ titlePrefixTooltip }}
            </infotip>
          </span>
          <span
            v-if="baseCurrencySymbol && !isBaseCurrencySymbolSingle"
            class="pt-4 pr-1 synthesis-text text-size-14 synthesis-brand-dark-blue--text"
            v-text="baseCurrencySymbol"
          />{{ baseCurrencySymbolSingle + titleValue }}
        </v-list-item-title>

        <v-list-item-subtitle class="pt-4">
          <template v-for="(tag, index) in tags">
            <v-chip
              :key="`tag-${index}`"
              :color="$vuetify.theme.themes.light['synthesis-ui-grey-05']"
              class="pa-1 mr-1 mb-1 synthesis-inter-text text-size-10 synthesis-ui-grey-01--text text-uppercase"
              label
              x-small
            >
              {{ tag }}
            </v-chip>
          </template>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        v-if="pieValues && pieValues.length"
        :size="120"
        class="ma-2 mr-0"
        tile
      >
        <base-e-chart-pie
          :axis-y-tick-prefix="baseCurrencySymbol"
          :chart-data-options="pieValues"
          :chart-height="130"
          :tooltip-show="true"
          chart-animation-easing="elasticOut"
          tooltip-confine
        />
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions class="pa-4">
      <span class="synthesis-inter-text text-size-10 synthesis-ui-grey-01--text">{{ dateCreated }}</span>

      <v-spacer />

      <template v-if="activeAnalysis">
        <div style="height: 36px;" />
      </template>

      <template>
        <v-menu
          v-if="$te('Risks.Tab.Archive.Card.MenuItems') && Array.isArray($t('Risks.Tab.Archive.Card.MenuItems')) && $t('Risks.Tab.Archive.Card.MenuItems').length"
          bottom
          offset-y
        >
          <template #activator="{on, attrs}">
            <v-btn
              color="grey"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-dots-horizontal
              </v-icon>
            </v-btn>
          </template>

          <v-list class="pa-0 ma-0">
            <template v-for="(menuItem, menuIndex) in $t('Risks.Tab.Archive.Card.MenuItems')">
              <v-list-item
                v-if="(activeAnalysis && menuItem.ActiveAnalysis) || !activeAnalysis"
                :key="`menu-item-${menuIndex}`"
                :disabled="!menuItem.Active || (menuItem.Event === 'archive-item-delete-button-click' && activeAnalysis)"
                @click="onCardMenuItemClick(menuItem)"
              >
                <v-list-item-title class="text-size-14">
                  {{ menuItem.Title }}
                </v-list-item-title>

                <v-list-item-action>
                  <v-icon
                    :color="menuItem.Active && !(menuItem.Event === 'archive-item-delete-button-click' && activeAnalysis) ? menuItem.Color : 'grey lighten-2'"
                  >
                    {{ menuItem.Icon }}
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>

        <v-btn
          v-else
          icon
          @click.stop="onCardEditClick"
        >
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>

import BaseEChartPie from '@/theme/default/components/charts/echarts/BaseEChartPie'
import Infotip       from '@/theme/default/components/common/Infotip'

export default {
  name      : 'RiskArchiveItemCard',
  components: {
    Infotip,
    BaseEChartPie
  },
  directives: {},
  mixins    : [],
  props     : {
    baseCurrencySymbol: {
      type   : String,
      default: ''
    },
    title: {
      type   : String,
      default: ''
    },
    titlePrefix: {
      type   : String,
      default: ''
    },
    titlePrefixTooltip: {
      type   : String,
      default: ''
    },
    titleValue: {
      type   : String,
      default: ''
    },
    dateCreated: {
      type   : String,
      default: ''
    },
    pieValues: {
      type   : Array,
      default: () => []
    },
    tags: {
      type   : Array,
      default: () => []
    },
    cardEvent: {
      type   : Boolean,
      default: false
    },
    activeAnalysis: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    isBaseCurrencySymbolSingle () {
      return this.baseCurrencySymbol.length === 1
    },

    baseCurrencySymbolSingle () {
      return this.isBaseCurrencySymbolSingle ? this.baseCurrencySymbol : ''
    },

    enableCardClick () {
      return this.cardEvent
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onCardClick () {
      this.$emit('card-click')
    },
    onCardEditClick () {
      this.$emit('card-edit-click')
    },
    onCardMenuItemClick (menuItem) {
      menuItem?.Event && this.$emit(menuItem.Event)
    }
  }
}
</script>

<style scoped>

</style>
