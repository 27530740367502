<template>
  <v-container
    class="fill-height align-start"
    fluid
  >
    <v-row
      class="fill-height"
      dense
    >
      <v-col
        class=""
        cols="12"
        lg="8"
        md="7"
        sm="12"
        xl="10"
      >
        <v-row>
          <section-header :breadcrumb-items="breadcrumbItems" />

          <v-col
            v-if="$vuetify.breakpoint.sm"
            class="py-0"
            cols="12"
          >
            <form-stepper-steps
              :step.sync="step"
              :steps="steps"
            />
          </v-col>

          <v-col
            :class="[{'pa-0': !stepHasCard}]"
            :cols="stepCols"
          >
            <v-card
              :color="stepBgColor"
              class="synthesis-card-border"
              flat
            >
              <v-card-text
                :class="[stepHasCard ? 'pa-sm-4' : 'pa-sm-0']"
                class="pa-0"
              >
                <v-form
                  ref="form"
                  v-model="isFormValid"
                  @submit.prevent
                >
                  <v-window v-model="step">
                    <v-window-item :value="1">
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12">
                            <label class="field-label">{{ $t('Positions.Edit.Form.Fields.Name.Label') }}</label>
                            <v-text-field
                              v-model="model.name"
                              :error-messages="serverErrorMessages['name']"
                              :placeholder="$t('Positions.Edit.Form.Fields.Name.Placeholder')"
                              :rules="model.validator.vuetifyFormFieldRules('name')"
                              outlined
                            />
                          </v-col>

                          <v-col cols="12">
                            <select-base-currency
                              v-model="model.baseCurrencyCode"
                              :disabled="model.trades.length > 0"
                              :error-messages="serverErrorMessages['baseCurrencyCode']"
                              :rules="model.validator.vuetifyFormFieldRules('baseCurrencyCode')"
                              :use-user-base-currency="$route.name !== 'PositionsEdit'"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-window-item>

                    <v-window-item :value="2">
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12">
                            <v-item-group
                              v-model="step2Model"
                              mandatory
                            >
                              <v-container
                                fluid
                                pa-0
                              >
                                <v-row>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-item
                                      v-slot="{active, toggle}"
                                      value="import"
                                    >
                                      <item-group-item-card
                                        :active="active"
                                        :item="{Title: 'Import Positions', InfoTip: 'Upload and import positions from a .csv file'}"
                                        :toggle="toggle"
                                        disabled
                                        large
                                      />
                                    </v-item>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-item
                                      v-slot="{active, toggle}"
                                      value="add"
                                    >
                                      <item-group-item-card
                                        :active="active"
                                        :item="{Title: 'Add New Positions', InfoTip: 'Manually add your positions'}"
                                        :toggle="toggle"
                                        large
                                      />
                                    </v-item>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-item-group>
                          </v-col>

                          <template v-if="step2Model === 'add'">
                            <v-col cols="12">
                              <trade-list-card
                                :items="model.tradesList"
                                :menu-items="$t('Positions.Edit.Form.Fields.Trades.MenuItems')"
                                @list-item-edit-button-click="onTradeListItemEditButtonClick"
                                @list-item-delete-button-click="onTradeListItemDeleteButtonClick"
                              />
                              <dashed-action-button
                                :disabled="isAddTradeDisabled"
                                :title="$t('Positions.Edit.Form.Fields.Trades.Label')"
                                @click="onAddTradeClick"
                              />
                            </v-col>
                          </template>
                        </v-row>
                      </v-container>
                    </v-window-item>

                    <v-window-item :value="3" />
                  </v-window>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        class="text-right"
        cols="12"
        lg="4"
        md="5"
        sm="12"
        xl="2"
      >
        <form-stepper
          :step.sync="step"
          :steps="steps"
          class="d-inline-flex"
          @click-btn-cancel="onStepperBtnCancelClick"
          @click-btn-back="onStepperBtnBackClick"
        />
      </v-col>
    </v-row>

    <add-position-dialog
      :base-currency-code="model.baseCurrencyCode"
      :item="selectedEditItem"
      :trades-list="model.trades"
      :visible.sync="tradesDialogVisible"
      component-lang="Positions"
      @form:save:success="onTradeSave"
      @dialog:close="onPositionDialogClose"
    />

    <overlay-loader :value="loaderVisible" />
  </v-container>
</template>

<script>

import i18nRouteMeta      from '@/mixins/i18n/i18nRouteMeta'
import SectionHeader      from '@/theme/default/components/common/SectionHeader'
import FormStepper        from '@/theme/default/components/form/formStepper/FormStepper'
import formStepper        from '@/theme/default/components/form/formStepper/mixins/formStepper'
import AddPositionDialog  from '@/theme/default/views/positions/AddPositionDialog'
import formData           from '@/mixins/form/formData'
import FormStepperSteps   from '@/theme/default/components/form/formStepper/components/FormStepperSteps'
import SelectBaseCurrency from '@/theme/default/components/form/SelectBaseCurrency'
import ItemGroupItemCard  from '@/theme/default/components/common/ItemGroupItemCard'
import DashedActionButton from '@/theme/default/components/common/DashedActionButton'
import PositionModel      from '@/api/models/positions/PositionModel'
import API                from '@/api/Api'
import OverlayLoader      from '@/theme/default/components/common/OverlayLoader'
import { clone }          from '@/lib/utils/helper'
import PositionsResource  from '@/api/resources/positions/PositionsResource'
import TradeListCard      from '@/theme/default/views/positions/TradeListCard'

export default {
  name      : 'PositionsNew',
  components: {
    TradeListCard,
    OverlayLoader,
    DashedActionButton,
    ItemGroupItemCard,
    SelectBaseCurrency,
    FormStepperSteps,
    AddPositionDialog,
    FormStepper,
    SectionHeader
  },
  directives: {},
  mixins    : [i18nRouteMeta, formData, formStepper],
  props     : {},
  enums     : {},
  dataStore : {
    positionsSummaryData: 'Positions.Summary.Data'
  },
  dataModel: PositionModel,
  data () {
    return {
      step2Model         : 'add',
      loaderVisible      : false,
      selectedTrade      : null,
      tradesDialogVisible: false,
      selectedEditItem   : null,
      breadcrumbItems    : [
        {
          text    : this.$t('Positions.Title'),
          disabled: false,
          exact   : true,
          to      : { name: 'Positions' }
        },
        {
          text    : this.$t('Positions.Edit.Title'),
          disabled: true
        }
      ]
    }
  },
  computed: {
    steps () {
      return [
        {
          Title   : this.$t('Positions.Edit.Form.Steps.Step1.Title'),
          Complete: this.step > 1,
          HasCard : true,
          BgColor : '#FFFFFF',
          Fields  : ['name', 'baseCurrencyCode']
        },
        {
          Title    : this.$t('Positions.Edit.Form.Steps.Step2.Title'),
          Complete : this.step > 2,
          HasCard  : false,
          BgColor  : 'transparent',
          Fields   : ['trades'],
          BtnAction: {
            Title         : this.$t('Positions.Edit.Form.Steps.Step2.Button.Action'),
            CallBack      : this.createPosition,
            PreventDefault: false
          }
        },
        {
          Title   : this.$t('Positions.Edit.Form.Steps.Step3.Title'),
          Complete: this.step > 3,
          HasCard : false,
          BgColor : 'transparent',
          Fields  : []
        }
      ]
    },

    isAddTradeDisabled () {
      return this.model.tradesList.length >= 10
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    if (this.$route.name === 'PositionsEdit' && !this.item) this.$router.replace({ name: 'Positions' })
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onStepperBtnBackClick () {},

    onStepperBtnCancelClick () {
      this.$router.push({ name: 'Positions' })
    },

    onTradeListItemEditButtonClick (exposureItem, index) {
      this.selectedEditItem = exposureItem.Exposure
      this.tradesDialogVisible = true
    },

    onTradeListItemDeleteButtonClick (exposureItem, index) {
      this.$root.$confirmDialog(
        this.$t('Positions.Edit.Form.Confirm.Delete.Title'),
        this.$t('Positions.Edit.Form.Confirm.Delete.Body'),
        {
          color : 'synthesis-ui-red-02',
          button: {
            no : { title: this.$t('Common.Button.Cancel') },
            yes: { title: this.$t('Common.Button.Delete') }
          }
        }
      ).then(response => {
        response && this.model.trades.splice(index, 1)
      })
    },

    onAddTradeClick () {
      this.tradesDialogVisible = true
    },

    onPositionDialogClose () {
      this.selectedEditItem = null
    },

    onTradeSave (data) {
      const tradeIndex = this.model.trades.findIndex(item => item.uuid === data.uuid)
      if (tradeIndex > -1) {
        this.model.trades[tradeIndex] = data
      } else {
        this.model.trades.push(data)
      }
    },

    createPosition () {
      this.model.accountId = this.user.selectedAccountId
      this.loaderVisible = true

      this.model.CreatePosition()
        .then(response => {
          this.handleCreatePositionResponse(response)
        })
        .catch(e => {
          this.handleCreatePositionResponse(e.response)
        })
        .finally(() => {
          this.loaderVisible = false
        })
    },
    handleCreatePositionResponse (response) {
      const data = API.responseData(response)?.addPositions || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const v = new PositionsResource(clone(data))
        // Store data to DataStore
        this.$set(this.positionsSummaryData, v.Id, v)
        this.$nextTick(() => {
          this.$router.push({
            name  : 'PositionsShow',
            params: {
              id          : v.Id,
              positionId  : v.Id,
              positionName: v.Name
            }
          })
        })
      } else {
        this.step--
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>

</style>
