import BaseResource from '@/lib/data/resource/BaseResource'

export default class RiskExtremeEventOptionResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Title = payload.eventName ?? ''
    this.Value = payload.eventCode ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
