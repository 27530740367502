<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-tabs
          v-model="activeTab"
          active-class="active-tab"
          background-color="transparent"
          height="30"
          hide-slider
          show-arrows
        >
          <v-tab :href="`#${$t('Hedging.Tab.Summary.Slug')}`">
            {{ $t('Hedging.Tab.Summary.Title') }}
          </v-tab>
          <v-tab :href="`#${$t('Hedging.Tab.Compare.Slug')}`">
            {{ $t('Hedging.Tab.Compare.Title') }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col
        class="pb-0"
        cols="12"
        md="6"
      >
        <v-container
          ma-0
          pa-0
        >
          <v-row
            v-if="activeTab === $t('Hedging.Tab.Summary.Slug')"
            class="ma-0"
          >
            <v-col class="text-right">
              <export-to-image
                key="export-to-image-strategy"
                :export-element="elementStrategy"
                :export-file-name="$t('Hedging.Tab.Summary.Title')"
                :text="$t('Common.Button.Export')"
                color="synthesis-ui-blue-04"
                color-hover="synthesis-ui-blue-03"
                dark
                large
                no-container-padding
                no-padding
                @export-to-image="exportToImage"
                @export-to-image-completed="exportToImageCompleted"
              />
            </v-col>
          </v-row>
        </v-container>

        <div
          v-if="activeTab === $t('Hedging.Tab.Compare.Slug')"
          class="d-flex d-inline-flex float-right"
        >
          <export-to-image
            key="export-to-image-compare"
            :export-element="elementCompare"
            :export-file-name="$t('Hedging.Section.Compare.ExportTitle')"
            :text="$t('Common.Button.Export')"
            class="pr-2"
            color="synthesis-ui-blue-04"
            color-hover="synthesis-ui-blue-03"
            dark
            large
            no-container-padding
            no-margin
            no-padding
            @export-to-image="exportToImage"
            @export-to-image-completed="exportToImageCompleted"
          />
          <v-btn
            v-if="compare"
            class="synthesis-text text-size-14 white--text"
            color="synthesis-ui-green-01"
            depressed
            height="42"
            large
            @click="onAddEditStrategyClick"
            v-text="hasStrategyProvided ? $t('Hedging.Button.EditCurrentStrategy.Title') : $t('Hedging.Button.AddCurrentStrategy.Title')"
          />
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        class="pa-0"
        cols="12"
      >
        <v-tabs-items v-model="activeTab">
          <v-tab-item :value="$t('Hedging.Tab.Summary.Slug')">
            <hedging-summary-block
              v-if="activeTab === $t('Hedging.Tab.Summary.Slug')"
              ref="hedgingStrategy"
              :chart-animation="chartAnimation"
              :disable-lazy="disableLazy"
              :policy-id="id"
            />
          </v-tab-item>

          <v-tab-item :value="$t('Hedging.Tab.Compare.Slug')">
            <hedging-compare-block
              v-if="activeTab === $t('Hedging.Tab.Compare.Slug')"
              ref="hedgingCompare"
              :chart-animation="chartAnimation"
              :disable-lazy="disableLazy"
              :policy-id="id"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <add-current-strategy-dialog
      :item="compare"
      :visible.sync="currentStrategyDialogVisible"
      component-lang="Hedging"
      @form:save:success="onCurrentStrategySave"
    />
  </v-container>
</template>

<script>

import policyId                 from '@/theme/default/views/hedging/mixins/policyId'
import HedgingCompareBlock      from '@/theme/default/views/hedging/blocks/HedgingCompareBlock'
import HedgingSummaryBlock      from '@/theme/default/views/hedging/blocks/HedgingSummaryBlock'
import routerTabState           from '@/mixins/routerTabState'
import AddCurrentStrategyDialog from '@/theme/default/views/hedging/edit/AddCurrentStrategyDialog'
import API                      from '@/api/Api'
import { clone }                from '@/lib/utils/helper'
import HedgingCompareResource   from '@/api/resources/hedging/HedgingCompareResource'
import ExportToImage            from '@/theme/default/components/common/ExportToImage'

export default {
  name      : 'HedgingStrategyShowBase',
  components: {
    ExportToImage,
    AddCurrentStrategyDialog,
    HedgingSummaryBlock,
    HedgingCompareBlock
  },
  directives: {},
  mixins    : [policyId, routerTabState],
  props     : {},
  dataStore : {
    hedgingCompareData: 'Hedging.Compare.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      currentStrategyDialogVisible: false,
      elementStrategy             : null,
      elementCompare              : null,
      chartAnimation              : true,
      disableLazy                 : false
    }
  },
  computed: {
    compare () {
      return this.hedgingCompareData[this.id] ?? null
    },

    hasStrategyProvided () {
      return this.compare?.CurrentStrategyProvided ?? false
    }
  },
  watch: {
    activeTab: {
      handler (val) {
        val && this.storeElements()
      },
      immediate: true
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onAddEditStrategyClick () {
      this.currentStrategyDialogVisible = true
    },

    onCurrentStrategySave (data) {
      API.Resource.Hedging.UpdateCurrentStrategy(data)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },
    handleResponse (response) {
      const data = API.responseData(response)?.updateCurrentStrategy || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const o = clone(data)
        const v = new HedgingCompareResource(o)

        // Store data to DataStore
        this.$set(this.hedgingCompareData, this.id, v)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    storeElements () {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.elementStrategy = this.$refs?.hedgingStrategy?.$el
            this.elementCompare = this.$refs?.hedgingCompare?.$el
          })
        })
      })
    },

    exportToImage () {
      this.chartAnimation = false
      this.disableLazy = true
    },

    exportToImageCompleted () {
      this.chartAnimation = true
    }
  }
}
</script>

<style scoped>

</style>
