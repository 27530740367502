import { API } from '@/api/Api'

import {
  AlertIncidentAcknowledgeMutation,
  AlertsActiveQuery,
  AlertsArchiveQuery,
  AlertsDashboardQuery,
  AlertSummaryQuery,
  CreateAlertAssetPriceMutation,
  CreateAlertPortfolioMTMMutation,
  CreateAlertTradeMTMMutation,
  DeleteAlertMutation,
  ListActiveIncidentsQuery,
  ListPositionsWithActiveTradesQuery,
  ListTradesQuery,
  UpdateAlertDefinitionMutation
} from '@/api/query/Alerts.graphql'

export default {
  // Queries
  async AlertsDashboard (accountId) {
    const Data = { accountId: accountId }

    return await API.GQLRequest(AlertsDashboardQuery, Data)
  },

  async Active (accountId) {
    const Data = { accountId: accountId }

    return await API.GQLRequest(AlertsActiveQuery, Data)
  },

  async ActiveIncidents (accountId) {
    const Data = { accountId: accountId }

    return await API.GQLRequest(ListActiveIncidentsQuery, Data)
  },

  async Archive (data) {
    const Data = { ...data }

    return await API.GQLRequest(AlertsArchiveQuery, Data)
  },

  async Acknowledge (incidentId) {
    const Data = { incidentId: incidentId }

    return await API.GQLRequest(AlertIncidentAcknowledgeMutation, Data)
  },

  async Summary (alertId) {
    const Data = { alertId: alertId }

    return await API.GQLRequest(AlertSummaryQuery, Data)
  },

  async Trades (accountId) {
    const Data = { accountId: accountId }

    return await API.GQLRequest(ListTradesQuery, Data)
  },

  async PositionsWithActiveTrades (accountId) {
    const Data = { accountId: accountId }

    return await API.GQLRequest(ListPositionsWithActiveTradesQuery, Data)
  },

  // Mutations
  async CreateAlertAssetPrice (data) {
    const Data = { ...data }

    return await API.GQLRequest(CreateAlertAssetPriceMutation, Data)
  },

  async CreateAlertTradeMTM (data) {
    const Data = { ...data }

    return await API.GQLRequest(CreateAlertTradeMTMMutation, Data)
  },

  async CreateAlertPortfolioMTM (data) {
    const Data = { ...data }

    return await API.GQLRequest(CreateAlertPortfolioMTMMutation, Data)
  },

  async UpdateAlertDefinition (data) {
    const Data = { ...data }

    return await API.GQLRequest(UpdateAlertDefinitionMutation, Data)
  },

  async DeleteAlert (alertId) {
    const Data = { alertId: alertId }

    return await API.GQLRequest(DeleteAlertMutation, Data)
  }
}
