<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <block-item-card>
          <v-card-title
            class="text-size-20 font-weight-medium synthesis-brand-dark-blue--text"
          >
            {{ $t('Industry.Attributes.Financial.Title') }}
            <infotip
              v-if="$t('Industry.Attributes.Financial.InfoTip')"
              icon-class="ml-1"
              icon-style="margin-top: -6px"
            >
              {{ $t('Industry.Attributes.Financial.InfoTip') }}
            </infotip>
          </v-card-title>

          <v-card-text>
            <group-item-radio
              v-model="model"
              :items="financial"
              :multiple="true"
              :sm="12"
              class="pb-3"
              col-class="pb-0"
              item-label-name="Name"
              item-value-name="Id"
              row-class="ma-0"
            />
          </v-card-text>
        </block-item-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <block-item-card>
          <v-card-title
            class="text-size-20 font-weight-medium synthesis-brand-dark-blue--text"
          >
            {{ $t('Industry.Attributes.Hedging.Title') }}
            <infotip
              v-if="$t('Industry.Attributes.Hedging.InfoTip')"
              icon-class="ml-1"
              icon-style="margin-top: -6px"
            >
              {{ $t('Industry.Attributes.Hedging.InfoTip') }}
            </infotip>
          </v-card-title>
          <v-card-text>
            <group-item-radio
              v-model="model"
              :items="hedging"
              :multiple="true"
              :sm="12"
              class="pb-3"
              col-class="pb-0"
              item-label-name="Name"
              item-value-name="Id"
              row-class="ma-0"
            />
          </v-card-text>
        </block-item-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import BlockItemCard      from '@/theme/default/components/common/BlockItemCard'
import API                from '@/api/Api'
import { clone }          from '@/lib/utils/helper'
import AttributesResource from '@/api/resources/industry/AttributesResource'
import GroupItemRadio     from '@/theme/default/components/form/GroupItemRadio'
import Infotip            from '@/theme/default/components/common/Infotip'

export default {
  name      : 'ComparisonAttributes',
  components: {
    Infotip,
    GroupItemRadio,
    BlockItemCard
  },
  directives: {},
  mixins    : [],
  props     : {
    value: {
      type   : Array,
      default: () => []
    }
  },
  dataStore: {
    industryAttributesData: 'Industry.Attributes.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    attributes () {
      return this.industryAttributesData ?? []
    },

    financial () {
      return this.attributes?.Financial || []
    },

    hedging () {
      return this.attributes?.Hedging || []
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Industry.Attributes()
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },

    handleResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const o = clone(data)

        this.industryAttributesData = new AttributesResource(o)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>

</style>
