import { API } from '@/api/Api'

import { MavenAdvisoryRequestMutation, SynthesisSupportRequestMutation } from '@/api/query/Help.graphql'

export default {
  // Mutations
  async MavenAdvisoryRequest (data) {
    const Data = { ...data }

    return await API.GQLRequest(MavenAdvisoryRequestMutation, Data)
  },

  async SynthesisSupportRequest (data) {
    const Data = { ...data }

    return await API.GQLRequest(SynthesisSupportRequestMutation, Data)
  }
}
