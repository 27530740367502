import BaseResource from '@/lib/data/resource/BaseResource'
import i18n         from '@/lang/lang'
import { clone }    from '@/lib/utils/helper'

export default class RiskDebtCreditMetricsResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Debt = payload?.debt ?? null
    this.Equity = payload?.equity ?? null
    this.Headers = payload?.headers ?? []
    this.DebtToEbitda = payload?.debtToEbitda ?? []
    this.EbitdaToInterest = payload?.ebitdaToInterest ?? []
  }

  /* PROPERTIES */
  get PieValues () {
    return [
      {
        name : i18n.t('Risks.Section.DebtProfile.Block.CreditMetrics.Pie.Debt.Title'),
        value: this.Debt,
        type : 'number'
      },
      {
        name : i18n.t('Risks.Section.DebtProfile.Block.CreditMetrics.Pie.Equity.Title'),
        value: this.Equity,
        type : 'number'
      }
    ]
  }

  get TableHeaders () {
    const headers = [{
      text     : '',
      align    : 'start',
      width    : 230,
      value    : 'Name',
      sortable : false,
      class    : 'synthesis-inter-text text-size-12 synthesis-brand-dark-blue--text',
      cellClass: 'text-size-14 synthesis-brand-dark-blue--text'
    }]

    const headerValues = clone(this.Headers)

    if (headerValues && !!headerValues.length) {
      headerValues.forEach((name, value) => {
        headers.push({
          text     : name,
          align    : 'right',
          sortable : false,
          value    : value.toString(),
          class    : 'synthesis-inter-text text-size-14 synthesis-brand-dark-blue--text',
          cellClass: 'text-size-14 synthesis-brand-dark-blue--text'
        })
      })
    }

    return headers
  }

  get TableValues () {
    const values = []

    // DebtToEbitda
    const debtToEbitdaValues = clone(this.DebtToEbitda)

    if (debtToEbitdaValues && !!debtToEbitdaValues.length) {
      const row = { Name: i18n.t('Risks.Section.DebtProfile.Block.CreditMetrics.Table.DebtToEbitda.Title') }

      debtToEbitdaValues.forEach((v, key) => {
        row[key] = v + 'x'
      })

      values.push(row)
    }

    // EbitdaToInterest
    const ebitdaToInterestValues = clone(this.EbitdaToInterest)

    if (ebitdaToInterestValues && !!ebitdaToInterestValues.length) {
      const row = { Name: i18n.t('Risks.Section.DebtProfile.Block.CreditMetrics.Table.EbitdaToInterest.Title') }

      ebitdaToInterestValues.forEach((v, key) => {
        row[key] = v + 'x'
      })

      values.push(row)
    }

    return values
  }

  /* METHODS */
}
