import BaseResource from '@/lib/data/resource/BaseResource'

export default class ActiveAnalysesRiskSummariesResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.riskAnalysisId = payload.riskAnalysisId ?? null
    this.label = payload.label ?? ''
    this.hedgeRatios = payload.hedgeRatios ?? {}
    this.selectedItem = payload.selectedItem ?? null
    this.isTypeSummary = true
  }

  /* PROPERTIES */

  /* METHODS */
}
