export default {
  Title: 'Industry',

  Message: {
    NoData: {
      Title: 'No Comparisons currently setup'
    }
  },

  Search: {
    Category: {
      Title: 'Search by Industry'
    }
  },

  Tab: {
    Archive: {
      Title  : 'My Comparisons',
      Slug   : 'my-comparisons',
      Confirm: {
        Delete: {
          Title: 'Delete Comparison?',
          Body : 'Are you sure you want to delete <span class="grey--text">"{Name}"</span>?'
        }
      },
      Card: {
        MenuItems: [
          {
            Event : 'list-item-export-button-click',
            Title : 'Export',
            Icon  : 'mdi-download',
            Color : 'synthesis-ui-grey-01',
            Active: false
          },
          {
            Event : 'archive-item-delete-button-click',
            Title : 'Delete',
            Icon  : 'mdi-trash-can',
            Color : 'synthesis-ui-red-05',
            Active: true
          }
        ]
      }
    }
  },

  Attributes: {
    Financial: {
      Title      : 'Financial Attributes',
      InfoTip    : 'Income Statement figures refer to the last twelve months based on public financials. Balance sheet figures refer to the latest available financial statement in the public domain.',
      Revenues   : 'Revenues',
      EBITDA     : 'EBITDA',
      Earnings   : 'Earnings',
      Debt       : 'Debt',
      ESGScore   : 'ESG Score',
      DebtEBITDA : 'Debt/EBITDA',
      DebtCapital: 'Debt/Capital',
      IntRevenues: '% Int’l Revenues'
    },
    Hedging: {
      Title     : 'Hedging Attributes',
      InfoTip   : 'Hedging attributes are based on public sources including annual reports, 10-K, 10-Q, public interviews etc.',
      Currencies: {
        Exposures    : 'Exposures',
        Hedges       : 'Hedges',
        HedgingPolicy: 'Hedging Policy'
      },
      Commodities: {
        Exposures    : 'Exposures',
        Hedges       : 'Hedges',
        HedgingPolicy: 'Hedging Policy'
      }
    }
  },

  Tag: {
    Companies : 'Companies: {Count}',
    Attributes: 'Attributes: {Count}'
  },

  Label: {
    Created: {
      Title: 'Created'
    },
    SelectedCompanies: {
      Title: 'Selected Companies'
    }
  },

  Button: {
    New: {
      Title: '@:Common.Button.New Comparison'
    },
    Expand: {
      Title: '@:Common.Button.Expand all fields'
    },
    Retract: {
      Title: '@:Common.Button.Retract all fields'
    }
  },

  Table: {
    Headers: {
      Company        : 'Company',
      Industry       : 'Industry',
      Name           : 'Name',
      KeyExposures   : 'Key Exposures',
      HedgedExposures: 'Hedged Exposures',
      HedgeRatio     : 'Hedge Ratio',
      Debt           : 'Debt'
    }
  },

  New: {
    Title: '@:Common.Button.New Comparison',
    Form : {
      Steps: {
        Step1: {
          Title: 'Add Companies'
        },
        Step2: {
          Title: 'Add Attributes'
        },
        Step3: {
          Title : 'Save Comparison',
          Button: {
            Action: 'Save Comparison',
            Expand: 'Expand all fields'
          }
        }
      },
      Fields: {
        Search: {
          Label      : 'Search',
          Placeholder: 'Search'
        },
        Name: {
          Label      : 'Comparison Name',
          Placeholder: 'Enter comparison name'
        }
      }
    }
  }
}
