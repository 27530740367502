import BaseResource from '@/lib/data/resource/BaseResource'

export default class ResetPasswordResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.newPassword = payload.Password ?? null
    this.newPasswordConfirmation = payload.ConfirmPassword ?? ''
    this.token = payload.Token ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
