import BaseModel                      from '@/lib/data/model/BaseModel'
import SynthesisSupportInputValidator from '@/api/validations/help/SynthesisSupportInputValidator'
import SynthesisSupportInputResource  from '@/api/resources/help/SynthesisSupportInputResource'
import API                            from '@/api/Api'

export default class SynthesisSupportInputModel extends BaseModel {
  constructor (payload = {}, resource = SynthesisSupportInputResource, validator = SynthesisSupportInputValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
  async SendRequest () {
    return await API.Resource.Help.SynthesisSupportRequest(this.clone())
  }
}
