export default {
  Title: 'Account Settings',

  Button: {
    Save: 'Save Details'
  },

  Section: {
    PersonalDetails: {
      Title : 'Personal Details',
      Fields: {
        Photo: {
          Label: 'Photo'
        },
        Name: {
          Label      : 'Name',
          Placeholder: 'Enter your name'
        },
        Email: {
          Label      : 'Email',
          Placeholder: 'Enter your email'
        }
      }
    },

    Password: {
      Title : 'Password',
      Fields: {
        OldPassword: {
          Label      : 'Old Password',
          Placeholder: 'Enter old password'
        },
        NewPassword: {
          Label      : 'New Password',
          Placeholder: 'Enter new password'
        },
        ConfirmPassword: {
          Label      : 'Confirm New Password',
          Placeholder: 'Confirm new password'
        }
      }
    },

    Notifications: {
      Title : 'Notification Preferences',
      Fields: {
        Title           : 'Email me when:',
        AlertIsTriggered: {
          Label: 'An alert is triggered'
        },
        NewTutorialsAdded: {
          Label: 'New tutorials or Knowledge Centre documents are added'
        },
        CompanyNewsAndUpdates: {
          Label: 'Company news and updates'
        },
        NewQuotesAdded: {
          Label: 'New stocks, commodities and currencies are added to the live prices list'
        },
        WeeklyUpdatesRiskPositions: {
          Label: 'Weekly Updates on Risk & Positions'
        }
      }
    }
  }
}
