import BaseResource                     from '@/lib/data/resource/BaseResource'
import RiskDebtAmortisationTypeEnumEnum from '@/api/enums/RiskDebtAmortisationTypeEnumEnum'

export default class RiskDeptFloatingInputResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.id = payload.id ?? null
    this.name = payload.name ?? ''
    this.maturity = payload.maturity ?? null
    this.notional = payload.notional ?? null
    this.spread = payload.spread ?? null
    this.amortisationType = payload.amortisationType ?? RiskDebtAmortisationTypeEnumEnum.ANNUALLY
    this.amortisationAnnually = payload.amortisationAnnually ?? []
    this.amortisationQuarterly = payload.amortisationQuarterly ?? []
    this.amortisation = payload.amortisation?.length ? payload.amortisation : payload.amortisationType === RiskDebtAmortisationTypeEnumEnum.ANNUALLY ? this.amortisationAnnually : this.amortisationQuarterly
  }

  /* PROPERTIES */

  /* METHODS */
}
