var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tableValues)?_c('table-header-gradient-blue',{scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('v-data-table',{staticClass:"font-weight-medium synthesis-card-border",attrs:{"headers":_vm.table.headers,"items":_vm.tableValues,"disable-pagination":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header.Forwards",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(header.tip)?_c('infotip',{attrs:{"icon-style":"margin-top: -12px;"}},[_vm._v(" "+_vm._s(header.tip)+" ")]):_vm._e()]}},{key:"header.Options",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(header.tip)?_c('infotip',{attrs:{"icon-style":"margin-top: -12px;"}},[_vm._v(" "+_vm._s(header.tip)+" ")]):_vm._e()]}},{key:"header.Collars",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(header.tip)?_c('infotip',{attrs:{"icon-style":"margin-top: -12px;"}},[_vm._v(" "+_vm._s(header.tip)+" ")]):_vm._e()]}},{key:"header.BestInstrument",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(header.tip)?_c('infotip',{attrs:{"icon-style":"margin-top: -12px;"}},[_vm._v(" "+_vm._s(header.tip)+" ")]):_vm._e()]}},{key:"item.Forwards",fn:function(ref){
var item = ref.item;
return [(item.HasForwards && item.BaseCurrency && !_vm.isBaseCurrencySymbolSingle(item.BaseCurrency))?_c('span',{staticClass:"pr-1 text-size-10 synthesis-brand-dark-blue--text",staticStyle:{"margin-top":"2px"},domProps:{"textContent":_vm._s(item.BaseCurrency)}}):_vm._e(),_vm._v(_vm._s(item.HasForwards ? _vm.baseCurrencySymbolSingle(item.BaseCurrency) + item.Forwards : '-')+" ")]}},{key:"item.Options",fn:function(ref){
var item = ref.item;
return [(item.HasOptions && item.BaseCurrency && !_vm.isBaseCurrencySymbolSingle(item.BaseCurrency))?_c('span',{staticClass:"pr-1 text-size-10 synthesis-brand-dark-blue--text",staticStyle:{"margin-top":"2px"},domProps:{"textContent":_vm._s(item.BaseCurrency)}}):_vm._e(),_vm._v(_vm._s(item.HasOptions ? _vm.baseCurrencySymbolSingle(item.BaseCurrency) + item.Options : '-')+" ")]}},{key:"item.Collars",fn:function(ref){
var item = ref.item;
return [(item.HasCollars && item.BaseCurrency && !_vm.isBaseCurrencySymbolSingle(item.BaseCurrency))?_c('span',{staticClass:"pr-1 text-size-10 synthesis-brand-dark-blue--text",staticStyle:{"margin-top":"2px"},domProps:{"textContent":_vm._s(item.BaseCurrency)}}):_vm._e(),_vm._v(_vm._s(item.HasCollars ? _vm.baseCurrencySymbolSingle(item.BaseCurrency) + item.Collars : '-')+" ")]}},{key:"item.BestInstrument",fn:function(ref){
var item = ref.item;
return [(item.BestInstrument)?_c('v-chip',{attrs:{"color":item.BestInstrumentColor,"text-color":item.BestInstrumentTextColor}},[_vm._v(" "+_vm._s(item.BestInstrument)+" ")]):_vm._e()]}}],null,false,4222423105)})]},proxy:true}],null,false,3360412419)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }