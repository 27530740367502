<template>
  <v-card
    v-if="strategy"
    :min-height="250"
    class="synthesis-card-border"
    flat
  >
    <v-card-title
      class="pb-0 text-size-20 font-weight-medium synthesis-brand-dark-blue--text"
      v-text="strategy.Name"
    />
    <div class="d-flex flex-no-wrap justify-space-between">
      <v-row class="ma-0">
        <v-col
          class="pa-0"
          style="min-width: 104px"
        >
          <v-card-text class="synthesis-inter-text text-size-10 synthesis-ui-grey-01--text">
            {{ $t('Hedging.Card.Current.Title') }}
            <infotip
              v-if="$t('Hedging.Card.Current.InfoTip')"
              icon-style="margin-top: -6px"
            >
              {{ $t('Hedging.Card.Current.InfoTip') }}
            </infotip>
            <br>
            <div
              class="pt-1 text-size-18 font-weight-medium synthesis-brand-dark-blue--text"
              v-text="strategy.Current"
            />
          </v-card-text>
          <v-card-text class="synthesis-inter-text text-size-10 synthesis-ui-grey-01--text">
            {{ $t('Hedging.Card.Optimal.Title') }}
            <infotip
              v-if="$t('Hedging.Card.Optimal.InfoTip')"
              icon-style="margin-top: -6px"
            >
              {{ $t('Hedging.Card.Optimal.InfoTip') }}
            </infotip>
            <br>
            <div
              class="pt-1 text-size-18 font-weight-medium synthesis-brand-dark-blue--text"
              v-text="strategy.Optimal"
            />
          </v-card-text>
          <v-card-text class="synthesis-inter-text text-size-10 synthesis-ui-grey-01--text">
            {{ $t('Hedging.Card.Industry.Title') }}
            <infotip
              v-if="$t('Hedging.Card.Industry.InfoTip')"
              icon-style="margin-top: -6px"
            >
              {{ $t('Hedging.Card.Industry.InfoTip') }}
            </infotip>
            <br>
            <div
              class="pt-1 text-size-18 font-weight-medium synthesis-brand-dark-blue--text"
              v-text="strategy.Industry"
            />
          </v-card-text>
        </v-col>
      </v-row>
      <v-card-text class="pt-1 pr-0">
        <v-lazy
          :options="{threshold: .5}"
          :value="disableLazy"
          transition="fade-transition"
        >
          <e-chart-hedging-compare-instrument-bar
            :bar-background-color="themeColorRGBA('synthesis-ui-grey-04', 0.3)"
            :chart-animation="chartAnimation"
            :chart-data-options="strategy.BarValues"
            :chart-height="200"
            axis-y-tick-suffix="%"
            chart-animation-easing="quinticInOut"
          />
        </v-lazy>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>

import chartsColorMixin                  from '@/theme/default/components/charts/mixins/chartsColorMixin'
import EChartHedgingCompareInstrumentBar from '@/theme/default/components/charts/echarts/EChartHedgingCompareInstrumentBar'
import Infotip                           from '@/theme/default/components/common/Infotip'
import useExportAdditional               from '@/mixins/useExportAdditional'

export default {
  name      : 'HedgingInstrumentCompareCard',
  components: {
    Infotip,
    EChartHedgingCompareInstrumentBar
  },
  directives: {},
  mixins    : [chartsColorMixin, useExportAdditional],
  props     : {
    strategy: {
      type   : Object,
      default: () => {}
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
