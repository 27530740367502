<template>
  <e-chart
    :ref="`echart${chartRefId}`"
    :autoresize="true"
    :option="getChartOptions"
    :style="`height: ${getChartHeight}px;`"
    class="chart"
  />
</template>

<script>
import baseEChartPieProps from '@/theme/default/components/charts/echarts/mixins/pie/baseEChartPieProps'
import baseEChartPieData  from '@/theme/default/components/charts/echarts/mixins/pie/baseEChartPieData'
import baseEChartColors   from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'
import chartsColorMixin   from '@/theme/default/components/charts/mixins/chartsColorMixin'

import { use }                                              from 'echarts/core'
import { PieChart }                                         from 'echarts/charts'
import { GridComponent, LegendComponent, TooltipComponent } from 'echarts/components'
import { LabelLayout }                                      from 'echarts/features'
import { CanvasRenderer }                                   from 'echarts/renderers'
import ChartsStyleEnum                                      from '@/api/enums/ChartsEnum'
import { toDecimalMark, toNumberFormatNotationShort }       from '@/lib/utils/helper'
import dayjs                                                from 'dayjs'
import chartsBaseCurrencySymbolSingle                       from '@/theme/default/components/charts/mixins/chartsBaseCurrencySymbolSingle'

use([
  GridComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
])

export default {
  name  : 'BaseEChartPie',
  enums : { ChartsStyleEnum },
  mixins: [baseEChartColors, chartsColorMixin, chartsBaseCurrencySymbolSingle, baseEChartPieProps, baseEChartPieData],
  data () {
    return {
      // echart: null
    }
  },
  computed: {
    getChartGridMargin () {
      return this.chartGridMargin || {
        left        : 0,
        top         : 0,
        right       : 0,
        bottom      : 0,
        containLabel: false
      }
    },

    getChartDataOptions () {
      return {
        animation         : this.chartAnimation,
        animationThreshold: this.chartAnimationThreshold,
        animationDuration : this.chartAnimationDuration,
        animationEasing   : this.chartAnimationEasing,
        tooltip           : {
          trigger        : 'item',
          show           : this.tooltipShow,
          confine        : this.tooltipConfine,
          backgroundColor: this.themeColorRGBA(this.tooltipBackgroundColor, 0.8),
          borderColor    : this.tooltipBorderColor,
          borderWidth    : this.tooltipBorderWidth,
          padding        : this.tooltipPadding,
          textStyle      : {
            color: this.tooltipLabelColor
          }
        },
        legend: {
          show     : this.legendShow,
          orient   : this.legendOrient,
          top      : this.legendTop,
          bottom   : this.legendBottom,
          left     : this.legendLeft,
          right    : this.legendRight,
          align    : this.legendAlign,
          padding  : this.legendPadding,
          data     : this.legendData,
          textStyle: {
            fontFamily: this.pieLegendFontFamily,
            fontSize  : this.pieLegendFontSize,
            color     : this.pieLegendFontColor,
            fontStyle : this.pieLegendFontStyle,
            fontWeight: this.pieLegendFontWeight
          }
        },
        color    : this.defaultColors ?? this.colors,
        textStyle: {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid  : this.getChartGridMargin,
        series: this.series
      }
    },

    series () {
      // const chartWidth = this.echart && this.echart.getWidth()
      const tooltipValuePrefix = this.isBaseCurrencySymbolSingle ? '' : '<span class="pt-4 pr-1 text-size-12">' + this.axisYTickPrefix + '</span>'
      const tooltipValueSuffix = this.isBaseCurrencySymbolSingle ? '' : '<span class="pt-4 pr-1 text-size-12">' + this.axisYTickSuffix + '</span>'
      const labelValuePrefix = this.isBaseCurrencySymbolSingle ? '' : `{currency|${ this.axisYTickPrefix }}`

      let values = []

      if (this.chartDataOptions.length) {
        values = this.chartDataOptions
      }
      return {
        type        : 'pie',
        // roseType: 'radius',
        top         : this.pieTop,
        left        : this.pieLeft,
        right       : this.pieRight,
        bottom      : this.pieBottom,
        width       : this.pieWidth,
        height      : this.pieHeight,
        center      : this.pieCenter,
        radius      : this.pieRadius,
        selectedMode: 'multiple',
        tooltip     : {
          formatter: params => {
            const type = params.data.type
            let value = params.value

            if (type === 'number') {
              value = toNumberFormatNotationShort(parseFloat(value))
            }
            if (type === 'percent') {
              value = toDecimalMark(parseFloat(value))
            }
            if (type === 'date') {
              value = dayjs(value).format(this.axisXLabelValueDateFormat)
            }

            return `<div class="pr-4"><span class="mr-2">${ params.marker } ${ params.name }</span><br />${ tooltipValuePrefix }<span class="ml-0 synthesis-text text-size-18 font-weight-regular">${ this.baseCurrencySymbolSingle }${ value }${ tooltipValueSuffix }</span></div>`
          }
        },
        itemStyle: {
          borderColor : this.pieBorderColor,
          borderWidth : this.pieBorderWidth,
          borderRadius: this.pieBorderRadius
        },
        label: {
          show      : this.labelShow,
          alignTo   : 'edge',
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight,
          position  : this.labelPosition,
          rich      : {
            asset: {
              color     : this.fontColor,
              fontSize  : 12,
              fontWeight: 400
            },
            currency: {
              color     : this.fontColor,
              fontSize  : 10,
              fontWeight: 400,
              padding   : [1, 4, 0, 0],
              align     : 'left'
            },
            assetValue: {
              color     : this.pieAssetValueFontColor,
              fontSize  : this.pieAssetValueFontSize,
              fontWeight: this.pieAssetValueFontWeight,
              align     : this.pieAssetValueAlign
            }
          },
          formatter: params => {
            const type = params.data.type
            let value = params.value

            if (type === 'number') {
              value = toNumberFormatNotationShort(parseFloat(value))
            }
            if (type === 'percent') {
              value = toDecimalMark(parseFloat(value))
            }
            if (type === 'date') {
              value = dayjs(value).format(this.axisXLabelValueDateFormat)
            }

            const asset = this.labelPieAsset ? `{asset|${ params.name }}\n` : ''
            const val = params.value > 0 ? `${ this.baseCurrencySymbolSingle }${ value }${ this.axisYTickSuffix }` : ''

            return `${ asset }${ labelValuePrefix }{assetValue|${ val }}`
          },
          minMargin   : 5,
          edgeDistance: 10,
          lineHeight  : 18
        },
        labelLine: {
          show           : this.labelLineShow,
          length         : 15,
          length2        : 0,
          maxSurfaceAngle: 80
        },
        /* labelLayout: function (params) {
          const isLeft = params.labelRect.x < chartWidth
          const points = params.labelLinePoints

          points[2][0] = isLeft
            ? params.labelRect.x
            : params.labelRect.x + params.labelRect.width
          return {
            labelLinePoints: points
          }
        }, */
        cursor: this.cursor,
        data  : values
      }
    }
  },
  mounted () {
    /* this.$nextTick(() => {
      this.echart = this.$refs[`echart${ this.chartRefId }`]
    }) */
  }
}
</script>

<style scoped>
.chart {
  width: 100%;
}
</style>
