import BaseValidator from '@/lib/data/validator/BaseValidator'
import AlertTypeEnum from '@/api/enums/AlertTypeEnum'

export default class AlertInputValidator extends BaseValidator {
  validatorRules = {
    accountId: {
      required: true,
      type    : BaseValidator.Type.String
    },
    name: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 3
    },
    description: {
      required  : false,
      type      : BaseValidator.Type.String,
      betweenLen: [3, 250]
    },
    type: {
      required: true,
      type    : BaseValidator.Type.String
    },
    priority: {
      required: true,
      type    : BaseValidator.Type.String
    },
    baseCurrencyCode: {
      required: true,
      type    : BaseValidator.Type.String
    },
    expirationDate: {
      required: false,
      type    : BaseValidator.Type.String
    },
    enableEmailNotifications: {
      required: true,
      type    : BaseValidator.Type.Boolean
    },
    triggers: {
      required: true,
      type    : BaseValidator.Type.Array,
      minLen  : 1
    },
    assetPriceTriggers: {
      requiredIf: ['type', AlertTypeEnum.AssetPrice],
      type      : BaseValidator.Type.Array,
      minLen    : 1
    },
    positionMtmTriggers: {
      requiredIf: ['type', AlertTypeEnum.PositionMTM],
      type      : BaseValidator.Type.Array,
      minLen    : 1
    },
    portfolioMtmTriggers: {
      requiredIf: ['type', AlertTypeEnum.PortfolioMTM],
      type      : BaseValidator.Type.Array,
      minLen    : 1
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
