<template>
  <block-item-card
    ref="debtProfileCardEdit"
    class="pt-0"
    :show-background="false"
    :show-border="false"
  >
    <v-form
      ref="form"
      v-model="isFormValid"
      @submit.prevent
    >
      <v-toolbar
        :class="toolbarClassForExport()"
        :style="toolbarStyleForExport()"
        flat
      >
        <v-toolbar-title class="synthesis-text text-size-28 font-weight-medium synthesis-brand-dark-blue--text">
          {{ $t('Risks.Section.DebtProfile.Edit.Title') }}
        </v-toolbar-title>
        <v-row>
          <v-col>
            <export-to-image
              v-if="elementDebtProfileEdit"
              row-padding="pt-3"
              large
              :export-element="elementDebtProfileEdit"
              :export-file-name="$t('Risks.Section.DebtProfile.Edit.TitleExport', {name: model.name})"
              @export-to-image="exportToImage"
              @export-to-image-completed="exportToImageCompleted"
            />
          </v-col>
        </v-row>
        <v-spacer />
        <v-btn
          class="cancel-button button-text-underline synthesis-ui-green-02--text"
          :class="ExportEnum.NO_EXPORT_CLASS"
          color="synthesis-ui-green-02"
          :disabled="isFormSaving"
          depressed
          large
          text
          @click="onCancelClick"
        >
          {{ $t('Risks.Section.DebtProfile.Edit.Form.Button.Cancel.Title') }}
        </v-btn>
      </v-toolbar>

      <v-row>
        <v-col cols="12">
          <block-item-card
            :title="$t('Risks.Section.DebtProfile.Edit.Form.Section.OtherInputs.Title')"
            min-height="150"
          >
            <v-container
              class="align-content-start"
              fluid
            >
              <v-row>
                <v-col cols="12">
                  <label class="field-label">{{ $t('Risks.Section.DebtProfile.Edit.Form.Section.OtherInputs.Fields.Name.Label') }}</label>
                  <v-text-field
                    v-model="model.name"
                    :placeholder="$t('Risks.Section.DebtProfile.Edit.Form.Section.OtherInputs.Fields.Name.Placeholder')"
                    :rules="model.validator.vuetifyFormFieldRules('name')"
                    hide-details="auto"
                    outlined
                  />
                </v-col>
                <v-col cols="12">
                  <label class="field-label">{{ $t('Risks.Section.DebtProfile.Edit.Form.Section.OtherInputs.Fields.Equity.Label') }}</label>
                  <v-text-field-currency-directive
                    v-model="model.equity"
                    :options="{precision: 0, locale: $i18n.code, currency: model.base.symbol}"
                    :prefix="model.base.displayName"
                    :placeholder="$t('Risks.Section.DebtProfile.Edit.Form.Section.OtherInputs.Fields.Equity.Placeholder')"
                    :rules="model.validator.vuetifyFormFieldRules('equity')"
                    hide-details="auto"
                    outlined
                  />
                </v-col>

                <template v-for="(ebitda, index) in model.ebitda">
                  <v-col
                    :key="`ebitda-${ebitda.year}`"
                    cols="6"
                  >
                    <label class="field-label">{{ $t('Risks.Section.DebtProfile.Edit.Form.Section.OtherInputs.Fields.Ebitda.Label') }} {{ ebitda.year }}</label>
                    <v-text-field-currency-directive
                      v-model="ebitda.amount"
                      :options="{precision: 0, locale: $i18n.code, currency: model.base.symbol}"
                      :prefix="model.base.displayName"
                      :placeholder="`${$t('Risks.Section.DebtProfile.Edit.Form.Section.OtherInputs.Fields.Ebitda.Placeholder')} ${ebitda.year}`"
                      :rules="model.validator.vuetifyFormFieldRules(`ebitda.${index}.amount`)"
                      hide-details="auto"
                      outlined
                    />
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </block-item-card>
        </v-col>

        <v-col cols="12">
          <risk-debt-fixed-input
            v-model="model.fixedDebts"
            :base-currency="model.base"
            :hide-for-export="hideForExport"
            :title="$t('Risks.Section.DebtProfile.Edit.Form.Section.FixedDebt.Title')"
          />
        </v-col>

        <v-col cols="12">
          <risk-debt-floating-input
            v-model="model.floatingDebts"
            :base-currency="model.base"
            :hide-for-export="hideForExport"
            :title="$t('Risks.Section.DebtProfile.Edit.Form.Section.FloatingDebt.Title')"
          />
        </v-col>

        <v-col cols="12">
          <risk-debt-derivatives-input
            v-model="model.debtDerivatives"
            :base-currency="model.base"
            :hide-for-export="hideForExport"
            :title="$t('Risks.Section.DebtProfile.Edit.Form.Section.Derivatives.Title')"
          />
        </v-col>

        <v-col
          cols="12"
          :class="ExportEnum.NO_EXPORT_CLASS"
        >
          <v-card-actions>
            <v-spacer />
            <v-btn
              class="cancel-button button-text-underline synthesis-ui-green-02--text"
              color="synthesis-ui-green-02"
              :disabled="isFormSaving"
              depressed
              large
              text
              @click="onCancelClick"
            >
              {{ $t('Risks.Section.DebtProfile.Edit.Form.Button.Cancel.Title') }}
            </v-btn>

            <v-btn
              class="action-button white--text"
              color="synthesis-ui-green-02"
              :disabled="!isFormValid || isFormSaving"
              :loading="isFormSaving"
              depressed
              large
              @click="saveDebtProfile"
            >
              {{ $t('Risks.Section.DebtProfile.Edit.Form.Button.Save.Title') }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-form>
  </block-item-card>
</template>

<script>

import i18nRouteMeta               from '@/mixins/i18n/i18nRouteMeta'
import viewHasData                 from '@/mixins/viewHasData'
import formData                    from '@/mixins/form/formData'
import BlockItemCard               from '@/theme/default/components/common/BlockItemCard.vue'
import API                         from '@/api/Api'
import RiskDebtProfileInputModel   from '@/api/models/risks/RiskDebtProfileInputModel'
import RiskDebtFixedInput          from '@/theme/default/views/risks/blocks/RiskDebtFixedInput.vue'
import RiskDebtFloatingInput       from '@/theme/default/views/risks/blocks/RiskDebtFloatingInput.vue'
import RiskDebtDerivativesInput    from '@/theme/default/views/risks/blocks/RiskDebtDerivativesInput.vue'
import VTextFieldCurrencyDirective from '@/theme/default/components/form/VTextFieldCurrencyDirective.vue'
import ExportToImage               from '@/theme/default/components/common/ExportToImage.vue'
import ExportEnum                  from '@/api/enums/ExportEnum'
import chartsColorMixin            from '@/theme/default/components/charts/mixins/chartsColorMixin'

export default {
  name      : 'RiskDebtEditBlock',
  components: {
    ExportToImage,
    VTextFieldCurrencyDirective,
    RiskDebtDerivativesInput,
    RiskDebtFloatingInput,
    RiskDebtFixedInput,
    BlockItemCard
  },
  directives: {},
  mixins    : [i18nRouteMeta, viewHasData, formData, chartsColorMixin],
  props     : {},
  dataStore : {
    riskDebtData: 'Risks.Debt.Data'
  },
  enums    : { ExportEnum },
  dataModel: RiskDebtProfileInputModel,
  data () {
    return {
      export                : false,
      hideForExport         : true,
      elementDebtProfileEdit: null
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.setExportElements()
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    toolbarClassForExport () {
      return this.export ? 'my-2 rounded' : 'transparent'
    },

    toolbarStyleForExport () {
      return this.export ? `border-radius: 8px !important; border: ${ this.themeColorRGBA('synthesis-ui-grey-02') } 1px solid` : ''
    },

    setExportElements () {
      this.$nextTick(() => {
        this.elementDebtProfileEdit = this.$refs?.debtProfileCardEdit?.$el
      })
    },

    exportToImage () {
      this.export = true
      this.hideForExport = false
    },

    exportToImageCompleted () {
      this.export = false
      this.hideForExport = true
    },

    saveDebtProfile () {
      this.model.accountId = this.user.selectedAccountId

      const isFormValid = this.validateForm()

      if (!isFormValid && this.model?.errors?.floatingDebts) {
        this.$bus.$emit('app:error', [
          { custom: { message: 'Risk analysis cannot be run without any floating component.' } }
        ])
      }

      if (this.isFormSaving || !isFormValid) return

      this.isFormSaving = true

      this.model.SaveDebtProfile()
        .then(response => {
          this.handleSaveDebtProfileResponse(response)
        })
        .catch(e => {
          this.handleSaveDebtProfileResponse(e.response)
        })
        .finally(() => {
          this.isFormSaving = false
        })
    },
    handleSaveDebtProfileResponse (response) {
      if (!response) return

      const data = API.responseData(response)?.saveDebtProfile || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.$emit('form:save:success', data)
      } else {
        this.$bus.$emit('app:error', errors)
        this.$emit('form:save:error')
      }
    },

    onCancelClick () {
      this.$emit('form:edit:cancel')
    }
  }
}
</script>

<style scoped>
/deep/ .v-toolbar .v-toolbar__content {
  padding-right : 0;
}

/deep/ .v-text-field__slot .v-text-field__prefix, /deep/ .v-text-field__slot .v-text-field__suffix {
  padding-right : 4px;
  margin-right  : 2px;
  display       : flex;
  align-items   : center;
  font-weight   : 400;
}

/deep/ .v-text-field__slot input {
  padding-left  : 4px;
  padding-right : 4px;
  margin-left   : 2px;
  margin-right  : 2px;
}
</style>
