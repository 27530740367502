<template>
  <v-card
    :height="height"
    class="pa-0 fill-height"
    flat
  >
    <v-card-text class="center-vertical-horizontal">
      <v-row
        v-if="headers && !!headers.length"
        align="center"
      >
        <template v-for="(header, index) in headers">
          <v-col
            :key="`debt-card-header-${index}`"
            class="synthesis-inter-text font-weight-light text-size-18 synthesis-brand-dark-blue--text text-center text-no-wrap"
            cols="4"
          >
            {{ header.text }}
          </v-col>
        </template>
      </v-row>

      <v-divider class="mt-2" />

      <template
        v-if="items && !!items.length"
      >
        <template v-for="(item, index) in items">
          <v-row
            :key="`debt-card-item-${index}`"
            class="mx-auto"
            align="center"
          >
            <v-col
              class="synthesis-inter-text font-weight-light text-size-14 text-lg-h6 synthesis-brand-dark-blue--text text-left text-no-wrap pa-7"
              cols="4"
            >
              {{ item.Name }}
            </v-col>
            <v-col
              class="synthesis-inter-text font-weight-light text-size-24 text-lg-h4 synthesis-brand-dark-blue--text text-center text-no-wrap pa-7"
              cols="4"
            >
              {{ item[0] }}
            </v-col>
            <v-col
              class="synthesis-inter-text font-weight-light text-size-24 text-lg-h4 synthesis-brand-dark-blue--text text-center text-no-wrap pa-7"
              cols="4"
            >
              {{ item[1] }}
            </v-col>
          </v-row>
          <v-divider
            :key="`debt-card-divider-${index}`"
          />
        </template>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>

import ExportEnum       from '@/api/enums/ExportEnum'
import chartsColorMixin from '@/theme/default/components/charts/mixins/chartsColorMixin'

export default {
  name      : 'DebtCreditMetricsTableCard',
  components: {},
  directives: {},
  mixins    : [chartsColorMixin],
  props     : {
    items: {
      type   : Array,
      default: () => []
    },
    headers: {
      type   : Array,
      default: () => []
    },
    height: {
      type   : [String, Number],
      default: 300
    }
  },
  enums: { ExportEnum },
  data () {
    return {}
  },
  computed: {
    color () {
      return this.themeColorRGBA('synthesis-ui-grey-01')
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
