<template>
  <v-overlay
    :absolute="absolute"
    :color="color"
    :opacity="opacity"
    :value="value"
    :z-index="zIndex"
  >
    <div class="text-center pa-4">
      <img
        class="animate-logo"
        src="/img/logo/logo-symbol.png"
        width="56"
      >
    </div>
    <div
      :class="titleClass"
      class="text-center"
      style="max-width: 300px;"
    >
      {{ title }}
    </div>
  </v-overlay>
</template>

<script>
export default {
  name      : 'OverlayLoader',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    value: {
      type   : Boolean,
      default: false
    },

    absolute: {
      type   : Boolean,
      default: false
    },

    color: {
      type   : String,
      default: '#ffffff'
    },

    opacity: {
      type   : Number,
      default: 0.35
    },

    zIndex: {
      type   : [String, Number],
      default: 99999
    },

    title: {
      type   : String,
      default: function () { return this.$t('Common.Message.Loading.Wait') }
    },

    titleClass: {
      type   : String,
      default: 'text-size-16 font-weight-medium synthesis-ui-grey-01--text'
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
