import BaseResource                                   from '@/lib/data/resource/BaseResource'
import i18n                                           from '@/lang/lang'
import { toDecimalMark, toNumberFormatNotationShort } from '@/lib/utils/helper'

export default class RiskValueAtRiskAssetAggregateResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Asset = payload.itemName ? (payload.itemName === 'portfolio' ? i18n.t('Common.Asset.Portfolio') : payload.itemName) : ''

    this.Exposure = payload.exposure ? toNumberFormatNotationShort(payload.exposure) : ''
    this.ExposureRaw = payload.exposure ?? ''

    this.ExposurePercent = payload.exposurePercent ? toDecimalMark(payload.exposurePercent) : ''
    this.ExposurePercentRaw = payload.exposurePercent ?? ''

    this.ValueAtRisk = payload.valueAtRisk ? toNumberFormatNotationShort(payload.valueAtRisk) : ''
    this.ValueAtRiskRaw = payload.valueAtRisk ?? ''

    this.ValueAtRiskPercent = payload.valueAtRiskPercent ? toDecimalMark(payload.valueAtRiskPercent) : ''
    this.ValueAtRiskPercentRaw = payload.valueAtRiskPercent ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
