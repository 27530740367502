import BaseResource from '@/lib/data/resource/BaseResource'

export default class RiskDebtInterestExpenseVariabilityItemsResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.AssetValue = payload?.year ?? ''
    this.SensitivityValue = payload?.sensitivity ?? ''
    this.BucketEdges = payload?.bucketEdges ?? []

    this.BeforeHedgingLow = payload?.beforeHedgingLow ?? ''
    this.BeforeHedgingMedian = payload?.beforeHedgingMedian ?? ''
    this.BeforeHedgingHigh = payload?.beforeHedgingHigh ?? ''
    this.BeforeHedgingMarket = payload?.beforeHedgingMarket ?? ''
    this.BeforeHedgingMarketIndex = payload?.beforeHedgingMarketIndex ?? ''

    this.BeforeHedgingLowIndex = payload?.beforeHedgingLowIndex ?? ''
    this.BeforeHedgingMedianIndex = payload?.beforeHedgingMedianIndex ?? ''
    this.BeforeHedgingHighIndex = payload?.beforeHedgingHighIndex ?? ''
    this.BeforeHedgingFrequencies = payload?.beforeHedgingFrequencies ?? []

    this.AfterHedgingLow = payload?.afterHedgingLow ?? ''
    this.AfterHedgingMedian = payload?.afterHedgingMedian ?? ''
    this.AfterHedgingHigh = payload?.afterHedgingHigh ?? ''

    this.AfterHedgingLowIndex = payload?.afterHedgingLowIndex ?? ''
    this.AfterHedgingMedianIndex = payload?.afterHedgingMedianIndex ?? ''
    this.AfterHedgingHighIndex = payload?.afterHedgingHighIndex ?? ''
    this.AfterHedgingFrequencies = payload?.afterHedgingFrequencies ?? []

    const buckets = this.trimBuckets(this.BucketEdges)
    const beforeHedgingFrequencies = this.trimBuckets(this.BeforeHedgingFrequencies)
    const afterHedgingFrequencies = this.trimBuckets(this.AfterHedgingFrequencies)

    this.Data = {
      BeforeHedging: {
        x                : buckets,
        y                : beforeHedgingFrequencies,
        lowBucket        : this.BeforeHedgingLow,
        medianBucket     : this.BeforeHedgingMedian,
        highBucket       : this.BeforeHedgingHigh,
        lowBucketIndex   : this.BeforeHedgingLowIndex,
        medianBucketIndex: this.BeforeHedgingMedianIndex,
        highBucketIndex  : this.BeforeHedgingHighIndex,
        marketValue      : this.BeforeHedgingMarket,
        marketIndex      : this.BeforeHedgingMarketIndex
      },
      AfterHedging: {
        x                : buckets,
        y                : afterHedgingFrequencies,
        lowBucket        : this.AfterHedgingLow,
        medianBucket     : this.AfterHedgingMedian,
        highBucket       : this.AfterHedgingHigh,
        lowBucketIndex   : this.AfterHedgingLowIndex,
        medianBucketIndex: this.AfterHedgingMedianIndex,
        highBucketIndex  : this.AfterHedgingHighIndex,
        marketValue      : '',
        marketIndex      : ''
      }
    }
  }

  /* PROPERTIES */

  /* METHODS */
  trimBuckets (v) {
    return v

    /*
    const start = 4
    const end = 2
    const buckets = clone(v)

    buckets.splice(0, start)
    buckets.splice(buckets.length - end, end)

    return buckets
    */
  }
}
