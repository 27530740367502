<template>
  <v-row>
    <v-col>
      <v-row v-if="showToolbarTitle || showToolbarLegend">
        <v-toolbar
          color="transparent"
          dense
          flat
          height="30"
        >
          <v-toolbar-title
            v-if="showToolbarTitle && toolbarTitle"
            class="synthesis-text text-size-24 font-weight-medium synthesis-brand-dark-blue--text"
            v-text="toolbarTitle"
          />
          <v-spacer />

          <div
            v-if="showToolbarLegend"
            class="mr-4 synthesis-text text-size-14 synthesis-brand-dark-blue--text"
          >
            <span
              :style="`border-radius:10px;width:10px;height:10px;background-color:${getHedgingInstrumentBarColors(1)};`"
              class="d-inline-block mr-1"
            />
            {{ $t('Hedging.Legend.Unhedged.Title') }}
          </div>

          <div
            v-if="showToolbarLegend"
            class="synthesis-text text-size-14 synthesis-brand-dark-blue--text"
          >
            <span
              :style="`border-radius:10px;width:10px;height:10px;background-color:${getHedgingInstrumentBarColors(0)};`"
              class="d-inline-block mr-1"
            />
            {{ $t('Hedging.Legend.Hedged.Title') }}
          </div>
        </v-toolbar>
      </v-row>

      <v-row v-if="instruments && instruments.length">
        <template v-for="(item, i) in instruments">
          <v-col
            :key="`hedging-instrument-card-${i}`"
            cols="12"
            md="6"
          >
            <v-lazy
              :options="{threshold: .5}"
              :value="disableLazy"
              transition="fade-transition"
            >
              <hedging-instrument-card
                :asset="item"
                :chart-animation="chartAnimation"
                :disable-lazy="disableLazy"
              />
            </v-lazy>
          </v-col>
        </template>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

import policyId              from '@/theme/default/views/hedging/mixins/policyId'
import HedgingInstrumentCard from '@/theme/default/components/common/HedgingInstrumentCard'
import baseEChartColors      from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'
import useExportAdditional   from '@/mixins/useExportAdditional'

export default {
  name      : 'HedgingSummaryListBlock',
  components: {
    HedgingInstrumentCard
  },
  directives: {},
  mixins    : [policyId, baseEChartColors, useExportAdditional],
  props     : {
    showToolbarTitle: {
      type   : Boolean,
      default: false
    },
    showToolbarLegend: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {
    hedgingSummaryData: 'Hedging.Summary.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    summary () {
      return this.hedgingSummaryData[this.id] || null
    },

    instruments () {
      return this.summary?.Instruments ?? []
    },

    mode () {
      return this.summary?.Mode ?? ''
    },

    toolbarTitle () {
      return this.$t('Hedging.Section.SuggestedStrategy.ToolbarTitle', { Mode: this.$t('Hedging.Mode.' + this.mode) })
    }

  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
