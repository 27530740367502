<template>
  <v-container
    class="align-content-start"
    fill-height
    fluid
  >
    <v-row>
      <section-header :breadcrumb-items="breadcrumb" />
    </v-row>

    <v-row ref="dashboard">
      <v-col
        :md="6"
        :sm="breakpoint"
        cols="12"
      >
        <v-lazy
          :options="{threshold: .5}"
          transition="fade-transition"
        >
          <live-risk-block
            :min-height="hasPositions ? minBlockHeight : 310"
            has-export
          />
        </v-lazy>
      </v-col>

      <v-col
        v-if="hasPositions"
        :md="6"
        :sm="breakpoint"
        cols="12"
      >
        <v-lazy
          :options="{threshold: .5}"
          transition="fade-transition"
        >
          <live-hedging-block
            :min-height="minBlockHeight"
            has-export
          />
        </v-lazy>
      </v-col>

      <v-col
        :md="hasPositions ? 4 : 6"
        cols="12"
        sm="6"
      >
        <v-lazy
          :options="{threshold: .5}"
          transition="fade-transition"
        >
          <live-prices-block />
        </v-lazy>
      </v-col>

      <v-col
        :md="hasPositions ? 4 : 6"
        cols="12"
        sm="6"
      >
        <v-lazy
          :options="{threshold: .5}"
          transition="fade-transition"
        >
          <live-news-block />
        </v-lazy>
      </v-col>

      <v-col
        :md="hasPositions ? 4 : 6"
        cols="12"
        sm="6"
      >
        <v-lazy
          :options="{threshold: .5}"
          transition="fade-transition"
        >
          <alerts-block />
        </v-lazy>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <v-lazy
          :options="{threshold: .5}"
          transition="fade-transition"
        >
          <knowledge-center-block />
        </v-lazy>
      </v-col>

      <v-col
        cols="12"
        md="8"
      >
        <v-lazy
          :options="{threshold: .5}"
          transition="fade-transition"
        >
          <dashboard-chart-block />
        </v-lazy>
      </v-col>

      <v-col
        cols="12"
        md="8"
      >
        <v-lazy
          :options="{threshold: .5}"
          transition="fade-transition"
        >
          <survey-block />
        </v-lazy>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-lazy
          :options="{threshold: .5}"
          transition="fade-transition"
        >
          <fun-facts-block />
        </v-lazy>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta                     from '@/mixins/i18n/i18nRouteMeta'
import SectionHeader                     from '@/theme/default/components/common/SectionHeader'
import LiveRiskBlock                     from '@/theme/default/views/dashboard/blocks/LiveRiskBlock'
import LivePricesBlock                   from '@/theme/default/views/dashboard/blocks/LivePricesBlock'
import LiveHedgingBlock                  from '@/theme/default/views/dashboard/blocks/LiveHedgingBlock'
import LiveNewsBlock                     from '@/theme/default/views/dashboard/blocks/LiveNewsBlock'
import AlertsBlock                       from '@/theme/default/views/dashboard/blocks/AlertsBlock'
import SurveyBlock                       from '@/theme/default/views/dashboard/blocks/SurveyBlock'
import FunFactsBlock                     from '@/theme/default/views/dashboard/blocks/FunFactsBlock'
import KnowledgeCenterBlock              from '@/theme/default/views/dashboard/blocks/KnowledgeCenterBlock'
import DashboardChartBlock               from '@/theme/default/views/dashboard/blocks/DashboardChartBlock'
import API                               from '@/api/Api'
import { clone }                         from '@/lib/utils/helper'
import Socket                            from '@/theme/default/views/dashboard/socket/socket'
import DashboardHedgingPositionsResource from '@/api/resources/dashboard/DashboardHedgingPositionsResource'

export default {
  name      : 'Dashboard',
  components: {
    DashboardChartBlock,
    KnowledgeCenterBlock,
    FunFactsBlock,
    SurveyBlock,
    AlertsBlock,
    LiveNewsBlock,
    LiveHedgingBlock,
    LivePricesBlock,
    LiveRiskBlock,
    SectionHeader
  },
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  dataStore : {
    appToolbarExtended  : 'App.Config.AppToolbarExtended',
    hedgingPositionsData: 'Dashboard.HedgingPositions.Data'
  },
  enums: {},
  data () {
    return {
      breakpoint    : (this.appToolbarExtended && this.$vuetify.breakpoint.smAndDown ? 12 : 6),
      minBlockHeight: 364
    }
  },
  computed: {
    breadcrumb () {
      return [{
        text    : this.$t('Dashboard.HeaderTitle', { Name: this.userName }),
        disabled: true
      }]
    },

    userName () {
      return this.user?.firstName || null
    },

    accountId () {
      return this.user?.selectedAccountId || null
    },

    hasPositions () {
      const positions = this.hedgingPositionsData || null
      const items = positions?.Items || null

      return (items && !!items.length)
    }
  },
  watch: {
    appToolbarExtended (val) {
      this.breakpoint = val && this.$vuetify.breakpoint.smAndDown ? 12 : 6
    },

    '$vuetify.breakpoint.smAndDown': {
      handler (val) {
        this.breakpoint = val && this.appToolbarExtended ? 12 : 6
      }
    }

  },
  beforeCreate () {
    Socket.init()
  },
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    Socket.close()
  },
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Positions.PositionsDashboard(this.accountId)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },
    handleResponse (response) {
      const data = API.responseData(response)?.positionsDashboard || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const o = clone(data)
        this.hedgingPositionsData = new DashboardHedgingPositionsResource(o)
      } else {
        this.hedgingPositionsData = null
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>

</style>
