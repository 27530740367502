import BaseResource                   from '@/lib/data/resource/BaseResource'
import i18n                           from '@/lang/lang'
import { collect }                    from '@/lib/utils/array'
import ComparisonCompanyTableResource from '@/api/resources/industry/ComparisonCompanyTableResource'

export default class ComparisonTableResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Page = payload.page ?? 0
    this.Total = payload.total ?? 0
    this.Items = payload.companies ? collect(payload.companies, ComparisonCompanyTableResource) : null
  }

  /* PROPERTIES */
  get Headers () {
    return [{
      text    : i18n.t('Industry.Table.Headers.Company'),
      align   : 'start',
      sortable: true,
      value   : 'Name',
      width   : 230
    }, {
      text    : i18n.t('Industry.Table.Headers.Industry'),
      align   : 'start',
      sortable: true,
      value   : 'Industry'
    }]
  }

  get Values () {
    if (this.Items) {
      return this.Items.map(item => item.TableRow)
    }
    return []
  }

  /* METHODS */
}
