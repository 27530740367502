import { clone }                        from '@/lib/utils/helper'
import RiskDebtAmortisationTypeEnumEnum from '@/api/enums/RiskDebtAmortisationTypeEnumEnum'

export default {
  data () {
    return {
      selectedItem                    : null,
      selectedIndex                   : null,
      addEditAmortisationDialogVisible: false
    }
  },

  props: {
    value: {
      type   : Array,
      default: () => []
    },
    title: {
      type   : String,
      default: ''
    },
    baseCurrency: {
      type    : Object,
      required: true
    },
    hideForExport: {
      type    : Boolean,
      required: true
    }
  },

  computed: {
    editMode () {
      return !!this.selectedItem?.editMode
    },

    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },

  methods: {
    onItemMaturityChange (item, index) {
      const data = this.initAmortisation(item)
      this.selectedItem = data
      this.selectedIndex = index
      this.selectedItem.editMode = true

      data.amortisation = data.amortisation.map(item => {
        if (!item.amount) item.amount = 0
        return item
      })

      this.onAddEditAmortisationSuccess(data)
    },
    onAmortisationTypeChange (item, val) {
      const payload = clone(this.selectedItem)
      payload.amortisation = clone(item.amortisation)
      this.selectedItem = this.initAmortisation(payload, val)
    },
    addAmortisation (item, index) {
      const data = this.initAmortisation(item)
      this.selectedItem = data
      this.selectedIndex = index
      this.selectedItem.editMode = false
      this.addEditAmortisationDialogVisible = true
    },
    editAmortisation (item, index) {
      const data = this.initAmortisation(item)
      this.selectedItem = data
      this.selectedIndex = index
      this.selectedItem.editMode = true
      this.addEditAmortisationDialogVisible = true
    },
    initAmortisation (item, amortisationType) {
      const data = clone(item)
      const currentYear = parseInt(this.$dayjs().year())
      const currentQuarter = parseInt(this.$dayjs().quarter())
      const maturityYear = parseInt(this.$dayjs(data.maturity).year())
      const maturityQuarter = parseInt(this.$dayjs(data.maturity).quarter())
      const years = maturityYear - currentYear
      const amortisationArr = []

      const fromAmortisationType = data.amortisationType

      if (amortisationType) {
        data.amortisationType = amortisationType

        if (fromAmortisationType === RiskDebtAmortisationTypeEnumEnum.ANNUALLY && amortisationType === RiskDebtAmortisationTypeEnumEnum.QUARTERLY) {
          data.amortisationAnnually = clone(data.amortisation)
          data.amortisationQuarterly = []
          data.amortisation = []
        } else if (fromAmortisationType === RiskDebtAmortisationTypeEnumEnum.QUARTERLY && amortisationType === RiskDebtAmortisationTypeEnumEnum.ANNUALLY) {
          data.amortisationAnnually = []
          data.amortisationQuarterly = clone(data.amortisation)
          data.amortisation = []
        }
      }

      if (data.amortisationType === RiskDebtAmortisationTypeEnumEnum.ANNUALLY) {
        for (let year = currentYear; year <= maturityYear; year++) {
          let amount = data.notional || 0
          if (fromAmortisationType === RiskDebtAmortisationTypeEnumEnum.QUARTERLY && amortisationType === RiskDebtAmortisationTypeEnumEnum.ANNUALLY) {
            amount = 0
          }

          amortisationArr.push({
            amount: amount,
            key   : `${ year }`,
            label : `${ year }`
          })
        }

        if (data.amortisationAnnually.length) {
          data.amortisationAnnually.slice(0, years + 1).forEach((item, index) => {
            amortisationArr[index].amount = item.amount || 0
          })

          for (let i = data.amortisationAnnually.length; i < amortisationArr.length; i++) {
            amortisationArr[i].amount = data.amortisationAnnually[data.amortisationAnnually.length - 1]?.amount || 0
          }
        }

        if (data.amortisation.length) {
          data.amortisation.slice(0, years + 1).forEach((item, index) => {
            amortisationArr[index].amount = item.amount || 0
          })

          for (let i = data.amortisation.length; i < amortisationArr.length; i++) {
            amortisationArr[i].amount = data.amortisation[data.amortisation.length - 1]?.amount || 0
          }
        }

        data.amortisationAnnually = clone(amortisationArr)
      } else {
        let totalQuarters = 0

        for (let year = currentYear; year <= maturityYear; year++) {
          const startQuarter = year === currentYear ? currentQuarter : 1
          const endQuarter = year === maturityYear ? maturityQuarter : 4

          for (let quarter = startQuarter; quarter <= endQuarter; quarter++) {
            let amount = data.notional || 0

            if (fromAmortisationType === RiskDebtAmortisationTypeEnumEnum.ANNUALLY && data.amortisationAnnually.length) {
              const item = data.amortisationAnnually?.find(item => String(item.key) === String(year))
              amount = item?.amount || 0
            }

            amortisationArr.push({
              amount: amount,
              key   : `${ year }Q${ quarter }`,
              label : `${ year } Q${ quarter }`
            })

            totalQuarters++
          }
        }

        if (data.amortisationQuarterly.length) {
          for (let i = data.amortisationQuarterly.length; i < amortisationArr.length; i++) {
            amortisationArr[i].amount = data.amortisationQuarterly[data.amortisationQuarterly.length - 1]?.amount || 0
          }
        }

        if (data.amortisation.length) {
          for (let i = data.amortisation.length; i < amortisationArr.length; i++) {
            amortisationArr[i].amount = data.amortisation[data.amortisation.length - 1]?.amount || 0
          }
        }

        data.amortisationQuarterly.length = totalQuarters
        data.amortisation.length = totalQuarters

        if (data.amortisationQuarterly.length) {
          data.amortisationQuarterly.slice(0, totalQuarters + 1).forEach((item, index) => {
            amortisationArr[index].amount = item.amount || 0
          })
        }

        if (data.amortisation.length) {
          data.amortisation.slice(0, years + 1).forEach((item, index) => {
            amortisationArr[index].amount = item.amount || 0
          })
        }

        data.amortisationQuarterly = clone(amortisationArr)
      }

      data.amortisation = clone(amortisationArr)

      return clone(data)
    },

    onAddEditAmortisationSuccess (data) {
      data = clone(data)
      this.model[this.selectedIndex].amortisationType = data.amortisationType
      this.model[this.selectedIndex].amortisation = data.amortisation

      if (data.amortisationType === RiskDebtAmortisationTypeEnumEnum.ANNUALLY) {
        this.model[this.selectedIndex].amortisationAnnually = data.amortisation
      } else {
        this.model[this.selectedIndex].amortisationQuarterly = data.amortisation
      }
    },

    onItemNotionalChange (data, index) {
      data = clone(data)

      this.selectedItem = data
      this.selectedIndex = index

      data.amortisation = data.amortisation.map(item => {
        item.amount = data.notional
        return item
      })
      this.model[this.selectedIndex].amortisation = data.amortisation

      data.amortisationAnnually = data.amortisationAnnually.map(item => {
        item.amount = data.notional
        return item
      })
      this.model[this.selectedIndex].amortisationAnnually = data.amortisationAnnually

      data.amortisationQuarterly = data.amortisationQuarterly.map(item => {
        item.amount = data.notional
        return item
      })
      this.model[this.selectedIndex].amortisationQuarterly = data.amortisationQuarterly
    },

    addMaturity () {},
    removeMaturity (index) {
      this.model.splice(index, 1)
    }
  }
}
