<template>
  <v-container fluid>
    <risk-summary-base-block :risk-analysis-id="id" />

    <v-row>
      <v-col cols="12">
        <v-tabs
          v-model="activeSubTab"
          active-class="active-tab"
          background-color="transparent"
          height="30"
          hide-slider
          show-arrows
        >
          <v-tab
            :disabled="!hasValueAtRisk"
            href="#value-at-risk"
          >
            {{ $t('Risks.Tab.Summary.Tab.ValueAtRisk.Title') }}
          </v-tab>
          <v-tab
            :disabled="!hasHistorical"
            href="#historical-spectrum"
          >
            {{ $t('Risks.Tab.Summary.Tab.HistoricalSpectrum.Title') }}
          </v-tab>
          <v-tab
            :disabled="!hasSimulation"
            href="#forward-simulations"
          >
            {{ $t('Risks.Tab.Summary.Tab.ForwardSimulations.Title') }}
          </v-tab>
          <v-tab
            :disabled="!hasEvents"
            href="#extreme-events"
          >
            {{ $t('Risks.Tab.Summary.Tab.ExtremeEvent.Title') }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        class="pa-0"
        cols="12"
      >
        <v-tabs-items v-model="activeSubTab">
          <v-tab-item
            v-if="hasValueAtRisk"
            :value="$t('Risks.Tab.Summary.Tab.ValueAtRisk.Slug')"
          >
            <method-one-block :risk-analysis-id="id" />
          </v-tab-item>

          <v-tab-item
            v-if="hasHistorical"
            :value="$t('Risks.Tab.Summary.Tab.HistoricalSpectrum.Slug')"
          >
            <method-two-block :risk-analysis-id="id" />
          </v-tab-item>

          <v-tab-item
            v-if="hasSimulation"
            :value="$t('Risks.Tab.Summary.Tab.ForwardSimulations.Slug')"
          >
            <method-three-block :risk-analysis-id="id" />
          </v-tab-item>

          <v-tab-item
            v-if="hasEvents"
            :value="$t('Risks.Tab.Summary.Tab.ExtremeEvent.Slug')"
          >
            <method-four-block :risk-analysis-id="id" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import MethodTwoBlock       from '@/theme/default/views/risks/blocks/methodologies/MethodTwoBlock'
import MethodOneBlock       from '@/theme/default/views/risks/blocks/methodologies/MethodOneBlock'
import MethodThreeBlock     from '@/theme/default/views/risks/blocks/methodologies/MethodThreeBlock'
import MethodFourBlock      from '@/theme/default/views/risks/blocks/methodologies/MethodFourBlock'
import RiskSummaryBaseBlock from '@/theme/default/views/risks/blocks/RiskSummaryBaseBlock'
import riskAnalysisId       from '@/theme/default/views/risks/mixins/riskAnalysisId'
import routerTabState       from '@/mixins/routerTabState'

export default {
  name      : 'RiskSummaryBlock',
  components: {
    RiskSummaryBaseBlock,
    MethodFourBlock,
    MethodThreeBlock,
    MethodOneBlock,
    MethodTwoBlock
  },
  directives: {},
  mixins    : [riskAnalysisId, routerTabState],
  props     : {},
  dataStore : {
    riskSummaryData: 'Risks.Summary.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    summary () {
      return this.riskSummaryData[this.id] || null
    },

    hasValueAtRisk () {
      return this.summary.HasValueAtRisk ?? true
    },

    hasHistorical () {
      return this.summary.HasHistorical ?? true
    },

    hasSimulation () {
      return this.summary.HasSimulation ?? true
    },

    hasEvents () {
      return this.summary.HasEvents ?? true
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
