import BaseResource from '@/lib/data/resource/BaseResource'

export default class TrackLivePriceCategoryItemResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.categoryName = payload.categoryName ?? ''
    this.category = payload.category ?? ''
    this.display = payload.display ?? false
  }

  /* PROPERTIES */

  /* METHODS */
}
