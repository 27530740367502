var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tableValues)?_c('table-header-gradient-blue',{attrs:{"export-data":_vm.exportData,"export-file-name":_vm.exportFileName,"title":_vm.title,"tooltip":_vm.$t('Hedging.Table.Costs.Titles.EstimatedBankFees.InfoTip')},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('v-data-table',{staticClass:"font-weight-medium synthesis-card-border",attrs:{"headers":_vm.table.headers,"items":_vm.tableValues,"disable-pagination":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.Maturity",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("" + (_vm.showOverlay ? 'text-blur' : ''))},[_vm._v(_vm._s(item.Maturity))])]}},{key:"item.ForwardFees",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("" + (_vm.showOverlay ? 'text-blur' : ''))},[_vm._v(_vm._s(item.ForwardFeesFmt))])]}},{key:"item.OptionPremiumFees",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("" + (_vm.showOverlay ? 'text-blur' : ''))},[_vm._v(_vm._s(item.OptionPremiumFeesFmt))])]}},{key:"item.CollarFees",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("" + (_vm.showOverlay ? 'text-blur' : ''))},[_vm._v(_vm._s(item.CollarFeesFmt))])]}}],null,false,3937454998)})]},proxy:true},(_vm.showOverlay)?{key:"toolbarActions",fn:function(){return [_c('button-unlock',{attrs:{"title":_vm.$t('Hedging.Table.Costs.Titles.EstimatedBankFees.Title')},on:{"button-unlock-click":function($event){return _vm.$router.push({name: 'SynthesisSupport', params: {item: _vm.supportModel}})}}})]},proxy:true}:null],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }