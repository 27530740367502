import BaseModel              from '@/lib/data/model/BaseModel'
import AssetExposureValidator from '@/api/validations/asset/AssetExposureValidator'
import AssetExposureResource  from '@/api/resources/asset/AssetExposureResource'
import { sum }                from '@/lib/utils/array'

export default class AssetExposureModel extends BaseModel {
  constructor (payload = {}, resource = AssetExposureResource, validator = AssetExposureValidator) {
    super(payload, resource, validator)
  }

  /* PROPERTIES */

  /* METHODS */
  boot () {
    super.boot()

    this.guarded = ['userProvidedSeasonality', 'selectedItem', 'asset']
  }

  sumSeasonality () {
    if (!this.userProvidedSeasonality) return
    this.notional = sum(this.seasonality.map(val => parseInt(val))).toString()
  }

  resetSeasonality () {
    this.seasonality = this.seasonality.map(val => '')
    this.notional = ''
  }
}
