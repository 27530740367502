export default {
  props: {
    exportElement: {
      type   : HTMLDivElement,
      default: undefined
    },
    exportFileName: {
      type   : String,
      default: ''
    }
  }
}
