import BaseResource                 from '@/lib/data/resource/BaseResource'
import AssetModel                   from '@/api/models/asset/AssetModel'
import RiskDeptFixedInputModel      from '@/api/models/risks/RiskDeptFixedInputModel'
import RiskDeptFloatingInputModel   from '@/api/models/risks/RiskDeptFloatingInputModel'
import RiskDeptDerivativeInputModel from '@/api/models/risks/RiskDeptDerivativeInputModel'
import { collect }                  from '@/lib/utils/array'

export default class RiskDebtProfileInputResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.accountId = payload.accountId ?? null
    this.id = payload.id ?? null
    this.name = payload.name ?? ''
    this.equity = payload.equity ?? null
    this.ebitda = payload.ebitda ?? null
    this.base = payload.base ?? new AssetModel(payload.base ?? null)
    this.fixedDebts = collect(payload.fixedDebts ?? [], RiskDeptFixedInputModel)
    this.floatingDebts = collect(payload.floatingDebts ?? [], RiskDeptFloatingInputModel)
    this.debtDerivatives = collect(payload.debtDerivatives ?? [], RiskDeptDerivativeInputModel)
    this.baseCurrencyCode = this.base?.symbol || 'USD'
  }

  /* PROPERTIES */

  /* METHODS */
}
