<template>
  <v-app>
    <vue-snotify />

    <template v-if="$vuetify.breakpoint.xs">
      <app-navigation-drawer-mobile
        :items="navigationDrawerItemsAuth"
        :navigation-drawer.sync="navigationDrawerMobileVisible"
      />

      <app-toolbar :navigation-drawer.sync="navigationDrawerMobileVisible" />
    </template>

    <app-navigation-drawer
      v-else
      :drawer-mini.sync="navigationDrawerMini"
      :items="navigationDrawerItemsAuth"
      :navigation-drawer.sync="navigationDrawerVisible"
    />

    <v-main class="synthesis">
      <v-container
        class="pa-0 pa-sm-3 pb-sm-2 pl-sm-0"
        fluid
        style="height: 100%;"
      >
        <v-row
          class="fill-height"
          dense
        >
          <v-col
            class="pb-0"
            cols="12"
          >
            <v-card
              class="fill-height section-bg-gradient rounded-lg pa-0 pa-sm-2"
              flat
            >
              <slot />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <confirm-dialog ref="confirmDialog" />
    <error-dialog ref="errorDialog" />
  </v-app>
</template>

<script>
import AppNavigationDrawer       from '@/theme/default/components/app/AppNavigationDrawer'
import ConfirmDialog             from '@/theme/default/components/common/dialog/ConfirmDialog'
import ErrorDialog               from '@/theme/default/components/common/dialog/ErrorDialog'
import GlobalErrorHandler        from '@/mixins/error/globalErrorHandler'
import AppNavigationDrawerMobile from '@/theme/default/components/app/AppNavigationDrawerMobile'
import AppToolbar                from '@/theme/default/components/app/AppToolbar'
import AlertActiveIncidents      from '@/theme/default/views/alerts/mixins/alertActiveIncidents'

export default {
  name      : 'DefaultLayout',
  components: {
    AppToolbar,
    AppNavigationDrawerMobile,
    ErrorDialog,
    ConfirmDialog,
    AppNavigationDrawer
  },
  mixins   : [GlobalErrorHandler, AlertActiveIncidents],
  dataStore: { Positions: 'Positions' },
  data     : function () {
    return {
      navigationDrawerVisible      : false,
      navigationDrawerMobileVisible: false,
      navigationDrawerMini         : false
    }
  },
  computed: {
    navigationDrawerItems () {
      return [
        {
          pos          : 'top',
          icon         : '',
          synthesisIcon: this.$t('Route.Dashboard.Icon'),
          text         : this.$t('Route.Dashboard.Title'),
          route        : {
            name : 'Dashboard',
            exact: false
          }
        },
        {
          pos          : 'top',
          icon         : '',
          synthesisIcon: this.$t('Route.Alerts.Icon'),
          text         : this.$t('Route.Alerts.Title'),
          route        : {
            name : 'Alerts',
            exact: false
          }
        },
        {
          pos          : 'top',
          icon         : '',
          synthesisIcon: this.$t('Route.Risks.Icon'),
          text         : this.$t('Route.Risks.Title'),
          route        : {
            name : 'Risks',
            exact: false
          }
        },
        {
          pos          : 'top',
          icon         : '',
          synthesisIcon: this.$t('Route.Hedging.Icon'),
          text         : this.$t('Route.Hedging.Title'),
          route        : {
            name : 'Hedging',
            exact: false
          }
        },
        {
          pos          : 'top',
          icon         : '',
          synthesisIcon: this.$t('Route.Industry.Icon'),
          text         : this.$t('Route.Industry.Title'),
          route        : {
            name : 'Industry',
            exact: false
          }
        },
        {
          pos          : 'top',
          icon         : '',
          synthesisIcon: this.$t('Route.Positions.Icon'),
          text         : this.$t('Route.Positions.Title'),
          route        : {
            name : 'Positions',
            exact: false
          }
        },
        {
          pos          : this.$vuetify.breakpoint.xs ? 'top' : 'bottom',
          icon         : '',
          synthesisIcon: this.$t('Route.KnowledgeCenter.Icon'),
          text         : this.$t('Route.KnowledgeCenter.Title'),
          route        : {
            name : 'KnowledgeCenter',
            exact: false
          }
        },
        {
          pos          : this.$vuetify.breakpoint.xs ? 'top' : 'bottom',
          icon         : '',
          synthesisIcon: this.$t('Route.Help.Icon'),
          text         : this.$t('Route.Help.Title'),
          model        : this.$route.path.includes('/help/'),
          children     : [
            {
              text : this.$t('Route.HelpSynthesisSupport.Title'),
              route: {
                name : 'SynthesisSupport',
                exact: true
              }
            },
            {
              text : this.$t('Route.HelpMavenAdvisory.Title'),
              route: {
                name : 'MavenAdvisory',
                exact: true
              }
            }
          ]
        },
        {
          pos          : this.$vuetify.breakpoint.xs ? 'bottom' : 'account',
          icon         : '',
          synthesisIcon: this.$t('Route.MyDetails.Icon'),
          text         : this.$t('Route.MyDetails.Title'),
          route        : {
            name : 'MyDetails',
            exact: false
          }
        },
        {
          pos          : this.$vuetify.breakpoint.xs ? 'bottom' : 'account',
          icon         : '',
          synthesisIcon: this.$t('Route.TermsAndPrivacy.Icon'),
          text         : this.$t('Route.TermsAndPrivacy.Title'),
          route        : {
            name : 'TermsAndPrivacy',
            exact: false
          }
        }
      ]
    },

    navigationDrawerItemsAuth () {
      const retVal = []

      this.navigationDrawerItems.forEach(item => {
        if (Array.isArray(item)) {
          let tmpc = []
          item.forEach(c => {
            if (c.hasOwnProperty('divider') ||
              (c.hasOwnProperty('text') && !c.hasOwnProperty('route')) ||
              (c.hasOwnProperty('text') && c.hasOwnProperty('route') && this.userCanAccess)
            ) {
              if (c.hasOwnProperty('children')) {
                const tmp = []
                c.children.forEach(child => {
                  if (child.hasOwnProperty('divider') || child.hasOwnProperty('heading') ||
                    (child.hasOwnProperty('text') && !child.hasOwnProperty('route')) ||
                    (child.hasOwnProperty('text') && child.hasOwnProperty('route') && this.userCanAccess)
                  ) {
                    tmp.push(child)
                  }
                })
                if (tmp.length > 0) {
                  c.children = tmp
                  tmpc.push(c)
                }
              } else {
                tmpc.push(c)
              }
            }
          })
          if (tmpc.length === 1 && tmpc[0].hasOwnProperty('divider')) tmpc = []
          tmpc.forEach(child => retVal.push(child))
        } else {
          if (item.hasOwnProperty('divider') || item.hasOwnProperty('heading') ||
            (item.hasOwnProperty('text') && !item.hasOwnProperty('route')) ||
            (item.hasOwnProperty('text') && item.hasOwnProperty('route') && this.userCanAccess)
          ) {
            if (item.hasOwnProperty('children')) {
              const tmp = []
              item.children.forEach(child => {
                if (child.hasOwnProperty('divider') || child.hasOwnProperty('heading') ||
                  (child.hasOwnProperty('text') && !child.hasOwnProperty('route')) ||
                  (child.hasOwnProperty('text') && child.hasOwnProperty('route') && this.userCanAccess)
                ) {
                  tmp.push(child)
                }
              })
              if (tmp.length > 0) {
                item.children = tmp
                retVal.push(item)
              }
            } else {
              retVal.push(item)
            }
          }
        }
      })

      return retVal
    }
  },
  watch: {},
  created () {},
  mounted () {
    this.$root.$confirmDialog = this.$refs.confirmDialog.open
  },
  beforeDestroy () {},
  methods: {}
}

</script>

<style>

</style>
