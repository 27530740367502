<template>
  <v-row class="ma-0">
    <v-col
      class="pa-0 pb-2"
      cols="12"
    >
      <block-item-card
        v-ripple
        :min-height="44"
        style="border: none;"
      >
        <v-overlay
          :absolute="true"
          :opacity=".1"
          :style="showOverlay ? 'backdrop-filter: blur(2px);background-color: rgba(165, 165, 165, 0.1)': ''"
          :value="showOverlay"
          color="rgba(165, 165, 165, .1)"
        />
        <v-container
          class="synthesis-ui-grey-06"
          fill-height
          pa-0
          style="height: 44px;border-radius: 8px !important; cursor: pointer;"
          @click="$router.push({name: 'Risks'})"
        >
          <v-row class="ma-0">
            <v-col
              class="pa-0 px-3"
              cols="4"
            >
              <div class="d-inline-block text-no-wrap">
                <div class="text-size-14 font-weight-medium synthesis-brand-dark-blue--text">
                  {{ item.Asset }}
                </div>
                <div class="synthesis-inter-text text-size-10 font-weight-regular synthesis-ui-grey-01--text">
                  {{ item.NotionalFmt }}<span
                    v-if="item.Label && $vuetify.breakpoint.lgAndUp"
                  ><v-icon small>
                    mdi-circle-small
                  </v-icon></span>
                  <span
                    v-if="item.Label && $vuetify.breakpoint.lgAndUp"
                  >{{ item.Label }}</span>
                </div>
              </div>
            </v-col>
            <v-col
              class="pa-0 px-3 text-center"
              cols="4"
            >
              <div
                class="d-inline-block text-no-wrap text-left"
              >
                <div class="text-size-14 font-weight-medium synthesis-ui-red-02--text">
                  {{ item.UnhedgedRiskFmt }}
                </div>
                <div class="synthesis-inter-text text-size-10 font-weight-regular synthesis-ui-grey-01--text">
                  {{ $t('Dashboard.Block.LiveRisk.Label.UnhedgedRisk') }}
                </div>
              </div>
            </v-col>
            <v-col
              class="pa-0 px-3 text-right"
              cols="4"
            >
              <div
                class="d-inline-block text-no-wrap text-left"
              >
                <div class="text-size-14 font-weight-medium synthesis-ui-green-01--text">
                  {{ item.HedgedRiskFmt }}
                </div>
                <div class="synthesis-inter-text text-size-10 font-weight-regular synthesis-ui-grey-01--text">
                  {{ $t('Dashboard.Block.LiveRisk.Label.HedgedRisk') }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </block-item-card>
    </v-col>
  </v-row>
</template>

<script>
import BlockItemCard from '@/theme/default/components/common/BlockItemCard'

export default {
  name      : 'LiveRiskListCard',
  components: { BlockItemCard },
  directives: {},
  mixins    : [],
  props     : {
    item: {
      type    : Object,
      required: true
    },
    showOverlay: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
