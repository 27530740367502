import BaseModel     from '@/lib/data/model/BaseModel'
import BaseResource  from '@/lib/data/resource/BaseResource'
import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class HedgingAssetParamsModel extends BaseModel {
  constructor (payload = {}, resource = BaseResource, validator = BaseValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}
