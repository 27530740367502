import BaseResource from '@/lib/data/resource/BaseResource'
import i18n         from '@/lang/lang'
import dayjs        from 'dayjs'

export default class ComparisonSummaryResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.id ?? ''
    this.Name = payload.name ?? ''
    this.Headers = payload.headers ?? []
    this.Values = payload.entries ?? []
    this.CreatedAt = payload.createdAt ?? ''
    this.CreatedAtFmt = this.getDateFmt(payload.createdAt ?? null)
  }

  /* PROPERTIES */
  get ExportData () {
    const data = []

    if (this.Values && !!this.Values.length) {
      this.Values.forEach(item => {
        const company = item?.companyName || ''
        const attributes = item?.attributeValues || []

        const row = { Name: company }

        attributes.forEach(v => {
          const name = v.attribute?.name || ''

          row[name] = `"${ v.value }"`
        })

        data.push(row)
      })
    }
    return data
  }

  get TableHeaders () {
    const headers = [{
      text     : i18n.t('Industry.Table.Headers.Name'),
      align    : 'start',
      width    : 230,
      value    : 'Name',
      class    : 'synthesis-inter-text text-size-12 synthesis-brand-dark-blue--text',
      cellClass: 'text-size-14 synthesis-brand-dark-blue--text'
    }]

    if (this.Headers && !!this.Headers.length) {
      this.Headers.forEach(item => {
        headers.push({
          text     : item?.attribute?.name || '',
          align    : item?.alignment?.toLowerCase(),
          sortable : item.sortEnabled ?? false,
          value    : item?.attribute?.id || '',
          class    : 'synthesis-inter-text text-size-12 synthesis-brand-dark-blue--text',
          cellClass: 'text-size-14 synthesis-brand-dark-blue--text'
        })
      })
    }

    return headers
  }

  get TableValues () {
    if (this.Values && !!this.Values.length) {
      const values = []

      this.Values.forEach(item => {
        const company = item?.companyName || ''
        const attributes = item?.attributeValues || []

        const row = { Name: company }

        attributes.forEach(v => {
          const key = v.attribute?.id || ''

          row[key] = v.rawNumberValue
          row[`${ key }_raw`] = v.value
        })

        values.push(row)
      })

      return values
    }

    return []
  }

  /* METHODS */
  getDateFmt (date) {
    return date ? dayjs(date).format('DD MMM YYYY') : ''
  }
}
