import BaseResource from '@/lib/data/resource/BaseResource'

export default class CountryResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.id = payload.id ?? ''
    this.name = payload.name ?? ''
    this.shortName = payload.shortName ?? ''
    this.currencyCode = payload.currencyCode ?? ''
    this.iso2 = payload.iso2 ?? ''
    this.iso3 = payload.iso3 ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
