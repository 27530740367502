<template>
  <v-row>
    <v-col>
      <block-item-card class="pa-2">
        <template #header>
          <v-container
            fluid
            pa-0
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
                md="2"
                sm="3"
              >
                <div class="d-inline-block">
                  <label class="field-label">{{ $t('Alerts.Section.History.AlertType.Title') }}</label>
                  <v-select
                    v-model="alertType"
                    :items="$t('Alerts.Section.History.AlertType.Items')"
                    :placeholder="$t('Alerts.Section.History.AlertType.Placeholder')"
                    dense
                    full-width
                    hide-details
                    item-text="Title"
                    item-value="Value"
                    outlined
                    style="max-width: 270px"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <table-header-gradient-blue v-if="tableValues">
                <template #table>
                  <v-data-table
                    :footer-props="{
                      itemsPerPageOptions: [5, 10, 15, 25],
                      disablePagination: loading
                    }"
                    :headers="table.headers"
                    :items="tableValues"
                    :items-per-page.sync="options.itemsPerPage"
                    :loading="loading"
                    :page.sync="options.page"
                    :server-items-length="options.total"
                    class="font-weight-medium synthesis-card-border"
                    disable-sort
                  >
                    <template #header.TriggerDate="{header}">
                      {{ header.text }}
                      <infotip
                        v-if="$t('Alerts.Table.Headers.TriggerDateInfoTip')"
                        icon-style="margin-top: -12px;"
                      >
                        {{ $t('Alerts.Table.Headers.TriggerDateInfoTip') }}
                      </infotip>
                    </template>

                    <template #item.Name="{item}">
                      {{ item.Name }}
                    </template>

                    <template #item.Asset="{item}">
                      <template
                        v-if="item.Tags && !!item.Tags.length"
                      >
                        <v-chip
                          v-for="(tag, index) in item.Tags"
                          :key="`tag-${index}`"
                          :color="$vuetify.theme.themes.light['synthesis-ui-grey-05']"
                          class="pa-1 mr-1 mb-1 synthesis-inter-text text-size-10 synthesis-ui-grey-01--text text-uppercase"
                          label
                          x-small
                        >
                          {{ tag }}
                        </v-chip>
                      </template>
                      <template v-else>
                        {{ item.Asset }}
                      </template>
                    </template>

                    <template #item.Type="{item}">
                      {{ item.Type }}
                    </template>

                    <template #item.AlertInception="{item}">
                      {{ item.AlertInception }}
                    </template>

                    <template #item.TriggerDate="{item}">
                      {{ item.TriggerDate }}
                    </template>

                    <template #item.Threshold="{item}">
                      {{ item.Threshold }}
                    </template>

                    <template #item.Priority="{item}">
                      <v-row
                        align="center"
                        class="ma-0 justify-center"
                      >
                        <v-icon
                          v-if="item.Priority"
                          :color="`synthesis-ui-${AlertPriorityEnumHelper.getColor(item.Priority)}-01`"
                        >
                          mdi-bell
                        </v-icon>
                        <span
                          class="ml-2 text-left"
                          style="min-width: 50px"
                        >{{ item.Priority }}</span>
                      </v-row>
                    </template>
                  </v-data-table>
                </template>
              </table-header-gradient-blue>
            </v-col>
          </v-row>
        </v-container>
      </block-item-card>
    </v-col>
  </v-row>
</template>

<script>

import { clone }                                      from '@/lib/utils/helper'
import TableHeaderGradientBlue                        from '@/theme/default/components/common/TableHeaderGradientBlue'
import BlockItemCard                                  from '@/theme/default/components/common/BlockItemCard'
import AlertPriorityEnum, { AlertPriorityEnumHelper } from '@/api/enums/AlertPriorityEnum'
import API                                            from '@/api/Api'
import Infotip                                        from '@/theme/default/components/common/Infotip'
import AlertsArchiveResource                          from '@/api/resources/alerts/AlertsArchiveResource'

export default {
  name      : 'AlertHistory',
  components: {
    Infotip,
    BlockItemCard,
    TableHeaderGradientBlue
  },
  directives: {},
  mixins    : [],
  props     : {},
  dataStore : {
    alertsArchiveData: 'Alerts.Archive.Data'
  },
  enums: {
    AlertPriorityEnum,
    AlertPriorityEnumHelper
  },
  data () {
    return {
      loading: true,
      options: {
        itemsPerPage: 10,
        page        : 1,
        total       : 0
      },
      alertType: null,
      table    : {
        headers: [
          {
            text     : this.$t('Alerts.Table.Headers.Name'),
            align    : 'start',
            value    : 'Name',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text'],
            width    : '200px'
          },
          {
            text     : this.$t('Alerts.Table.Headers.Asset'),
            value    : 'Asset',
            align    : 'center',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text'],
            width    : '250px'
          },
          {
            text     : this.$t('Alerts.Table.Headers.Type'),
            value    : 'Type',
            align    : 'center',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text'],
            width    : '250px'
          },
          {
            text     : this.$t('Alerts.Table.Headers.AlertInception'),
            align    : 'center',
            value    : 'AlertInception',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Alerts.Table.Headers.TriggerDate'),
            align    : 'center',
            value    : 'TriggerDate',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Alerts.Table.Headers.TriggerLevel'),
            align    : 'center',
            value    : 'Threshold',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Alerts.Table.Headers.Priority'),
            align    : 'center',
            value    : 'Priority',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          }
        ]
      }
    }
  },
  computed: {
    accountId () {
      return this.user?.selectedAccountId || null
    },

    tableValues () {
      return this.alertsArchiveData?.TableValues ?? []
    }
  },
  watch: {
    'options.page': {
      handler () {
        this.getData()
      },
      immediate: true
    },

    alertType () {
      this.options.page = 1
      this.getData()
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      this.loading = true

      API.Resource.Alerts.Archive({
        accountId   : this.accountId,
        acknowledged: null,
        alertType   : this.alertType,
        itemsPerPage: this.options.itemsPerPage,
        page        : (this.options.page - 1)
      })
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        const list = data?.listAlertIncidents || null
        if (list) {
          const o = clone(list)
          const v = new AlertsArchiveResource(o)

          this.alertsArchiveData = v
          this.options.total = v.Total
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>
/deep/ .v-data-footer__select {
  visibility: hidden;
}
</style>
