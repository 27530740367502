export const Alerts = {
  Active: {
    Data: {}
  },

  Archive: {
    Data: {}
  },

  Summary: {
    Data: {}
  },

  ActiveIncidents: {
    Data: []
  }
}
