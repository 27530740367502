<template>
  <v-card
    :class="`pa-2 synthesis-card-border section-bg-gradient-${cardColor}${activeCard ? ' active': ''}`"
    :height="210"
    :min-height="210"
    flat
    v-on="enableCardClick ? {click: onCardClick} : {}"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="synthesis-text text-size-20 font-weight-medium synthesis-brand-dark-blue--text">
          <span
            v-if="caption"
            class="synthesis-inter-text text-size-10 synthesis-ui-grey-01--text d-block"
          >
            {{ caption }}
          </span>
          {{ title }}
        </div>
        <v-list-item-subtitle>
          <template v-for="(tag, index) in tags">
            <v-chip
              :key="`tag-${index}`"
              :color="$vuetify.theme.themes.light['synthesis-ui-grey-05']"
              class="pa-1 mr-1 mb-1 synthesis-inter-text text-size-10 synthesis-ui-grey-01--text text-uppercase"
              label
              x-small
            >
              {{ tag }}
            </v-chip>
          </template>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        :height="120"
        :min-height="120"
        :min-width="1"
        :width="1"
        class="ma-2"
        tile
      />
      <slot name="avatar" />
    </v-list-item>

    <v-card-actions class="pa-4">
      <span class="synthesis-inter-text text-size-10 synthesis-ui-grey-01--text">{{ dateCreated }}</span>

      <v-spacer />

      <v-menu
        v-if="menuItems && menuItems.length"
        bottom
        offset-y
      >
        <template #activator="{on, attrs}">
          <v-btn
            color="grey"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              mdi-dots-horizontal
            </v-icon>
          </v-btn>
        </template>

        <v-list class="pa-0 ma-0">
          <v-list-item
            v-for="(menuItem, menuIndex) in menuItems"
            :key="`menu-item-${menuIndex}`"
            :disabled="!menuItem.Active"
            @click="onCardMenuItemClick(menuItem)"
          >
            <v-list-item-title class="text-size-14">
              {{ getItemTitle(menuItem) }}
            </v-list-item-title>

            <v-list-item-action>
              <v-icon :color="menuItem.Active ? menuItem.Color : 'grey lighten-2'">
                {{ menuItem.Icon }}
              </v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        v-else
        icon
        @click.stop="onCardEditClick"
      >
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import i18n from '@/lang/lang'

export default {
  name      : 'ArchiveItemCard',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    baseCurrencySymbol: {
      type   : String,
      default: ''
    },
    caption: {
      type   : String,
      default: ''
    },
    title: {
      type   : String,
      default: ''
    },
    dateCreated: {
      type   : String,
      default: ''
    },
    tags: {
      type   : Array,
      default: () => []
    },
    cardColor: {
      type     : String,
      validator: (val) => ['blue', 'red', 'yellow', 'green', ''].find(item => item !== val.toLocaleLowerCase(i18n.locale)),
      default  : 'blue'
    },
    cardEvent: {
      type   : Boolean,
      default: false
    },
    menuItems: {
      type   : Array,
      default: () => []
    },
    activeCard: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    enableCardClick () {
      return this.cardEvent
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onCardClick () {
      this.$emit('card-click')
    },
    onCardEditClick () {
      this.$emit('card-edit-click')
    },
    onCardMenuItemClick (menuItem) {
      menuItem?.Event && this.$emit(menuItem.Event)
    },

    getItemTitle (menuItem) {
      if (menuItem?.Toggle) return this[menuItem?.Toggle?.Field] ? menuItem?.Toggle?.Title : menuItem.Title
      return menuItem.Title
    }
  }
}
</script>

<style scoped>
/deep/ .v-list-item--three-line .v-list-item__content {
  align-self  : initial;
  padding-top : 0;
}

/deep/ .v-list-item {
  align-items : initial;
}
</style>
