import BaseResource                             from '@/lib/data/resource/BaseResource'
import RiskForecastFlowRuglSummaryTableResource from '@/api/resources/risks/RiskForecastFlowRuglSummaryTableResource'

export default class RiskForecastFlowRuglSummaryResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Ugl = new RiskForecastFlowRuglSummaryTableResource(payload.ugl ?? null)
    this.Rgl = new RiskForecastFlowRuglSummaryTableResource(payload.rgl ?? null)
    this.UglHedgesFlows = payload.uglHedgesFlows ?? null
    this.RglHedgesFlows = payload.rglHedgesFlows ?? null
  }

  /* PROPERTIES */

  /* METHODS */
}
