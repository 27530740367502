<script>
import BaseEChartBar    from '@/theme/default/components/charts/echarts/BaseEChartBar'
import baseEChartColors from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'

import { first }                       from '@/lib/utils/array'
import { toNumberFormatNotationShort } from '@/lib/utils/helper'
import chartsBaseCurrencySymbolSingle  from '@/theme/default/components/charts/mixins/chartsBaseCurrencySymbolSingle'
import { use }                         from 'echarts/core'
import { GraphicComponent }            from 'echarts/components'

use([
  GraphicComponent
])

export default {
  name   : 'EChartHedgingInstrumentBar',
  extends: BaseEChartBar,
  mixins : [baseEChartColors, chartsBaseCurrencySymbolSingle],
  props  : {
    barTitle: {
      type   : String,
      default: ''
    },
    barColor: {
      type   : String,
      default: ''
    }
  },
  computed: {
    getChartGridMargin () {
      return this.chartGridMargin || {
        left        : 0,
        top         : 0,
        right       : 160,
        bottom      : 0,
        containLabel: false
      }
    },

    getChartDataOptions () {
      const o = this.series

      const title = {
        text     : this.barTitle,
        right    : 0,
        top      : 5,
        textAlign: 'left',
        textStyle: {
          fontFamily: this.fontFamily,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontSize  : 12,
          fontWeight: 600
        }
      }

      return {
        animation              : this.chartAnimation,
        animationThreshold     : this.chartAnimationThreshold,
        animationDuration      : this.chartAnimationDuration,
        animationEasing        : this.chartAnimationEasing,
        animationDurationUpdate: this.chartAnimationDuration,
        animationEasingUpdate  : this.chartAnimationEasing,
        tooltip                : { show: false },
        legend                 : { show: false },
        textStyle              : {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid : this.getChartGridMargin,
        xAxis: {
          type     : 'value',
          min      : 0,
          max      : o.max,
          axisLabel: { show: false },
          axisLine : { show: false },
          axisTick : { show: false },
          splitLine: { show: false }
          // boundaryGap: [0, '100%']
        },
        yAxis: {
          data     : o.yAxis,
          type     : 'category',
          axisLabel: { show: false },
          axisLine : { show: false },
          axisTick : { show: false },
          splitLine: { show: false }
        },
        color : this.barColor,
        cursor: this.cursor,
        series: o.series,
        title : title
      }
    },

    series () {
      let o = {
        x  : [],
        y  : [],
        max: ''
      }

      if (this.chartDataOptions.length) {
        o = first(this.chartDataOptions)
      }

      const baseCurrencySymbol = this.isBaseCurrencySymbolSingle ? this.baseCurrencySymbolSingle : this.axisYTickPrefix + ' '
      const labelColor = this.themeColorRGBA('synthesis-brand-dark-blue')

      const series = o.y.map((name, index) => {
        const values = new Array(o.x.length).fill('')
        values[index] = parseFloat(o.x[index]) || 0

        return {
          name          : name,
          type          : 'bar',
          stack         : 'chart',
          barCategoryGap: '25%',
          label         : {
            show          : true,
            silent        : true,
            position      : 'right',
            offset        : [-1, 0],
            fontSize      : 12,
            fontWeight    : 600,
            valueAnimation: true,
            color         : labelColor,
            formatter     : params => {
              return parseFloat(params.value) === 0 ? '-' : `${ baseCurrencySymbol }${ toNumberFormatNotationShort(parseFloat(params.value)) }`
            }
          },
          showBackground : this.showBackground,
          backgroundStyle: {
            color: this.barBackgroundColor
          },
          itemStyle: {
            // (clockwise upper left, upper right, bottom right and bottom left)
            borderRadius: [this.barBorderRadius, this.barBorderRadius, this.barBorderRadius, this.barBorderRadius]
          },
          data: values
        }
      })

      return {
        max   : o.max,
        yAxis : o.y,
        series: series
      }
    }
  },
  methods: {}
}
</script>

<style scoped>
.chart {
  width: 100%;
}
</style>
