export default {
  props: {
    step: {
      type   : Number,
      default: 1
    },

    steps: {
      type    : Array,
      required: true
    },

    nonLinear: {
      type   : Boolean,
      default: false
    },

    showFullStep: {
      type   : Boolean,
      default: false
    }
  },

  computed: {
    stepObj () {
      return this.steps[this.step - 1]
    },

    totalSteps () {
      return this.steps.length || 1
    },

    stepModel: {
      get () {
        return this.step
      },
      set (val) {
        this.$emit('update:step', val)
      }
    }
  },

  methods: {
    onStepperStepClick (index, step) {
      this.$emit('click-step', index, step)
    }
  }
}
