<template>
  <block-item-card
    :min-height="310"
    :title="$t('Dashboard.Block.Alerts.Title')"
  >
    <template #actions>
      <v-spacer />
      <v-card-title class="pa-0">
        <v-btn
          :to="{name: 'Alerts'}"
          class="px-2 button-text-underline synthesis-text text-size-12 font-weight-400"
          color="synthesis-ui-green-02"
          height="30"
          text
        >
          {{ $t('Dashboard.Block.Alerts.Button.ViewAll.Title') }}
        </v-btn>
      </v-card-title>
    </template>
    <v-card-text
      class="overflow-y-auto pt-0"
      style="height: 260px"
    >
      <template
        v-for="(item, i) in items"
      >
        <alert-list-card
          :key="`alert-list-card-${i}`"
          :item="item"
          :show-overlay="item.IsDummy"
          @alert-list-card-click="onAlertListCardClick(item)"
        />
      </template>
    </v-card-text>
  </block-item-card>
</template>

<script>
import BlockItemCard                   from '@/theme/default/components/common/BlockItemCard'
import AlertListCard                   from '@/theme/default/views/dashboard/blocks/AlertListCard'
import API                             from '@/api/Api'
import { clone }                       from '@/lib/utils/helper'
import DashboardAlertsListItemResource from '@/api/resources/dashboard/DashboardAlertsListItemResource'
import { collect }                     from '@/lib/utils/array'

export default {
  name      : 'AlertsBlock',
  components: {
    AlertListCard,
    BlockItemCard
  },
  directives: {},
  mixins    : [],
  props     : {},
  dataStore : {
    items: 'Dashboard.AlertsList.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    accountId () {
      return this.user?.selectedAccountId || null
    },

    alerts () {
      return this.alertsListData || null
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Alerts.AlertsDashboard(this.accountId)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },
    handleResponse (response) {
      const data = API.responseData(response)?.listDashboardAlerts || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        if (data) {
          const o = clone(data.length ? data : this.getDummyData())
          this.items = collect(o, DashboardAlertsListItemResource)
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    onAlertListCardClick (item) {
      if (item && item.Id) {
        this.$router.push({
          name  : 'AlertShow',
          params: {
            id     : item.Id,
            alertId: item.Id
          }
        })
      }
    },

    getDummyData () {
      return [
        {
          dummy          : true,
          id             : '44e08233-5c02-4849-a1b9-aa271706554b',
          name           : 'USD',
          priority       : 'High',
          alertType      : 'Asset_Price',
          threshold      : 1.17,
          conditionType  : 'Upper_Limit',
          currentDistance: 1.3
        },
        {
          dummy          : true,
          id             : '18d7ed96-4e26-4f9e-9a78-b4d2f06a4205',
          name           : 'Brent Oil',
          priority       : 'Medium',
          alertType      : 'Asset_Price',
          threshold      : 110,
          conditionType  : 'Upper_Limit',
          currentDistance: 0.24
        },
        {
          dummy          : true,
          id             : '81e4f270-9388-4911-a447-d33f24903f2f',
          name           : 'eur-oil',
          priority       : 'Low',
          alertType      : 'Asset_Price',
          threshold      : 115.26,
          conditionType  : 'Upper_Limit',
          currentDistance: 0.18
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
