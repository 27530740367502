<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>

import API         from '@/api/Api'
import { collect } from '@/lib/utils/array'
import AssetModel  from '@/api/models/asset/AssetModel'
import AssetEnum   from '@/api/enums/AssetEnum'

export default {
  name      : 'App',
  directives: {},
  components: {},
  mixins    : [],
  dataStore : {
    AppVersion: 'App.Version',
    AppConfig : 'App.Config',
    AppTheme  : 'App.Theme'
  },
  data () {
    return {}
  },
  computed: {
    layout () {
      return (this.$route?.meta?.layout || 'default') + '-layout'
    }
  },
  watch: {},
  created () {

    this.$bus.$on('Auth:Login:Success', this.getData)
    if (this.userIsAuthenticated) this.getData()
  },
  mounted () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off('Auth:Login:Success', this.getData)
  },
  methods: {
    getData () {
      this.getSupportedAssets()
    },

    getSupportedAssets () {
      API.Resource.Assets.SupportedAssets()
        .then(response => {
          this.handleSupportedAssetsResponse(response)
        })
        .catch(e => {
          this.handleSupportedAssetsResponse(e.response)
        })
        .finally(() => {})
    },
    handleSupportedAssetsResponse (response) {
      const data = API.responseData(response)?.supportedRiskAssets || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        this.$dataStore.Assets.All = collect(data, AssetModel)
        this.$dataStore.Assets.Currencies = collect(data.filter(item => item.type === AssetEnum.CURRENCY), AssetModel)
        this.$dataStore.Assets.CommonCurrencies = collect(this.$dataStore.Assets.Currencies.filter(item => ['EUR', 'GBP', 'USD'].includes(item.symbol)), AssetModel)
        this.$dataStore.Assets.Commodities = collect(data.filter(item => item.type === AssetEnum.COMMODITY), AssetModel)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style type="text/css">
::-webkit-scrollbar {
  width  : 8px;
  height : 10px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow    : inset 0 0 2px rgba(0, 0, 0, 0.3);
  -webkit-border-radius : 4px;
  border-radius         : 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius : 4px;
  border-radius         : 4px;
  background            : rgb(220, 220, 220);
  -webkit-box-shadow    : inset 0 0 1px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:window-inactive {
  background : rgb(220, 220, 220);
}

::-webkit-scrollbar-track {
  /*margin : 20px 20px;*/
}

/* PROJECT SPECIFIC GLOBAL VUETIFY CSS OVERRIDES */
</style>
