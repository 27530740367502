import { groupBy } from '@/lib/utils/array'

export default {
  name: 'AssetExposureCommon',
  data () {
    return {
      selectedExposure      : null,
      exposuresDialogVisible: false,
      maxExposures          : 10,
      maxExposuresPerAsset  : 4
    }
  },

  computed: {
    isAddExposureDisabled () {
      const grouped = groupBy(this.model.exposuresList, item => item.Exposure.assetCode)

      return Object.keys(grouped).length >= this.maxExposures && Object.values(grouped).every(item => {
        return item.length >= this.maxExposuresPerAsset
      })
    }
  },

  methods: {
    onExposureListItemEditButtonClick (exposureItem, index) {
      this.selectedEditItem = exposureItem.Exposure
      this.exposuresDialogVisible = true
    },

    onExposureListItemDeleteButtonClick (exposureItem, index) {
      this.$root.$confirmDialog(
        this.$t('Risks.AddExposure.Form.Confirm.Delete.Title'),
        this.$t('Risks.AddExposure.Form.Confirm.Delete.Body'),
        {
          color : 'synthesis-ui-red-02',
          button: {
            no : { title: this.$t('Common.Button.Cancel') },
            yes: { title: this.$t('Common.Button.Delete') }
          }
        }
      ).then(response => {
        response && this.model.exposures.splice(index, 1)
      })
    },

    onAddExposureClick () {
      this.exposuresDialogVisible = true
    },

    onExposureDialogClose () {
      this.selectedEditItem = null
    },

    onExposureSave (data) {
      const exposureIndex = this.model.exposures.findIndex(item => item.code === data.code)

      if (exposureIndex > -1) {
        this.$set(this.model.exposures, exposureIndex, data)
      } else {
        this.model.exposures.push(data)
      }

      this.validateForm()
    }

  }

}
