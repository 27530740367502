import BaseResource      from '@/lib/data/resource/BaseResource'
import AlertPriorityEnum from '@/api/enums/AlertPriorityEnum'

export default class AlertActiveIncidentResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.alertId = payload.alertId ?? null
    this.incidentId = payload.incidentId ?? null
    this.alertName = payload.alertName ?? ''
    this.message = payload.message ?? ''
    this.priority = payload.priority ?? AlertPriorityEnum.Low
    this.shownToUser = payload.shownToUser ?? false
  }

  /* PROPERTIES */

  /* METHODS */
}
