import { render, staticRenderFns } from "./MethodTwoBlock.vue?vue&type=template&id=b56f9998&scoped=true&"
import script from "./MethodTwoBlock.vue?vue&type=script&lang=js&"
export * from "./MethodTwoBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b56f9998",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardTitle,VCol,VLazy,VRow,VSpacer})
