import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class PositionValidator extends BaseValidator {
  validatorRules = {
    accountId: {
      required: true,
      type    : BaseValidator.Type.String
    },
    baseCurrencyCode: {
      required: true,
      type    : BaseValidator.Type.String
    },
    name: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 3
    },
    trades: {
      required: true,
      type    : BaseValidator.Type.Array,
      minLen  : 1
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
