import BaseResource                from '@/lib/data/resource/BaseResource'
import { collect }                 from '@/lib/utils/array'
import TrackLivePricesPairResource from '@/api/resources/dashboard/TrackLivePricesPairResource'

export default class TrackLivePricesInputResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.livePricesPairs = collect(payload.livePricesPairs, TrackLivePricesPairResource)
  }

  /* PROPERTIES */

  /* METHODS */
}
