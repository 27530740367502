import BaseResource from '@/lib/data/resource/BaseResource'

export default class TrackLivePricesPairResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.baseSymbol = payload?.pair?.baseSymbol || ''
    this.quoteSymbol = payload?.pair?.quoteSymbol || ''
  }

  /* PROPERTIES */

  /* METHODS */
}
