import BaseResource from '@/lib/data/resource/BaseResource'

export default class IndustryComparisonInputResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.accountId = payload.accountId ?? ''
    this.name = payload.name ?? null
    this.comparisonAttributeIds = payload.comparisonAttributeIds ?? []
    this.companyIds = payload.companyIds ?? []
  }

  /* PROPERTIES */

  /* METHODS */
}
