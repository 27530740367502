import BaseResource from '@/lib/data/resource/BaseResource'

export default class IndustryResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.id = payload.id ?? ''
    this.name = payload.name ?? ''
    this.sectorId = payload.sectorId ?? ''
    this.sectorName = payload.sectorName ?? ''
    this.industryGroup = payload.industryGroup ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
