<script>
import BaseEChartLine                                                              from '@/theme/default/components/charts/echarts/BaseEChartLine'
import baseEChartLineAreaProps                                                     from '@/theme/default/components/charts/echarts/mixins/lineArea/baseEChartLineAreaProps'
import baseEChartLineAreaData                                                      from '@/theme/default/components/charts/echarts/mixins/lineArea/baseEChartLineAreaData'
import { first }                                                                   from '@/lib/utils/array'
import { clone, toDecimalMark, toDecimalSignificant, toNumberFormatNotationShort } from '@/lib/utils/helper'
import dayjs                                                                       from 'dayjs'
import baseEChartColors                                                            from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'
import chartsBaseCurrencySymbolSingle                                              from '@/theme/default/components/charts/mixins/chartsBaseCurrencySymbolSingle'
import Common                                                                      from '@/lang/locale/en/Common'

export default {
  name    : 'EChartHistoricalSpectrum',
  extends : BaseEChartLine,
  mixins  : [baseEChartColors, chartsBaseCurrencySymbolSingle, baseEChartLineAreaProps, baseEChartLineAreaData],
  computed: {
    setChartDataOptions () {
      let values = {
        x: [],
        y: []
      }

      if (this.chartDataOptionsUpdated.length) {
        values = clone(this.chartDataOptionsUpdated)

        const series = values.map(item => {
          return {
            data: item.values
          }
        })

        return {
          series: series
        }
      }

      return {
        series: []
      }
    },

    getChartDataOptions () {
      const tooltipValuePrefix = this.isBaseCurrencySymbolSingle ? '' : '<span class="pt-4 pr-1 text-size-12">' + this.axisYTickPrefix + '</span>'

      let values = {
        x: [],
        y: []
      }

      if (this.chartDataOptions.length) {
        values = first(this.chartDataOptions)
      }

      const lineGraphValues = []

      const series = values.y.map(item => {
        lineGraphValues.push({
          name  : item.name,
          source: item.lineSource,
          target: item.lineTarget,
          color : item.lineColor,
          value : item.risk,
          x     : values.x,
          y     : item.values
        })

        return {
          type        : 'line',
          smooth      : this.chartLineSmooth,
          symbol      : this.chartLineSymbol,
          symbolSize  : this.chartLineSymbolSize,
          symbolOffset: this.chartLineSymbolOffset,
          showSymbol  : this.chartLineShowSymbol,
          sampling    : 'lttb',
          itemStyle   : {
            color: this.themeColorRGBA(item.color)
          },
          cursor: this.cursor,
          data  : item.values
        }
      })

      const o = {
        animation         : this.chartAnimation,
        animationThreshold: this.chartAnimationThreshold,
        animationDuration : this.chartAnimationDuration,
        animationEasing   : this.chartAnimationEasing,
        tooltip           : {
          trigger        : 'axis',
          backgroundColor: this.themeColorRGBA(this.tooltipBackgroundColor, 0.8),
          borderColor    : this.tooltipBorderColor,
          borderWidth    : this.tooltipBorderWidth,
          padding        : this.tooltipPadding,
          textStyle      : {
            color: this.tooltipLabelColor
          },
          formatter: params => {
            let axisValue = params[0].axisValue

            if (this.axisXLabelValueFormat === 'number') {
              axisValue = toNumberFormatNotationShort(parseFloat(axisValue))
            }
            if (this.axisXLabelValueFormat === 'percent') {
              axisValue = toDecimalMark(parseFloat(axisValue))
            }
            if (this.axisXLabelValueFormat === 'date') {
              axisValue = dayjs(axisValue).format(this.axisXLabelValueDateFormat)
            }

            const values = params.map(item => {
              if (item.seriesType === 'line') {
                return `<div class="pr-4"><span class="mr-2">${ item.marker } </span>${ tooltipValuePrefix }<span class="ml-0 synthesis-text text-size-18 font-weight-regular">${ this.baseCurrencySymbolSingle }${ toNumberFormatNotationShort(item.value) }</span></div>`
              }
            }).join('')

            return `<span class="synthesis-text text-size-14 font-weight-regular">${ axisValue }</span><br/>${ values }`
          }
        },
        textStyle: {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid : this.getChartGridMargin,
        xAxis: {
          data       : values.x,
          type       : 'category',
          offset     : 5,
          axisPointer: {
            z        : 1,
            show     : this.axisXPointerShow,
            snap     : true,
            lineStyle: {
              type      : this.axisPointerLineStyleType,
              dashOffset: this.axisPointerLineDashOffset,
              color     : this.axisPointerLineStyleColor,
              width     : this.axisPointerLineStyleWidth
            },
            label: {
              show     : this.axisYPointerLabelShow,
              formatter: (params) => {
                return this.$dayjs(params.value).format(this.axisXLabelValueDateFormat)
              },
              backgroundColor: this.axisPointerLabelBackgroundColor
            }
          },
          axisLine: {
            show     : this.axisXGridLineShow,
            lineStyle: {
              color: this.axisXGridLineStyleColor
            }
          },
          axisLabel: {
            visible   : true,
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            color     : this.axisLabelFontColor,
            formatter : (params) => {
              return (this.axisXTickPrefix || '') + this.$dayjs(params).format(this.axisXLabelValueDateFormat) + (this.axisXTickSuffix || '')
            },
            align      : 'center',
            inside     : this.axisXLabelInside,
            margin     : this.axisXLabelMargin,
            padding    : [0, 0, 0, 5], // [top, right, bottom, left]
            interval   : this.axisXLabelInterval,
            hideOverlap: this.axisXLabelHideOverlap
          },
          axisTick: {
            show          : false,
            inside        : true,
            alignWithLabel: true
          },
          minInterval: 30, // Minimum gap between split lines.
          splitLine  : {
            show     : this.axisXSplitLineShow,
            lineStyle: {
              type   : this.axisXSplitLineType,
              color  : this.axisXSplitLineColor,
              opacity: this.axisXSplitLineOpacity,
              width  : this.axisXSplitLineWidth
            }
          },
          splitNumber: this.axisXSplitNumber,
          min        : this.axisXMinValue,
          max        : this.axisXMaxValue,
          boundaryGap: this.axisXBoundaryGap,
          silent     : true
        },
        yAxis: {
          scale      : this.chartScale,
          type       : 'value',
          axisPointer: {
            show     : this.axisYPointerShow,
            lineStyle: {
              type      : this.axisPointerLineStyleType,
              dashOffset: this.axisPointerLineDashOffset,
              color     : this.axisPointerLineStyleColor,
              width     : this.axisPointerLineStyleWidth
            },
            label: {
              show           : this.axisYPointerLabelShow,
              backgroundColor: this.axisPointerLabelBackgroundColor
            }
          },
          axisLine: {
            onZero   : false,
            show     : this.axisYGridLineShow,
            lineStyle: {
              color: this.axisYGridLineStyleColor
            }
          },
          axisLabel: {
            visible   : true,
            inside    : this.axisYLabelInside,
            margin    : this.axisYLabelMargin,
            padding   : [0, 0, 0, 0], // [top, right, bottom, left]
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            color     : this.axisLabelFontColor,
            formatter : value => {
              if (this.axisYLabelValueFormat === 'number') {
                value = (value === 0 ? value : toNumberFormatNotationShort(parseFloat(value)))
              }
              if (this.axisYLabelValueFormat === 'percent') {
                value = (value === 0 ? value : toDecimalSignificant(parseFloat(value)))
              }
              if (this.axisYLabelValueFormat === 'date') {
                value = dayjs(value).format(this.axisYLabelValueDateFormat)
              }

              return (this.axisYTickPrefix + ' ' || '') + value + (this.axisYTickSuffix || '')
            },
            interval     : this.axisYLabelInterval,
            hideOverlap  : this.axisYLabelHideOverlap,
            verticalAlign: this.axisYLabelVerticalAlign
          },
          axisTick: {
            show          : false,
            inside        : true,
            alignWithLabel: true
          },
          splitLine: {
            show     : this.axisYSplitLineShow,
            lineStyle: {
              type   : this.axisYSplitLineType,
              color  : this.axisYSplitLineColor,
              opacity: this.axisYSplitLineOpacity,
              width  : this.axisYSplitLineWidth
            }
          },
          splitNumber: this.axisYSplitNumber,
          min        : this.axisYMinValue,
          max        : this.axisYMaxValue,
          boundaryGap: this.axisYBoundaryGap,
          silent     : true
        },
        series: series
      }

      if (this.markLineValue) {
        series[0].markLine = this.getMarkLine(this.markLineValue)
      }

      if (this.graphLineShow) {
        // https://echarts.apache.org/en/option.html#series-graph.type

        if (lineGraphValues && lineGraphValues.length) {
          let lineGraphValuesFiltered = clone(lineGraphValues)

          if (lineGraphValues.length > 1) {
            lineGraphValuesFiltered = lineGraphValues.filter(item => {
              return item.name !== Common.Seasonality.Map.MaxSeasonality
            })

            lineGraphValues.forEach(item1 => {
              lineGraphValues.forEach(item2 => {
                if (item1.name === Common.Seasonality.Map.UserSeasonality && item2.name === Common.Seasonality.Map.NoSeasonality) {
                  lineGraphValuesFiltered = [clone(item1)]
                }
              })
            })
          }

          lineGraphValuesFiltered.map(item => {
            const xx = item.x
            const graphLineValues = item.y.map((v, index) => {
              return {
                x    : xx[index],
                y    : v,
                value: v
              }
            })

            series.push({
              z                : 10,
              animationDuration: 400,
              animationDelay   : 400,
              silent           : true,
              type             : 'graph',
              layout           : 'none',
              symbol           : 'none',
              edgeSymbol       : ['circle', 'arrow'],
              coordinateSystem : 'cartesian2d',
              data             : graphLineValues,
              links            : [
                {
                  source      : item.source,
                  target      : item.target,
                  symbolSize  : [10, 10],
                  symbol      : ['pin', 'arrow'],
                  symbolRotate: -90,
                  label       : {
                    show      : true,
                    fontFamily: this.fontFamily,
                    fontSize  : this.axisLabelFontSize,
                    fontWeight: this.axisLabelFontWeight,
                    color     : item.color,
                    formatter : params => {
                      return (this.axisYTickPrefix + ' ' || '') + item.value
                    }
                  },

                  lineStyle: {
                    type     : 'dotted',
                    width    : 3,
                    opacity  : 1,
                    curveness: -0.36,
                    color    : this.themeColorRGBA(item.color)
                  }
                }
              ]
            })
          })
        }
      }

      return o
    }

  }
}
</script>
