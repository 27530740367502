<template>
  <block-item-card
    :min-height="100"
  >
    <v-card-title
      class="synthesis-text text-size-16 font-weight-medium synthesis-brand-dark-blue--text"
    >
      {{ $t('Hedging.Edit.Form.Fields.NaturalHedging.Label') }}
      <infotip
        v-if="$t('Hedging.Edit.Form.Fields.NaturalHedging.InfoTip')"
        icon-class="ml-1"
        icon-style="margin-top: -6px"
      >
        {{ $t('Hedging.Edit.Form.Fields.NaturalHedging.InfoTip') }}
      </infotip>
    </v-card-title>
    <v-card-text>
      <v-item-group
        v-model="model"
        :mandatory="true"
      >
        <v-container
          fluid
          pa-0
        >
          <v-row>
            <v-col cols="6">
              <v-item
                v-slot="{active, toggle}"
                :value="true"
              >
                <v-card
                  :class="[{'item-group-card-active': active}]"
                  class="item-group-card d-flex align-center"
                  flat
                  height="56"
                  @click="toggle"
                >
                  <v-icon
                    :color="active ? 'synthesis-ui-blue-04' : 'synthesis-ui-grey-02'"
                    class="pa-3"
                  >
                    {{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
                  </v-icon>

                  <v-card-title class="synthesis-text synthesis-brand-dark-blue--text text-size-14 font-weight-regular pl-0 text-no-wrap">
                    {{ $t('Hedging.Edit.Form.Fields.NaturalHedging.Include.Label') }}
                    <infotip
                      v-if="$t('Hedging.Edit.Form.Fields.NaturalHedging.Include.InfoTip')"
                      icon-class="ml-1"
                      icon-style="margin-top: -12px"
                    >
                      {{ $t('Hedging.Edit.Form.Fields.NaturalHedging.Include.InfoTip') }}
                    </infotip>
                  </v-card-title>
                </v-card>
              </v-item>
            </v-col>
            <v-col cols="6">
              <v-item
                v-slot="{active, toggle}"
                :value="false"
              >
                <v-card
                  :class="[{'item-group-card-active': active}]"
                  class="item-group-card d-flex align-center"
                  flat
                  height="56"
                  @click="toggle"
                >
                  <v-icon
                    :color="active ? 'synthesis-ui-blue-04' : 'synthesis-ui-grey-02'"
                    class="pa-3"
                  >
                    {{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
                  </v-icon>

                  <v-card-title class="synthesis-text synthesis-brand-dark-blue--text text-size-14 font-weight-regular pl-0 text-no-wrap">
                    {{ $t('Hedging.Edit.Form.Fields.NaturalHedging.Exclude.Label') }}
                  </v-card-title>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
    </v-card-text>
  </block-item-card>
</template>

<script>
import Infotip       from '@/theme/default/components/common/Infotip'
import BlockItemCard from '@/theme/default/components/common/BlockItemCard'

export default {
  name      : 'SelectNaturalHedging',
  components: {
    BlockItemCard,
    Infotip
  },
  directives: {},
  mixins    : [],
  props     : {
    value: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped>
.item-group-card {
  border: 1px solid var(--v-synthesis-ui-grey-02-base) !important;
  background-color: #FFFFFF;
}

.item-group-card-active {
  border: 1px solid var(--v-synthesis-ui-blue-04-base) !important;
  background-color: var(--v-synthesis-brand-lightest-blue-base);
}
</style>
