import { API } from '@/api/Api'

import {
  DeletePositionMutation,
  DeletePositionTradesMutation,
  NewPositionMutation,
  PositionHistoricalMtmQuery,
  PositionsArchiveQuery,
  PositionsDashboardQuery,
  PositionSetTradeStressTestFactorMutation,
  PositionSummaryMaturedTradesQuery,
  PositionSummaryQuery,
  PositionVisibilityMutation,
  TradeHistoricalMtmQuery
} from '@/api/query/Positions.graphql'

export default {
  // Queries
  async PositionsDashboard (accountId) {
    const Data = { accountId: accountId }

    return await API.GQLRequest(PositionsDashboardQuery, Data)
  },

  async Archive (accountId) {
    const Data = { accountId: accountId }

    return await API.GQLRequest(PositionsArchiveQuery, Data)
  },

  async Summary (positionId) {
    const Data = { positionId: positionId }

    return await API.GQLRequest(PositionSummaryQuery, Data)
  },

  async SummaryMaturedTrades (positionId) {
    const Data = { positionId: positionId }

    return await API.GQLRequest(PositionSummaryMaturedTradesQuery, Data)
  },

  async TradeHistoricalMtm (tradeId, period) {
    const Data = {
      tradeId: tradeId,
      from   : period
    }

    return await API.GQLRequest(TradeHistoricalMtmQuery, Data)
  },

  async PositionHistoricalMtm (portfolioId, period) {
    const Data = {
      portfolioId: portfolioId,
      from       : period
    }

    return await API.GQLRequest(PositionHistoricalMtmQuery, Data)
  },

  // Mutations
  async CreatePosition (data) {
    const Data = { ...data }

    return await API.GQLRequest(NewPositionMutation, Data)
  },

  async PositionVisibility (positionId, visibleOnDashboard) {
    const Data = {
      positionId        : positionId,
      visibleOnDashboard: visibleOnDashboard
    }

    return await API.GQLRequest(PositionVisibilityMutation, Data)
  },

  async UpdateTradeStressFactor (positionId, tradeStressFactors) {
    const Data = {
      portfolioId       : positionId,
      tradeStressFactors: tradeStressFactors
    }

    return await API.GQLRequest(PositionSetTradeStressTestFactorMutation, Data)
  },

  async DeletePosition (positionId) {
    const Data = { positionId: positionId }

    return await API.GQLRequest(DeletePositionMutation, Data)
  },

  async DeletePositionTrades (positionId, tradeIds) {
    const Data = {
      positionId: positionId,
      tradeIds  : tradeIds
    }

    return await API.GQLRequest(DeletePositionTradesMutation, Data)
  }
}
