export default {
  name : 'AppNavigationDrawerCommon',
  props: {
    navigationDrawer: {
      type    : Boolean,
      required: true
    },
    items: {
      type    : Array,
      required: true
    }
  },
  computed: {
    topItems () {
      return this.items.filter(item => item.pos === 'top')
    },

    bottomItems () {
      return this.items.filter(item => item.pos === 'bottom')
    },

    accountItems () {
      return this.items.filter(item => item.pos === 'account')
    },

    helpItems () {
      return this.items.filter(item => item.pos === 'help')
    },

    navigationDrawerVisible: {
      get () {
        return this.navigationDrawer
      },
      set (val) {
        this.$emit('update:navigationDrawer', val)
      }
    },

    userCompanyName () {
      return this.user?.selectedAccount?.company?.shortName || ''
    },

    userAvatarLetter () {
      return this.userCompanyName?.charAt(0) || ''
    }
  },

  methods: {
    onLogoutBtnClick () {
      this.$bus.$emit('app:logout')
    }
  }
}
