<template>
  <v-btn
    :color="disabled ? 'synthesis-ui-grey-02' : color"
    :disabled="disabled"
    :height="height"
    block
    class="font-weight-medium text-size-14"
    style="border: 2px dashed;"
    text
    x-large
    @click="$emit('click')"
  >
    <slot
      :icon="icon"
      :title="title"
    >
      <v-icon left>
        {{ icon }}
      </v-icon>
      {{ title }}
    </slot>
  </v-btn>
</template>

<script>

export default {
  name      : 'DashedActionButton',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    title: {
      type   : String,
      default: ''
    },

    icon: {
      type   : String,
      default: 'mdi-plus-circle-outline'
    },

    height: {
      type   : [Number, String],
      default: 80
    },

    color: {
      type   : String,
      default: 'synthesis-ui-green-02'
    },

    disabled: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
