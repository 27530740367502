<template>
  <v-card
    :min-height="90"
    class="synthesis-card-border pa-1"
    flat
  >
    <v-card-text>
      <v-row>
        <v-col>
          <div
            class="synthesis-inter-text text-size-12 synthesis-ui-grey-01--text"
            v-text="caption"
          />
          <p
            class="mb-0 synthesis-text text-size-32 font-weight-medium synthesis-brand-dark-blue--text"
          >
            <span
              v-if="baseCurrencySymbol && !isBaseCurrencySymbolSingle(baseCurrencySymbol)"
              class="pr-1 text-size-10 synthesis-brand-dark-blue--text"
              style="margin-top: 2px;"
              v-text="baseCurrencySymbol"
            />{{ baseCurrencySymbolSingle(baseCurrencySymbol) + price }}

            <template v-if="showPercent">
              <span
                :class="`ml-2 synthesis-inter-text text-size-14 ${percent > 0 ? 'synthesis-ui-green-01': 'synthesis-ui-red-02'}--text`"
              >
                <v-icon
                  :color="`${percent > 0 ? 'synthesis-ui-green-01': 'synthesis-ui-red-02'}`"
                  size="14"
                  style="margin-top: -2px;"
                >
                  {{ percent > 0 ? 'mdi-arrow-top-right' : 'mdi-arrow-bottom-right' }}
                </v-icon>
                {{ percent }}%
              </span>
            </template>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <slot name="combo" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name      : 'PositionsSummaryStressTestItemCard',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    baseCurrencySymbol: {
      type   : String,
      default: ''
    },
    caption: {
      type   : String,
      default: ''
    },
    price: {
      type   : [Number, String],
      default: ''
    },
    percent: {
      type   : [Number, String],
      default: ''
    },
    showPercent: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    isBaseCurrencySymbolSingle (baseCurrency) {
      return baseCurrency.length === 1
    },

    baseCurrencySymbolSingle (baseCurrency) {
      return this.isBaseCurrencySymbolSingle(baseCurrency) ? baseCurrency : ''
    }
  }
}
</script>

<style scoped>

</style>
