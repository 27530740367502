import BaseResource   from '@/lib/data/resource/BaseResource'
import OptionTypeEnum from '@/api/enums/OptionTypeEnum'

export default class OptionInputResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.strike = payload.strike ?? ''
    this.type = payload.type ?? OptionTypeEnum.Call
  }

  /* PROPERTIES */

  /* METHODS */
}
