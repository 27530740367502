import BaseModel                   from '@/lib/data/model/BaseModel'
import RiskDeptFixedInputValidator from '@/api/validations/risks/RiskDeptFixedInputValidator'
import RiskDeptFixedInputResource  from '@/api/resources/risks/RiskDeptFixedInputResource'

export default class RiskDeptFixedInputModel extends BaseModel {
  constructor (payload = {}, resource = RiskDeptFixedInputResource, validator = RiskDeptFixedInputValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = ['amortisationAnnually', 'amortisationQuarterly']
  }

  /* PROPERTIES */

  /* METHODS */
}
