import i18n     from '@/lang/lang'
import RoleEnum from '@/api/enums/RoleEnum'

export default async (to, from, next, injection) => {
  const vue = injection
  const roles = Object.values(RoleEnum)
  if (to.meta.requiresAuth) {
    if (!vue.user) {
      next({
        replace: true,
        name   : 'Login',
        params : { lang: i18n.locale },
        query  : {}
      })
    } else {
      next(vue.userCan(to.name, roles))
    }
  } else {
    next()
  }
}
