<template>
  <v-col
    cols="12"
  >
    <block-item-card class="pa-0 ma-0 px-2 pt-3">
      <template #header>
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <div class="d-inline-block">
                <label class="field-label">{{ $t('Risks.Section.CorrelationsAcrossExposures.DateRange') }}</label>
                <select-date-range
                  v-model="selectedDateRange"
                  :disabled="isInShowView"
                  :max="rangeMaxDate"
                  :min="rangeMinDate"
                  :min-range="6"
                  :presets-calc-from-date="rangeMaxDate"
                  :presets-list="$t('Risks.Section.CorrelationsAcrossExposures.Preset')"
                  @input="getDataByDateRange"
                />
              </div>
            </v-col>

            <v-col
              class="text-sm-right"
              cols="12"
              sm="6"
            >
              <div class="d-inline-block">
                <asset-button-group
                  :disabled="isInShowView"
                  :items="assets"
                  :selected.sync="selectedAsset"
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <v-card-text class="pa-0">
        <v-container
          class="overflow-y-auto"
          fluid
        >
          <template v-for="(row, i) in table">
            <v-row
              :key="`correlation-item-${i}`"
              class="mt-0"
            >
              <v-col
                class="pt-0 flex-nowrap text-no-wrap"
                cols="12"
                style="margin-top: -1px !important;"
              >
                <template v-for="(item, k) in row">
                  <v-sheet
                    :key="`sheet-correlation-row-item-${i}-${k}`"
                    :class="[item.class, 'text-no-wrap']"
                    :color="item.color"
                    :height="item.height"
                    :rounded="item.rounded"
                    :width="item.width"
                    min-width="130"
                    v-html="item.value"
                  />
                </template>
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-card-text>
    </block-item-card>
  </v-col>
</template>

<script>

import i18nRouteMeta            from '@/mixins/i18n/i18nRouteMeta'
import API                      from '@/api/Api'
import { clone }                from '@/lib/utils/helper'
import RiskCorrelationsResource from '@/api/resources/risks/RiskCorrelationsResource'
import riskAnalysisId           from '@/theme/default/views/risks/mixins/riskAnalysisId'
import BlockItemCard            from '@/theme/default/components/common/BlockItemCard'
import chartsColorMixin         from '@/theme/default/components/charts/mixins/chartsColorMixin'
import SelectDateRange          from '@/theme/default/components/form/SelectDateRange'
import AssetButtonGroup         from '@/theme/default/components/common/AssetButtonGroup'

export default {
  name      : 'RiskCorrelationsBlock',
  components: {
    AssetButtonGroup,
    SelectDateRange,
    BlockItemCard
  },
  directives: {},
  mixins    : [i18nRouteMeta, riskAnalysisId, chartsColorMixin],
  props     : {},
  dataStore : {
    riskCorrelationsData: 'Risks.Correlations.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      selectedDateRange: [],
      selectedAsset    : this.$t('Risks.Section.CorrelationsAcrossExposures.Monthly')
    }
  },
  computed: {
    rangeMinDate () {
      return this?.correlations?.RangeStartDate ? this.$dayjs(this.correlations.RangeStartDate).format('YYYY-MM') : undefined
    },

    rangeMaxDate () {
      return this?.correlations?.RangeEndDate ? this.$dayjs(this.correlations.RangeEndDate).format('YYYY-MM') : undefined
    },

    isInShowView () {
      return this.$route.name !== 'RisksAnalysisNew'
    },

    assets () {
      return [{
        Title: this.$t('Risks.Section.CorrelationsAcrossExposures.Weekly'),
        Value: this.$t('Risks.Section.CorrelationsAcrossExposures.Weekly')
      }, {
        Title: this.$t('Risks.Section.CorrelationsAcrossExposures.Monthly'),
        Value: this.$t('Risks.Section.CorrelationsAcrossExposures.Monthly')
      }]
    },

    correlations () {
      return this.riskCorrelationsData[this.id] || null
    },

    table () {
      return this.correlations?.Table ?? []
    }
  },
  watch: {
    selectedAsset () {
      if (!this.isInShowView) {
        this.calculateCorrelations()
      }
    },

    correlations: {
      handler () {
        this.setExportElements()
      },
      immediate: true
    }
  },
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Risks.RiskCorrelations(this.id)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
    },

    handleResponse (response) {
      const data = API.responseData(response)
      const errors = API.responseErrors(response) || []
      const result = data?.findAnalysis || null

      if (API.isResponseSuccess(response) && result) {
        const o = clone(result)
        const v = new RiskCorrelationsResource(o)

        this.$set(this.riskCorrelationsData, this.id, v)

        this.selectedAsset = this.correlations?.Frequency || this.$t('Risks.Section.CorrelationsAcrossExposures.Monthly')
        this.selectedDateRange = this.isInShowView ? this.correlations?.WindowDateRange || [] : this.correlations?.DateRange || []
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    getDataByDateRange (date) {
      this.calculateCorrelations()
    },

    calculateCorrelations () {
      const [startDate, endDate] = this.selectedDateRange
      const data = {
        riskAnalysisId: this.id,
        startDate     : startDate,
        endDate       : endDate,
        frequency     : this.selectedAsset
      }

      API.Resource.Risks.CalculateCorrelations(data)
        .then(response => {
          this.handleResponseDateByRange(response)
        })
        .catch(e => {
          this.handleResponseDateByRange(e.response)
        })
    },

    handleResponseDateByRange (response) {
      const data = API.responseData(response)
      const errors = API.responseErrors(response) || []
      const result = data?.calculateCorrelations || null

      if (API.isResponseSuccess(response) && result) {
        const o = clone(result)
        const v = new RiskCorrelationsResource(o)

        this.$set(this.riskCorrelationsData, this.id, v)

        this.selectedDateRange = this.correlations?.WindowDateRange || []
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    dateRangeWindow (date) {
      return this.$dayjs(date).isBetween(this?.correlations?.WindowStartDate, this?.correlations?.WindowEndDate, 'month', '[[')
    },

    setExportElements () {
      this.$nextTick(() => {
        this.$emit('export-element-updated', { el: this.$el })
      })
    }
  }
}
</script>

<style scoped>
/deep/ .override--border {
  border: var(--v-synthesis-ui-grey-02-base) 1px solid !important;
}

/deep/ .override--border-left-none {
  border-left: none !important;
}

/deep/ .override--border-left {
  border-left: var(--v-synthesis-ui-grey-02-base) 1px solid !important;
  margin-left: -1px;
}

/deep/ .override--border-top-none {
  border-top: none !important;
}

/deep/ .override--border-none {
  border: none !important;
}
</style>
