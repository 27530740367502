<template>
  <v-container
    class="fill-height align-start"
    fluid
  >
    <v-row
      class="fill-height"
      dense
    >
      <v-col class="">
        <v-row>
          <section-header :breadcrumb-items="breadcrumbItems" />

          <v-col
            v-if="$vuetify.breakpoint.xs"
            class="py-0"
            cols="12"
          >
            <form-stepper-steps
              :step.sync="step"
              :steps="steps"
            />
          </v-col>

          <v-col
            :class="[{'pa-0': !stepHasCard}]"
            :cols="stepCols"
          >
            <v-card
              :color="stepBgColor"
              class="synthesis-card-border"
              flat
            >
              <v-card-text
                :class="[stepHasCard ? 'pa-sm-4' : 'pa-sm-0']"
                class="pa-0"
              >
                <v-form
                  ref="form"
                  v-model="isFormValid"
                  @submit.prevent
                >
                  <v-window v-model="step">
                    <v-window-item :value="1">
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12">
                            <label class="field-label">{{ $t('Alerts.Edit.Form.Fields.Name.Label') }}</label>
                            <v-text-field
                              v-model="model.name"
                              :error-messages="serverErrorMessages['name']"
                              :placeholder="$t('Alerts.Edit.Form.Fields.Name.Placeholder')"
                              :rules="model.validator.vuetifyFormFieldRules('name')"
                              hide-details="auto"
                              outlined
                            />
                          </v-col>

                          <v-col cols="12">
                            <label class="field-label">{{ $t('Alerts.Edit.Form.Fields.Description.Label') }}</label>
                            <v-textarea
                              v-model="model.description"
                              :error-messages="serverErrorMessages['description']"
                              :placeholder="$t('Alerts.Edit.Form.Fields.Description.Placeholder')"
                              :rules="model.validator.vuetifyFormFieldRules('description')"
                              hide-details="auto"
                              outlined
                              rows="2"
                            />
                          </v-col>

                          <v-col cols="6">
                            <label class="field-label">{{ $t('Alerts.Edit.Form.Fields.Type.Label') }}</label>
                            <v-select
                              v-model="model.type"
                              :error-messages="serverErrorMessages['type']"
                              :items="$t('Alerts.Edit.Form.Fields.Type.Items')"
                              :placeholder="$t('Alerts.Edit.Form.Fields.Type.Placeholder')"
                              :rules="model.validator.vuetifyFormFieldRules('type')"
                              hide-details="auto"
                              item-text="Title"
                              item-value="Value"
                              outlined
                            />
                          </v-col>

                          <v-col cols="6">
                            <label class="field-label">{{ $t('Alerts.Edit.Form.Fields.Priority.Label') }}</label>
                            <v-select
                              v-model="model.priority"
                              :error-messages="serverErrorMessages['priority']"
                              :items="$t('Alerts.Edit.Form.Fields.Priority.Items')"
                              :placeholder="$t('Alerts.Edit.Form.Fields.Priority.Placeholder')"
                              :rules="model.validator.vuetifyFormFieldRules('priority')"
                              hide-details="auto"
                              item-text="Title"
                              item-value="Value"
                              outlined
                            >
                              <template #item="{item}">
                                <v-list-item-content>
                                  <v-list-item-title v-text="item.Title" />
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-icon
                                    :color="item.IconColor"
                                    :size="18"
                                  >
                                    {{ item.Icon }}
                                  </v-icon>
                                </v-list-item-action>
                              </template>
                            </v-select>
                          </v-col>

                          <v-col
                            v-show="model.type === AlertTypeEnum.AssetPrice"
                            cols="12"
                          >
                            <select-base-currency
                              v-model="model.baseCurrencyCode"
                              :error-messages="serverErrorMessages['baseCurrencyCode']"
                              :rules="model.validator.vuetifyFormFieldRules('baseCurrencyCode')"
                              :use-user-base-currency="false"
                              disabled
                            />
                          </v-col>

                          <v-col cols="12">
                            <label class="field-label">
                              {{ $t('Alerts.Edit.Form.Fields.ExpirationDate.Label') }}
                            </label>

                            <select-date
                              v-model="model.expirationDate"
                              :error-messages="serverErrorMessages['expirationDate']"
                              :min="$dayjs().format('YYYY-MM-DD')"
                              :placeholder="$t('Alerts.Edit.Form.Fields.ExpirationDate.Placeholder')"
                              :rules="model.validator.vuetifyFormFieldRules('expirationDate')"
                              hide-details="auto"
                              clearable
                            />

                            <v-checkbox
                              v-model="model.enableEmailNotifications"
                              :error-messages="serverErrorMessages['enableEmailNotifications']"
                              :false-value="false"
                              :label="$t('Alerts.Edit.Form.Fields.EnableEmailNotifications.Label')"
                              :rules="model.validator.vuetifyFormFieldRules('enableEmailNotifications')"
                              :true-value="true"
                              hide-details="auto"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-window-item>

                    <v-window-item :value="2">
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12">
                            <item-list-card
                              :items="model.triggersList"
                              :menu-items="$t('Alerts.Edit.Form.Fields.Triggers.MenuItems')"
                              @list-item-edit-button-click="onTriggerListItemEditButtonClick"
                              @list-item-delete-button-click="onTriggerListItemDeleteButtonClick"
                            />

                            <dashed-action-button
                              :disabled="isAddTriggerDisabled"
                              :title="$t('Alerts.New.Form.Fields.Triggers.Label')"
                              @click="onAddTriggerClick"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-window-item>
                  </v-window>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        class="text-right"
        cols="12"
        lg="4"
        md="5"
        sm="12"
        xl="2"
      >
        <form-stepper
          :step.sync="step"
          :steps="steps"
          class="d-inline-flex"
          @click-btn-cancel="onStepperBtnCancelClick"
          @click-btn-back="onStepperBtnBackClick"
        />
      </v-col>
    </v-row>

    <add-alert-trigger-dialog
      :alert-type="model.type"
      :base-currency-code.sync="model.baseCurrencyCode"
      :item="selectedEditItem"
      :triggers-list="model.triggers"
      :visible.sync="addTriggerDialogVisible"
      @form:save:success="onTriggerSave"
      @dialog:close="onTriggerDialogClose"
    />

    <overlay-loader :value="loaderVisible" />
  </v-container>
</template>

<script>

import i18nRouteMeta         from '@/mixins/i18n/i18nRouteMeta'
import SectionHeader         from '@/theme/default/components/common/SectionHeader'
import FormStepper           from '@/theme/default/components/form/formStepper/FormStepper'
import formStepper           from '@/theme/default/components/form/formStepper/mixins/formStepper'
import AddAlertTriggerDialog from '@/theme/default/views/alerts/AddAlertTriggerDialog'
import OverlayLoader         from '@/theme/default/components/common/OverlayLoader'
import formData              from '@/mixins/form/formData'
import API                   from '@/api/Api'
import SelectBaseCurrency    from '@/theme/default/components/form/SelectBaseCurrency'
import SelectDate            from '@/theme/default/components/form/SelectDate'
import AlertTypeEnum         from '@/api/enums/AlertTypeEnum'
import ItemListCard          from '@/theme/default/components/common/ItemListCard'
import AlertEditInputModel   from '@/api/models/alerts/AlertEditInputModel'
import FormStepperSteps      from '@/theme/default/components/form/formStepper/components/FormStepperSteps'
import { first }             from '@/lib/utils/array'
import DashedActionButton    from '@/theme/default/components/common/DashedActionButton'

export default {
  name      : 'AlertEdit',
  components: {
    DashedActionButton,
    FormStepperSteps,
    ItemListCard,
    SelectDate,
    SelectBaseCurrency,
    OverlayLoader,
    AddAlertTriggerDialog,
    FormStepper,
    SectionHeader
  },
  directives: {},
  mixins    : [i18nRouteMeta, formData, formStepper],
  props     : {},
  enums     : {
    AlertTypeEnum
  },
  dataStore: {},
  dataModel: AlertEditInputModel,
  data () {
    return {
      loaderVisible          : false,
      addTriggerDialogVisible: false,
      selectedEditItem       : null,
      breadcrumbItems        : [
        {
          text    : this.$t('Alerts.Title'),
          disabled: false,
          exact   : true,
          to      : { name: 'Alerts' }
        },
        {
          text    : this.$t('Alerts.Edit.Title'),
          disabled: true
        }
      ]
    }
  },
  computed: {
    steps () {
      return [
        {
          Title   : this.$t('Alerts.Edit.Form.Steps.Step1.Title'),
          Complete: this.step > 1,
          HasCard : true,
          BgColor : '#FFFFFF',
          Fields  : ['name', 'description', 'type', 'priority', 'baseCurrencyCode', 'expirationDate', 'enableEmailNotifications']
        },
        {
          Title    : this.$t('Alerts.Edit.Form.Steps.Step2.Title'),
          Complete : this.step > 2,
          HasCard  : false,
          BgColor  : 'transparent',
          Fields   : ['triggers'],
          BtnAction: {
            Title         : this.$t('Alerts.Edit.Form.Steps.Step2.Button.Action'),
            CallBack      : this.updateAlert,
            PreventDefault: true
          }
        }
      ]
    },

    isAddTriggerDisabled () {
      return this.model.triggers.length >= 10
    }
  },
  watch: {
    'model.type': function (newVal, oldVal) {
      if (newVal !== oldVal && oldVal !== undefined) this.model.triggers = []
    }
  },
  beforeCreate () {},
  created () {
    if (!this.item) this.$router.replace({ name: 'Alerts' })
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onStepperBtnBackClick () {},

    onStepperBtnCancelClick () {
      this.$router.push({ name: 'Alerts' })
    },

    onTriggerListItemEditButtonClick (triggerItem, index) {
      this.selectedEditItem = triggerItem.Trigger
      this.addTriggerDialogVisible = true
    },

    onTriggerListItemDeleteButtonClick (triggerItem, index) {
      this.$root.$confirmDialog(
        this.$t('Alerts.AddTrigger.Form.Confirm.Delete.Title'),
        this.$t('Alerts.AddTrigger.Form.Confirm.Delete.Body'),
        {
          color : 'synthesis-ui-red-02',
          button: {
            no : { title: this.$t('Common.Button.Cancel') },
            yes: { title: this.$t('Common.Button.Delete') }
          }
        }
      ).then(response => {
        response && this.model.triggers.splice(index, 1)
      })
    },

    onAddTriggerClick () {
      this.addTriggerDialogVisible = true
    },

    onTriggerDialogClose () {
      this.selectedEditItem = null
    },

    onTriggerSave (data) {
      const triggerIndex = this.model.triggers.findIndex(item => item.id === data.id)
      if (triggerIndex > -1) {
        this.model.triggers[triggerIndex] = data
      } else {
        this.model.triggers.push(data)
      }
      this.selectedEditItem = null
    },

    updateAlert () {
      this.loaderVisible = true

      this.model.UpdateAlert()
        .then(response => {
          this.handleUpdateAlertResponse(response)
        })
        .catch(e => {
          this.handleUpdateAlertResponse(e.response)
        })
        .finally(() => {
          this.loaderVisible = false
        })
    },

    handleUpdateAlertResponse (response) {
      const data = API.responseData(response)?.updateAlertDefinition || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.$router.push({ name: 'Alerts' })
      } else {
        const error = first(errors)
        if (error?.extensions?.classification === 'DataFetchingException' && error?.extensions?.code === 'new-alert-is-firing') {
          this.$root.$confirmDialog(
            this.$t('Alerts.New.Form.Confirm.IgnoreFiring.Title'),
            this.$t('Alerts.New.Form.Confirm.IgnoreFiring.Body'),
            {
              color : 'synthesis-ui-orange-02',
              button: {
                no : { title: this.$t('Common.Button.Cancel') },
                yes: { title: this.$t('Common.Button.Proceed') }
              }
            }
          ).then(response => {
            if (response) {
              this.model.ignoreFiring = true
              this.updateAlert()
            }
          })
        } else {
          this.$bus.$emit('app:error', errors)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
