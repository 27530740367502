<template>
  <v-btn-toggle
    :disabled="disabled"
    active-class="toggle-active"
    borderless
    class="d-flex pa-1 rounded synthesis-ui-grey-03"
    dense
    group
    mandatory
    style="padding: 0 !important; border-radius: 8px !important;"
  >
    <v-btn
      :disabled="disabled"
      :height="height"
      :style="`margin: ${buttonMargin}px; border-radius: ${buttonBorderRadius}px !important;`"
      class="grow rounded"
      text
      x-small
      @click="onButtonClick"
    >
      {{ title }}
      <v-icon
        v-if="icon"
        color="synthesis-ui-grey-01"
        v-text="icon"
      />
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name      : 'ButtonTextToggle',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    disabled: {
      type   : Boolean,
      default: false
    },
    title: {
      type   : String,
      default: ''
    },
    icon: {
      type   : String,
      default: ''
    },
    buttonBorderRadius: {
      type   : [String, Number],
      default: 6
    },
    buttonMargin: {
      type   : [String, Number],
      default: 2
    },
    height: {
      type   : [String, Number],
      default: 28
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    onButtonClick () {
      this.$emit('button-text-toggle-click')
    }
  }
}
</script>

<style scoped>
/deep/ .theme--light.toggle-active.v-btn--active::before,
.theme--light.toggle-active.v-btn--active:hover::before,
.theme--light.toggle-active.v-btn--active {
  opacity: 1 !important;
  background-color: var(--v-synthesis-ui-grey-06-base) !important;
}

/deep/ .theme--light.v-btn {
  font-size: 12px !important;
  font-weight: 600 !important;
}

/deep/ .theme--light.v-btn::before {
  background-color: var(--v-synthesis-ui-grey-06-base) !important;
  opacity: 0;
}

/deep/ .theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before {
  color: var(--v-synthesis-ui-purple-02-base);
}

/deep/ .v-btn-toggle .v-btn > span.v-btn__content {
  color: var(--v-synthesis-ui-purple-02-base) !important;
  font-weight: normal !important;
}

/deep/ .theme--light.toggle-active.v-btn--active > span.v-btn__content {
  color: var(--v-synthesis-ui-purple-02-base) !important;
  font-weight: 600 !important;
}

/deep/ .theme--light.v-btn {
  color: var(--v-synthesis-ui-grey-01-base) !important;
}
</style>
