<template>
  <block-item-card
    :min-height="310"
    :title="title"
  >
    <v-row
      v-if="chartValues && chartValues.length"
      class="ma-0"
    >
      <v-col
        class="pa-0"
        cols="12"
      >
        <v-lazy
          :options="{threshold: .5}"
          transition="fade-transition"
        >
          <template v-if="isForwardSimulations || isHistogram">
            <e-chart-forward-simulations
              :axis-x-boundary-gap="true"
              :axis-y-tick-prefix="baseCurrency"
              :bar-background-color="themeColorRGBA('synthesis-ui-grey-06', 0.1)"
              :chart-data-options="chartValues"
              :chart-height="262"
              :chart-scale="true"
              :has-line-bell="false"
              :is-bar-chart="isHistogram"
              axis-x-label-value-date-format="MMM YY"
              axis-y-label-value-format="percent"
              chart-animation-easing="quarticOut"
            />
          </template>
          <template v-else-if="isBarChartPct || isBarChart">
            <base-e-chart-bar
              :axis-label-font-color="themeColorRGBA('synthesis-ui-grey-01')"
              :axis-x-label-value-format="axisXLabelValueFormat"
              :axis-x-tick-suffix="axisXTickSuffix"
              :axis-y-label-inside="false"
              :axis-y-label-margin="0"
              :axis-y-label-show="true"
              :axis-y-label-value-format="axisYLabelValueFormat"
              :axis-y-split-line-dash-offset="4"
              :axis-y-split-line-opacity="1"
              :axis-y-split-line-type="[8, 8]"
              :axis-y-split-number="2"
              :axis-y-tick-prefix="baseCurrency"
              :axis-y-tick-suffix="axisYTickSuffix"
              :bar-background-color="themeColorRGBA('synthesis-ui-grey-05', 0.1)"
              :chart-data-options="chartValues"
              :chart-grid-margin="{left: 10,top: 20,right: 10,bottom: 10,containLabel: true}"
              :tooltip-show="false"
              :tooltip-show-content="false"
              chart-animation-easing="quarticOut"
            />
          </template>
          <template v-else-if="isPieChart">
            <base-e-chart-pie
              :axis-y-tick-prefix="baseCurrency"
              :chart-data-options="chartValues"
              :chart-height="260"
              chart-animation-easing="elasticOut"
            />
          </template>
        </v-lazy>
      </v-col>
    </v-row>
  </block-item-card>
</template>
<script>

import BlockItemCard            from '@/theme/default/components/common/BlockItemCard'
import API                      from '@/api/Api'
import { clone }                from '@/lib/utils/helper'
import DashboardChartResource   from '@/api/resources/dashboard/DashboardChartResource'
import EChartForwardSimulations from '@/theme/default/components/charts/echarts/EChartForwardSimulations'
import DashboardPlotTypeEnum    from '@/api/enums/DashboardPlotTypeEnum'
import chartsColorMixin         from '@/theme/default/components/charts/mixins/chartsColorMixin'
import BaseEChartBar            from '@/theme/default/components/charts/echarts/BaseEChartBar'
import BaseEChartPie            from '@/theme/default/components/charts/echarts/BaseEChartPie'

export default {
  name      : 'DashboardChartBlock',
  components: {
    BaseEChartPie,
    BaseEChartBar,
    EChartForwardSimulations,
    BlockItemCard
  },
  directives: {},
  mixins    : [chartsColorMixin],
  props     : {},
  dataStore : {
    chartData: 'Dashboard.Chart.Data'
  },
  enums    : { DashboardPlotTypeEnum },
  dataModel: null,
  data () {
    return {
      axisXTickSuffix      : '%',
      axisYTickSuffix      : '%',
      axisXLabelValueFormat: 'percent',
      axisYLabelValueFormat: 'percent'
    }
  },
  computed: {
    accountId () {
      return this.user?.selectedAccountId || null
    },

    chart () {
      return this.chartData || null
    },

    title () {
      return this.chartData?.Title || ''
    },

    baseCurrency () {
      return this.chartData?.BaseCurrencyTitle || ''
    },

    chartType () {
      return this.chartData?.Type ?? DashboardPlotTypeEnum.FORWARD_LOOKING_CHART
    },

    isForwardSimulations () {
      return this.chartType === DashboardPlotTypeEnum.FORWARD_LOOKING_CHART
    },

    isHistogram () {
      return this.chartType === DashboardPlotTypeEnum.BAR_CHART_HISTOGRAM
    },

    isBarChart () {
      return this.chartType === DashboardPlotTypeEnum.BAR_CHART
    },

    isBarChartPct () {
      return this.chartType === DashboardPlotTypeEnum.BAR_CHART_PCT
    },

    isPieChart () {
      return this.chartType === DashboardPlotTypeEnum.PIE_CHART
    },

    chartValues () {
      return this.chart?.Values || []
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Dashboard.Chart(this.accountId)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },
    handleResponse (response) {
      const data = API.responseData(response)?.dashboardPlot || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        if (data) {
          const o = clone(data)
          this.chartData = new DashboardChartResource(o)

          this.$nextTick(() => {
            if (this.isBarChartPct) {
              this.axisYTickSuffix = '%'
              this.axisXTickSuffix = '%'
              this.axisYLabelValueFormat = 'percent'
              this.axisXLabelValueFormat = 'percent'
            }

            if (this.isBarChart) {
              this.axisYTickSuffix = ''
              this.axisXTickSuffix = ''
              this.axisYLabelValueFormat = 'number'
              this.axisXLabelValueFormat = 'percent'
            }
          })
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>

</style>
