<template>
  <v-app>
    <v-main class="synthesis login-bg">
      <v-container
        class="fill-height"
        fluid
        pa-0
      >
        <v-row class="fill-height">
          <v-col
            class="text-center"
            cols="12"
          >
            <v-container
              class="fill-height"
              fluid
              style="max-width: 550px;"
            >
              <v-row class="fill-height align-center">
                <v-col cols="12">
                  <v-card
                    class="pa-2 py-12 pa-sm-12"
                    flat
                    style="border-radius: 8px;"
                  >
                    <slot />
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name      : 'LoginLayout',
  components: {},
  mixins    : [],
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {},
  methods : {}
}

</script>

<style scoped>

</style>
