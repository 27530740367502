<template>
  <v-row>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="550px"
      persistent
      scrollable
      transition="slide-x-reverse-transition"
    >
      <v-card min-height="100vh">
        <div>
          <v-toolbar
            color="section-bg-gradient-yellow"
            flat
          >
            <v-spacer />

            <v-btn
              icon
              @click="closeDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <div class="px-5 mt-1">
            <v-icon
              color="orange"
              size="24"
            >
              mdi-poll
            </v-icon>

            <div class="mt-0">
              <div class="synthesis-text text-size-24 synthesis-brand-dark-blue--text font-weight-medium">
                {{ title }}
              </div>
              <div class="synthesis-text text-size-14 synthesis-ui-grey-01--text" />
            </div>

            <v-list class="pa-0 ma-0">
              <v-list-item class="px-0">
                <v-list-item-content>
                  <v-list-item-title class="synthesis-text text-size-16 synthesis-brand-dark-blue--text font-weight-medium">
                    {{ baseSymbol }}
                  </v-list-item-title>

                  <v-list-item-subtitle class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium">
                    {{ quoteDisplayName }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action-text class="text-right">
                  <v-chip
                    class="font-weight-medium"
                    color="synthesis-ui-green-07 synthesis-ui-green-00--text"
                    label
                    small
                  >
                    {{ quotePrice }}
                  </v-chip>
                  <div class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium pt-1">
                    {{ description }}
                  </div>
                </v-list-item-action-text>
              </v-list-item>
            </v-list>
          </div>
          <v-divider />
        </div>

        <div>
          <v-card-text class="pt-5">
            <v-container
              fluid
              pa-0
            >
              <v-row>
                <v-col cols="12">
                  <base-e-chart-line-area
                    v-if="historicalDailyQuotesChartData"
                    :axis-pointer-line-style-color="themeColorRGBA('synthesis-ui-purple-06')"
                    :axis-pointer-line-style-width="2"
                    :axis-x-label-padding="[0, 2, 0, 0]"
                    :axis-x-label-value-date-format="dateValueFormat"
                    :axis-y-label-inside="false"
                    :axis-y-label-margin="10"
                    :axis-y-split-line-color="themeColorRGBA('synthesis-ui-grey-03', 1)"
                    :axis-y-split-line-dash-offset="4"
                    :axis-y-split-line-opacity="1"
                    :axis-y-split-line-type="[8, 8]"
                    :axis-y-tick-prefix="tickSymbolPrefix"
                    :axis-y-tick-suffix="tickSymbolSuffix"
                    :axis-y-label-value-format="labelValueFormat"
                    :chart-animation-duration="800"
                    :chart-area-fill-color="[themeColorRGBA('synthesis-ui-purple-01', 0.4), themeColorRGBA('synthesis-ui-purple-01', 0.03)]"
                    :chart-data-options="historicalDailyQuotesChartValues"
                    :chart-grid-margin="{left: 5, right: 0, top: 10, bottom: 10, containLabel: true}"
                    :chart-height="400"
                    :chart-line-color="themeColorRGBA('synthesis-ui-purple-01')"
                    :chart-line-show-symbol="false"
                    :chart-line-symbol-offset="[0, 0]"
                    :chart-line-symbol-size="10"
                    :chart-scale="true"
                    axis-x-label-align="right"
                    axis-x-label-value-format="date"
                    axis-x-type="category"
                    chart-line-symbol="circle"
                  />
                </v-col>
                <v-col
                  class="text-right"
                  cols="12"
                >
                  <div class="d-inline-flex">
                    <asset-button-group
                      :height="26"
                      :items="periodItems"
                      :selected.sync="selectedPeriod"
                      disable-dropdown-on-mobile
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider />
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dialogVisible      from '@/mixins/dialog/dialogVisible'
import AssetEnum          from '@/api/enums/AssetEnum'
import AssetButtonGroup   from '@/theme/default/components/common/AssetButtonGroup'
import API                from '@/api/Api'
import { first }          from '@/lib/utils/array'
import BaseEChartLineArea from '@/theme/default/components/charts/echarts/BaseEChartLineArea'
import chartsColorMixin   from '@/theme/default/components/charts/mixins/chartsColorMixin'
import { clone }          from '@/lib/utils/helper'

export default {
  name      : 'ShowLivePriceQuoteDialog',
  components: {
    BaseEChartLineArea,
    AssetButtonGroup
  },
  directives: {},
  mixins    : [dialogVisible, chartsColorMixin],
  props     : {
    quote: {
      type   : Object,
      default: () => {}
    }
  },
  dataStore: {},
  enums    : { AssetEnum },
  data () {
    return {
      historicalDailyQuotesChartData: null,
      selectedPeriod                : this.$dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      dateValueFormat               : 'D MMM',
      periodItems                   : this.$t('Common.Date.PeriodItemsWithDaily')
    }
  },
  computed: {
    title () {
      return this.historicalDailyQuotesChartData?.pair?.quote?.name || ''
    },

    baseSymbol () {
      return this.historicalDailyQuotesChartData?.pair?.base?.displayName || ''
    },

    quoteSymbol () {
      return this.historicalDailyQuotesChartData?.pair?.quote?.displaySymbol || ''
    },

    quoteDisplayName () {
      return this.historicalDailyQuotesChartData?.pair?.quote?.displayName || ''
    },

    quotePrice () {
      return this.historicalDailyQuotesChartData?.pair?.latestQuote?.displayPrice || ''
    },

    description () {
      return this.historicalDailyQuotesChartData?.quotation?.description || ''
    },

    assetType () {
      return this.historicalDailyQuotesChartData?.pair?.base?.type || ''
    },

    tickSymbolPrefix () {
      return this.assetType === AssetEnum.INTERBANK_RATE ? '' : this.quoteSymbol
    },

    tickSymbolSuffix () {
      return this.assetType === AssetEnum.INTERBANK_RATE ? '%' : ''
    },

    labelValueFormat () {
      return this.assetType === AssetEnum.INTERBANK_RATE ? AssetEnum.INTERBANK_RATE : 'number'
    },

    historicalDailyQuotesChartValues () {
      return [{
        x: this.historicalDailyQuotesChartData?.dates || [],
        y: this.fixChartDataPrices()
      }]
    }
  },
  watch: {
    isVisible (val) {
      if (val) {
        this.getHistoricalDailyQuotes()
      } else {
        this.historicalDailyQuotesChartData = null
      }
    },

    selectedPeriod (val) {
      this.setDateValueFormat(val)
      this.getHistoricalDailyQuotes()
    }
  },
  beforeCreate () {},
  created () {
    this.getHistoricalDailyQuotes()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getHistoricalDailyQuotes (pair = this.quote?.Pair, period = this.selectedPeriod) {
      if (!pair) return

      API.Resource.Assets.HistoricalDailyQuotes([pair], period)
        .then(response => {
          this.handleHistoricalDailyQuotesResponse(response)
        })
        .catch(e => {
          this.handleHistoricalDailyQuotesResponse(e.response)
        })
        .finally(() => {})
    },
    handleHistoricalDailyQuotesResponse (response) {
      const data = API.responseData(response)?.historicalDailyQuotes || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.historicalDailyQuotesChartData = first(data)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    setDateValueFormat (value) {
      const o = clone(this.periodItems)
      const index = o.findIndex(item => item.Value === value)
      if (index > -1) {
        const period = o[index]
        switch (period.Title) {
          case this.$t('Common.Date.Periods.Day'):
            this.dateValueFormat = 'H'
            break
          case this.$t('Common.Date.Periods.Week'):
            this.dateValueFormat = 'ddd D'
            break
          case this.$t('Common.Date.Periods.Month'):
            this.dateValueFormat = 'D MMM'
            break
          case this.$t('Common.Date.Periods.Year'):
          case this.$t('Common.Date.Periods.All'):
            this.dateValueFormat = 'MMM YY'
        }
      }
    },

    fixChartDataPrices () {
      const prices = this.historicalDailyQuotesChartData?.prices || []

      if (this.assetType !== AssetEnum.INTERBANK_RATE) {
        return prices
      }

      const v = []

      if (prices && !!prices.length) {
        prices.forEach(price => {
          v.push(price * 100)
        })
      }

      return v
    }
  }
}
</script>

<style scoped>
/* v-dialog overrides */
.v-dialog__content {
  align-items: start;
  justify-content: end;
  height: 100%;
}

/deep/ .v-dialog {
  border-radius: 4px;
  margin: 0px !important;
  max-height: 100% !important;
}
</style>
