import BaseResource                from '@/lib/data/resource/BaseResource'
import { collect, first, last }    from '@/lib/utils/array'
import RiskSummaryAssetResource    from '@/api/resources/risks/RiskSummaryAssetResource'
import RiskSummaryPieAssetResource from '@/api/resources/risks/RiskSummaryPieAssetResource'
import i18n                        from '@/lang/lang'
import { clone }                   from '@/lib/utils/helper'

export default class RiskSummaryResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const o = payload.result ?? null
    const summary = o.summary ?? null

    this.HasEvents = o.hasEvents ?? false
    this.HasHistorical = o.hasHistorical ?? false
    this.HasSimulation = o.hasSimulation ?? false
    this.HasValueAtRisk = o.hasVar ?? false
    this.HasDebtProfile = o.hasDebtProfile ?? false

    this.Id = payload.id ?? ''
    this.Name = payload.name ?? ''
    this.Active = payload.active ?? false
    this.Assets = summary.assets ?? []
    this.Items = collect(summary.items ?? [], RiskSummaryAssetResource)
    this.PieAssets = collect(summary.pieComponents ?? [], RiskSummaryPieAssetResource)
  }

  /* PROPERTIES */
  get TotalRiskPerAsset () {
    return this.Items.filter(item => item.Asset !== i18n.t('Common.Asset.Portfolio'))
  }

  get Portfolio () {
    const index = this.Items.findIndex(item => item.Asset === i18n.t('Common.Asset.Portfolio'))
    if (index > -1) {
      return this.Items[index]
    }

    return null
  }

  get PortfolioContributionValues () {
    const p = this.Portfolio

    if (p) {
      return [
        p?.ValueAtRiskRaw,
        p?.HistoricalRaw,
        p?.SimulationRaw,
        p?.ExtremeEventRaw
      ]
    }
    return []
  }

  get AnnualRisk () {
    return this.Portfolio?.AnnualRisk || ''
  }

  get PieValues () {
    if (this.PieAssets) {
      return this.PieAssets.map(item => {
        return {
          name : item.Asset,
          type : item.Type,
          value: item.Value
        }
      })
    }

    return []
  }

  get MethodologyContribution () {
    const series = [{
      xAxis    : i18n.t('Risks.Section.RiskSummary.MethodologyBreakdown.BarChart.XAxis'),
      data     : [],
      portfolio: this.PortfolioContributionValues
    }]

    const v = this.Items

    if (v && v.length) {
      const o = clone(v).filter(item => item.Asset !== i18n.t('Common.Asset.Portfolio'))
      const data = []
      const dataAggregate = []
      const dataUp = {
        A: [],
        B: [],
        C: [],
        D: []
      }
      const dataDown = {
        A: [],
        B: [],
        C: [],
        D: []
      }
      o.forEach(item => {
        data[item.Asset] = []
        dataAggregate[item.Asset] = item.PerCashflowItems
      })
      o.forEach(item => {
        // Value At Risk
        if (item.hasOwnProperty('ValueAtRiskRaw')) {
          data[item.Asset].push(item.ValueAtRiskRaw)

          if (item.ValueAtRiskRaw > 0) {
            dataUp.A.push(item.Asset)
          } else {
            dataDown.A.push(item.Asset)
          }
        }

        // Historical
        if (item.hasOwnProperty('HistoricalRaw')) {
          data[item.Asset].push(item.HistoricalRaw)

          if (item.HistoricalRaw > 0) {
            dataUp.B.push(item.Asset)
          } else {
            dataDown.B.push(item.Asset)
          }
        }

        // Forward Simulation
        if (item.hasOwnProperty('SimulationRaw')) {
          data[item.Asset].push(item.SimulationRaw)

          if (item.SimulationRaw > 0) {
            dataUp.C.push(item.Asset)
          } else {
            dataDown.C.push(item.Asset)
          }
        }

        // Extreme Events
        if (item.hasOwnProperty('ExtremeEventRaw')) {
          data[item.Asset].push(item.ExtremeEventRaw)

          if (item.ExtremeEventRaw > 0) {
            dataUp.D.push(item.Asset)
          } else {
            dataDown.D.push(item.Asset)
          }
        }
      })

      // Asset labels for portfolio
      const labelKeys = []
      for (const [key, value] of Object.entries(dataUp)) {
        if (value.length) {
          labelKeys.push(last(value))
        } else {
          labelKeys.push(first(dataDown[key]))
        }
      }

      series[0].data = data
      series[0].dataAggregate = dataAggregate
      series[0].labels = labelKeys
    }

    return series
  }

  /* METHODS */
}
