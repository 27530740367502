<template>
  <v-col
    ref="extremeEvents"
    cols="12"
  >
    <v-row v-if="coreEvents && !!coreEvents.length">
      <template v-for="(item, i) in coreEvents">
        <v-col
          :key="`extreme-events-col-${i}`"
          cols="12"
          lg="6"
          md="6"
        >
          <block-item-card
            :base-currency-symbol="riskBaseCurrencyDisplayName"
            :export-element="i === 0 ? elementExtremeEvents : undefined"
            :export-file-name="$t('Risks.Tab.Summary.Tab.ExtremeEvent.Title')"
            :min-height="150"
            :title="item.Name"
            :title-large="false"
            :title-value="item.PortfolioLoss"
            :title-value-color-green="item.PortfolioChangePositive"
            :title-value-color-red="item.PortfolioChangeNegative"
            :tooltip="i === 0 ? $t('Risks.Section.ExtremeEvents.InfoTip') : ''"
            @export-to-image="onExportToImageClick"
            @export-to-image-completed="onExportToImageCompleted"
          >
            <v-lazy
              :options="{threshold: .5}"
              :value="disableLazy"
              transition="fade-transition"
            >
              <base-e-chart-line-area
                :axis-pointer-line-style-color="themeColorRGBA('synthesis-ui-blue-06')"
                :axis-pointer-line-style-width="2"
                :axis-x-label-interval="30"
                :axis-x-max-value="item.EndDate"
                :axis-x-min-value="item.StartDate"
                :axis-x-split-number="2"
                :axis-y-label-inside="false"
                :axis-y-label-margin="5"
                :axis-y-split-number="1"
                :axis-y-tick-prefix="riskBaseCurrencyDisplayName"
                :chart-animation="chartAnimation"
                :chart-animation-duration="600"
                :chart-data-options="item.ChartData"
                :chart-grid-margin="{left: 20,top: 10,right: 20,bottom: 20,containLabel: true}"
                :chart-height="280"
                :chart-scale="true"
              />
            </v-lazy>

            <v-card-text
              v-if="item.PerAssetRates && item.PerAssetRates.length"
              class="pt-0 pb-2 overflow-y-auto"
              style="max-height: 220px"
            >
              <template
                v-for="(asset, k) in item.PerAssetRates"
              >
                <item-chart-legend
                  :key="`extreme-events-per-asset-legend-${i}-${k}`"
                  :background-color="themeColorRGBA(asset.ColorBg)"
                  :title="asset.Asset"
                  :title-value="asset.PercentageChange"
                  :title-value-color="asset.Color"
                />
              </template>
            </v-card-text>
          </block-item-card>
        </v-col>
      </template>
    </v-row>

    <v-row v-if="visibleExtraEvents && extraEvents && !!extraEvents.length">
      <template v-for="(item, i) in extraEvents">
        <v-col
          :key="`extreme-events-col-${i}`"
          cols="12"
          lg="6"
          md="6"
        >
          <block-item-card
            :base-currency-symbol="riskBaseCurrencyDisplayName"
            :min-height="150"
            :title="item.Name"
            :title-large="false"
            :title-value="item.PortfolioLoss"
            :title-value-color-green="item.PortfolioChangePositive"
            :title-value-color-red="item.PortfolioChangeNegative"
          >
            <template #title>
              <extreme-event-select
                :key="`extreme-event-select-${i}`"
                v-model="extremeEventSelected[i]"
                :disabled="disabledExtraEventsSelect"
                :items="allSupportedEvents"
                :name="item.Name"
              />
            </template>

            <v-lazy
              :options="{threshold: .5}"
              :value="disableLazy"
              transition="fade-transition"
            >
              <base-e-chart-line-area
                :axis-pointer-line-style-color="themeColorRGBA('synthesis-ui-blue-06')"
                :axis-pointer-line-style-width="2"
                :axis-x-label-interval="30"
                :axis-x-max-value="item.EndDate"
                :axis-x-min-value="item.StartDate"
                :axis-x-split-number="2"
                :axis-y-label-inside="false"
                :axis-y-label-margin="5"
                :axis-y-split-number="1"
                :axis-y-tick-prefix="riskBaseCurrencyDisplayName"
                :chart-animation="chartAnimation"
                :chart-animation-duration="600"
                :chart-data-options="item.ChartData"
                :chart-grid-margin="{left: 20,top: 10,right: 20,bottom: 20,containLabel: true}"
                :chart-height="280"
                :chart-scale="true"
              />
            </v-lazy>

            <v-card-text
              v-if="item.PerAssetRates && item.PerAssetRates.length"
              class="pt-0 pb-2 overflow-y-auto"
              style="max-height: 220px"
            >
              <template
                v-for="(asset, k) in item.PerAssetRates"
              >
                <item-chart-legend
                  :key="`extreme-events-per-asset-legend-${i}-${k}`"
                  :background-color="themeColorRGBA(asset.ColorBg)"
                  :title="asset.Asset"
                  :title-value="asset.PercentageChange"
                  :title-value-color="asset.Color"
                />
              </template>
            </v-card-text>
          </block-item-card>
        </v-col>
      </template>
    </v-row>
  </v-col>
</template>

<script>
import chartsColorMixin              from '@/theme/default/components/charts/mixins/chartsColorMixin'
import BaseEChartLineArea            from '@/theme/default/components/charts/echarts/BaseEChartLineArea'
import API                           from '@/api/Api'
import { clone }                     from '@/lib/utils/helper'
import BlockItemCard                 from '@/theme/default/components/common/BlockItemCard'
import ItemChartLegend               from '@/theme/default/components/common/ItemChartLegend'
import riskAnalysisId                from '@/theme/default/views/risks/mixins/riskAnalysisId'
import riskBaseCurrency              from '@/theme/default/views/risks/mixins/riskBaseCurrency'
import RiskExtremeEventsBaseResource from '@/api/resources/risks/RiskExtremeEventsBaseResource'
import ExtremeEventSelect            from '@/theme/default/components/common/ExtremeEventSelect'

export default {
  name      : 'MethodFourBlock',
  components: {
    ExtremeEventSelect,
    ItemChartLegend,
    BlockItemCard,
    BaseEChartLineArea
  },
  directives: {},
  mixins    : [riskBaseCurrency, chartsColorMixin, riskAnalysisId],
  props     : {},
  dataStore : {
    riskExtremeEventsData: 'Risks.ExtremeEvents.Data',
    riskBaseCurrencyData : 'Risks.BaseCurrency.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      onInit               : true,
      elementExtremeEvents : null,
      hideExtraEventsSelect: false,
      disableLazy          : false,
      chartAnimation       : true,
      extremeEventSelected : []
    }
  },
  computed: {
    extremeEvents () {
      return this.riskExtremeEventsData[this.id] || null
    },

    riskAnalysisName () {
      return this.extremeEvents?.Name || ''
    },

    events () {
      return this.extremeEvents?.Events || null
    },

    coreEvents () {
      return this.extremeEvents?.CoreEvents || null
    },

    extraEvents () {
      return this.extremeEvents?.ExtraEvents || null
    },

    allSupportedEvents () {
      return this.extremeEvents?.AllSupportedEvents || null
    },

    selectedEvents () {
      return this.extremeEvents?.SelectedEvents || null
    },

    visibleExtraEvents () {
      return this.extremeEvents?.Visible || false
    },

    disabledExtraEventsSelect () {
      if (this.hideExtraEventsSelect) {
        return true
      }

      return this.extremeEvents?.Disabled || false
    }
  },
  watch: {
    extremeEventSelected () {
      if (this.onInit) {
        this.onInit = false
        return
      }
      this.updateExtremeEvents()
    }
  },
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {
    this.$nextTick(() => {
      this.elementExtremeEvents = this.$el
    })
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    updateExtremeEvents () {
      API.Resource.Risks.UpdateExtremeEvents(this.id, this.extremeEventSelected)
        .then(response => {
          this.handleUpdateExtremeEventsResponse(response)
        })
        .catch(e => {
          this.handleUpdateExtremeEventsResponse(e.response)
        })
    },
    handleUpdateExtremeEventsResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []
      const result = data?.selectExtremeEvents || null

      if (API.isResponseSuccess(response) && result) {
        const o = clone(result)

        o.id = this.id
        o.name = this.riskAnalysisName

        const v = new RiskExtremeEventsBaseResource(o)

        this.$set(this.riskExtremeEventsData, this.id, v)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    getData () {
      API.Resource.Risks.ExtremeEvents(this.id)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
    },
    handleResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []
      const result = data?.findAnalysis || null

      if (API.isResponseSuccess(response) && result) {
        const o = clone(result)
        const v = new RiskExtremeEventsBaseResource(o)

        if (v) {
          if (v.SelectedEvents && !!v.SelectedEvents.length) {
            this.extremeEventSelected = [
              v.SelectedEvents[0].Value,
              v.SelectedEvents[1].Value
            ]
          }
        }
        this.$set(this.riskExtremeEventsData, this.id, v)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    onExportToImageClick () {
      this.chartAnimation = false
      this.disableLazy = true
      this.hideExtraEventsSelect = true
    },

    onExportToImageCompleted () {
      this.hideExtraEventsSelect = false
    }
  }
}
</script>

<style scoped>

</style>
