<template>
  <v-container
    class="align-content-start"
    fill-height
    fluid
  >
    <v-row>
      <section-header
        :breadcrumb-items="breadcrumb"
        :button="true"
        :button-title="$t('Risks.Button.New.Title')"
        button-to="RisksAnalysisNew"
      />
    </v-row>

    <v-row style="height: calc(100% - 70px);">
      <v-col cols="12">
        <v-container
          class="align-content-start"
          fill-height
          fluid
          pa-0
        >
          <v-row>
            <v-col cols="12">
              <v-tabs
                v-model="activeTab"
                active-class="active-tab"
                background-color="transparent"
                height="30"
                hide-slider
                show-arrows
              >
                <v-tab :href="`#${$t('Risks.Tab.Active.Slug')}`">
                  {{ $t('Risks.Tab.Active.Title') }}
                </v-tab>

                <v-tab :href="`#${$t('Risks.Tab.Archive.Slug')}`">
                  {{ $t('Risks.Tab.Archive.Title') }}
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>

          <v-row class="fill-height">
            <v-col
              class="pa-0"
              cols="12"
            >
              <v-tabs-items
                v-model="activeTab"
                class="fill-height"
              >
                <v-tab-item :value="$t('Risks.Tab.Active.Slug')">
                  <risks-analysis-active v-if="activeTab === $t('Risks.Tab.Active.Slug')" />
                </v-tab-item>

                <v-tab-item :value="$t('Risks.Tab.Archive.Slug')">
                  <risks-analysis-archive v-if="activeTab === $t('Risks.Tab.Archive.Slug')" />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta        from '@/mixins/i18n/i18nRouteMeta'
import SectionHeader        from '@/theme/default/components/common/SectionHeader'
import Auth                 from '@/mixins/auth'
import RisksAnalysisActive  from '@/theme/default/views/risks/RisksAnalysisActive'
import RisksAnalysisArchive from '@/theme/default/views/risks/RisksAnalysisArchive'
import routerTabState       from '@/mixins/routerTabState'

export default {
  name      : 'Risks',
  components: {
    RisksAnalysisArchive,
    RisksAnalysisActive,
    SectionHeader
  },
  directives: {},
  mixins    : [Auth, i18nRouteMeta, routerTabState],
  props     : {},
  dataStore : {},
  enums     : {},
  data () {
    return {}
  },
  computed: {
    breadcrumb () {
      return [{
        text    : this.$t('Risks.Title'),
        disabled: true
      }]
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
/deep/ .v-window__container,
/deep/ .v-window-item {
  height: 100% !important;
}
</style>
