<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :persistent="options.persistent"
    @keydown.esc="close"
  >
    <v-card flat>
      <v-toolbar
        dense
        flat
      >
        <v-spacer />

        <v-btn
          icon
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="px-6 pb-6">
        <div
          v-if="options.icon"
          class="text-center"
        >
          <v-icon :color="options.color">
            {{ options.icon }}
          </v-icon>
        </div>

        <v-card-title
          class="justify-center text-center text-size-20 font-weight-medium synthesis-brand-dark-blue--text"
          v-html="title"
        />

        <v-card-text
          class="justify-center text-center text-size-16 font-weight-medium synthesis-ui-grey-01--text"
          v-html="message"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Error Dialog component
 *
 * Insert component where you want to use it:
 * <error-dialog ref="errorDialog"></error-dialog>
 *
 * Call it:
 * this.$refs.errorDialog.open('Delete', 'Are you sure?', { color: 'red' }).then((close) => {})
 * Or use await:
 * if (await this.$refs.errorDialog.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$errorDialog
 * <template>
 *   <v-app>
 *     ...
 *     <error-dialog ref="errorDialog"></confirm-dialog>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$errorDialog = this.$refs.errorDialog.open
 * }
 */

export default {
  name      : 'ErrorDialog',
  components: {},
  directives: {},
  mixins    : [],
  props     : [],
  data () {
    return {
      dialog        : false,
      resolve       : null,
      title         : '',
      message       : '',
      defaultOptions: {
        icon      : 'mdi-alert-circle',
        color     : 'synthesis-ui-red-02',
        width     : 400,
        persistent: true
      },
      options: {}
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {
    this.options = { ...this.defaultOptions }
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    open (title = this.$t('Common.Error.Generic.Title'), message = this.$t('Common.Error.Generic.Message'), options = {}) {
      this.title = title || ''
      this.message = message || ''
      this.options = { ...this.defaultOptions, ...options }
      this.dialog = true
      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },

    close () {
      this.resolve && this.resolve(true)
      this.dialog = false
    }
  }
}
</script>

<style scoped>
/deep/ .v-dialog {
  box-shadow : none;
}
</style>
