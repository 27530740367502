import BaseResource from '@/lib/data/resource/BaseResource'
import dayjs        from 'dayjs'

export default class ArticleResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.id ?? ''
    this.Title = payload.title ?? ''
    this.Summary = payload.summary ?? ''
    this.Category = payload.category ?? ''
    this.ImageUrl = payload.imageUrl ?? ''
    this.Difficulty = payload.difficulty ?? ''
    this.Date = this.getDateFmt(payload.publicationDate ?? null)
    this.DateFmt = this.getDateFmt(payload.publicationDate ?? null)
  }

  /* PROPERTIES */

  /* METHODS */
  getDateFmt (date) {
    return date ? dayjs(date).format('DD MMM YYYY') : ''
  }
}
