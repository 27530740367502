import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class TrackLivePricesInputValidator extends BaseValidator {
  validatorRules = {
    livePricesPairs: {
      required: true,
      type    : BaseValidator.Type.Array,
      minLen  : 0
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
