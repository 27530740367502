import BaseResource from '@/lib/data/resource/BaseResource'
import i18n         from '@/lang/lang'

export default class AssetCodeNameResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const code = payload.code ?? null

    this.Title = payload.name ?? ''
    this.Value = code ? code.toLocaleLowerCase(i18n.code) : ''
  }

  /* PROPERTIES */

  /* METHODS */
}
