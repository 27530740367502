var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('block-item-card',{staticClass:"pa-2",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-container',{attrs:{"fluid":"","pa-0":""}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"12","md":"2","sm":"3"}},[_c('div',{staticClass:"d-inline-block"},[_c('label',{staticClass:"field-label"},[_vm._v(_vm._s(_vm.$t('Alerts.Section.History.AlertType.Title')))]),_c('v-select',{staticStyle:{"max-width":"270px"},attrs:{"items":_vm.$t('Alerts.Section.History.AlertType.Items'),"placeholder":_vm.$t('Alerts.Section.History.AlertType.Placeholder'),"dense":"","full-width":"","hide-details":"","item-text":"Title","item-value":"Value","outlined":""},model:{value:(_vm.alertType),callback:function ($$v) {_vm.alertType=$$v},expression:"alertType"}})],1)])],1)],1)]},proxy:true}])},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.tableValues)?_c('table-header-gradient-blue',{scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('v-data-table',{staticClass:"font-weight-medium synthesis-card-border",attrs:{"footer-props":{
                    itemsPerPageOptions: [5, 10, 15, 25],
                    disablePagination: _vm.loading
                  },"headers":_vm.table.headers,"items":_vm.tableValues,"items-per-page":_vm.options.itemsPerPage,"loading":_vm.loading,"page":_vm.options.page,"server-items-length":_vm.options.total,"disable-sort":""},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:page":function($event){return _vm.$set(_vm.options, "page", $event)}},scopedSlots:_vm._u([{key:"header.TriggerDate",fn:function(ref){
                  var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(_vm.$t('Alerts.Table.Headers.TriggerDateInfoTip'))?_c('infotip',{attrs:{"icon-style":"margin-top: -12px;"}},[_vm._v(" "+_vm._s(_vm.$t('Alerts.Table.Headers.TriggerDateInfoTip'))+" ")]):_vm._e()]}},{key:"item.Name",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.Name)+" ")]}},{key:"item.Asset",fn:function(ref){
                  var item = ref.item;
return [(item.Tags && !!item.Tags.length)?_vm._l((item.Tags),function(tag,index){return _c('v-chip',{key:("tag-" + index),staticClass:"pa-1 mr-1 mb-1 synthesis-inter-text text-size-10 synthesis-ui-grey-01--text text-uppercase",attrs:{"color":_vm.$vuetify.theme.themes.light['synthesis-ui-grey-05'],"label":"","x-small":""}},[_vm._v(" "+_vm._s(tag)+" ")])}):[_vm._v(" "+_vm._s(item.Asset)+" ")]]}},{key:"item.Type",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.Type)+" ")]}},{key:"item.AlertInception",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.AlertInception)+" ")]}},{key:"item.TriggerDate",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.TriggerDate)+" ")]}},{key:"item.Threshold",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.Threshold)+" ")]}},{key:"item.Priority",fn:function(ref){
                  var item = ref.item;
return [_c('v-row',{staticClass:"ma-0 justify-center",attrs:{"align":"center"}},[(item.Priority)?_c('v-icon',{attrs:{"color":("synthesis-ui-" + (_vm.AlertPriorityEnumHelper.getColor(item.Priority)) + "-01")}},[_vm._v(" mdi-bell ")]):_vm._e(),_c('span',{staticClass:"ml-2 text-left",staticStyle:{"min-width":"50px"}},[_vm._v(_vm._s(item.Priority))])],1)]}}],null,false,3007927816)})]},proxy:true}],null,false,1151426139)}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }