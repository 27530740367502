import BaseResource                    from '@/lib/data/resource/BaseResource'
import AssetModel                      from '@/api/models/asset/AssetModel'
import { toNumberFormatNotationShort } from '@/lib/utils/helper'

export default class HedgingHedgeRatiosResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Name = payload.itemName ?? ''
    this.Asset = new AssetModel(payload.detailedAsset ?? {})
    this.BaseCurrency = new AssetModel(payload.baseCurrency ?? null)
    this.Ratio = payload.ratio ?? ''
    this.Scores = payload.scores ?? []
    this.HedgeAmount = payload.hedgeAmount ?? ''
    this.HedgeAmountFmt = payload.hedgeAmount ? toNumberFormatNotationShort(payload.hedgeAmount) : ''
  }

  /* PROPERTIES */

  /* METHODS */
}
