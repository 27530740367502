<template>
  <v-toolbar
    color="transparent"
    flat
  >
    <div>
      <slot name="caption">
        <div
          v-if="caption"
          class="text-size-12 font-weight-medium synthesis-ui-grey-01--text"
        >
          {{ caption }}
        </div>
      </slot>

      <slot name="title">
        <div
          v-if="title"
          class="text-size-24 font-weight-medium synthesis-brand-dark-blue--text pl-0"
        >
          {{ title }}
          <slot name="tooltip">
            <infotip
              v-if="tooltip"
              icon-class="pb-4"
            >
              {{ tooltip }}
            </infotip>
          </slot>
        </div>
      </slot>
    </div>

    <slot name="actions" />
  </v-toolbar>
</template>

<script>
import Infotip from '@/theme/default/components/common/Infotip'

export default {
  name      : 'BlockToolbar',
  components: { Infotip },
  props     : {
    caption: {
      type   : String,
      default: ''
    },
    title: {
      type   : String,
      default: ''
    },
    tooltip: {
      type   : String,
      default: ''
    }
  }
}
</script>

<style scoped>
</style>
