import BaseModel             from '@/lib/data/model/BaseModel'
import TriggerInputValidator from '@/api/validations/alerts/TriggerInputValidator'
import TriggerInputResource  from '@/api/resources/alerts/TriggerInputResource'

export default class TriggerInputModel extends BaseModel {
  constructor (payload = {}, resource = TriggerInputResource, validator = TriggerInputValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = ['selectedItem']
  }

  /* PROPERTIES */

  /* METHODS */
}
