import BaseResource         from '@/lib/data/resource/BaseResource'
import CompanyModel         from '@/api/models/user/CompanyModel'
import SubscriptionTierEnum from '@/api/enums/SubscriptionTierEnum'

export default class AccountResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.id = payload.id ?? null
    this.name = payload.name ?? ''
    this.contactEmail = payload.contactEmail ?? ''
    this.createdAt = payload.createdAt ?? ''
    this.validUntil = payload.validUntil ?? ''
    this.suspended = payload.suspended ?? false
    this.subscriptionTier = payload.subscriptionTier ?? SubscriptionTierEnum.STANDARD
    this.company = new CompanyModel(payload.company ?? null)
    this.hasDebtProfile = payload.hasDebtProfile ?? false
    this.hasForecastFlow = payload.hasForecastFlow ?? false
  }

  /* PROPERTIES */

  /* METHODS */
}
