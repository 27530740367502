<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <block-item-card
          :title="$t('Risks.Section.SeasonalityAnalysis.Block.CashFlowProfile.Title')"
          :tooltip="$t('Risks.Section.SeasonalityAnalysis.Block.CashFlowProfile.InfoTip')"
        >
          <template
            v-if="$vuetify.breakpoint.mdAndUp"
            #actions
          >
            <v-spacer />
            <asset-button-group
              :items="assets"
              :selected.sync="selectedAsset"
              class="mt-4"
            />
          </template>
          <template
            v-else
            #actions-row
          >
            <v-card-title
              class="mx-3 pa-0"
              :class="`${aggregateItem ? 'mb-2' : 'mb-4'}`"
            >
              <asset-button-group
                :items="assets"
                :selected.sync="selectedAsset"
              />
            </v-card-title>
            <v-card-title class="mx-3 mb-4 pa-0">
              <asset-button-group
                v-if="aggregateItem"
                :items="aggregateAssets"
                :selected.sync="selectedAggregateAsset"
              />
            </v-card-title>
          </template>

          <template
            v-if="$vuetify.breakpoint.mdAndUp"
            #extra
          >
            <v-spacer />
            <asset-button-group-orange
              v-if="aggregateItem"
              :items="aggregateAssets"
              :selected.sync="selectedAggregateAsset"
              :mandatory="false"
              class="mt-2 mr-4 mb-0"
            />
          </template>

          <v-row
            v-if="seasonalityChart && seasonalityChart.length"
            class="mt-0"
          >
            <v-col
              cols="12"
              md="9"
              xl="10"
              :class="`${aggregateItem ? 'pt-0' : ''}`"
            >
              <v-lazy
                :options="{threshold: .5}"
                transition="fade-transition"
              >
                <e-chart-seasonality-bar
                  :axis-label-font-color="themeColorRGBA('synthesis-ui-grey-01')"
                  :axis-y-label-inside="false"
                  :axis-y-label-margin="0"
                  :axis-y-split-line-dash-offset="4"
                  :axis-y-split-line-opacity="1"
                  :axis-y-split-line-type="[8, 8]"
                  :axis-y-split-number="2"
                  :axis-y-tick-prefix="seasonalityDisplayUnit"
                  :bar-background-color="themeColorRGBA('synthesis-ui-grey-05', 0.3)"
                  :chart-animation-duration="2000"
                  :chart-data-options="seasonalityChart"
                  :chart-grid-margin="{left: 10,top: 20,right: 10,bottom: 15,containLabel: true}"
                  chart-animation-easing="elasticOut"
                  chart-ref-id="SeasonalityCashFlowProfile"
                />
              </v-lazy>
            </v-col>

            <v-col
              class="pl-md-0 pt-0"
              :class="`${aggregateItem ? 'pt-md-3' : 'pt-md-6'}`"
              cols="12"
              md="3"
              xl="2"
            >
              <div class="px-4 pb-4 pb-md-0 pl-md-0">
                <v-card
                  class="synthesis-inter-text rounded-lg"
                  color="synthesis-ui-green-08"
                  flat
                  rounded
                >
                  <v-card-subtitle class="pa-5 pb-0 synthesis-ui-grey-01--text">
                    {{ $t('Risks.Section.SeasonalityAnalysis.Title') }}
                    <infotip
                      v-if="$t('Risks.Section.SeasonalityAnalysis.InfoTip')"
                      icon-style="margin-top: -16px"
                    >
                      {{ $t('Risks.Section.SeasonalityAnalysis.InfoTip') }}
                    </infotip>
                  </v-card-subtitle>
                  <v-card-title
                    class="pa-5 pt-2 synthesis-text text-size-28 synthesis-ui-green-02--text"
                  >
                    {{ seasonalityImpactLabel }}
                  </v-card-title>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </block-item-card>

        <v-row
          v-if="tableValues && !!tableValues.length"
          class="mt-3"
        >
          <v-col>
            <table-header-gradient-blue>
              <template #table>
                <v-data-table
                  :headers="table.headers"
                  :items="tableValues"
                  class="font-weight-medium synthesis-card-border"
                  disable-pagination
                  disable-sort
                  hide-default-footer
                >
                  <template #header.Method="{header}">
                    {{ header.text }}
                    <infotip
                      v-if="$t('Risks.Table.Seasonality.Headers.MethodInfoTip')"
                      icon-style="margin-top: -16px"
                    >
                      {{ $t('Risks.Table.Seasonality.Headers.MethodInfoTip') }}
                    </infotip>
                  </template>

                  <template #header.VaR_Pct_1="{header}">
                    {{ header.text }}
                    <infotip
                      v-if="$t('Risks.Table.Seasonality.Headers.VaR_Pct_1InfoTip')"
                      icon-style="margin-top: -16px"
                    >
                      {{ $t('Risks.Table.Seasonality.Headers.VaR_Pct_1InfoTip') }}
                    </infotip>
                  </template>

                  <template #header.Impact="{header}">
                    {{ header.text }}
                    <infotip
                      v-if="$t('Risks.Table.Seasonality.Headers.ImpactInfoTip')"
                      icon-style="margin-top: -16px"
                    >
                      {{ $t('Risks.Table.Seasonality.Headers.ImpactInfoTip') }}
                    </infotip>
                  </template>

                  <template #item.VaR_Pct_1="{item}">
                    <span
                      v-if="riskBaseCurrencyDisplayName && !isRiskBaseCurrencySymbolSingle"
                      :class="`pr-1 text-size-10 ${item.ImpactTextColor}--text`"
                      style="margin-top: 2px;"
                      v-text="riskBaseCurrencyDisplayName"
                    />{{ riskBaseCurrencySymbolSingle + item.VaR_Pct_1 }}
                  </template>
                  <template #item.Impact="{item}">
                    <v-chip
                      v-if="item.Impact"
                      :color="item.ImpactColor"
                      :text-color="item.ImpactTextColor"
                    >
                      <span
                        v-if="item.Impact !== '-' && riskBaseCurrencyDisplayName && !isRiskBaseCurrencySymbolSingle"
                        :class="`pr-1 text-size-10 ${item.ImpactTextColor}--text`"
                        style="margin-top: 2px;"
                        v-text="riskBaseCurrencyDisplayName"
                      />{{ item.Impact !== '-' ? riskBaseCurrencySymbolSingle : '' }}{{ item.Impact }}
                    </v-chip>
                  </template>
                </v-data-table>
              </template>
            </table-header-gradient-blue>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta           from '@/mixins/i18n/i18nRouteMeta'
import chartsColorMixin        from '@/theme/default/components/charts/mixins/chartsColorMixin'
import riskAnalysisId          from '@/theme/default/views/risks/mixins/riskAnalysisId'
import BlockItemCard           from '@/theme/default/components/common/BlockItemCard'
import AssetButtonGroup        from '@/theme/default/components/common/AssetButtonGroup'
import API                     from '@/api/Api'
import { clone }               from '@/lib/utils/helper'
import RiskSeasonalityResource from '@/api/resources/risks/RiskSeasonalityResource'
import EChartSeasonalityBar    from '@/theme/default/components/charts/echarts/EChartSeasonalityBar'
import riskBaseCurrency        from '@/theme/default/views/risks/mixins/riskBaseCurrency'
import TableHeaderGradientBlue from '@/theme/default/components/common/TableHeaderGradientBlue'
import { first }               from '@/lib/utils/array'
import Infotip                 from '@/theme/default/components/common/Infotip'
import AssetButtonGroupOrange  from '@/theme/default/components/common/AssetButtonGroupOrange.vue'

export default {
  name      : 'RiskSeasonalityBlock',
  components: {
    AssetButtonGroupOrange,
    Infotip,
    TableHeaderGradientBlue,
    EChartSeasonalityBar,
    AssetButtonGroup,
    BlockItemCard
  },
  directives: {},
  mixins    : [riskBaseCurrency, chartsColorMixin, i18nRouteMeta, riskAnalysisId],
  props     : {},
  dataStore : {
    riskSeasonalityData : 'Risks.Seasonality.Data',
    riskBaseCurrencyData: 'Risks.BaseCurrency.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      selectedAsset         : null,
      selectedAggregateAsset: null,
      selectedSeasonality   : 'user',
      table                 : {
        headers: [
          {
            text : this.$t('Risks.Table.Seasonality.Headers.Method'),
            align: 'start',
            value: 'Method',
            class: 'grey lighten-5'
          },
          {
            text : this.$t('Risks.Table.Seasonality.Headers.VaR_Pct_1'),
            value: 'VaR_Pct_1',
            class: 'grey lighten-5'
          },
          {
            text : this.$t('Risks.Table.Seasonality.Headers.Impact'),
            value: 'Impact',
            class: 'grey lighten-5'
          }
        ]
      }
    }
  },
  computed: {
    seasonality () {
      return this.riskSeasonalityData[this.id] || null
    },

    assets () {
      return this.seasonality?.Assets || []
    },

    seasonalityData () {
      return this.seasonality?.Data || null
    },

    selectedItem () {
      return this.seasonalityData?.find(item => item.AssetValue === this.selectedAsset) || null
    },

    aggregateItem () {
      return this.selectedItem?.AggregateItem || false
    },

    aggregateAssets () {
      return this.selectedItem?.PerCashflowCodeNames || []
    },

    selectedAggregateItem () {
      return this.selectedItem?.PerCashflowItems?.find(item => item.AssetValue === this.selectedAggregateAsset) || null
    },

    selectedFinalItem () {
      return this.aggregateItem && this.selectedAggregateItem ? this.selectedAggregateItem : this.selectedItem
    },

    seasonalityChart () {
      return this.selectedFinalItem?.Values || []
    },

    seasonalityDisplayUnit () {
      return this.selectedFinalItem?.DisplayUnit || ''
    },

    seasonalityImpactLabel () {
      return this.selectedFinalItem?.ImpactLabel || ''
    },

    tableValues () {
      return this.selectedFinalItem?.TableValues || []
    }
  },
  watch: {
    selectedAsset () {
      this.setSelectedAssetAggregate()
    }
  },
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Risks.RiskSeasonality(this.id)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
    },
    handleResponse (response) {
      const data = API.responseData(response)
      const errors = API.responseErrors(response) || []
      const result = data?.findAnalysis || null

      if (API.isResponseSuccess(response) && result) {
        const o = clone(result)
        const v = new RiskSeasonalityResource(o)

        this.$set(this.riskSeasonalityData, this.id, v)

        // Fix for dropdown
        if (this.assets && !!this.assets.length) {
          this.selectedAsset = first(this.assets)?.Value
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },
    setSelectedAssetAggregate () {
      this.selectedAggregateAsset = ''
    }
  }
}
</script>

<style scoped>

</style>
