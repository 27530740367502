<template>
  <e-chart
    :ref="`echart${chartRefId}`"
    :autoresize="true"
    :option="getChartOptions"
    :style="`height: ${getChartHeight}px;`"
    class="chart"
  />
</template>

<script>
import baseEChartLineProps from '@/theme/default/components/charts/echarts/mixins/line/baseEChartLineProps'
import baseEChartLineData  from '@/theme/default/components/charts/echarts/mixins/line/baseEChartLineData'

import { first }                                                            from '@/lib/utils/array'
import { toDecimalMark, toDecimalSignificant, toNumberFormatNotationShort } from '@/lib/utils/helper'

import { use }                              from 'echarts/core'
import { LineChart }                        from 'echarts/charts'
import { LabelLayout, UniversalTransition } from 'echarts/features'
import { CanvasRenderer }                   from 'echarts/renderers'

import { DatasetComponent, GridComponent, LegendComponent, MarkLineComponent, MarkPointComponent, TooltipComponent, TransformComponent } from 'echarts/components'

import AssetEnum from '@/api/enums/AssetEnum'
import dayjs     from 'dayjs'

import chartsColorMixin               from '@/theme/default/components/charts/mixins/chartsColorMixin'
import chartsBaseCurrencySymbolSingle from '@/theme/default/components/charts/mixins/chartsBaseCurrencySymbolSingle'

use([
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent,
  TransformComponent,
  MarkLineComponent,
  MarkPointComponent,
  LineChart,
  CanvasRenderer,
  LabelLayout,
  UniversalTransition
])

export default {
  name    : 'BaseEChartLine',
  mixins  : [chartsColorMixin, chartsBaseCurrencySymbolSingle, baseEChartLineProps, baseEChartLineData],
  computed: {
    setChartDataOptions () {
      let values = {
        x: [],
        y: []
      }

      if (this.chartDataOptionsUpdated.length) {
        values = first(this.chartDataOptionsUpdated)
      }

      return {
        xAxis: {
          data: values.x
        },
        series: [{
          data: values.y
        }]
      }
    },

    getChartDataOptions () {
      const tooltipValuePrefix = this.isBaseCurrencySymbolSingle ? '' : '<span class="pt-4 pr-1 text-size-12">' + this.axisYTickPrefix + '</span>'
      const tooltipValueSuffix = this.axisYTickSuffix

      let values = {
        x: [],
        y: []
      }

      if (this.chartDataOptions.length) {
        values = first(this.chartDataOptions)
      }

      const series = [{
        type        : 'line',
        smooth      : this.chartLineSmooth,
        symbol      : this.chartLineSymbol,
        symbolSize  : this.chartLineSymbolSize,
        symbolOffset: this.chartLineSymbolOffset,
        showSymbol  : this.chartLineShowSymbol,
        sampling    : 'lttb',
        itemStyle   : {
          color: this.chartLineColor
        },
        cursor: this.cursor,
        silent: this.silent,
        data  : values.y
      }]

      const o = {
        animation         : this.chartAnimation,
        animationThreshold: this.chartAnimationThreshold,
        animationDuration : this.chartAnimationDuration,
        animationEasing   : this.chartAnimationEasing,
        tooltip           : {
          trigger        : 'axis',
          backgroundColor: this.themeColorRGBA(this.tooltipBackgroundColor, 0.8),
          borderColor    : this.tooltipBorderColor,
          borderWidth    : this.tooltipBorderWidth,
          padding        : this.tooltipPadding,
          textStyle      : {
            color: this.tooltipLabelColor
          },
          formatter: params => {
            let axisValue = params[0].axisValue

            if (this.axisXLabelValueFormat === 'number') {
              axisValue = toNumberFormatNotationShort(parseFloat(axisValue))
            }
            if (this.axisXLabelValueFormat === 'percent') {
              axisValue = toDecimalMark(parseFloat(axisValue))
            }
            if (this.axisXLabelValueFormat === 'date') {
              axisValue = dayjs(axisValue).format(this.axisXLabelValueDateFormatTooltip)
            }

            const values = params.map(item => {
              let axisYValue = item.value

              if (this.axisYLabelValueFormat === AssetEnum.INTERBANK_RATE) {
                axisYValue = axisYValue === 0 ? axisYValue : toDecimalSignificant(axisYValue)
              } else {
                axisYValue = toNumberFormatNotationShort(item.value)
              }

              return `${ tooltipValuePrefix }<span class="ml-0 synthesis-text text-size-18 font-weight-regular">${ this.baseCurrencySymbolSingle }${ axisYValue }</span>${ tooltipValueSuffix }</div>`
            }).join('')

            return `<span class="synthesis-text text-size-14 font-weight-regular">${ axisValue }</span><br/>${ values }`
          }
        },
        textStyle: {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid : this.getChartGridMargin,
        xAxis: {
          show       : this.axisXShow,
          data       : values.x,
          type       : this.axisXType,
          offset     : 5,
          axisPointer: {
            z        : 1,
            show     : this.axisXPointerShow,
            snap     : true,
            lineStyle: {
              type      : this.axisPointerLineStyleType,
              dashOffset: this.axisPointerLineDashOffset,
              color     : this.axisPointerLineStyleColor,
              width     : this.axisPointerLineStyleWidth
            },
            label: {
              show     : this.axisYPointerLabelShow,
              formatter: (params) => {
                return this.$dayjs(params.value).format(this.axisXLabelValueDateFormat)
              },
              backgroundColor: this.axisPointerLabelBackgroundColor
            }
          },
          axisLine: {
            show     : this.axisXGridLineShow,
            lineStyle: {
              color: this.axisXGridLineStyleColor
            }
          },
          axisLabel: {
            visible   : true,
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            color     : this.axisLabelFontColor,
            formatter : (params) => {
              return (this.axisXTickPrefix || '') + this.$dayjs(params).format(this.axisXLabelValueDateFormat) + (this.axisXTickSuffix || '')
            },
            align      : this.axisXLabelAlign,
            inside     : this.axisXLabelInside,
            margin     : this.axisXLabelMargin,
            padding    : this.axisXLabelPadding,
            interval   : this.axisXLabelInterval,
            hideOverlap: this.axisXLabelHideOverlap
          },
          axisTick: {
            show          : false,
            inside        : true,
            alignWithLabel: true
          },
          minInterval: 30, // Minimum gap between split lines.
          splitLine  : {
            show     : this.axisXSplitLineShow,
            lineStyle: {
              type   : this.axisXSplitLineType,
              color  : this.axisXSplitLineColor,
              opacity: this.axisXSplitLineOpacity,
              width  : this.axisXSplitLineWidth
            }
          },
          splitNumber: this.axisXSplitNumber,
          min        : this.axisXMinValue,
          max        : this.axisXMaxValue,
          boundaryGap: this.axisXBoundaryGap,
          silent     : true
        },
        yAxis: {
          show       : this.axisYShow,
          scale      : this.chartScale,
          type       : 'value',
          axisPointer: {
            show     : this.axisYPointerShow,
            lineStyle: {
              type      : this.axisPointerLineStyleType,
              dashOffset: this.axisPointerLineDashOffset,
              color     : this.axisPointerLineStyleColor,
              width     : this.axisPointerLineStyleWidth
            },
            label: {
              show           : this.axisYPointerLabelShow,
              backgroundColor: this.axisPointerLabelBackgroundColor
            }
          },
          axisLine: {
            onZero   : false,
            show     : this.axisYGridLineShow,
            lineStyle: {
              color: this.axisYGridLineStyleColor
            }
          },
          axisLabel: {
            visible   : true,
            inside    : this.axisYLabelInside,
            margin    : this.axisYLabelMargin,
            padding   : [0, 0, 0, 0], // [top, right, bottom, left]
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            color     : this.axisLabelFontColor,
            formatter : value => {
              if (this.axisYLabelValueFormat === 'number') {
                value = (value === 0 ? value : toNumberFormatNotationShort(parseFloat(value)))
              }
              if (this.axisYLabelValueFormat === 'percent' || this.axisYLabelValueFormat === AssetEnum.INTERBANK_RATE) {
                value = (value === 0 ? value : toDecimalSignificant(parseFloat(value)))
              }
              if (this.axisYLabelValueFormat === 'date') {
                value = dayjs(value).format(this.axisYLabelValueDateFormat)
              }
              return (this.axisYTickPrefix + ' ' || '') + value + (this.axisYTickSuffix || '')
            },
            interval     : this.axisYLabelInterval,
            hideOverlap  : this.axisYLabelHideOverlap,
            verticalAlign: this.axisYLabelVerticalAlign
          },
          axisTick: {
            show          : false,
            inside        : true,
            alignWithLabel: true
          },
          splitLine: {
            show     : this.axisYSplitLineShow,
            lineStyle: {
              type   : this.axisYSplitLineType,
              color  : this.axisYSplitLineColor,
              opacity: this.axisYSplitLineOpacity,
              width  : this.axisYSplitLineWidth
            }
          },
          splitNumber: this.axisYSplitNumber,
          min        : this.axisYMinValue,
          max        : this.axisYMaxValue,
          boundaryGap: this.axisYBoundaryGap,
          silent     : true
        },
        series: series
      }

      if (this.markLineValue) {
        series[0].markLine = this.getMarkLine()
      }

      return o
    }
  },
  methods: {
    getMarkLine () {
      const data = this.markLineHorizontal ? [{ yAxis: this.markLineValue.toString() }] : [{ xAxis: this.markLineValue.toString() }]

      return {
        animation         : this.markLineAnimation,
        animationThreshold: this.chartAnimationThreshold,
        animationDuration : this.markLineAnimationDuration,
        animationEasing   : this.markLineAnimationEasing,
        silent            : true,
        symbol            : 'none',
        lineStyle         : {
          type      : this.markLineLineStyleType,
          dashOffset: this.markLineLineStyleDashOffset,
          width     : this.markLineLineStyleWidth,
          color     : this.markLineLineStyleColor
        },
        label: {
          show     : this.markLineLabelShow,
          formatter: params => {
            let value = this.markLineValueLabel ?? params.value

            if (this.markLineValueLabelFormat === 'number') {
              value = toNumberFormatNotationShort(parseFloat(value))
            }

            if (this.markLineValueLabelFormat === 'percent') {
              value = toDecimalMark(parseFloat(value))
            }

            if (this.markLineValueLabelFormat === 'date') {
              value = dayjs(value).format('MMM YYYY')
            }

            return (this.markLineLabelPrefix || '') + value + (this.markLineLabelSuffix || '')
          },
          position       : 'middle',
          color          : this.markLineLabelColor,
          backgroundColor: this.markLineLabelBackgroundColor,
          borderRadius   : 30,
          padding        : [6, 10, 6, 10], // [top, right, bottom, left]
          distance       : [0, -12] // [horizontal, vertical]
        },
        data: data
      }
    }
  }
}
</script>

<style scoped>
.chart {
  width: 100%;
}
</style>
