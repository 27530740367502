import BaseResource                       from '@/lib/data/resource/BaseResource'
import { collect }                        from '@/lib/utils/array'
import AssetNameResource                  from '@/api/resources/asset/AssetNameResource'
import HedgingHedgeRatiosResource         from '@/api/resources/hedging/HedgingHedgeRatiosResource'
import HedgingInstrumentHistogramResource from '@/api/resources/hedging/HedgingInstrumentHistogramResource'

export default class HedgingInstrumentResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Assets = collect(payload.assets ?? [], AssetNameResource)
    this.Items = collect(payload.items ?? [], AssetNameResource)
    this.OptimalHedgeRatios = collect(payload.optimalHedgeRatios ?? [], HedgingHedgeRatiosResource)
    this.HedgedCashFlows = collect(payload.hedgedCashFlows ?? [], HedgingInstrumentHistogramResource)
    this.OptimalCashFlows = collect(payload.optimalCashFlows ?? [], HedgingInstrumentHistogramResource)
    this.RollingCashFlows = collect(payload.rollingCashFlows ?? [], HedgingInstrumentHistogramResource)
    this.RollingCashFlowsExist = payload.rollingCashFlowsExist ?? false
  }

  /* PROPERTIES */
  get ChartValues () {
    if (this.OptimalHedgeRatios && this.OptimalHedgeRatios.length) {
      return [{
        x: this.OptimalHedgeRatios.map(item => item.Name),
        y: this.OptimalHedgeRatios.map(item => item.Ratio)
      }]
    }
    return []
  }

  get Instruments () {
    if (this.OptimalHedgeRatios && this.OptimalHedgeRatios.length) {
      return this.OptimalHedgeRatios.map(item => {
        return {
          Name        : item.Name,
          HedgeAmount : item.HedgeAmountFmt,
          BaseCurrency: item.BaseCurrency.displaySymbol ?? ''
        }
      })
    }
    return []
  }

  /* METHODS */
}
