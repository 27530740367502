<template>
  <v-container
    fill-height
    pa-0
  >
    <v-row class="ma-0">
      <template v-for="(item, i) in items">
        <v-col
          :key="`survey-list-card-${i}`"
          class="pa-0 px-4 pb-2"
          cols="12"
          md="4"
        >
          <v-card-text class="synthesis-text text-size-14 font-weight-medium">
            {{ item.Title }}
          </v-card-text>
          <template v-if="item.Result && !!item.Result.length">
            <e-chart-survey-pie
              :chart-data-options="item.Result"
              :chart-height="140"
              :default-colors="surveyColors"
              :tooltip-show="true"
              chart-animation-easing="elasticOut"
            />
          </template>
          <template v-else>
            <v-card-text class="py-1 text-center justify-center">
              <v-btn
                block
                class="synthesis-brand-white--text"
                color="synthesis-ui-green-02"
                depressed
                height="42"
                @click="submit(item, SurveyAnswerEnum.Yes)"
                v-text="$t('Common.Button.Yes')"
              />
            </v-card-text>
            <v-card-text class="py-1 text-center justify-center">
              <v-btn
                block
                class="synthesis-brand-white--text"
                color="synthesis-ui-red-02"
                depressed
                height="42"
                @click="submit(item, SurveyAnswerEnum.No)"
                v-text="$t('Common.Button.No')"
              />
            </v-card-text>
            <v-card-text class="py-1 text-center justify-center">
              <v-btn
                block
                class="synthesis-brand-dark-blue--text"
                color="synthesis-ui-grey-04"
                depressed
                height="42"
                @click="submit(item, SurveyAnswerEnum.Unsure)"
                v-text="$t('Common.Button.Unsure')"
              />
            </v-card-text>
          </template>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>

import baseEChartColors from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'
import SurveyAnswerEnum from '@/api/enums/SurveyAnswerEnum'
import EChartSurveyPie  from '@/theme/default/components/charts/echarts/EChartSurveyPie'

export default {
  name      : 'SurveyListCard',
  components: {
    EChartSurveyPie
  },
  directives: {},
  mixins    : [baseEChartColors],
  props     : {
    items: {
      type    : Array,
      required: true
    }
  },
  dataStore: {},
  enums    : { SurveyAnswerEnum },
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    submit (item, action) {
      this.$emit('survey-item-click', item.Id, action)
    }
  }
}
</script>

<style scoped>

</style>
