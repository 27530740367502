import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class UserProfileInputValidator extends BaseValidator {
  validatorRules = {
    'personalDetailsUpdate.name': {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 3
    },

    'passwordUpdate.oldPassword': {
      required: false,
      type    : BaseValidator.Type.String,
      minLen  : 8
    },

    'passwordUpdate.newPassword': {
      required       : false,
      type           : BaseValidator.Type.String,
      minLen         : 8,
      isValidPassword: this.isValidPassword
    },

    'passwordUpdate.newPasswordConfirmation': {
      required       : false,
      type           : BaseValidator.Type.String,
      minLen         : 8,
      isValidPassword: this.isValidPassword,
      equals         : 'passwordUpdate.newPassword'
    }
  }

  validatorMessages = {
    el: {
      equals         : 'Must be same as "New Password"',
      isValidPassword: 'Should contain at least one lowercase, uppercase, number, and symbol character'
    },
    en: {
      equals         : 'Must be same as "New Password"',
      isValidPassword: 'Should contain at least one lowercase, uppercase, number, and symbol character'
    }
  }

  constructor (data = {}) {
    super(data)
  }

  isValidPassword (password) {
    // The password should be at least 8 characters long and contain at least one lowercase, uppercase, number, and symbol character
    return /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/.test(password)
  }
}
