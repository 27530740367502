import BaseResource          from '@/lib/data/resource/BaseResource'
import LivePriceCategoryEnum from '@/api/enums/LivePriceCategoryEnum'

export default class DashboardLivePriceItemResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Category = payload.category ?? ''
    this.Name = payload.name ?? ''
    this.Label = payload.label ?? ''
    this.Price = payload.price ?? 0
    this.Symbol = payload.symbol ?? ''
    this.DailyChange = payload.dailyChange ?? 0
    this.QuoteDisplaySymbol = payload.quoteDisplaySymbol ?? ''
    this.PairItem = payload.pair ?? null
  }

  /* PROPERTIES */
  get Pair () {
    return this.PairItem
  }

  get PriceFmt () {
    return this.getCurrencySymbol() + this.Price + this.getPriceSuffix()
  }

  get DailyChangeFmt () {
    return (this.DailyChange >= 0 ? '+' : '') + (this.DailyChange === 0 ? 0.0000 : this.DailyChange) + '%'
  }

  get DailyChangeColor () {
    if (this.DailyChange === 0) {
      return 'synthesis-ui-blue-02'
    } else {
      return (this.DailyChange > 0 ? 'synthesis-ui-green-01' : 'synthesis-ui-red-02')
    }
  }

  get DailyChangeBgColor () {
    if (this.DailyChange === 0) {
      return 'synthesis-ui-blue-07'
    } else {
      return (this.DailyChange > 0 ? 'synthesis-ui-green-07' : 'synthesis-ui-red-07')
    }
  }

  get DailyChangeBgColors () {
    return ['synthesis-ui-blue-07', 'synthesis-ui-green-07', 'synthesis-ui-red-07']
  }

  /* METHODS */
  getCurrencySymbol () {
    if (this.Category === LivePriceCategoryEnum.CURRENCY) {
      return ''
    }

    return this.QuoteDisplaySymbol.length === 1 ? this.QuoteDisplaySymbol : (this.QuoteDisplaySymbol + ' ')
  }

  getPriceSuffix () {
    if (this.Category === LivePriceCategoryEnum.INTER_BANK_RATE) {
      return '%'
    }

    return ''
  }
}
