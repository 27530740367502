<template>
  <view-data-container />
</template>

<script>
import ViewDataContainer from '@/theme/default/components/common/ViewDataContainer'

export default {
  name      : 'PlaceHolder',
  components: { ViewDataContainer },
  directives: {},
  mixins    : [],
  props     : [],
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
