import BaseModel                  from '@/lib/data/model/BaseModel'
import HedgingCarryInputValidator from '@/api/validations/hedging/HedgingCarryInputValidator'
import HedgingCarryInputResource  from '@/api/resources/hedging/HedgingCarryInputResource'

export default class HedgingCarryInputModel extends BaseModel {
  constructor (payload = {}, resource = HedgingCarryInputResource, validator = HedgingCarryInputValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}
