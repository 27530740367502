import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class HedgingCurrentStrategyValidator extends BaseValidator {
  validatorRules = {
    policyId: {
      required: false,
      type    : BaseValidator.Type.String
    },
    currentAssetStrategy: {
      required: true,
      type    : BaseValidator.Type.Array,
      minLen  : 1
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
