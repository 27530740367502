export default {
  props: {
    disableLazy: {
      type   : Boolean,
      default: false
    },
    chartAnimation: {
      type   : Boolean,
      default: true
    }
  }
}
