import { API } from '@/api/Api'

import {
  HedgingArchiveAllQuery,
  HedgingArchiveQuery,
  HedgingCompareQuery,
  HedgingCreatePolicyMutation,
  HedgingDeletePolicyMutation,
  HedgingPersistPolicyMutation,
  HedgingRollingPolicyMutation,
  HedgingShowRatesQuery,
  HedgingSummaryQuery,
  HedgingUpdateCurrentStrategyMutation
} from '@/api/query/Hedging.graphql'

export default {
  // Queries
  async Archive (accountId) {
    const Data = { accountId: accountId }

    return await API.GQLRequest(HedgingArchiveQuery, Data)
  },

  async ArchiveAll (accountId) {
    const Data = { accountId: accountId }

    return await API.GQLRequest(HedgingArchiveAllQuery, Data)
  },

  async Rates (data) {
    const Data = { ...data }

    return await API.GQLRequest(HedgingShowRatesQuery, Data)
  },

  async Summary (policyId) {
    const Data = { policyId: policyId }

    return await API.GQLRequest(HedgingSummaryQuery, Data)
  },

  async Compare (policyId) {
    const Data = { policyId: policyId }

    return await API.GQLRequest(HedgingCompareQuery, Data)
  },

  // Mutations
  async CreatePolicy (data) {
    const Data = { ...data }

    return await API.GQLRequest(HedgingCreatePolicyMutation, Data)
  },

  async RollingPolicy (policyId) {
    const Data = { policyId: policyId }

    return await API.GQLRequest(HedgingRollingPolicyMutation, Data)
  },

  async PersistPolicy (policyId) {
    const Data = { policyId: policyId }

    return await API.GQLRequest(HedgingPersistPolicyMutation, Data)
  },

  async UpdateCurrentStrategy (data) {
    const Data = { ...data }

    return await API.GQLRequest(HedgingUpdateCurrentStrategyMutation, Data)
  },

  async DeletePolicy (policyId) {
    const Data = { policyId: policyId }

    return await API.GQLRequest(HedgingDeletePolicyMutation, Data)
  }
}
