import SnotifyToast                                    from './Snotify.vue'
import SnotifyService                                  from './SnotifyService'
import { SnotifyAction, SnotifyPosition, SnotifyType } from './enums'

function install (Vue, options = {}) {
  Vue.filter('truncate', function (value, limit = 40, trail = '...') {
    return value.length > limit ? value.substring(0, limit) + trail : value
  })
  Vue.component('VueSnotify', SnotifyToast)
  SnotifyService.setConfig(options.config || null, options.options || null)
  Vue.prototype.$snotify = SnotifyService
}

// auto install
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(install)
}

export {
  install as default,
  SnotifyType,
  SnotifyAction,
  SnotifyPosition
}
