import BaseModel         from '@/lib/data/model/BaseModel'
import AddressValidator from '@/api/validations/user/AddressValidator'
import AddressResource  from '@/api/resources/user/AddressResource'

export default class AddressModel extends BaseModel {
  constructor (payload = {}, resource = AddressResource, validator = AddressValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}
