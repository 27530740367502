import BaseResource from '@/lib/data/resource/BaseResource'
import AssetModel   from '@/api/models/asset/AssetModel'

export default class AssetStrategyInputResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.assetCode = payload.assetCode ?? payload.Asset?.symbol ?? ''
    this.currentHedgeRatio = payload.currentHedgeRatio ?? payload.CurrentValue ?? 0
    this.currentInstrument = payload.currentInstrument ?? (payload.Current && payload.Current !== '-' ? payload.Current.replace(/\s+/g, '') : null) ?? null

    this.currentStrategyProvided = payload.currentStrategyProvided ?? payload.CurrentStrategyProvided ?? false
    this.asset = payload.asset ? new AssetModel(payload.asset) : payload.Asset ? new AssetModel(payload.Asset) : new AssetModel()
    this.baseCurrency = payload.baseCurrency ? new AssetModel(payload.baseCurrency) : payload.BaseCurrency ? new AssetModel(payload.BaseCurrency) : new AssetModel()
    this.instrumentOptions = payload.InstrumentOptions
  }

  /* PROPERTIES */

  /* METHODS */
}
