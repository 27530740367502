import { i18n }              from '@/lang/lang'
import { pathToValue }       from '@/lib/utils/object'
import { ValidatorMessages } from '@/lib/data/validator/ValidatorMessages'
import * as ValidatorRules   from '@/lib/data/validator/ValidatorRules'
import typesEnum             from './ValidatorTypesEnum'

export default class BaseValidator {
  #model = null
  #errors = {}
  #validatorMessages = ValidatorMessages
  validatorMessages = {
    el: {},
    en: {}
  }

  validatorRules = {}

  /**
   * Create a BaseValidator instance
   *
   * @param data {Object | BaseModel} [data={}] object or model to validate.
   * @param validatorRules {Object} object with validator rules
   * @param validatorMessages {Object} object with validator i18n error messages
   *
   * @return {BaseValidator} A BaseValidator object.
   */
  constructor (data = {}, validatorRules = null, validatorMessages = null) {
    this.#model = data

    if (validatorRules) this.validatorRules = validatorRules
    if (validatorMessages) this.validatorMessages = validatorMessages
  }

  /* ENUM */
  /**
   * Validator Rule Types Enumeration
   *
   * @returns {Object}
   */
  static get Type () {
    return typesEnum
  }

  /* PROPERTIES */
  /**
   * Get validator model or data
   *
   * @returns {Object | BaseModel}
   */
  get model () {
    return this.#model
  }

  /**
   * Get validator i18n error messages
   *
   * @returns {Object}
   */
  get messages () {
    return Object.assign({}, this.#validatorMessages[i18n.locale], this.validatorMessages[i18n.locale])
  }

  /**
   * Get the validator errors object
   *
   * @returns {Object}
   */
  get errors () {
    return this.#errors
  }

  /* METHODS */
  /**
   * Validate the validator data or model
   *
   * @returns {boolean}
   */
  validate () {
    this.clearErrors()

    Object.keys(this.model).forEach(property => {
      if (property in this.validatorRules) {
        this.runValidations(property)
      }
    })

    Object.keys(this.validatorRules).filter(key => key.includes('.')).forEach(property => {
      this.runValidations(property)
    })

    return Object.keys(this.errors).length <= 0
  }

  /**
   * Validate a specific property/field of the validator data or model
   *
   * @param property {String}
   *
   * @returns {boolean}
   */
  validateField (property) {
    this.clearErrors()

    if (property in this.validatorRules) {
      this.runValidations(property)
    }

    // eslint-disable-next-line no-console
    // Object.keys(this.errors).length && console.warn('VALIDATOR:: ', this.constructor.name, this.errors, this.model)

    return Object.keys(this.errors).length <= 0
  }

  /**
   * Run all validations against data or model and set appropriate error messages
   *
   * @param property {String}
   */
  runValidations (property) {
    const propertyRules = this.validatorRules[property]
    if (propertyRules.hasOwnProperty('requiredIf')) propertyRules.required = ValidatorRules.requiredIf(this.validatorRules[property].requiredIf, property.includes('.') ? pathToValue(property, this.model) : this.model[property], this.model)
    if (propertyRules.hasOwnProperty('requiredIfNot')) propertyRules.required = ValidatorRules.requiredIfNot(this.validatorRules[property].requiredIfNot, property.includes('.') ? pathToValue(property, this.model) : this.model[property], this.model)
    const propertyIsRequired = propertyRules.hasOwnProperty('required') && propertyRules.required

    Object.keys(propertyRules).forEach(rule => {
      let ruleResult = true
      const propertyValue = property.includes('.') ? pathToValue(property, this.model) : this.model[property]
      const propertyRule = this.validatorRules[property][rule]

      if (rule in ValidatorRules || typeof propertyRule === 'function') {
        if (rule === 'type') {
          if (propertyRule in ValidatorRules.types || typeof propertyRule === 'function') {
            ruleResult = ValidatorRules[rule](propertyRule, propertyValue)
          } else {
            // eslint-disable-next-line no-console
            console.warn(`VALIDATOR ::: ${ property }:: Rule: ${ rule } ${ propertyRule } is not a valid rule!`)
          }
        } else if (rule === 'equals') {
          ruleResult = ValidatorRules[rule](propertyRule, propertyValue, this.model)
        } else if (typeof propertyRule === 'function') {
          ruleResult = propertyRule(propertyValue)
        } else {
          ruleResult = ValidatorRules[rule](propertyRule, propertyValue)
        }
        if (!ruleResult && (propertyIsRequired || propertyValue)) {
          let errorMessage = null
          errorMessage = property.includes('.') ? pathToValue(property, this.errors) : this.errors[property]

          if (property.includes('.')) {
            let obj = this.errors
            const p = property.split('.')
            const lastKeyIndex = p.length - 1
            if (errorMessage) {
              errorMessage.push(this.getErrorMessage(rule, propertyRule))
            } else {
              for (let i = 0; i < lastKeyIndex; ++i) {
                const key = p[i]
                if (!(key in obj)) {
                  obj[key] = {}
                }
                obj = obj[key]
              }
              obj[p[lastKeyIndex]] = [this.getErrorMessage(rule, propertyRule)]
            }
          } else {
            if (errorMessage) {
              errorMessage.push(this.getErrorMessage(rule, propertyRule))
            } else {
              errorMessage = [this.getErrorMessage(rule, propertyRule)]
              this.#errors[property] = errorMessage
            }
          }
        }
      } else {
        // eslint-disable-next-line no-console
        console.warn(`VALIDATOR ::: Filed: ${ property } - Rule: ${ rule } is not a valid rule!`)
      }
    })
  }

  /**
   * Get Vuetify form rules object
   *
   * @returns {Object}
   */
  vuetifyFormRules () {
    const rulesObject = {}

    Object.keys(this.model).forEach(property => {
      if (property in this.validatorRules) {
        rulesObject[property] = this.vuetifyFormFieldRules(property)
      }
    })

    Object.keys(this.validatorRules).filter(key => key.includes('.')).forEach(property => {
      let obj = rulesObject
      const p = property.split('.')
      const lastKeyIndex = p.length - 1

      for (let i = 0; i < lastKeyIndex; ++i) {
        const key = p[i]
        if (!(key in obj)) {
          obj[key] = {}
        }
        obj = obj[key]
      }
      obj[p[lastKeyIndex]] = this.vuetifyFormFieldRules(property)
    })

    return rulesObject
  }

  /**
   * Get Vuetify property/field rules array
   *
   * @param property {String}
   *
   * @returns {Array}
   */
  vuetifyFormFieldRules (property = '') {
    const rulesArray = []

    if (property in this.validatorRules) {
      const propertyRules = this.validatorRules[property]
      if (propertyRules.hasOwnProperty('requiredIf')) propertyRules.required = ValidatorRules.requiredIf(this.validatorRules[property].requiredIf, property.includes('.') ? pathToValue(property, this.model) : this.model[property], this.model)
      if (propertyRules.hasOwnProperty('requiredIfNot')) propertyRules.required = ValidatorRules.requiredIfNot(this.validatorRules[property].requiredIfNot, property.includes('.') ? pathToValue(property, this.model) : this.model[property], this.model)
      const propertyIsRequired = propertyRules.hasOwnProperty('required') && propertyRules.required

      Object.keys(propertyRules).forEach(rule => {
        const propertyValue = property.includes('.') ? pathToValue(property, this.model) : this.model[property]
        const propertyRule = this.validatorRules[property][rule]

        if (rule in ValidatorRules || typeof propertyRule === 'function') {
          if (rule === 'type') {
            if (propertyRule in ValidatorRules.types) {
              rulesArray.push((v) => {
                if (propertyIsRequired || propertyValue) {
                  return ValidatorRules[rule](propertyRule, v) || this.getErrorMessage(rule, propertyRule)
                } else {
                  return true
                }
              })
            } else {
              // eslint-disable-next-line no-console
              console.warn(`VALIDATOR ::: Field: ${ property } - Rule: ${ rule } ${ propertyRule } is not a valid rule!`)
            }
          } else if (rule === 'equals') {
            rulesArray.push((v) => {
              if (propertyIsRequired || propertyValue) {
                return ValidatorRules[rule](propertyRule, v, this.model) || this.getErrorMessage(rule, propertyRule)
              } else {
                return true
              }
            })
          } else if (typeof propertyRule === 'function') {
            rulesArray.push((v) => {
              if (propertyIsRequired || propertyValue) {
                return propertyRule(v) || this.getErrorMessage(rule, propertyRule)
              } else {
                return true
              }
            })
          } else if (rule !== 'requiredIf' && rule !== 'requiredIfNot') {
            rulesArray.push((v) => {
              if (propertyIsRequired || propertyValue) {
                return ValidatorRules[rule](propertyRule, v) || this.getErrorMessage(rule, propertyRule)
              } else {
                return true
              }
            })
          }
        } else {
          // eslint-disable-next-line no-console
          console.warn(`VALIDATOR ::: ${ property }:: Rule: ${ rule } is not a valid rule!`)
        }
      })
    }

    return rulesArray
  }

  /**
   * Clear validator errors
   */
  clearErrors () {
    this.#errors = {}
  }

  /**
   * Get error message for a rule and value
   *
   * @param ruleName {String}
   * @param ruleValue {any}
   *
   * @returns {String}
   */
  getErrorMessage (ruleName, ruleValue) {
    let msg = ''
    if (ruleName in this.messages) {
      if (ruleName === 'type') {
        if (this.messages.type.hasOwnProperty(ruleValue) && this.messages.type[ruleValue] !== '') {
          msg = this.messages.type[ruleValue]
        } else {
          msg = this.messages.type.undefined
        }
      } else {
        msg = this.messages[ruleName]
      }
    } else {
      msg = this.messages.undefined
    }

    if (!Array.isArray(ruleValue)) ruleValue = [ruleValue]

    ruleValue.forEach(val => {
      if (ruleName === 'equals' && val.includes('|')) val = val.split('|')[1]
      msg = msg.replace('{rule}', val)
    })

    return msg
  }
}
