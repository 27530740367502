<template>
  <e-chart
    :ref="`echart${chartRefId}`"
    :autoresize="true"
    :option="getChartOptions"
    :style="`height: ${getChartHeight}px;`"
    class="chart"
  />
</template>

<script>
import baseEChartBarProps from '@/theme/default/components/charts/echarts/mixins/bar/baseEChartBarProps'
import baseEChartBarData  from '@/theme/default/components/charts/echarts/mixins/bar/baseEChartBarData'
import baseEChartColors   from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'
import chartsColorMixin   from '@/theme/default/components/charts/mixins/chartsColorMixin'

import { first } from '@/lib/utils/array'

import { use }                 from 'echarts/core'
import { BarChart }            from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer }      from 'echarts/renderers'

import { GridComponent, LegendComponent, TitleComponent, TooltipComponent, TransformComponent } from 'echarts/components'
import { toDecimalSignificant, toNumberFormatNotationShort }                                    from '@/lib/utils/helper'
import chartsBaseCurrencySymbolSingle                                                           from '@/theme/default/components/charts/mixins/chartsBaseCurrencySymbolSingle'
import dayjs                                                                                    from 'dayjs'
import { isEmpty }                                                                              from '@/lib/utils/type'

use([
  BarChart,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
  CanvasRenderer,
  UniversalTransition
])

export default {
  name    : 'BaseEChartBarStack',
  mixins  : [baseEChartColors, chartsBaseCurrencySymbolSingle, chartsColorMixin, baseEChartBarProps, baseEChartBarData],
  computed: {
    getChartGridMargin () {
      return this.chartGridMargin || {
        left        : 30,
        top         : 20,
        right       : 20,
        bottom      : 20,
        containLabel: true
      }
    },
    getChartDataOptions () {
      // const tooltipValuePrefix = this.isBaseCurrencySymbolSingle ? '' : '<span class="pt-4 pr-1 text-size-12">' + this.axisYTickPrefix + '</span>'

      let o = {
        xAxis        : [],
        data         : [],
        dataAggregate: [],
        portfolio    : []
      }

      if (this.chartDataOptions.length) {
        o = first(this.chartDataOptions)
      }

      const prefix = (this.axisXTickPrefix + ' ' || '')
      const suffix = (this.axisXTickSuffix || '')

      const dataAggregate = o?.dataAggregate ?? null
      let totalAggregateItems = 0
      const totalAggregateItemsPerCol = 25
      const totalAggregateItemsPerRow = 5

      if (dataAggregate) {
        Object.values(dataAggregate).forEach(item => {
          totalAggregateItems += item.length
        })
      }

      let tooltipFontSizeClass = 'text-size-18'
      let tooltipFontSizeClass2 = 'text-size-18'
      if (totalAggregateItems > 0) {
        tooltipFontSizeClass = 'text-size-12'
        tooltipFontSizeClass2 = 'text-size-14'
      }

      return {
        animation         : this.chartAnimation,
        animationThreshold: this.chartAnimationThreshold,
        animationDuration : this.chartAnimationDuration,
        animationEasing   : this.chartAnimationEasing,
        tooltip           : {
          show           : this.tooltipShow,
          trigger        : 'axis',
          confine        : true,
          backgroundColor: this.themeColorRGBA(this.tooltipBackgroundColor, 0.8),
          borderColor    : this.tooltipBorderColor,
          borderWidth    : this.tooltipBorderWidth,
          padding        : this.tooltipPadding,
          textStyle      : {
            color: this.tooltipLabelColor
          },
          formatter: params => {
            const axisValue = params[0].axisValue
            const values = params.map((item) => {
              const marker = '<i aria-hidden="true" class="v-icon notranslate pt-0 mdi mdi-circle-outline theme--light" style="font-size: 12px; color: ' + item.color + '; caret-color: ' + item.color + ';"></i>'

              const aggregateItems = dataAggregate[item.seriesName] || []

              const aggregateItemsHtml = aggregateItems.map(item2 => {
                if (item2.length <= 0) {
                  return ''
                }

                let value2 = 0
                if (axisValue === 'VAR') {
                  value2 = toNumberFormatNotationShort(parseFloat(item2.ValueAtRiskRaw))
                }
                if (axisValue === 'HIST') {
                  value2 = toNumberFormatNotationShort(parseFloat(item2.HistoricalRaw))
                }
                if (axisValue === 'FWD') {
                  value2 = toNumberFormatNotationShort(parseFloat(item2.SimulationRaw))
                }
                if (axisValue === 'EX EV') {
                  value2 = toNumberFormatNotationShort(parseFloat(item2.ExtremeEventRaw))
                }
                return `<div class="pl-4 pr-4"><span class="mr-2 ${ tooltipFontSizeClass }">${ marker } ${ item2.Asset }</span><span class="ml-0 synthesis-text ${ tooltipFontSizeClass2 } font-weight-regular">${ prefix }${ value2 }${ suffix }</span></div>`
              }).join('')

              let value = item.value

              if (this.axisXLabelValueFormat === 'number') {
                value = toNumberFormatNotationShort(parseFloat(item.value))
              }
              if (this.axisXLabelValueFormat === 'percent') {
                value = (item.value === 0 ? item.value : toDecimalSignificant(parseFloat(item.value)))
              }
              if (this.axisXLabelValueFormat === 'date') {
                value = dayjs(item.value).format('MMM YY')
              }

              if (isEmpty(value)) {
                value = 0
              }

              return `<div class="pr-4"><span class="mr-2">${ item.marker } ${ item.seriesName }</span><span class="ml-0 synthesis-text text-size-18 font-weight-regular">${ prefix }${ value }${ suffix }</span></div>${ aggregateItemsHtml }`
            }).reverse()

            let htmlValues = ''

            if (totalAggregateItems > totalAggregateItemsPerCol) {
              const colStart = '<div class="col-6">'
              const colEnd = '</div>'

              let valueItems = []
              const len = values.length - 1

              htmlValues = colStart

              values.forEach((item, index) => {
                valueItems.push(item)

                htmlValues += item

                if (valueItems.length === totalAggregateItemsPerRow) {
                  htmlValues += colEnd
                  valueItems = []
                  htmlValues += (len === index) ? '' : colStart
                }
              })
            } else {
              htmlValues = values.join('')
            }

            const rowStart = totalAggregateItems > totalAggregateItemsPerCol ? '<div class="row">' : ''
            const rowEnd = totalAggregateItems > totalAggregateItemsPerCol ? '</div>' : ''

            return `${ axisValue }<br/>${ rowStart }${ htmlValues }${ rowEnd }`
          }
        },
        legend   : { show: this.legendShow },
        textStyle: {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid : this.getChartGridMargin,
        xAxis: [
          {
            type     : 'category',
            axisLabel: {
              show      : this.axisXLabelShow,
              inside    : this.axisXLabelInside,
              margin    : this.axisXLabelMargin,
              fontFamily: this.fontFamily,
              fontSize  : this.axisLabelFontSize,
              fontWeight: this.axisLabelFontWeight,
              color     : this.axisLabelFontColor
            },
            axisPointer: {
              show     : this.axisXPointerShow,
              lineStyle: {
                opacity: this.axisPointerLineStyleOpacity,
                color  : this.axisPointerLineStyleColor,
                width  : this.axisPointerLineStyleWidth

              },
              label: {
                show           : this.axisXPointerLabelShow,
                backgroundColor: this.axisPointerLabelBackgroundColor
              }
            },
            splitLine: { show: false },
            axisLine : { show: false },
            axisTick : { show: false },
            data     : o.xAxis
          }, {
            type     : 'category',
            show     : true,
            axisLabel: {
              show          : this.axisXLabelShow,
              margin        : 4,
              color         : this.axisLabelFontColor,
              fontFamily    : 'Aeonik, Roboto, sans-serif',
              fontSize      : 12,
              fontWeight    : 500,
              valueAnimation: true,
              formatter     : value => {
                if (value) {
                  if (this.axisXLabelValueFormat === 'number') {
                    value = toNumberFormatNotationShort(parseFloat(value))
                  }
                  if (this.axisXLabelValueFormat === 'percent') {
                    value = (value === 0 ? value : toDecimalSignificant(parseFloat(value)))
                  }
                  if (this.axisXLabelValueFormat === 'date') {
                    value = dayjs(value).format('MMM YY')
                  }

                  if (isEmpty(value)) {
                    value = 0
                  }

                  const prefix = (this.axisXTickPrefix + ' ' || '')
                  const suffix = (this.axisXTickSuffix || '')

                  return prefix + value + suffix
                }

                return ''
              }
            },
            axisPointer: { show: false },
            splitLine  : { show: false },
            axisLine   : { show: false },
            axisTick   : { show: false },
            data       : o.portfolio
          }
        ],
        yAxis: {
          type       : 'value',
          axisPointer: {
            show     : this.axisYPointerShow,
            lineStyle: {
              color: this.axisPointerLineStyleColor,
              width: this.axisPointerLineStyleWidth
            },
            label: {
              show           : this.axisYPointerLabelShow,
              backgroundColor: this.axisPointerLabelBackgroundColor
            }
          },
          axisLabel: {
            show      : this.axisYLabelShow,
            inside    : this.axisYLabelInside,
            margin    : this.axisYLabelMargin,
            padding   : [0, 0, 0, 0], // [top, right, bottom, left]
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            color     : this.axisLabelFontColor,
            formatter : value => {
              if (this.axisYLabelValueFormat === 'number') {
                value = toNumberFormatNotationShort(parseFloat(value))
              }
              if (this.axisYLabelValueFormat === 'percent') {
                value = (value === 0 ? value : toDecimalSignificant(parseFloat(value)))
              }
              if (this.axisYLabelValueFormat === 'date') {
                value = dayjs(value).format('MMM YY')
              }

              if (isEmpty(value)) {
                value = 0
              }

              const prefix = (this.axisYTickPrefix + ' ' || '')
              const suffix = (this.axisYTickSuffix || '')

              return prefix + value + suffix
            },
            interval     : this.axisYLabelInterval,
            verticalAlign: this.axisYLabelVerticalAlign
          },
          splitLine: {
            z        : 5,
            show     : this.axisYSplitLineShow,
            lineStyle: {
              type   : this.axisYSplitLineType,
              color  : this.axisYSplitLineColor,
              opacity: this.axisYSplitLineOpacity,
              width  : this.axisYSplitLineWidth
            }
          },
          splitNumber: this.axisYSplitNumber,
          axisLine   : { show: false },
          axisTick   : { show: false }
        },
        color : this.chartColors || this.colors,
        series: this.series
      }
    },

    series () {
      let o = {
        xAxis    : [],
        data     : [],
        labels   : [],
        portfolio: []
      }

      if (this.chartDataOptions.length) {
        o = first(this.chartDataOptions)
      }

      const barMaxWidth = (o.data.length < 4 ? (this.$vuetify.breakpoint.xs ? '100%' : '25%') : '100%')
      const data = []

      for (const [key, value] of Object.entries(o.data)) {
        const o = {
          name        : key,
          type        : 'bar',
          stack       : 'stack',
          barMinHeight: this.barMinHeight, // 2,
          label       : {
            show          : false,
            silent        : true,
            position      : 'top',
            fontFamily    : 'Aeonik, Roboto, sans-serif',
            fontSize      : 12,
            fontWeight    : 500,
            valueAnimation: true,
            formatter     : params => {
              const asset = o.labels[params.dataIndex] ?? ''

              if (asset === params.seriesName) {
                const portfolioValue = o.portfolio[params.dataIndex] ?? null

                if (portfolioValue) {
                  return this.baseCurrencySymbolSingle + toNumberFormatNotationShort(portfolioValue)
                }
              }

              return ''
            }
          },
          itemStyle: {
            // (clockwise upper left, upper right, bottom right and bottom left)
            borderRadius: [0, 0, 0, 0]
          },
          showBackground : true,
          backgroundStyle: {
            color: this.barBackgroundColor
          },
          cursor: this.cursor,
          data  : value
        }

        if (this.barCategoryGap === '-1') {
          o.barMaxWidth = barMaxWidth
        } else {
          o.barCategoryGap = this.barCategoryGap
        }

        data.push(o)
      }

      // Fix border radius
      if (data && data[0]?.itemStyle) {
        data[0].itemStyle.borderRadius = [0, 0, this.barBorderRadius, this.barBorderRadius]
        data[data.length - 1].itemStyle.borderRadius = [this.barBorderRadius, this.barBorderRadius, 0, 0]
      }

      return data
    }
  }
}
</script>

<style scoped>
.chart {
  width: 100%;
}
</style>
