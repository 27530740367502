import BaseResource                                          from '@/lib/data/resource/BaseResource'
import dayjs                                                 from 'dayjs'
import { toDecimalSignificant, toNumberFormatNotationShort } from '@/lib/utils/helper'
import AssetPairResource                                     from '@/api/resources/asset/AssetPairResource'
import { isEmpty }                                           from '@/lib/utils/type'

export default class PositionsTradeResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.id ?? ''
    this.Direction = payload.direction ?? ''
    this.DailyChange = payload.dailyChange ?? ''
    this.DailyChangeFmt = payload.dailyChange ? toDecimalSignificant(Math.abs(payload.dailyChange), 4) : ''
    this.Label = payload.label ?? ''
    this.Instrument = payload.instrument ?? ''
    this.MaturityDate = payload.maturityDate ?? ''
    this.MaturityDateFmt = this.getDateFmt(payload.maturityDate ?? null)
    this.MtmValue = payload.mtmValue ?? ''
    this.MtmValueFmt = payload.mtmValue ? toNumberFormatNotationShort(payload.mtmValue) : ''
    this.Notional = payload.notional ?? ''
    this.NotionalFmt = payload.notional ? toNumberFormatNotationShort(payload.notional) : ''
    this.StressTestMtm = payload.stressTestMtm ?? ''
    this.StressTestMtmFmt = payload.stressTestMtm ? toNumberFormatNotationShort(payload.stressTestMtm) : ''
    this.Rate = payload.rate ?? ''
    this.TradeDate = payload.tradeDate ?? ''
    this.TradeDateFmt = this.getDateFmt(payload.tradeDate ?? null)
    this.AssetPair = new AssetPairResource(payload.assetPair ?? null)
    this.OptionType = payload.optionType ?? ''
    this.DisplayInstrument = payload.displayInstrument ?? ''
    this.CurrentStressTestFactor = payload.currentStressTestFactor ?? 0
    this.StressTestOptions = this.getStressTestOptions(payload?.stressTestOptions || [])
  }

  /* PROPERTIES */
  get Pair () {
    return this.AssetPair?.Pair || ''
  }

  get Asset () {
    return this.AssetPair?.Asset || ''
  }

  get BaseAsset () {
    return this.AssetPair?.BaseAsset || ''
  }

  get QuoteAsset () {
    return this.AssetPair?.QuoteAsset || ''
  }

  get TableRow () {
    return {
      Id                 : this.Id,
      Asset              : this.Asset,
      BaseAsset          : this.BaseAsset,
      QuoteAsset         : this.QuoteAsset,
      Pair               : this.Pair,
      Label              : this.Label,
      Instrument         : this.DisplayInstrument,
      TradeDate          : this.TradeDateFmt,
      MaturityDate       : this.MaturityDateFmt,
      Rate               : this.Rate,
      MTMValue           : this.MtmValue,
      MTMValueFmt        : this.MtmValueFmt,
      Notional           : this.NotionalFmt,
      DailyChange        : this.DailyChange,
      DailyChangeFmt     : this.DailyChangeFmt,
      Direction          : this.Direction,
      BaseCurrency       : this.AssetPair?.QuoteSymbol || '',
      DailyChangeBgColor : this.getDailyChangeBgColor(),
      DailyChangeBgColors: this.getDailyChangeBgColors(),
      AnimatePriceTimeout: null
    }
  }

  get ExportRow () {
    return {
      Asset       : this.Asset,
      Label       : this.Label,
      Instrument  : this.DisplayInstrument,
      TradeDate   : this.TradeDateFmt,
      MaturityDate: this.MaturityDateFmt,
      Rate        : this.Rate,
      MTMValue    : this.MtmValueFmt,
      Notional    : this.NotionalFmt,
      DailyChange : this.DailyChange,
      Direction   : this.Direction
    }
  }

  get Card () {
    return {
      Id                     : this.Id,
      Asset                  : this.BaseAsset,
      Price                  : this.StressTestMtmFmt,
      Percent                : this.DailyChangeFmt,
      StressTestOptions      : this.StressTestOptions,
      CurrentStressTestFactor: this.CurrentStressTestFactor,
      BaseCurrency           : this.AssetPair?.QuoteSymbol || ''
    }
  }

  /* METHODS */
  getDateFmt (date) {
    return date ? dayjs(date).format('DD MMM YYYY') : ''
  }

  getDailyChangeBgColor () {
    if (this.DailyChange === 0) {
      return 'synthesis-ui-blue-07'
    } else {
      return (this.DailyChange > 0 ? 'synthesis-ui-green-07' : 'synthesis-ui-red-07')
    }
  }

  getDailyChangeBgColors () {
    return ['synthesis-ui-blue-07', 'synthesis-ui-green-07', 'synthesis-ui-red-07']
  }

  getStressTestOptions (values) {
    if (isEmpty(values)) {
      return []
    }

    return values.map(value => {
      return {
        Id     : this.Id,
        Title  : value,
        Value  : value,
        Loading: false
      }
    })
  }
}
