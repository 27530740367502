<template>
  <v-row>
    <v-col cols="12">
      <hedging-instrument-results-table-block :policy-id="id" />

      <v-row>
        <v-col>
          <block-item-card
            :title="$t('Hedging.Section.Results.InstrumentResults')"
          >
            <template
              v-if="$vuetify.breakpoint.mdAndUp"
              #actions
            >
              <v-spacer />
              <asset-button-group
                :items="assets"
                :selected.sync="selectedAsset"
                class="mt-4"
              />
            </template>
            <template
              v-else
              #actions-row
            >
              <v-card-title class="mx-3 mb-4 pa-0">
                <asset-button-group
                  :items="assets"
                  :selected.sync="selectedAsset"
                />
              </v-card-title>
            </template>
            <v-lazy
              :options="{threshold: .5}"
              :value="disableLazy"
              transition="fade-transition"
            >
              <e-chart-hedging-instruments-histogram
                :axis-x-label-hide-overlap="true"
                :axis-x-label-interval="8"
                :axis-x-label-margin="12"
                :axis-x-pointer-show="false"
                :axis-y-label-inside="false"
                :axis-y-label-margin="0"
                :axis-y-pointer-show="false"
                :axis-y-split-line-color="themeColorRGBA('synthesis-ui-grey-02', 1)"
                :axis-y-split-line-dash-offset="4"
                :axis-y-split-line-opacity="1"
                :axis-y-split-line-type="[4, 4]"
                :chart-animation="chartAnimation"
                :chart-animation-duration="600"
                :chart-area-fill-color="[themeColorRGBA('synthesis-ui-grey-01', 0.4), themeColorRGBA('synthesis-ui-grey-01', 0.03)]"
                :chart-data-options="chartValues"
                :chart-grid-margin="[
                  {left: 15,top: 30,right: '68%',bottom: 0,containLabel: true, show: true, borderWidth: 0},
                  {left: '34%',top: 30,right: '35%',bottom: 0,containLabel: true, show: true, borderWidth: 0},
                  {left: '67%',top: 30,right: 25,bottom: 0,containLabel: true, show: true, borderWidth: 0}
                ]"
                :chart-height="chartHeightBreakpoint"
                :chart-line-color="themeColorRGBA('synthesis-ui-grey-01')"
                :chart-line-show-symbol="false"
                :chart-scale="false"
                axis-x-label-value-format="number"
                axis-y-label-value-format="percent"
                axis-y-tick-suffix="%"
              />
            </v-lazy>

            <v-row class="pa-4">
              <v-col
                :md="appToolbarExtended ? 6 : 4"
                class="pr-3 pr-md-1 pb-0"
                cols="12"
                lg="4"
              >
                <item-chart-legend
                  :background-color="themeColorRGBA('synthesis-ui-purple-08')"
                  :base-currency-symbol="forwardLegend.baseCurrency"
                  :icon-color="themeColorRGBA('synthesis-ui-purple-02')"
                  :title="$t('Hedging.Table.Results.Instrument.Forward')"
                  :title-value="forwardLegend.value"
                />
              </v-col>
              <v-col
                :md="appToolbarExtended ? 6 : 4"
                class="pl-3 pl-md-1 pr-3 pr-lg-1 pb-0 pt-0 pt-md-3"
                cols="12"
                lg="4"
              >
                <item-chart-legend
                  :background-color="themeColorRGBA('synthesis-ui-red-08')"
                  :base-currency-symbol="optionLegend.baseCurrency"
                  :icon-color="themeColorRGBA('synthesis-ui-red-03')"
                  :title="$t('Hedging.Table.Results.Instrument.Option')"
                  :title-value="optionLegend.value"
                />
              </v-col>
              <v-col
                :md="appToolbarExtended ? 12 : 4"
                class="py-0 pt-0 pt-lg-3 pl-3 pl-lg-1"
                cols="12"
                lg="4"
              >
                <item-chart-legend
                  :background-color="themeColorRGBA('synthesis-ui-yellow-08')"
                  :base-currency-symbol="collarLegend.baseCurrency"
                  :icon-color="themeColorRGBA('synthesis-ui-yellow-02')"
                  :title="$t('Hedging.Table.Results.Instrument.Collar')"
                  :title-value="collarLegend.value"
                />
              </v-col>
            </v-row>
          </block-item-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

import policyId                           from '@/theme/default/views/hedging/mixins/policyId'
import HedgingInstrumentResultsTableBlock from '@/theme/default/views/hedging/blocks/instrument/HedgingInstrumentResultsTableBlock'
import BlockItemCard                      from '@/theme/default/components/common/BlockItemCard'
import AssetButtonGroup                   from '@/theme/default/components/common/AssetButtonGroup'
import EChartHedgingInstrumentsHistogram  from '@/theme/default/components/charts/echarts/EChartHedgingInstrumentsHistogram'
import chartsColorMixin                   from '@/theme/default/components/charts/mixins/chartsColorMixin'
import InstrumentTypeEnum                 from '@/api/enums/InstrumentTypeEnum'
import ItemChartLegend                    from '@/theme/default/components/common/ItemChartLegend'
import useExportAdditional                from '@/mixins/useExportAdditional'

export default {
  name      : 'HedgingInstrumentResultsBlock',
  components: {
    EChartHedgingInstrumentsHistogram,
    HedgingInstrumentResultsTableBlock,
    AssetButtonGroup,
    ItemChartLegend,
    BlockItemCard
  },
  directives: {},
  mixins    : [chartsColorMixin, policyId, useExportAdditional],
  props     : {},
  dataStore : {
    appToolbarExtended: 'App.Config.AppToolbarExtended',
    hedgingSummaryData: 'Hedging.Summary.Data',
    hedgingInstrument : 'Hedging.Instrument'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      selectedAsset: 'portfolio'
    }
  },
  computed: {
    chartHeightBreakpoint () {
      return this.$vuetify.breakpoint.mdAndDown ? 750 : 280
    },

    forward () {
      return this.hedgingInstrument.Forward.Data[this.id] ?? null
    },

    forwardHedgedCashFlows () {
      if (this.forward) {
        return this.forward.HedgedCashFlows.find(item => item.AssetValue === this.selectedAsset) || null
      }
      return null
    },

    option () {
      return this.hedgingInstrument.Option.Data[this.id] ?? null
    },

    optionHedgedCashFlows () {
      if (this.option) {
        return this.option.HedgedCashFlows.find(item => item.AssetValue === this.selectedAsset) || null
      }
      return null
    },

    collar () {
      return this.hedgingInstrument.Collar.Data[this.id] ?? null
    },

    collarHedgedCashFlows () {
      if (this.collar) {
        return this.collar.HedgedCashFlows.find(item => item.AssetValue === this.selectedAsset) || null
      }
      return null
    },

    forwardLegend () {
      if (this.forwardHedgedCashFlows) {
        return {
          baseCurrency: this.forwardHedgedCashFlows?.BaseCurrencyDisplayName || '',
          value       : this.forwardHedgedCashFlows?.MeanFmt || ''
        }
      }
      return ''
    },

    optionLegend () {
      if (this.optionHedgedCashFlows) {
        return {
          baseCurrency: this.optionHedgedCashFlows?.BaseCurrencyDisplayName || '',
          value       : this.optionHedgedCashFlows?.MinFmt || ''
        }
      }
      return ''
    },

    collarLegend () {
      if (this.collarHedgedCashFlows) {
        return {
          baseCurrency: this.collarHedgedCashFlows?.BaseCurrencyDisplayName || '',
          value       : this.collarHedgedCashFlows?.MinFmt + '-' + this.collarHedgedCashFlows?.MaxFmt
        }
      }
      return ''
    },

    assets () {
      return this.forward?.Items || []
    },

    chartValues () {
      return [{
        [InstrumentTypeEnum.FORWARD]: {
          data : this.forwardHedgedCashFlows?.ChartValues || [],
          color: this.themeColorRGBA('synthesis-ui-purple-02')
        },
        [InstrumentTypeEnum.OPTION]: {
          data : this.optionHedgedCashFlows?.ChartValues || [],
          color: this.themeColorRGBA('synthesis-ui-red-03')
        },
        [InstrumentTypeEnum.COLLAR]: {
          data : this.collarHedgedCashFlows?.ChartValues || [],
          color: this.themeColorRGBA('synthesis-ui-yellow-02')
        }
      }]
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
