import BaseModel         from '@/lib/data/model/BaseModel'
import IndustryResource  from '@/api/resources/user/IndustryResource'

export default class IndustryModel extends BaseModel {
  constructor (payload = {}, resource = IndustryResource, validator = null) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}
