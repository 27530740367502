import BaseResource      from '@/lib/data/resource/BaseResource'
import { toDecimalMark } from '@/lib/utils/helper'

export default class RiskExtremeEventsAssetsResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Asset = payload.asset ?? ''
    this.FinalRate = payload.finalRate ?? ''
    this.InitialRate = payload.initialRate ?? ''

    this.PercentageChangeRaw = payload.percentageChange ? toDecimalMark(payload.percentageChange) : payload.percentageChange
    this.PercentageChange = this.getValueSign(this.PercentageChangeRaw)

    this.Color = this.getColor(payload.percentageChange)
    this.ColorBg = this.getColorBg(payload.percentageChange)
  }

  /* PROPERTIES */

  /* METHODS */
  getValueSign (value) {
    return (value > 0 ? ('+' + value) : value) + '%'
  }

  getColor (value) {
    if (value === 0) {
      return 'synthesis-ui-grey-01'
    }

    return value < 0 ? 'synthesis-ui-red-03' : 'synthesis-ui-green-01'
  }

  getColorBg (value) {
    if (value === 0) {
      return 'synthesis-ui-grey-05'
    }

    return value < 0 ? 'synthesis-ui-red-08' : 'synthesis-ui-green-08'
  }
}
