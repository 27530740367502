import BaseResource          from '@/lib/data/resource/BaseResource'
import LivePriceCategoryEnum from '@/api/enums/LivePriceCategoryEnum'

export default class TrackLivePriceItemResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.category = payload.category ?? ''
    this.name = payload.name ?? ''
    this.label = payload.label ?? ''
    this.price = payload.price ?? 0
    this.pair = payload.pair ?? null
    this.dailyChange = payload.dailyChange ?? 0
    this.dailyChangeColor = this.getDailyChangeColor(this.dailyChange)
    this.quoteDisplaySymbol = payload.quoteDisplaySymbol ?? ''
    this.symbol = payload.symbol ?? ''
    this.selected = this.pairIsInUserPreferences()
  }

  /* PROPERTIES */
  get priceFmt () {
    return this.getCurrencySymbol() + this.price + this.getPriceSuffix()
  }

  get dailyChangeFmt () {
    return this.dailyChange ? `${ this.dailyChange > 0 ? '+' : '' }${ this.dailyChange }%` : '0.00%'
  }

  /* METHODS */
  getCurrencySymbol () {
    return this.quoteDisplaySymbol.length === 1 ? this.quoteDisplaySymbol : (this.quoteDisplaySymbol + ' ')
  }

  getPriceSuffix () {
    if (this.category === LivePriceCategoryEnum.INTER_BANK_RATE) {
      return '%'
    }

    return ''
  }

  getDailyChangeColor (v) {
    if (v === 0) return 'synthesis-brand-dark-blue--text'

    return (v > 0 ? 'synthesis-ui-green-01--text' : 'synthesis-ui-red-02--text')
  }

  pairIsInUserPreferences () {
    if (!this.pair) return false
    const userPairs = window && window?.Vue?.user?.livePricesPairs || []

    return !!userPairs.find(userPair => {
      return userPair.baseSymbol === this.pair.baseSymbol && userPair.quoteSymbol === this.pair.quoteSymbol
    })
  }
}
