import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class AddressValidator extends BaseValidator {
  validatorRules = {}

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
