import BaseResource       from '@/lib/data/resource/BaseResource'
import { collect }        from '@/lib/utils/array'
import SubsidiaryResource from '@/api/resources/user/SubsidiaryResource'

export default class CorporateGroupResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.id = payload?.id ?? ''
    this.name = payload?.name ?? ''
    this.subsidiaries = collect(payload?.subsidiaries, SubsidiaryResource)
  }

  /* PROPERTIES */

  /* METHODS */
}
