import BaseResource from '@/lib/data/resource/BaseResource'

export default class HedgingCarryInputResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.assetCode = payload.assetCode ?? ''
    this.carry = parseFloat(payload.carry) ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
