<template>
  <v-card
    :min-height="minHeight"
    :style="getStyle"
    class="pa-0 rounded"
    flat
  >
    <slot name="header">
      <v-subheader
        v-if="title"
        class="synthesis-inter-text synthesis-ui-grey-01--text"
      >
        <slot name="title">
          {{ title }}
        </slot>
        <slot name="tooltip">
          <infotip
            v-if="tooltip"
            icon-class="ml-1"
            icon-style="margin-top: -6px"
          >
            {{ tooltip }}
          </infotip>
        </slot>
        <slot name="export">
          <v-card-title class="pa-0">
            <export-to-image
              v-if="exportElement"
              :export-element="exportElement"
              :export-file-name="exportFileName || title"
              @export-to-image="$emit('export-to-image')"
              @export-to-image-completed="$emit('export-to-image-completed')"
            />
          </v-card-title>
        </slot>
        <slot name="actions" />
      </v-subheader>
      <slot name="actions-row" />
    </slot>

    <slot name="header2" />

    <v-card-title
      v-if="subTitle"
      class="pt-0 pl-4 text-size-24 font-weight-medium synthesis-brand-dark-blue--text"
      style="margin-top: -5px"
    >
      {{ subTitle }}
    </v-card-title>

    <v-card-title
      v-if="titleValue"
      :class="`pt-0 pl-${titleValuePaddingLeft} text-size-${titleLarge ? titleLargeSize : titleSize} ${getTitleValueColorClass()}`"
      :style="`margin-top: ${titleValueMarginTop}px;`"
    >
      <span
        v-if="titlePrefix"
        :class="`${showOverlay ? 'text-blur' : ''}`"
        class="pt-md-6 pt-3 pr-2 text-size-24 font-weight-bold synthesis-brand-dark-blue--text"
      >
        {{ titlePrefix }}
        <infotip
          v-if="titlePrefixTooltip"
          icon-style="margin-top: -26px"
        >{{ titlePrefixTooltip }}
        </infotip>
      </span>

      <span
        v-if="titleValuePrefix"
        :class="`${showOverlay ? 'text-blur' : ''}`"
        class="pt-2 text-size-18 synthesis-brand-dark-blue--text"
      >
        {{ titleValuePrefix + '&nbsp;' }}
      </span>

      <span
        v-if="baseCurrencySymbol && !isBaseCurrencySymbolSingle"
        :class="`pt-${titleLarge ? '6' : '3'} pr-1 text-size-${titleLarge ? '24' : '12'} ${getTitleValueColorClass()} ${showOverlay ? 'text-blur' : ''}`"
        v-text="baseCurrencySymbol"
      /><span :class="`${showOverlay ? 'text-blur' : ''}`">{{ baseCurrencySymbolSingle + titleValue }}</span>

      <span
        v-if="titleValueSuffix"
        :class="`${showOverlay ? 'text-blur' : ''}`"
        class="pt-2 text-size-18 font-weight-bold synthesis-brand-dark-blue--text"
        v-text="titleValueSuffix"
      />

      <span
        v-if="titleValueChange"
        :class="`text-size-14 ${titleValueChangeColor}--text ${showOverlay ? 'text-blur' : ''}`"
        class="mt-6 ml-3"
      >
        <v-icon
          v-if="titleValueChangeIcon"
          :color="titleValueChangeColor"
          size="14"
          style="margin-top: -7px;"
        >
          {{ titleValueChangeIcon }}
        </v-icon>
        {{ titleValueChange }}
      </span>
      <slot name="avatar" />

      <slot name="tooltipValue">
        <infotip
          v-if="tooltipValue"
          icon-class="ml-1"
          icon-style="margin-top: -12px"
        >
          {{ tooltipValue }}
        </infotip>
      </slot>
    </v-card-title>
    <slot name="extra" />

    <slot />
  </v-card>
</template>

<script>

import chartsColorMixin from '@/theme/default/components/charts/mixins/chartsColorMixin'
import Infotip          from '@/theme/default/components/common/Infotip'
import ExportToImage    from '@/theme/default/components/common/ExportToImage'

export default {
  name      : 'BlockItemCard',
  components: {
    ExportToImage,
    Infotip
  },
  directives: {},
  mixins    : [chartsColorMixin],
  props     : {
    exportElement: {
      type   : HTMLDivElement,
      default: undefined
    },
    exportFileName: {
      type   : String,
      default: ''
    },
    minHeight: {
      type   : [String, Number],
      default: 350
    },
    title: {
      type   : String,
      default: ''
    },
    titleSize: {
      type   : [String, Number],
      default: 28
    },
    titleLarge: {
      type   : Boolean,
      default: true
    },
    titleLargeSize: {
      type   : [String, Number],
      default: 52
    },
    subTitle: {
      type   : String,
      default: ''
    },
    titlePrefix: {
      type   : String,
      default: ''
    },
    titlePrefixTooltip: {
      type   : String,
      default: ''
    },
    titleValue: {
      type   : [String, Number],
      default: ''
    },
    titleValuePrefix: {
      type   : String,
      default: ''
    },
    titleValueSuffix: {
      type   : String,
      default: ''
    },
    titleValueMarginTop: {
      type   : Number,
      default: -5
    },
    titleValuePaddingLeft: {
      type   : Number,
      default: 4
    },
    titleValueChange: {
      type   : String,
      default: ''
    },
    titleValueChangeColor: {
      type   : String,
      default: ''
    },
    titleValueChangeIcon: {
      type   : String,
      default: ''
    },
    baseCurrencySymbol: {
      type   : String,
      default: ''
    },
    titleValueColorRed: {
      type   : Boolean,
      default: true
    },
    titleValueColorGreen: {
      type   : Boolean,
      default: false
    },
    titleValueColor: {
      type   : String,
      default: undefined
    },
    tooltip: {
      type   : String,
      default: ''
    },
    tooltipValue: {
      type   : String,
      default: ''
    },
    showOverlay: {
      type   : Boolean,
      default: false
    },
    showBackground: {
      type   : Boolean,
      default: true
    },
    showBorder: {
      type   : Boolean,
      default: true
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    isBaseCurrencySymbolSingle () {
      return this.baseCurrencySymbol.length === 1
    },

    baseCurrencySymbolSingle () {
      return this.isBaseCurrencySymbolSingle ? this.baseCurrencySymbol : ''
    },

    getStyle () {
      let style = this.showBackground ? '' : 'background: none !important;'
      style += this.showBorder ? `border-radius: 8px !important; border: ${ this.themeColorRGBA('synthesis-ui-grey-02') } 1px solid` : ''

      return style
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getTitleValueColorClass () {
      if (this.titleValueColor) {
        return this.titleValueColor
      }
      return this.titleValueColorRed ? 'synthesis-ui-red-02--text' : (this.titleValueColorGreen ? 'synthesis-ui-green-01--text' : 'synthesis-brand-dark-blue--text')
    }
  }
}
</script>

<style scoped>
.text-blur {
  filter: blur(4px);
}
</style>
