import BaseResource            from '@/lib/data/resource/BaseResource'
import { collect }             from '@/lib/utils/array'
import AssetStrategyInputModel from '@/api/models/asset/AssetStrategyInputModel'

export default class HedgingCurrentStrategyResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.policyId = payload.policyId ?? payload.Id ?? ''
    this.currentAssetStrategy = collect(payload.currentAssetStrategy ?? payload.Data ?? [], AssetStrategyInputModel)
  }

  /* PROPERTIES */

  /* METHODS */
}
