import BaseResource                 from '@/lib/data/resource/BaseResource'
import CompanyAttributeCategoryEnum from '@/api/enums/CompanyAttributeCategoryEnum'

export default class ComparisonAttributeResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.id ?? ''
    this.Name = payload.name ?? ''
    this.Category = payload.category ?? CompanyAttributeCategoryEnum.Financial
  }

  /* PROPERTIES */

  /* METHODS */
}
