<template>
  <v-container
    class="align-content-start"
    fill-height
    fluid
  >
    <v-row>
      <section-header
        :breadcrumb-items="breadcrumb"
        :button="viewHasData"
        :button-title="$t('Alerts.Button.New.Title')"
        button-to="AlertNew"
      />
    </v-row>

    <v-row style="height: calc(100% - 70px);">
      <v-col cols="12">
        <v-container
          class="align-content-start"
          fill-height
          fluid
          pa-0
        >
          <v-row>
            <v-col cols="12">
              <v-tabs
                v-model="activeTab"
                active-class="active-tab"
                background-color="transparent"
                height="30"
                hide-slider
                show-arrows
              >
                <v-tab :href="`#${$t('Alerts.Tab.Active.Slug')}`">
                  {{ $t('Alerts.Tab.Active.Title') }}
                </v-tab>
                <v-tab :href="`#${$t('Alerts.Tab.History.Slug')}`">
                  {{ $t('Alerts.Tab.History.Title') }}
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>

          <v-row class="fill-height">
            <v-col
              class="pa-0"
              cols="12"
            >
              <v-tabs-items
                v-model="activeTab"
                class="fill-height"
              >
                <v-tab-item
                  v-if="activeTab === $t('Alerts.Tab.Active.Slug')"
                  :value="$t('Alerts.Tab.Active.Slug')"
                >
                  <view-data-container
                    :button-title="$t('Alerts.Button.New.Title')"
                    :loading="isLoadingData"
                    :no-data-title="$t('Alerts.Message.NoData.Title')"
                    button
                    button-to="AlertNew"
                    class="pa-0"
                  >
                    <template v-if="viewHasData">
                      <v-container fluid>
                        <v-row>
                          <template v-for="(item, index) in items">
                            <v-col
                              :key="`tab-${activeTab}-items-${index}`"
                              cols="12"
                              lg="4"
                              sm="6"
                              style="padding: 10px"
                            >
                              <v-lazy
                                :key="`lazy-alerts-active-tab-${activeTab}-items-${index}`"
                                :options="{threshold: .5}"
                                transition="fade-transition"
                              >
                                <archive-item-card
                                  :caption="item.Caption"
                                  :card-color="item.Color"
                                  :card-event="true"
                                  :date-created="item.CreatedAtFmt"
                                  :menu-items="$t('Alerts.Tab.Active.Card.MenuItems')"
                                  :tags="item.Tags"
                                  :title="item.Name"
                                  @card-click="onItemCardClick(item)"
                                  @active-list-item-edit-button-click="onItemCardEditClick(item)"
                                  @archive-item-delete-button-click="onItemCardDeleteClick(item)"
                                >
                                  <template #avatar>
                                    <v-avatar
                                      :color="`synthesis-ui-${item.Color}-07`"
                                      class="mt-5"
                                      size="36"
                                    >
                                      <v-icon :color="`synthesis-ui-${item.Color}-01`">
                                        mdi-bell
                                      </v-icon>
                                    </v-avatar>
                                  </template>
                                </archive-item-card>
                              </v-lazy>
                            </v-col>
                          </template>
                        </v-row>
                      </v-container>
                    </template>
                  </view-data-container>
                </v-tab-item>
                <v-tab-item
                  v-if="activeTab === $t('Alerts.Tab.History.Slug')"
                  :value="$t('Alerts.Tab.History.Slug')"
                >
                  <alerts-history />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta     from '@/mixins/i18n/i18nRouteMeta'
import SectionHeader     from '@/theme/default/components/common/SectionHeader'
import viewHasData       from '@/mixins/viewHasData'
import routerTabState    from '@/mixins/routerTabState'
import ArchiveItemCard   from '@/theme/default/components/common/ArchiveItemCard'
import API               from '@/api/Api'
import { clone }         from '@/lib/utils/helper'
import AlertResource     from '@/api/resources/alerts/AlertResource'
import { collect }       from '@/lib/utils/array'
import AlertsHistory     from '@/theme/default/views/alerts/AlertsHistory'
import ViewDataContainer from '@/theme/default/components/common/ViewDataContainer'

export default {
  name      : 'Alerts',
  components: {
    ViewDataContainer,
    AlertsHistory,
    ArchiveItemCard,
    SectionHeader
  },
  directives: {},
  mixins    : [i18nRouteMeta, viewHasData, routerTabState],
  props     : {},
  dataStore : {
    items            : 'Alerts.Active.Data',
    alertsSummaryData: 'Alerts.Summary.Data'
  },
  enums: {},
  data () {
    return {}
  },
  computed: {
    breadcrumb () {
      return [{
        text    : this.$t('Alerts.Title'),
        disabled: true
      }]
    },

    accountId () {
      return this.user?.selectedAccountId || null
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      this.isLoadingData = true

      API.Resource.Alerts.Active(this.accountId)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {
          this.isLoadingData = false
        })
    },
    handleResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        const list = data?.listAlerts || null

        if (list) {
          const o = clone(list)

          this.items = collect(o, AlertResource)
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    onItemCardEditClick (item) {
      if (!item || !item.Id) return

      this.$router.push({
        name  : 'AlertEdit',
        params: {
          id  : item.Id,
          item: item
        }
      })
    },

    onItemCardDeleteClick (item) {
      if (!item || !item.Id) return

      this.$root.$confirmDialog(
        this.$t('Alerts.Tab.Active.Confirm.Delete.Title'),
        this.$t('Alerts.Tab.Active.Confirm.Delete.Body', { Name: item.Name }),
        {
          color : 'synthesis-ui-red-02',
          button: {
            no : { title: this.$t('Common.Button.Cancel') },
            yes: { title: this.$t('Common.Button.Delete') }
          }
        }
      ).then(response => {
        if (response) {
          API.Resource.Alerts.DeleteAlert(item.Id)
            .then(response => {
              this.handleDeleteAlertResponse(response)
            })
            .catch(e => {
              this.handleDeleteAlertResponse(e.response)
            })
            .finally(() => {})
        }
      })
    },
    handleDeleteAlertResponse (response) {
      const alertId = API.responseData(response)?.deleteAlert
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && alertId) {
        this.deleteStorageDataById(alertId)
        this.getData()
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },
    deleteStorageDataById (alertId) {
      this.items = null
      this.$delete(this.alertsSummaryData, alertId)
    },

    getItemData (item) {
      API.Resource.Alerts.Summary(item.Id)
        .then(response => {
          this.handleItemResponse(response)
        })
        .catch(e => {
          this.handleItemResponse(e.response)
        })
        .finally(() => {})
    },
    handleItemResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        const p = data?.findAlert || null
        if (p) {
          const o = clone(p)
          const v = new AlertResource(o)

          // Store data to DataStore
          this.$set(this.alertsSummaryData, v.Id, v)
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    onItemCardClick (item) {
      this.getItemData(item)

      this.$router.push({
        name  : 'AlertShow',
        params: {
          id     : item.Id,
          alertId: item.Id
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
