<template>
  <view-data-container
    :button-title="$t('Alerts.Button.New.Title')"
    :loading="isLoadingData"
    :no-data-title="$t('Alerts.Message.NoData.Title')"
    :style="`${hasArchiveData ? '' : 'min-height: calc(100vh - 160px);'}`"
  >
    <template v-if="hasArchiveData">
      <alert-history />
    </template>
  </view-data-container>
</template>

<script>

import ViewDataContainer     from '@/theme/default/components/common/ViewDataContainer'
import AlertHistory          from '@/theme/default/views/alerts/blocks/AlertHistory'
import API                   from '@/api/Api'
import { clone }             from '@/lib/utils/helper'
import AlertsArchiveResource from '@/api/resources/alerts/AlertsArchiveResource'
import viewHasData           from '@/mixins/viewHasData'

export default {
  name      : 'AlertsHistory',
  components: {
    AlertHistory,
    ViewDataContainer
  },
  directives: {},
  mixins    : [viewHasData],
  props     : {},
  dataStore : {
    alertsArchiveData: 'Alerts.Archive.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    breadcrumb () {
      return [{
        text    : this.$t('Alerts.Title'),
        disabled: false,
        exact   : true,
        to      : { name: 'Alerts' }
      }]
    },

    accountId () {
      return this.user?.selectedAccountId || null
    },

    hasArchiveData () {
      return this.alertsArchiveData && !!this.alertsArchiveData?.TableValues
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      this.isLoadingData = true

      API.Resource.Alerts.Archive({
        accountId   : this.accountId,
        itemsPerPage: 10,
        page        : 0
      })
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {
          this.isLoadingData = false
        })
    },
    handleResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        const list = data?.listAlertIncidents || null
        if (list) {
          const o = clone(list)
          this.alertsArchiveData = new AlertsArchiveResource(o)
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>
/deep/ .v-toolbar__content, .v-toolbar__extension {
  padding : 4px 0 !important;
}
</style>
