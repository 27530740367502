export default {
  data () {
    return {
      customWatchers: []
    }
  },

  methods: {
    watch (objStr, callback, deep, immediate) {
      if (this.customWatchers.find(item => item.obj === objStr)) return

      let unwatchFunction = null
      deep = deep || false
      immediate = immediate || false

      unwatchFunction = this.$watch(objStr, callback, {
        deep     : deep,
        immediate: immediate
      })
      this.customWatchers.push({
        obj: objStr,
        fn : unwatchFunction
      })
    },

    unwatch (objStr) {
      const index = this.customWatchers.findIndex(item => item.obj === objStr)
      if (index > -1) {
        this.customWatchers[index].fn()
        this.customWatchers.splice(index, 1)
      }
    },

    unwatchAll () {
      let i = 0
      for (i = 0; i < this.customWatchers.length; i++) {
        this.customWatchers[i].fn()
      }
      this.customWatchers = []
    }
  },
  beforeDestroy () {
    this.unwatchAll()
  }

}
