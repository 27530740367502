<script>
import { graphic }                                                  from 'echarts/core'
import { clone, toDecimalSignificant, toNumberFormatNotationShort } from '@/lib/utils/helper'
import { last }                                                     from '@/lib/utils/array'
import baseEChartColors                                             from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'
import chartsColorMixin                                             from '@/theme/default/components/charts/mixins/chartsColorMixin'
import BaseEChartLine                                               from '@/theme/default/components/charts/echarts/BaseEChartLine'
import BaseEChartLineAreaProps                                      from '@/theme/default/components/charts/echarts/mixins/lineArea/baseEChartLineAreaProps'
import BaseEChartLineAreaData                                       from '@/theme/default/components/charts/echarts/mixins/lineArea/baseEChartLineAreaData'
import dayjs                                                        from 'dayjs'
import chartsBaseCurrencySymbolSingle                               from '@/theme/default/components/charts/mixins/chartsBaseCurrencySymbolSingle'

export default {
  name    : 'EChartForecasts',
  extends : BaseEChartLine,
  mixins  : [baseEChartColors, chartsColorMixin, chartsBaseCurrencySymbolSingle, BaseEChartLineAreaProps, BaseEChartLineAreaData],
  computed: {
    getChartDataOptions () {
      const tooltipValuePrefix = this.isBaseCurrencySymbolSingle ? '' : '<span class="pt-4 pr-1 text-size-12">' + this.axisYTickPrefix + '</span>'

      let chartAreaFillColor = this.chartAreaFillColor

      if (this.chartAreaFillColor?.length && this.chartAreaFillColor?.length < 2) {
        chartAreaFillColor = [this.chartAreaFillColor[0], '']
      }

      let values1 = {
        x: [],
        y: []
      }

      let values2 = {
        x     : [],
        y     : [],
        labels: []
      }

      if (this.chartDataOptions.length) {
        values1 = this.chartDataOptions[0]
        values2 = this.chartDataOptions[1]
      }

      const series = []

      /**
       * Historical
       */
      series.push({
        type        : 'line',
        smooth      : this.chartLineSmooth,
        symbol      : this.chartLineSymbol,
        symbolSize  : this.chartLineSymbolSize,
        symbolOffset: this.chartLineSymbolOffset,
        showSymbol  : this.chartLineShowSymbol,
        sampling    : 'lttb',
        itemStyle   : {
          color: this.chartLineColor
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color : chartAreaFillColor[0]
            },
            {
              offset: 0.60,
              color : chartAreaFillColor[1]
            },
            {
              offset: 1,
              color : chartAreaFillColor[1]
            }
          ])
        },
        universalTransition    : true,
        animationDurationUpdate: 400,
        animationEasingUpdate  : 'linear',
        cursor                 : this.cursor,
        silent                 : this.silent,
        data                   : values1.y
      })

      const lastValueFromHistorical = last(values1.y)
      const min1Y = Math.min(...values1.y)
      const max1Y = Math.max(...values1.y)
      const min2Ys = []
      const max2Ys = []

      /**
       * Forecasts
       */
      if (values2.y && !!values2.y.length) {
        const labels = values2.labels

        values2.y.forEach((data, index) => {
          const v = clone(data)
          v.unshift(lastValueFromHistorical)

          const vData = v.map((v, i) => {
            return {
              name : `${ labels[index] }-${ i }`,
              value: v,
              type : 'forecast'
            }
          })

          min2Ys.push(Math.min(...data))
          max2Ys.push(Math.max(...data))

          const color = (index === 0 ? this.themeColorRGBA('synthesis-ui-grey-01') : this.colors[index])

          series.push({
            type        : 'line',
            name        : labels[index],
            stack       : index + 1,
            smooth      : this.chartLineSmooth,
            symbol      : 'emptyCircle',
            showSymbol  : this.chartLineShowSymbol,
            symbolSize  : 6,
            symbolOffset: [0, 0],
            sampling    : 'lttb',
            tooltip     : {
              formatter: params => {
                const values = values2.x.map((item, index) => {
                  return `<div class="pr-4"><span class="mr-2">${ dayjs(item).format(this.axisXLabelValueDateFormat) }</span>${ tooltipValuePrefix }<span class="ml-0 synthesis-text text-size-18 font-weight-regular text-right">${ this.baseCurrencySymbolSingle }${ toNumberFormatNotationShort(v[index]) }</span></div>`
                }).join('')

                return `${ params.marker } ${ params.seriesName }<br/>${ values }`
              }
            },
            itemStyle: {
              color: color
            },
            labelLayout: {
              moveOverlap: 'shiftY'
            },
            emphasis: {
              focus: 'series'
            },
            xAxisIndex             : 1,
            yAxisIndex             : 1,
            animationDelay         : 900,
            animationDurationUpdate: this.chartAnimationDuration,
            animationEasingUpdate  : 'linear',
            cursor                 : this.cursor,
            data                   : vData
          })
        })
      }

      const min = Math.min(min1Y, ...min2Ys)
      const max = Math.max(max1Y, ...max2Ys)

      return {
        animation: this.chartAnimation,
        textStyle: {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid   : this.getChartGridMargin,
        tooltip: {
          trigger        : 'item',
          backgroundColor: this.themeColorRGBA(this.tooltipBackgroundColor, 0.8),
          borderColor    : this.tooltipBorderColor,
          borderWidth    : this.tooltipBorderWidth,
          padding        : this.tooltipPadding,
          textStyle      : {
            color: this.tooltipLabelColor
          },
          formatter: params => {
            if (params && params.length) {
              const axisValue = params[0].axisValueLabel

              const values = params.map(item => {
                return `${ tooltipValuePrefix }<span class="ml-0 synthesis-text text-size-18 font-weight-regular">${ this.baseCurrencySymbolSingle }${ toNumberFormatNotationShort(item.value) }</span></div>`
              }).join('')

              return `<span class="synthesis-text text-size-14 font-weight-regular">${ axisValue }</span><br/>${ values }`
            }

            return ''
          }
        },
        xAxis: [
          {
            data       : values1.x,
            min        : 'dataMin',
            max        : 'dataMax',
            type       : 'category',
            axisPointer: {
              z        : 1,
              show     : this.axisXPointerShow,
              snap     : true,
              lineStyle: {
                type      : this.axisPointerLineStyleType,
                dashOffset: this.axisPointerLineDashOffset,
                color     : this.axisPointerLineStyleColor,
                width     : this.axisPointerLineStyleWidth
              },
              label: {
                show     : this.axisYPointerLabelShow,
                formatter: (params) => {
                  return this.$dayjs(params.value).format(this.axisXLabelValueDateFormat)
                },
                backgroundColor: this.axisPointerLabelBackgroundColor
              }
            },
            axisLine: {
              show     : this.axisXGridLineShow,
              lineStyle: {
                color: this.axisXGridLineStyleColor
              }
            },
            axisLabel: {
              show      : true,
              fontFamily: this.fontFamily,
              fontSize  : this.axisLabelFontSize,
              color     : this.axisLabelFontColor,
              formatter : (params) => {
                return (this.axisXTickPrefix || '') + this.$dayjs(params).format('MMM YYYY') + (this.axisXTickSuffix || '')
              },
              align      : 'center',
              inside     : this.axisXLabelInside,
              margin     : this.axisXLabelMargin,
              padding    : [0, 0, 0, -20], // [top, right, bottom, left]
              interval   : this.axisXLabelInterval,
              hideOverlap: this.axisXLabelHideOverlap,
              rotate     : 0
            },
            axisTick: {
              show          : false,
              inside        : true,
              alignWithLabel: true
            },
            minInterval: 30, // Minimum gap between split lines.
            splitLine  : {
              show     : this.axisXSplitLineShow,
              lineStyle: {
                type   : this.axisXSplitLineType,
                color  : this.axisXSplitLineColor,
                opacity: this.axisXSplitLineOpacity,
                width  : this.axisXSplitLineWidth
              }
            },
            splitNumber: this.axisXSplitNumber,
            boundaryGap: true, // this.axisXBoundaryGap,
            silent     : true
          },
          {
            gridIndex  : 1,
            data       : values2.x,
            min        : 'dataMin',
            max        : 'dataMax',
            type       : 'category',
            axisPointer: {
              z             : 1,
              type          : 'none',
              show          : true,
              snap          : true,
              triggerTooltip: false,
              lineStyle     : {
                type      : this.axisPointerLineStyleType,
                dashOffset: this.axisPointerLineDashOffset,
                color     : this.axisPointerLineStyleColor,
                width     : this.axisPointerLineStyleWidth
              },
              label: {
                show     : this.axisYPointerLabelShow,
                formatter: (params) => {
                  return this.$dayjs(params.value).format(this.axisXLabelValueDateFormat)
                },
                backgroundColor: this.axisPointerLabelBackgroundColor
              }
            },
            axisLine: {
              show     : false, // this.axisXGridLineShow,
              lineStyle: {
                color: this.axisXGridLineStyleColor
              }
            },
            axisLabel: {
              show      : true,
              fontFamily: this.fontFamily,
              fontSize  : this.axisLabelFontSize,
              color     : this.axisLabelFontColor,
              formatter : (params) => {
                if (params) {
                  return (this.axisXTickPrefix || '') + this.$dayjs(params).format(this.axisXLabelValueDateFormat) + (this.axisXTickSuffix || '')
                }
                return ''
              },
              align      : 'left',
              inside     : this.axisXLabelInside,
              margin     : this.axisXLabelMargin,
              padding    : [0, 0, 0, 0], // [top, right, bottom, left]
              rotate     : 0,
              interval   : 0,
              hideOverlap: this.axisXLabelHideOverlap
            },
            axisTick : { show: false },
            // minInterval: 30, // Minimum gap between split lines.
            splitLine: {
              show     : this.axisXSplitLineShow,
              lineStyle: {
                type   : this.axisXSplitLineType,
                color  : this.axisXSplitLineColor,
                opacity: this.axisXSplitLineOpacity,
                width  : this.axisXSplitLineWidth
              }
            },
            splitNumber: this.axisXSplitNumber,
            boundaryGap: this.axisXBoundaryGap,
            silent     : false
          }
        ],
        yAxis: [
          {
            type       : 'value',
            scale      : this.chartScale,
            axisPointer: {
              show     : this.axisYPointerShow,
              lineStyle: {
                type      : this.axisPointerLineStyleType,
                dashOffset: this.axisPointerLineDashOffset,
                color     : this.axisPointerLineStyleColor,
                width     : this.axisPointerLineStyleWidth
              },
              label: {
                show           : this.axisYPointerLabelShow,
                backgroundColor: this.axisPointerLabelBackgroundColor
              }
            },
            axisLine: {
              onZero   : true,
              show     : this.axisYGridLineShow,
              lineStyle: {
                color: this.axisYGridLineStyleColor
              }
            },
            axisLabel: {
              show      : this.axisYLabelShow,
              inside    : this.axisYLabelInside,
              margin    : this.axisYLabelMargin,
              padding   : [0, 0, 0, 0], // [top, right, bottom, left]
              fontFamily: this.fontFamily,
              fontSize  : this.axisLabelFontSize,
              color     : this.axisLabelFontColor,
              formatter : value => {
                if (this.axisYLabelValueFormat === 'number') {
                  value = (value === 0 ? value : toNumberFormatNotationShort(parseFloat(value)))
                }
                if (this.axisYLabelValueFormat === 'percent') {
                  value = (value === 0 ? value : toDecimalSignificant(parseFloat(value)))
                }
                if (this.axisYLabelValueFormat === 'date') {
                  value = dayjs(value).format(this.axisYLabelValueDateFormat)
                }

                return (this.axisYTickPrefix + ' ' || '') + value + (this.axisYTickSuffix || '')
              },

              interval     : this.axisYLabelInterval,
              hideOverlap  : this.axisYLabelHideOverlap,
              verticalAlign: this.axisYLabelVerticalAlign
            },
            axisTick: {
              show          : false,
              inside        : true,
              alignWithLabel: true
            },
            splitLine: {
              show     : this.axisYSplitLineShow,
              lineStyle: {
                type   : this.axisYSplitLineType,
                color  : this.axisYSplitLineColor,
                opacity: this.axisYSplitLineOpacity,
                width  : this.axisYSplitLineWidth
              }
            },
            splitNumber: this.axisYSplitNumber,
            boundaryGap: this.axisYBoundaryGap,
            silent     : true,
            min        : min,
            max        : max
          },
          {
            gridIndex  : 1,
            type       : 'value',
            scale      : this.chartScale,
            axisPointer: {
              show     : this.axisYPointerShow,
              lineStyle: {
                type      : this.axisPointerLineStyleType,
                dashOffset: this.axisPointerLineDashOffset,
                color     : this.axisPointerLineStyleColor,
                width     : this.axisPointerLineStyleWidth
              },
              label: {
                show           : this.axisYPointerLabelShow,
                backgroundColor: this.axisPointerLabelBackgroundColor
              }
            },
            axisLine: {
              onZero   : true,
              show     : this.axisYGridLineShow,
              lineStyle: {
                color: this.axisYGridLineStyleColor
              }
            },
            axisLabel: {
              show: false
            },
            splitLine: {
              show     : this.axisYSplitLineShow,
              lineStyle: {
                type   : this.axisYSplitLineType,
                color  : this.axisYSplitLineColor,
                opacity: this.axisYSplitLineOpacity,
                width  : this.axisYSplitLineWidth
              }
            },
            splitNumber: this.axisYSplitNumber,
            boundaryGap: this.axisYBoundaryGap,
            silent     : true,
            min        : min,
            max        : max
          }
        ],
        series: series
      }
    }
  },
  mounted () {
    /* const echart = this.$refs[`echart${ this.chartRefId }`]

    if (!echart) {
      return
    }

    const instance = echart?.chart

    echart.dispatchAction({
      type       : 'select',
      seriesIndex: 1
    })

    instance.on('mouseover', 'series.line', e => {
      // console.log('mouseover', e)

      /!* echart.dispatchAction({
        type       : 'showTip',
        seriesIndex: 1,
        dataIndex  : 1
      }) *!/
    })

    instance.on('mouseout', 'series.line', e => {
      // console.log('mouseout', e)

      /!* instance.dispatchAction({
        type: 'hideTip'
      }) *!/
    }) */
  }
}
</script>
