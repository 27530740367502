import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class RiskDebtProfileInputValidator extends BaseValidator {
  validatorRules = {
    accountId: {
      required: false,
      type    : BaseValidator.Type.String
    },
    id: {
      required: false,
      type    : BaseValidator.Type.String
    },
    name: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 3
    },
    equity: {
      required                    : true,
      NotEmptyZeroOrPositiveNumber: (v) => parseFloat(v) >= 0 && v !== ''
    },
    ebitda: {
      required: true,
      type    : BaseValidator.Type.Array,
      length  : 2
    },
    base: {
      required: false,
      type    : BaseValidator.Type.Object
    },
    fixedDebts: {
      required: false,
      type    : BaseValidator.Type.Array,
      minLen  : 0
    },
    floatingDebts: {
      required: true,
      type    : BaseValidator.Type.Array,
      minLen  : 1
    },
    debtDerivatives: {
      required: false,
      type    : BaseValidator.Type.Array,
      minLen  : 0
    }
  }

  validatorMessages = {
    el: {
      NotEmptyZeroOrPositiveNumber: 'Enter zero or a positive value'
    },
    en: {
      NotEmptyZeroOrPositiveNumber: 'Enter zero or a positive value'
    }
  }

  constructor (data = {}) {
    super(data)

    this.model.ebitda.forEach((obj, index) => {
      this.validatorRules[`ebitda.${ index }.amount`] = {
        required                    : true,
        NotEmptyZeroOrPositiveNumber: (v) => parseFloat(v) >= 0 && v !== ''
      }
    })
  }
}
