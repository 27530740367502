import BaseModel          from '@/lib/data/model/BaseModel'
import PositionValidator  from '@/api/validations/positions/PositionValidator'
import PositionResource   from '@/api/resources/positions/PositionResource'
import API                from '@/api/Api'
import InstrumentTypeEnum from '@/api/enums/InstrumentTypeEnum'

export default class PositionModel extends BaseModel {
  constructor (payload = {}, resource = PositionResource, validator = PositionValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = ['tradesList']
  }

  /* PROPERTIES */

  /* METHODS */
  clone (includeGuardedFields = false, Resource = null) {
    const data = super.clone(includeGuardedFields, Resource)
    data.trades = data.trades.map(trade => {
      if (trade.instrument === InstrumentTypeEnum.FORWARD) {
        delete trade.collar
        delete trade.option
        delete trade.interestRateSwap
      }
      if (trade.instrument === InstrumentTypeEnum.OPTION) {
        delete trade.forward
        delete trade.collar
        delete trade.interestRateSwap
      }
      if (trade.instrument === InstrumentTypeEnum.COLLAR) {
        delete trade.forward
        delete trade.option
        delete trade.interestRateSwap
      }
      if (trade.instrument === InstrumentTypeEnum.INTEREST_RATE_SWAP) {
        delete trade.forward
        delete trade.collar
        delete trade.option
      }
      delete trade.displayInstrument
      delete trade.selectedItem
      delete trade.asset
      delete trade.uuid
      return trade
    })

    return data
  }

  async CreatePosition () {
    return await API.Resource.Positions.CreatePosition(this.clone())
  }
}
