import BaseResource                          from '@/lib/data/resource/BaseResource'
import { collect }                           from '@/lib/utils/array'
import TradeInputModel                       from '@/api/models/positions/TradeInputModel'
import AssetEnum                             from '@/api/enums/AssetEnum'
import { toDecimalMark, toDecimalMarkRange } from '@/lib/utils/helper'
import i18n                                  from '@/lang/lang'
import InstrumentTypeEnum                    from '@/api/enums/InstrumentTypeEnum'
import dayjs                                 from 'dayjs'

export default class PositionResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.accountId = payload.accountId ?? ''
    this.positionId = payload.positionId ?? null
    this.baseCurrencyCode = payload.baseCurrencyCode ?? ''
    this.name = payload.name ?? ''
    this.trades = collect(payload.trades ?? [], TradeInputModel)
  }

  /* PROPERTIES */
  get tradesList () {
    const retVal = []
    const minDecimals = 0
    const maxDecimals = 5

    this.trades.forEach(trade => {
      const instrument = trade?.instrument || ''
      let displayInstrument = trade?.displayInstrument || ''

      // Fix the Display instrument
      if (instrument !== InstrumentTypeEnum.INTEREST_RATE_SWAP) {
        displayInstrument = instrument
      }

      const pair = `${ trade?.selectedItem?.pair?.quote?.displayName || '' }-${ trade?.selectedItem?.pair?.base?.displayName || '' }`

      let rate = null

      if (instrument === InstrumentTypeEnum.FORWARD) {
        rate = `${ toDecimalMarkRange(parseFloat(trade?.forward?.rate || '0'), minDecimals, maxDecimals) }`
      }
      if (instrument === InstrumentTypeEnum.OPTION) {
        rate = `${ toDecimalMarkRange(parseFloat(trade?.option?.strike || '0'), minDecimals, maxDecimals) } ${ trade?.option?.type || '' }`
      }
      if (instrument === InstrumentTypeEnum.COLLAR) {
        rate = `${ toDecimalMarkRange(parseFloat(trade?.collar?.lowStrike || '0'), minDecimals, maxDecimals) }-${ toDecimalMarkRange(parseFloat(trade?.collar?.highStrike || '0'), minDecimals, maxDecimals) }`
      }
      if (instrument === InstrumentTypeEnum.INTEREST_RATE_SWAP) {
        rate = `${ toDecimalMarkRange(parseFloat(trade?.interestRateSwap?.rate || '0'), minDecimals, maxDecimals) }`
      }

      const tradeDate = dayjs(trade?.tradeDate).format('DD MMM YYYY')

      const maturityDate = dayjs(trade?.maturityDate).format('DD MMM YYYY')

      const notional = trade?.selectedItem.pair.base.type === AssetEnum.CURRENCY
        ? `${ toDecimalMark(parseFloat(trade.notional || '0'), 0) }`
        : `${ toDecimalMark(parseFloat(trade.notional || '0'), 0) } ${ trade?.selectedItem?.quotation?.displayUnit }`

      retVal.push({
        Exposure: trade,
        Items   : [
          {
            Title: displayInstrument,
            Value: pair
          },
          {
            Title: i18n.t('Positions.AddPosition.Form.Fields.Rate.Label'),
            Value: rate
          },
          {
            Title: i18n.t('Positions.AddPosition.Form.Fields.TradeDate.Label'),
            Value: tradeDate
          },
          {
            Title: i18n.t('Positions.AddPosition.Form.Fields.MaturityDate.Label'),
            Value: maturityDate
          },
          {
            Title: i18n.t('Positions.AddPosition.Form.Fields.Notional.Label'),
            Value: notional
          },
          {
            Title: i18n.t('Positions.AddPosition.Form.Fields.Direction.Label'),
            Value: trade?.direction || ''
          },
          {
            Title: i18n.t('Positions.AddPosition.Form.Fields.Label.Label'),
            Value: trade?.label || ''
          }
        ]
      })
    })
    return retVal
  }

  /* METHODS */
}
