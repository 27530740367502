import BaseModel     from '@/lib/data/model/BaseModel'
import AssetResource from '@/api/resources/asset/AssetResource'

export default class AssetModel extends BaseModel {
  constructor (payload = {}, resource = AssetResource, validator = null) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}
