<template>
  <v-container
    class="align-content-start"
    fill-height
    fluid
  >
    <v-row>
      <section-header :breadcrumb-items="breadcrumb" />
    </v-row>

    <v-row>
      <v-col
        cols="12"
        lg="6"
        md="8"
        offset-lg="3"
        offset-md="2"
        offset-sm="1"
        offset-xl="3"
        sm="10"
        xl="6"
      >
        <block-item-card
          v-if="!formSubmitted"
          class="pa-sm-16 pt-sm-12"
        >
          <v-container fluid>
            <v-form
              ref="form"
              v-model="isFormValid"
              @submit.prevent
            >
              <v-row class="ma-0">
                <v-card-title
                  class="pa-3 pt-0 synthesis-text text-size-32 synthesis-brand-dark-blue--text"
                  v-html="$t('Help.MavenAdvisory.Title')"
                />
                <v-col
                  class="py-0"
                  cols="12"
                >
                  <label class="field-label">
                    {{ $t('Help.MavenAdvisory.Fields.Email.Label') }}
                  </label>
                  <v-text-field
                    v-model="model.email"
                    :error-messages="serverErrorMessages['email']"
                    :placeholder="$t('Help.MavenAdvisory.Fields.Email.Placeholder')"
                    :rules="model.validator.vuetifyFormFieldRules('email')"
                    outlined
                    type="email"
                  />
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                >
                  <label class="field-label">
                    {{ $t('Help.MavenAdvisory.Fields.ContactNumber.Label') }}
                  </label>
                  <v-text-field
                    v-model="model.contactNumber"
                    v-mask="`##############`"
                    :error-messages="serverErrorMessages['contactNumber']"
                    :placeholder="$t('Help.MavenAdvisory.Fields.ContactNumber.Placeholder')"
                    :rules="model.validator.vuetifyFormFieldRules('contactNumber')"
                    outlined
                    type="text"
                  />
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                >
                  <label class="field-label">
                    {{ $t('Help.SynthesisSupport.Fields.Subject.Label') }}
                  </label>
                  <v-text-field
                    v-model="model.subject"
                    :error-messages="serverErrorMessages['subject']"
                    :placeholder="$t('Help.SynthesisSupport.Fields.Subject.Placeholder')"
                    :rules="model.validator.vuetifyFormFieldRules('subject')"
                    outlined
                    type="text"
                  />
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                >
                  <label class="field-label">
                    {{ $t('Help.MavenAdvisory.Fields.Message.Label') }}
                  </label>
                  <v-textarea
                    v-model="model.message"
                    :error-messages="serverErrorMessages['message']"
                    :placeholder="$t('Help.MavenAdvisory.Fields.Message.Placeholder')"
                    :rules="model.validator.vuetifyFormFieldRules('message')"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col
                  class="py-0"
                  cols="4"
                  offset="8"
                >
                  <v-btn
                    :disabled="!isFormValid || isFormSaving"
                    :loading="isFormSaving"
                    block
                    class="px-4 px-sm-8 px-md-12 synthesis-text text-size-14 font-weight-medium white--text action-button"
                    color="synthesis-ui-green-02"
                    depressed
                    x-large
                    @click="submitForm"
                    v-text="$t('Common.Button.Submit')"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </block-item-card>

        <div
          v-else
          class="center-vertical-horizontal pa-2"
        >
          <block-item-card
            class="px-sm-16 py-sm-12"
            min-height="100"
            style="max-width: 550px;"
          >
            <v-card-title
              class="pa-3 pt-0 synthesis-text text-size-32 synthesis-brand-dark-blue--text"
              v-html="$t('Help.MavenAdvisory.Success.Title')"
            />
            <v-card-text
              class="pa-3 text-size-14 synthesis-brand-dark-blue--text"
              v-html="$t('Help.MavenAdvisory.Success.Body')"
            />
            <v-card-actions>
              <v-btn
                :to="{name: 'KnowledgeCenter'}"
                class="px-4 white--text action-button"
                color="synthesis-ui-green-02"
                depressed
                x-large
              >
                {{ $t('KnowledgeCenter.Title') }}
              </v-btn>
            </v-card-actions>
          </block-item-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta           from '@/mixins/i18n/i18nRouteMeta'
import SectionHeader           from '@/theme/default/components/common/SectionHeader'
import BlockItemCard           from '@/theme/default/components/common/BlockItemCard'
import formData                from '@/mixins/form/formData'
import MavenAdvisoryInputModel from '@/api/models/help/MavenAdvisoryInputModel'
import API                     from '@/api/Api'

export default {
  name      : 'HelpMavenAdvisory',
  components: {
    BlockItemCard,
    SectionHeader
  },
  directives: {},
  mixins    : [i18nRouteMeta, formData],
  props     : {},
  dataStore : {},
  enums     : {},
  dataModel : MavenAdvisoryInputModel,
  data () {
    return {
      formSubmitted: false
    }
  },
  computed: {
    breadcrumb () {
      return [{
        text    : this.$t('Help.Title'),
        disabled: true
      }, {
        text    : this.$t('Help.HelpMavenAdvisory.Title'),
        disabled: false
      }]
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    submitForm () {
      if (this.isFormSaving || !this.validateForm()) return
      this.isFormSaving = true

      this.model.SendRequest()
        .then(response => {
          this.handleResponse(response)
        })
        .catch(error => {
          this.handleResponse(error.response)
        })
        .finally(() => {
          this.isFormSaving = false
        })
    },

    handleResponse (response) {
      const data = API.responseData(response)?.submitAdviceRequest || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.formSubmitted = true
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }

  }
}
</script>

<style scoped>

</style>
