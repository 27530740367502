import BaseResource from '@/lib/data/resource/BaseResource'

export default class AddressResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.cityId = payload.cityId ?? ''
    this.cityName = payload.cityName ?? ''
    this.countryName = payload.countryName ?? ''
    this.lineOne = payload.lineOne ?? ''
    this.lineTwo = payload.lineTwo ?? ''
    this.province = payload.province ?? ''
    this.zipCode = payload.zipCode ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
