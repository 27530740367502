import BaseResource                         from '@/lib/data/resource/BaseResource'
import AssetExposureNewFromExistingResource from '@/api/resources/asset/AssetExposureNewFromExistingResource'
import { collect }                          from '@/lib/utils/array'

export default class RiskAnalysisNewFromExistingResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.id = payload.id ?? ''
    this.accountId = payload.accountId ?? ''
    this.activeVar = payload.result?.hasVar ?? true
    this.activeHistorical = payload.result?.hasHistorical ?? false
    this.activeEvents = payload.result?.hasEvents ?? false
    this.activeSimulation = payload.result?.hasSimulation ?? false

    this.name = `Copy of ${ payload.name ?? '' }`
    this.baseCurrencyCode = payload.baseCurrency?.symbol ?? ''
    this.exposures = collect(payload.exposures ?? [], AssetExposureNewFromExistingResource)

    this.multipleExposuresPerAsset = this.hasMultipleExposuresPerAsset()
  }

  /* PROPERTIES */

  /* METHODS */
  hasMultipleExposuresPerAsset () {
    const valuesArr = this.exposures.map(exposure => exposure.assetCode)
    const hasDuplicates = valuesArr.some((item, idx) => valuesArr.indexOf(item) !== idx)

    return hasDuplicates
  }
}
