export default {
  data () {
    return {
      step: 1
    }
  },

  computed: {
    isStepValid () {
      if (!Array.isArray(this.stepObj?.Fields) || (Array.isArray(this.stepObj?.Fields) && !this.stepObj?.Fields?.length)) return true
      return this.model && this.stepObj.Fields.every(field => this.model.validateField(field)) || false
    },

    stepHasCard () {
      return this.stepObj?.HasCard ?? true
    },

    stepBgColor () {
      return this.stepObj?.BgColor || '#FFFFFF'
    },

    stepCols () {
      if (this.$vuetify.breakpoint.mdAndDown) return 12

      return this.stepObj?.Cols ? this.stepObj?.Cols : this.stepHasCard ? 6 : 12
    },

    stepObj () {
      return this.steps[this.step - 1]
    },

    steps () {
      return []
    }
  }
}
