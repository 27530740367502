import BaseResource                       from '@/lib/data/resource/BaseResource'
import { collect }                        from '@/lib/utils/array'
import AssetNameResource                  from '@/api/resources/asset/AssetNameResource'
import HedgingInstrumentHistogramResource from '@/api/resources/hedging/HedgingInstrumentHistogramResource'

export default class HedgingRollingInstrumentResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Items = collect(payload.items ?? [], AssetNameResource)
    this.RollingCashFlows = collect(payload.rollingCashFlows ?? [], HedgingInstrumentHistogramResource)
  }

  /* PROPERTIES */

  /* METHODS */
}
