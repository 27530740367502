import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class AssetExposureValidator extends BaseValidator {
  validatorRules = {
    code: {
      required: true,
      type    : BaseValidator.Type.String
    },
    name: {
      required: true,
      type    : BaseValidator.Type.String,
      maxLen  : 13

    },
    assetCode: {
      required: true,
      type    : BaseValidator.Type.String
    },
    label: {
      required: false,
      type    : BaseValidator.Type.String,
      minLen  : 3,
      maxLen  : 1000
    },
    notional: {
      required: true
    },
    seasonality: {
      type: BaseValidator.Type.Array
    }
  }

  validatorMessages = {
    el: {
      NotEmptyOrZeroOrNull: 'Enter at least one positive or a negative value'
    },
    en: {
      NotEmptyOrZeroOrNull: 'Enter at least one positive or a negative value'
    }
  }

  constructor (data = {}) {
    super(data)

    this.validatorRules.notional = {
      required            : true,
      NotEmptyOrZeroOrNull: (v, model = this.model) => model.userProvidedSeasonality ? (model.seasonality.some(val => parseInt(val) !== 0 && val !== '' && val !== null) && v !== '') : (parseInt(v) !== 0 && v !== '')
    }

    this.validatorRules.seasonality = {
      type                : BaseValidator.Type.Array,
      NotEmptyOrZeroOrNull: (v, model = this.model) => model.userProvidedSeasonality ? v.every(val => parseInt(val) !== 0 || val !== '' || val !== null) : true
    }
  }
}
