<template>
  <div class="snotifyToast__buttons">
    <template v-for="(button, index) in buttons">
      <button
        v-if="button.visible !== false"
        :key="index"
        :class="[{'snotifyToast__buttons--bold': button.bold}, button.class]"
        type="button"
        @click="button.action && button.action(id, value)"
      >
        {{ button.text }}
      </button>
    </template>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['buttons', 'value', 'id']
}
</script>
