import { AES, HmacMD5 }          from '@/lib/crypto/crypto'
import i18n, { setI18nLanguage } from '@/lang/lang'

const storageKey = HmacMD5.hash('ROUTE')

export default async (route, injection = window.Vue) => {
  const vue = injection
  const $router = injection.$router
  const $route = route
  const langExists = i18n.messages.hasOwnProperty($route.params.lang)
  const lang = langExists ? $route.params.lang : i18n.locale
  const toLogin = {
    name  : 'Login',
    params: { lang: lang },
    query : {}
  }

  if (langExists) {
    setI18nLanguage(lang)
  } else {
    $router.replace({
      replace: true,
      name   : $route.matched.length ? $route.name : 'Dashboard',
      params : { lang: lang }
    })
  }

  const storedRoute = AES.decrypt(vue.$sessionStorage.get(storageKey))

  if (storedRoute && $route.name !== 'Login' && $route.name !== 'ForgotPassword' && $route.name !== 'ResetPassword') {
    if (storedRoute.meta.requiresAuth && (!vue.user || !vue.userCanAccess)) {
      $router.replace(toLogin)
    } else {
      $router.replace(storedRoute)
    }
  } else if (route.meta.requiresAuth) {
    if (!vue.user || !vue.userCanAccess) {
      $router.replace(toLogin)
    }
  }
}
