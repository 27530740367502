import ApiResponseEnum from '@/api/enums/ApiResponseEnum'
import Http            from '@/lib/data/api/Http'
import KnowledgeCenter from '@/api/endpoint/KnowledgeCenter'
import Dashboard       from '@/api/endpoint/Dashboard'
import User            from '@/api/endpoint/User'
import News            from '@/api/endpoint/News'
import Risks           from '@/api/endpoint/Risks'
import Hedging         from '@/api/endpoint/Hedging'
import Positions       from '@/api/endpoint/Positions'
import Industry        from '@/api/endpoint/Industry'
import Alerts          from '@/api/endpoint/Alerts'
import Assets          from '@/api/endpoint/Assets'
import Help            from '@/api/endpoint/Help'
import GQL             from '@/lib/data/api/GQL'

export const API = {
  get RESTRequest () {
    return Http.Request
  },
  get RESTRequestAll () {
    return Http.RequestAll
  },

  get GQLRequest () {
    return GQL.Request
  },

  get Resource () {
    return {
      Dashboard      : Dashboard,
      User           : User,
      News           : News,
      Risks          : Risks,
      Alerts         : Alerts,
      Hedging        : Hedging,
      Positions      : Positions,
      Industry       : Industry,
      KnowledgeCenter: KnowledgeCenter,
      Assets         : Assets,
      Help           : Help
    }
  },

  get Endpoint () {
    return {}
  },

  responseData     : (response) => response?.data?.data || null,
  responseErrors   : (response) => response?.data?.errors || null,
  isResponseSuccess: (response) => response?.status === ApiResponseEnum.SUCCESS && API.responseData(response) && !API.responseErrors(response)
}

export { default as Http } from '../lib/data/api/Http'
export default API
