<template>
  <v-row class="ma-0 ml-4 mr-4">
    <template v-for="(item, i) in $t('Common.Seasonality.Items')">
      <v-col
        :key="`seasonality-switch-${i})`"
        class="pa-1"
        cols="12"
        md="4"
      >
        <v-card
          class="pa-0 ma-0"
          flat
        >
          <v-switch
            v-model="model"
            :color="item.Color"
            :style="`border: ${themeColorRGBA('synthesis-ui-grey-02')} 1px solid;border-radius: ${CommonEnum.Components.SeasonalitySwitch.BorderRadius}px !important;`"
            :value="item.Value"
            class="ma-0 pa-0 v-input--reverse v-input--expand pa-2 mb-2 rounded d-flex synthesis-ui-grey-01--text"
            hide-details
            inset
          >
            <template #label>
              {{ item.Title }}
              <infotip
                v-if="item.InfoTip"
                icon-style="margin-top: -16px"
              >
                {{ item.InfoTip }}
              </infotip>
            </template>
          </v-switch>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>

import chartsColorMixin from '@/theme/default/components/charts/mixins/chartsColorMixin'
import CommonEnum       from '@/api/enums/CommonEnum'
import Infotip          from '@/theme/default/components/common/Infotip'

export default {
  name      : 'SeasonalitySwitch',
  components: { Infotip },
  directives: {},
  mixins    : [chartsColorMixin],
  props     : {
    selected: {
      type   : Array,
      default: () => []
    }
  },
  dataStore: {},
  enums    : { CommonEnum },
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    model: {
      get () {
        return this.selected
      },
      set (val) {
        this.$emit('update:selected', val)
      }
    }
  },
  watch: {
    model (newVal, oldVal) {
      if (newVal.length) {
        this.model = newVal
      } else {
        this.$nextTick(() => {
          this.model = oldVal
        })
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
/deep/ .v-input__control {
  padding: 6px;
}

/deep/ .v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  width: 100%;
}

/deep/ .v-input--reverse .v-input__slot .v-input--selection-controls__input {
  margin-right: 0;
  margin-left: 8px;
}

/deep/ .v-input--reverse .v-input__slot .v-input--selection-controls__input {
  margin-left: 0;
  margin-right: 8px;
}

/deep/ .v-input--expand .v-input__slot .v-label {
  display: block;
  flex: 1;
}
</style>
