export default {
  data () {
    return {
      isLoadingData: true
    }
  },
  computed: {
    viewHasData () {
      return this.items && !!this.items.length
    }
  }
}
