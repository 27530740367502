import BaseResource from '@/lib/data/resource/BaseResource'

export default class GroupHedgeRatioInputResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.assetCode = payload.assetCode ?? payload.detailedAsset?.symbol ?? ''
    this.ratio = payload.ratio ?? 0
  }

  /* PROPERTIES */

  /* METHODS */
}
