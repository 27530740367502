<template>
  <div>
    <label class="field-label">{{ baseTitle }}</label>

    <v-item-group
      v-model="groupModel"
      :disabled="disabled"
      :mandatory="groupModel !== 'Other'"
    >
      <v-container
        fluid
        pa-0
      >
        <template v-if="showSelectOnly">
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="model"
                :disabled="disabled"
                :error-messages="errorMessages"
                :items="BaseCurrencies"
                :placeholder="$t('Risks.Edit.Form.Fields.BaseCurrency.Placeholder')"
                :rules="rules"
                hide-details="auto"
                item-text="symbol"
                item-value="symbol"
                outlined
              >
                <template #item="{item}">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name" />
                    <v-list-item-subtitle
                      class="caption"
                      v-text="item.symbol"
                    />
                  </v-list-item-content>
                </template>

                <template #selection="{item}">
                  <div>{{ item.name }} <span class="caption grey--text">{{ item.symbol }}</span></div>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </template>

        <template v-else>
          <v-row>
            <template v-for="(item, index) in commonCurrenciesOther">
              <v-col
                :key="`base-currency-${index}-${item.symbol}`"
                :cols="cols"
              >
                <v-item
                  v-slot="{active, toggle}"
                  :disabled="disabled"
                  :value="item.symbol"
                >
                  <v-card
                    :class="[{'item-group-card-active': active}]"
                    :disabled="disabled"
                    class="item-group-card d-flex align-center"
                    flat
                    height="80"
                    @click="toggle"
                  >
                    <v-avatar
                      class="ma-3"
                      color="synthesis-ui-purple-07"
                      size="35"
                    >
                      <span class="synthesis-text font-weight-medium text-size-20 synthesis-ui-purple-01--text">
                        {{ item.symbol === 'Other' ? '&curren;' : item.displaySymbol }}
                      </span>
                    </v-avatar>

                    <v-card-title class="synthesis-text synthesis-brand-dark-blue--text text-size-14 font-weight-regular pl-0">
                      {{ item.symbol }}
                    </v-card-title>
                  </v-card>
                </v-item>
              </v-col>
            </template>
          </v-row>

          <v-row v-show="groupModel === 'Other'">
            <v-col cols="12">
              <label class="field-label">{{ selectTitle }}</label>

              <v-select
                v-model="model"
                :disabled="disabled"
                :error-messages="errorMessages"
                :items="baseCurrenciesExcludeCommon"
                :placeholder="$t('Risks.Edit.Form.Fields.BaseCurrency.Placeholder')"
                :rules="rules"
                hide-details="auto"
                item-text="symbol"
                item-value="symbol"
                outlined
              >
                <template #item="{item}">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name" />
                    <v-list-item-subtitle
                      class="caption"
                      v-text="item.symbol"
                    />
                  </v-list-item-content>
                </template>

                <template #selection="{item}">
                  <div>{{ item.name }} <span class="caption grey--text">{{ item.symbol }}</span></div>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-item-group>
  </div>
</template>

<script>
export default {
  name      : 'SelectBaseCurrency',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    value: {
      type   : String,
      default: null
    },

    rules: {
      type   : Array,
      default: () => []
    },

    errorMessages: {
      type   : Array,
      default: () => []
    },

    items: {
      type   : Array,
      default: () => []
    },

    baseTitle: {
      type   : String,
      default: function () { return this.$t('Risks.Edit.Form.Fields.BaseCurrency.Label') }
    },

    selectTitle: {
      type   : String,
      default: function () { return this.$t('Risks.Edit.Form.Fields.BaseCurrency.SelectLabel') }
    },

    disabled: {
      type   : Boolean,
      default: false
    },

    useUserBaseCurrency: {
      type   : Boolean,
      default: true
    },

    cols: {
      type   : [Number, String],
      default: 6
    },

    showSelectOnly: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {
    BaseCurrencies  : 'Assets.Currencies',
    CommonCurrencies: 'Assets.CommonCurrencies'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      groupModel: null
    }
  },
  computed: {
    baseCurrenciesExcludeCommon () {
      return this.BaseCurrencies.filter(currency => this.CommonCurrencies.every(common => common.symbol !== currency.symbol))
    },

    commonCurrenciesOther () {
      return [
        ...this.CommonCurrencies,
        ...[{
          name         : 'Other',
          symbol       : 'Other',
          displaySymbol: '',
          type         : 'Other'
        }]
      ]
    },

    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    value (newVal) {
      this.initGroupModelValue(newVal)
    },

    groupModel (newVal) {
      if (newVal !== 'Other') {
        this.model = newVal
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    if (this.useUserBaseCurrency) {
      this.groupModel = this.model = this.user?.selectedAccountBaseCurrency?.symbol || null
    } else {
      this.initGroupModelValue(this.model)
    }
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    initGroupModelValue (value) {
      if (!this.commonCurrenciesOther.find(c => c.symbol === value)) {
        this.groupModel = 'Other'
      } else {
        this.groupModel = value
      }
    }
  }
}
</script>

<style scoped>
.item-group-card {
  border           : 1px solid var(--v-synthesis-ui-grey-02-base) !important;
  background-color : #FFFFFF;
}

.item-group-card-active {
  border           : 1px solid var(--v-synthesis-ui-blue-04-base) !important;
  background-color : var(--v-synthesis-brand-lightest-blue-base);
}
</style>
