import BaseResource      from '@/lib/data/resource/BaseResource'
import dayjs             from 'dayjs'
import AlertPriorityEnum from '@/api/enums/AlertPriorityEnum'
import i18n              from '@/lang/lang'

export default class AlertIncidentResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.id ?? ''
    this.Name = payload.alertName ?? ''
    this.Asset = payload?.assetPair?.displaySymbol || ''
    this.Active = payload.active ?? false
    this.Type = payload.alertType ? i18n.t(`Alerts.Section.History.AlertType.Type.${ payload.alertType }`) : ''
    this.Priority = payload.priority ?? AlertPriorityEnum.Low
    this.Acknowledged = payload.acknowledged ?? false
    this.AcknowledgedAt = payload.acknowledgedAt ?? null
    this.AcknowledgedAtFmt = this.getDateFmt(payload.acknowledgedAt ?? null)
    this.FiredAt = payload.firedAt ?? ''
    this.FiredAtFmt = this.getDateFmt(payload.firedAt ?? null)
    this.ClosedAt = payload.closedAt ?? ''
    this.ClosedAtFmt = this.getDateFmt(payload.closedAt ?? null)
    this.CreatedAt = payload.createdAt ?? ''
    this.CreatedAtFmt = this.getDateFmt(payload.createdAt ?? null)
    this.Threshold = payload.threshold ?? ''
    this.DisplayThreshold = payload.displayThreshold ?? '0'
    this.Assets = this.getPortfolioAssets(payload)
    this.Tags = this.getPortfolioTags(payload)
  }

  /* PROPERTIES */
  get TableRow () {
    return {
      Id            : this.Id,
      Name          : this.Name,
      Asset         : this.Asset,
      Type          : this.Type,
      AlertInception: this.CreatedAtFmt,
      TriggerDate   : this.FiredAtFmt,
      Threshold     : this.DisplayThreshold,
      Priority      : this.Priority,
      Assets        : this.Assets,
      Tags          : this.Tags
    }
  }

  /* METHODS */
  getDateFmt (date) {
    return date ? dayjs(date).format('DD MMM YYYY') : ''
  }

  getPortfolioAssets (payload) {
    const trades = payload.trades ?? []

    return trades && trades.map(trade => {
      return trade.assetPair.base.displayName ?? ''
    }).filter(item => item !== '').join(', ') || ''
  }

  getPortfolioTags (payload) {
    const trades = payload.trades ?? []

    return trades && trades.map(trade => {
      return trade.assetPair.base.displayName ?? ''
    }).filter(item => item !== '') || []
  }
}
