export default {
  Login: {
    Title: '@:Users.Login.Title',
    Icon : '',
    Meta : {
      Title: '@:Route.Login.Title',
      Desc : '',
      Img  : ''
    }
  },

  ResetPassword: {
    Title: '@:Users.ResetPassword.Title',
    Icon : '',
    Meta : {
      Title: '@:Route.ResetPassword.Title',
      Desc : '',
      Img  : ''
    }
  },

  ForgotPassword: {
    Title: '@:Users.ForgotPassword.Title',
    Icon : '',
    Meta : {
      Title: '@:Route.ForgotPassword.Title',
      Desc : '',
      Img  : ''
    }
  },

  Dashboard: {
    Title: '@:Dashboard.Title',
    Icon : 'dashboard',
    Meta : {
      Title: '@:Route.Dashboard.Title',
      Desc : '',
      Img  : ''
    }
  },

  Alerts: {
    Title: '@:Alerts.Title',
    Icon : 'alerts',
    Meta : {
      Title: '@:Route.Alerts.Title',
      Desc : '',
      Img  : ''
    }
  },

  AlertEdit: {
    Title: '@:Alerts.Edit.Title',
    Icon : 'alerts',
    Meta : {
      Title: '@:Route.Alerts.Title',
      Desc : '',
      Img  : ''
    }
  },

  AlertNew: {
    Title: '@:Alerts.New.Title',
    Icon : 'alerts',
    Meta : {
      Title: '@:Route.Alerts.Title',
      Desc : '',
      Img  : ''
    }
  },

  Risks: {
    Title: '@:Risks.Title',
    Icon : 'risk',
    Meta : {
      Title: '@:Route.Risks.Title',
      Desc : '',
      Img  : ''
    }
  },

  Hedging: {
    Title: '@:Hedging.Title',
    Icon : 'hedging',
    Meta : {
      Title: '@:Route.Hedging.Title',
      Desc : '',
      Img  : ''
    }
  },

  Industry: {
    Title: '@:Industry.Title',
    Icon : 'industry',
    Meta : {
      Title: '@:Route.Industry.Title',
      Desc : '',
      Img  : ''
    }
  },

  Positions: {
    Title: '@:Positions.Title',
    Icon : 'positions',
    Meta : {
      Title: '@:Route.Positions.Title',
      Desc : '',
      Img  : ''
    }
  },

  KnowledgeCenter: {
    Title: '@:KnowledgeCenter.Title',
    Icon : 'knowledge-center',
    Meta : {
      Title: '@:Route.KnowledgeCenter.Title',
      Desc : '',
      Img  : ''
    }
  },

  Help: {
    Title: '@:Help.Title',
    Icon : 'help',
    Meta : {
      Title: '@:Route.Help.Title',
      Desc : '',
      Img  : ''
    }
  },

  HelpSynthesisSupport: {
    Title: '@:Help.HelpSynthesisSupport.Title',
    Icon : '',
    Meta : {
      Title: '@:Route.HelpSynthesisSupport.Title',
      Desc : '',
      Img  : ''
    }
  },

  HelpMavenAdvisory: {
    Title: '@:Help.HelpMavenAdvisory.Title',
    Icon : '',
    Meta : {
      Title: '@:Route.HelpMavenAdvisory.Title',
      Desc : '',
      Img  : ''
    }
  },

  MyDetails: {
    Title: '@:MyDetails.Title',
    Icon : '',
    Meta : {
      Title: '@:Route.MyDetails.Title',
      Desc : '',
      Img  : ''
    }
  },

  TermsAndPrivacy: {
    Title: '@:TermsAndPrivacy.Title',
    Icon : '',
    Meta : {
      Title: '@:Route.TermsAndPrivacy.Title',
      Desc : '',
      Img  : ''
    }
  }
}
