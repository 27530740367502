import BaseModel         from '@/lib/data/model/BaseModel'
import TrackLivePricesInputValidator from '@/api/validations/dashboard/TrackLivePricesInputValidator'
import TrackLivePricesInputResource  from '@/api/resources/dashboard/TrackLivePricesInputResource'

export default class TrackLivePricesInputModel extends BaseModel {
  constructor (payload = {}, resource = TrackLivePricesInputResource, validator = TrackLivePricesInputValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}
