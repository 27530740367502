import BaseModel                            from '@/lib/data/model/BaseModel'
import ActiveAnalysesRiskSummariesValidator from '@/api/validations/risks/ActiveAnalysesRiskSummariesValidator'
import ActiveAnalysesRiskSummariesResource  from '@/api/resources/risks/ActiveAnalysesRiskSummariesResource'
import { collect }                          from '@/lib/utils/array'
import GroupHedgeRatioInputResource         from '@/api/resources/risks/GroupHedgeRatioInputResource'

export default class ActiveAnalysesRiskSummariesModel extends BaseModel {
  constructor (payload = {}, resource = ActiveAnalysesRiskSummariesResource, validator = ActiveAnalysesRiskSummariesValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = ['selectedItem', 'isTypeSummary']
  }

  /* PROPERTIES */

  /* METHODS */
  clone (includeGuardedFields = false, Resource = null) {
    const data = super.clone(includeGuardedFields, Resource)
    data.hedgeRatios = collect(Object.values(data.hedgeRatios), GroupHedgeRatioInputResource)
    return data
  }
}
