<template>
  <block-item-card
    :min-height="minHeight"
    :title="$t('Dashboard.Block.LiveNews.Title')"
  >
    <v-card-text
      class="overflow-y-auto pt-0"
      style="height: 260px"
    >
      <v-list
        flat
        subheader
        three-line
      >
        <template v-for="(item, i) in items">
          <v-list-item-group :key="`news-list-item-group-${i}`">
            <v-list-item
              :key="`news-list-item-${i}`"
              :href="item.Link"
              class="pl-1"
              target="_blank"
            >
              <v-lazy
                :key="`lazy-news-list-item-${i}`"
                :options="{threshold: .5}"
                transition="fade-transition"
              >
                <v-list-item-content>
                  <v-list-item-subtitle
                    class="synthesis-text synthesis-brand-dark-blue--text text-size-14 font-weight-medium"
                  >
                    {{ item.Title }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle
                    class="synthesis-inter-text text-size-10 synthesis-ui-grey-01--text"
                  >
                    {{ item.Source }}
                    <v-icon small>
                      mdi-circle-small
                    </v-icon>
                    {{ item.Date }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-lazy>
            </v-list-item>

            <v-divider
              :key="`news-list-item-divider-${i}`"
            />
          </v-list-item-group>
        </template>
      </v-list>
    </v-card-text>
  </block-item-card>
</template>

<script>
import BlockItemCard     from '@/theme/default/components/common/BlockItemCard'
import API               from '@/api/Api'
import { clone }         from '@/lib/utils/helper'
import { collect }       from '@/lib/utils/array'
import NewsItemResource  from '@/api/resources/news/NewsItemResource'
import autoUpdateApiData from '@/mixins/api/autoUpdateApiData'

export default {
  name      : 'LiveNewsBlock',
  components: { BlockItemCard },
  directives: {},
  mixins    : [autoUpdateApiData],
  props     : {
    minHeight: {
      type   : [Number, String],
      default: 310
    }
  },
  dataStore: {
    items: 'News.Latest.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      autoUpdateApiDataOptions: {
        // Five minutes
        timeout: 5 * (60 * 1000)
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.News.LatestNews()
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },
    handleResponse (response) {
      const data = API.responseData(response)?.latestNews || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        if (data) {
          const o = clone(data)
          this.items = collect(o, NewsItemResource)
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>

</style>
