<template>
  <v-item-group
    v-model="model"
    :disabled="disabled"
    :mandatory="mandatory"
    :multiple="multiple"
  >
    <v-container
      fluid
      pa-0
    >
      <v-row :class="rowClass">
        <template v-for="(item, index) in items">
          <v-col
            :key="`item-${index}`"
            :class="colClass"
            :sm="sm"
            cols="12"
          >
            <v-item
              v-slot="{active, toggle}"
              :disabled="disabled"
              :value="item[itemValueName]"
            >
              <v-card
                :class="[{'item-group-card-active': active}]"
                :disabled="disabled"
                class="item-group-card d-flex align-center"
                flat
                height="56"
                @click="toggle"
              >
                <v-icon
                  :color="active ? 'synthesis-ui-blue-04' : 'synthesis-ui-grey-02'"
                  class="pa-3"
                >
                  {{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
                </v-icon>

                <v-card-title class="synthesis-text synthesis-brand-dark-blue--text text-size-14 font-weight-regular pl-0 text-no-wrap">
                  {{ item[itemLabelName] }}
                </v-card-title>
              </v-card>
            </v-item>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
export default {
  name      : 'GroupItemRadio',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    value: {
      type   : [String, Number, Object, Array, Boolean],
      default: undefined
    },

    rules: {
      type   : Array,
      default: () => []
    },

    errorMessages: {
      type   : Array,
      default: () => []
    },

    items: {
      type   : Array,
      default: () => []
    },

    disabled: {
      type   : Boolean,
      default: false
    },

    mandatory: {
      type   : Boolean,
      default: false
    },

    multiple: {
      type   : Boolean,
      default: false
    },

    sm: {
      type   : [Number, String],
      default: 6
    },

    rowClass: {
      type   : [String, Array],
      default: undefined
    },

    colClass: {
      type   : [String, Array],
      default: undefined
    },

    itemLabelName: {
      type   : String,
      default: 'Label'
    },

    itemValueName: {
      type   : String,
      default: 'Value'
    }
  },
  data () {
    return {}
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  }
}
</script>

<style scoped>
.item-group-card {
  border           : 1px solid var(--v-synthesis-ui-grey-02-base) !important;
  background-color : #FFFFFF;
}

.item-group-card-active {
  border           : 1px solid var(--v-synthesis-ui-blue-04-base) !important;
  background-color : var(--v-synthesis-brand-lightest-blue-base);
}
</style>
