import BaseResource from '@/lib/data/resource/BaseResource'

export default class HedgingCarryPerAssetResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.carry = payload.carry ?? ''
    this.detailedAsset = payload.selectedItem?.pair?.base ?? payload.detailedAsset ?? payload.asset ?? {
      displayName  : '',
      displaySymbol: '',
      name         : '',
      symbol       : '',
      type         : ''
    }
    this.asset = this.detailedAsset.displayName ?? ''
    this.assetCode = this.detailedAsset.symbol ?? payload.assetCode ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
