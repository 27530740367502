export default {
  Title  : 'Positions',
  InfoTip: 'Each card denotes a portfolio of hedge trades. User can choose which portfolios or “Positions” will be displayed in the dashboard.',

  Summary: {
    Title     : 'Summary',
    StressTest: {
      Title  : 'Stress Test: How much will an adverse market move cost?',
      InfoTip: 'The Stress Test figure is a dynamic calculation that shows each time the hypothetical MTM of each trade if there was an unfavourable spot move of 15% from today’s value.'
    },
    Card: {
      Combo: {
        Factor: 'Factor'
      }
    }
  },

  Message: {
    NoData: {
      Title: 'No Positions currently setup'
    }
  },

  Button: {
    New: {
      Title: '@:Common.Button.New Position'
    },
    Add: {
      Title: '@:Common.Button.Add Position'
    },
    View: {
      Title: '@:Common.Button.View Position'
    },
    Save: {
      Title: '@:Common.Button.Save Position'
    }
  },

  Tab: {
    Archive: {
      Title  : 'My Positions',
      Slug   : 'my-positions',
      Confirm: {
        Visibility: {
          Title   : '{Value} Dashboard?',
          Body    : 'Are you sure you want to {Show} <span class="grey--text">"{Name}"</span>?',
          ShowOn  : 'Show on',
          HideFrom: 'Hide from'
        },
        Delete: {
          Title: 'Delete Position?',
          Body : 'If you proceed, alerts linked to this positions portfolio <span class="grey--text">"{Name}"</span> will also be deleted.<br><br>Do you want to proceed?'
        }
      },
      Card: {
        MenuItems: [
          {
            Event : 'archive-item-visibility-button-click',
            Title : 'Show on Dashboard',
            Icon  : 'mdi-eye-outline',
            Color : 'synthesis-ui-blue-04',
            Active: true,
            Toggle: {
              Title: 'Hide from Dashboard',
              Field: 'activeCard'
            }
          },
          {
            Event : 'archive-item-edit-button-click',
            Title : 'Edit',
            Icon  : 'mdi-pencil',
            Color : 'synthesis-ui-grey-01',
            Active: true
          },
          {
            Event : 'archive-item-delete-button-click',
            Title : 'Delete',
            Icon  : 'mdi-trash-can',
            Color : 'synthesis-ui-red-05',
            Active: true
          }
        ]
      }
    },
    OpenTrades: {
      Title: 'Open Trades',
      Slug : 'open-trades'
    },
    MaturedTrades: {
      Title  : 'Matured Trades',
      Slug   : 'matured-trades',
      Confirm: {
        Delete: {
          Title: 'Delete Trade?',
          Body : 'If you proceed, <span class="synthesis-brand-dark-blue--text font-weight-bold">"{Name}"</span> trade will be deleted.<br><br>Do you want to proceed?'
        }
      }
    }
  },

  Table: {
    Rows: {
      AggregateMTM: 'Aggregate MTM'
    },
    Headers: {
      Asset       : 'Asset',
      Label       : 'Source',
      Instrument  : 'Instrument',
      TradeDate   : 'Trade Date',
      MaturityDate: 'Maturity Date',
      Rate        : 'Rate',
      Notional    : 'Local Notional',
      DailyChange : 'Daily Change',
      Direction   : 'Local Direction',
      MTMValue    : 'MTM Value',
      Actions     : 'Actions'
    },
    Export: {
      OpenTrades: {
        Headers: {
          Asset       : 'Asset',
          Label       : 'Source',
          Instrument  : 'Instrument',
          TradeDate   : 'Trade Date',
          MaturityDate: 'Maturity Date',
          Rate        : 'Rate',
          Notional    : 'Local Notional',
          DailyChange : 'Daily Change',
          Direction   : 'Local Direction',
          MTMValue    : 'MTM Value'
        }
      },
      MaturedTrades: {
        Headers: {
          Asset       : 'Asset',
          Label       : 'Source',
          Instrument  : 'Instrument',
          TradeDate   : 'Trade Date',
          MaturityDate: 'Maturity Date',
          Rate        : 'Rate',
          Notional    : 'Local Notional',
          Direction   : 'Local Direction'
        }
      }
    }
  },

  Edit: {
    Title: '@:Common.Button.New Position',
    Form : {
      Steps: {
        Step1: {
          Title: 'Portfolio Inputs'
        },
        Step2: {
          Title : 'Trade Details',
          Button: {
            Action: '@:Common.Button.Save Position'
          }
        },
        Step3: {
          Title: 'Position Summary'
        }
      },

      Fields: {
        Name: {
          Label      : 'New Portfolio Name',
          Placeholder: 'Enter positions name'
        },
        BaseCurrency: {
          Label      : 'Base Currency',
          SelectLabel: 'Select @:Positions.Edit.Form.Fields.BaseCurrency.Label',
          Placeholder: 'Please Select'
        },
        Trades: {
          Label      : 'Add Trade',
          Placeholder: 'Select currency, commodity or interest rate',
          MenuItems  : [
            {
              Event : 'list-item-edit-button-click',
              Title : 'Edit',
              Icon  : 'mdi-pencil',
              Color : 'synthesis-ui-grey-01',
              Active: true
            },
            {
              Event : 'list-item-delete-button-click',
              Title : 'Delete',
              Icon  : 'mdi-trash-can',
              Color : 'synthesis-ui-red-05',
              Active: true
            }
          ]
        }
      },

      Confirm: {
        Delete: {
          Title: 'Delete Trade?',
          Body : `
            Are you sure you want to delete this trade?

            <p class="caption">Deleting this trade will also delete any related alerts.<p/>
          `
        }
      }

    }
  },

  AddPosition: {
    Title   : '@:Common.Button.Add Trade',
    InfoTip : 'Currency in bottom row in smaller font is the base currency chosen. Currency or Commodity on the top row is the traded asset versus the base currency. The value to the right denotes the most recent price of the asset in base currency terms.',
    Subtitle: 'Select currency, commodity or interest rate',
    Form    : {
      Button: {
        Save: {
          Title: '@:Common.Button.Save Trade'
        },
        Update: {
          Title: '@:Common.Button.Update Trade'
        }
      },
      Fields: {
        Instrument: {
          Label      : 'Instrument',
          Placeholder: 'Please select'
        },
        Rate: {
          Label      : 'Rate',
          Placeholder: 'Enter value'
        },
        ForwardRate: {
          Label      : 'Forward Rate',
          Placeholder: 'Enter value'
        },
        InterestRateSwap: {
          Label      : 'Interest Rate',
          Placeholder: 'Enter value'
        },
        OptionStrike: {
          Label      : 'Option Strike',
          Placeholder: 'Enter value'
        },
        OptionType: {
          Label      : 'Option Type',
          Placeholder: 'Please Select',
          Items      : [
            {
              Label: 'Call',
              Value: 'Call'
            },
            {
              Label: 'Put',
              Value: 'Put'
            }
          ]
        },
        LowStrike: {
          Label      : 'Low Strike',
          Placeholder: 'Enter value'
        },
        HighStrike: {
          Label      : 'High Strike',
          Placeholder: 'Enter value'
        },
        TradeDate: {
          Label      : 'Trade Date',
          Placeholder: 'Add date'
        },
        MaturityDate: {
          Label      : 'Maturity Date',
          Placeholder: 'Add date'
        },
        Notional: {
          Label      : 'Notional',
          Placeholder: 'Enter value'
        },
        Direction: {
          Label: 'Direction',
          Items: [
            {
              Label: 'Long',
              Value: 'Long'
            },
            {
              Label: 'Short',
              Value: 'Short'
            }
          ],
          PayFixed: {
            Label: 'Pay Fixed'
          },
          ReceiveFixed: {
            Label: 'Receive Fixed'
          },
          PayFloating: {
            Label: 'Pay Floating'
          },
          ReceiveFloating: {
            Label: 'Receive Floating'
          }
        },
        Label: {
          Label      : 'Source name',
          Placeholder: 'Enter asset source name e.g. exports receivables'
        }
      }
    }
  },

  HistoricalMtmValue: {
    Title: 'Historical MTM Value'
  }
}
