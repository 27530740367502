export const Dashboard = {
  RiskSummary: {
    Data: null
  },

  HedgingPositions: {
    Data: null
  },

  LivePrices: {
    Data: {},
    All : {}
  },

  AlertsList: {
    Data: {}
  },

  Chart: {
    Data: {}
  },

  SurveyList: {
    Data: []
  },

  FunFacts: {
    Data: []
  }
}
