export default {
  props: {
    positionId: {
      type   : String,
      default: ''
    }
  },
  computed: {
    id () {
      return this.positionId || this.$route.params.id
    }
  }
}
