import BaseResource from '@/lib/data/resource/BaseResource'

export default class PreferencesResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.emailOnAlertIncident = payload?.emailOnAlertIncident ?? false
    this.emailOnCompanyUpdates = payload?.emailOnCompanyUpdates ?? false
    this.emailOnNewArticles = payload?.emailOnNewArticles ?? false
    this.emailOnNewAssets = payload?.emailOnNewAssets ?? false
    this.emailOnRiskSummaryUpdates = payload?.emailOnRiskSummaryUpdates ?? false
    this.livePricesPairs = payload?.livePricesPairs ?? []
    this.newsTopics = payload?.newsTopics ?? []
  }

  /* PROPERTIES */

  /* METHODS */
}
