<template>
  <v-row>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="550px"
      persistent
      scrollable
      transition="slide-x-reverse-transition"
    >
      <v-card min-height="100vh">
        <div class="">
          <v-toolbar
            color="section-bg-gradient-yellow"
            flat
          >
            <v-spacer />

            <v-btn
              :disabled="isFormSaving"
              icon
              @click="closeDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <div class="px-5">
            <v-icon
              color="orange"
              size="24"
            >
              mdi-poll
            </v-icon>

            <div class="mt-4 mb-6">
              <div class="synthesis-text text-size-24 synthesis-brand-dark-blue--text font-weight-medium">
                {{ $t(`${ componentLang }.Title`) }}
                <infotip
                  v-if="$t(`${ componentLang }.InfoTip`)"
                  icon-style="margin-top: -24px"
                >
                  {{ $t(`${ componentLang }.InfoTip`) }}
                </infotip>
              </div>
              <div class="synthesis-text text-size-14 synthesis-ui-grey-01--text">
                {{ $t(`${ componentLang }.Subtitle`) }}
              </div>
            </div>
          </div>

          <v-divider />
        </div>

        <v-card-text
          ref="formContainer"
          class="pt-6"
          style="overflow-y: scroll;"
        >
          <v-form
            v-if="carry"
            ref="form"
            v-model="isFormValid"
            @submit.prevent
          >
            <template v-for="(item, index) in carry">
              <div
                :key="`item-${index}`"
                class="mb-4"
              >
                <label class="field-label">{{ item.asset }}: {{ $t(`${ componentLang }.Form.Fields.CostOfCarry.Label`) }}</label>
                <v-text-field-currency-directive
                  v-model="model[item.assetCode]"
                  :class="{'over-percent-limit-warning': isOverPercentLimit(model[item.assetCode])}"
                  :options="{precision: {min: 2, max: 2}, autoSign: true, valueRange: {min: -999, max: 999}, valueScaling: undefined, locale: $i18n.code}"
                  :placeholder="$t(`${componentLang}.Form.Fields.CostOfCarry.Placeholder`)"
                  :readonly="mode === 'Show'"
                  flat
                  hide-details="auto"
                  outlined
                  suffix="%"
                />
              </div>
            </template>

            <div class="mb-4">
              <label class="field-label">{{ $t(`${ componentLang }.Form.Fields.Option.Label`) }}</label>
              <v-text-field-currency-directive
                v-model="model.strikeMoneynessOption"
                :class="{'over-percent-limit-warning': isOverPercentLimit(model.strikeMoneynessOption)}"
                :options="{precision: {min: 2, max: 2}, autoSign: true, valueRange: {min: -999, max: 999}, valueScaling: undefined, locale: $i18n.code}"
                :placeholder="$t(`${componentLang}.Form.Fields.Option.Placeholder`)"
                :readonly="mode === 'Show'"
                flat
                hide-details="auto"
                outlined
                suffix="%"
              />
            </div>

            <div class="mb-4">
              <label class="field-label">{{ $t(`${ componentLang }.Form.Fields.Collar.Label`) }}</label>
              <v-text-field-currency-directive
                v-model="model.strikeMoneynessRiskReversal"
                :class="{'over-percent-limit-warning': isOverPercentLimit(model.strikeMoneynessRiskReversal)}"
                :options="{precision: {min: 2, max: 2}, autoSign: false, valueRange: {min: 0, max: 999}, valueScaling: undefined, locale: $i18n.code}"
                :placeholder="$t(`${componentLang}.Form.Fields.Collar.Placeholder`)"
                :readonly="mode === 'Show'"
                flat
                hide-details="auto"
                outlined
                suffix="%"
              />
            </div>
          </v-form>
        </v-card-text>

        <template v-if="mode !== 'Show'">
          <v-divider />

          <v-card-actions class="py-3">
            <v-btn
              :disabled="isFormSaving || !isModelValid"
              :loading="isFormSaving"
              block
              class="action-button white--text"
              color="synthesis-ui-green-02"
              depressed
              large
              @click="saveFromData"
            >
              {{ $t(`${ componentLang }.Form.Button.Save.Title`) }}
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dialogData                  from '@/mixins/dialog/dialogData'
import dialogVisible               from '@/mixins/dialog/dialogVisible'
import HedgingAssetParamsModel     from '@/api/models/hedging/HedgingAssetParamsModel'
import BaseValidator               from '@/lib/data/validator/BaseValidator'
import { collect }                 from '@/lib/utils/array'
import HedgingCarryInputModel      from '@/api/models/hedging/HedgingCarryInputModel'
import VTextFieldCurrencyDirective from '@/theme/default/components/form/VTextFieldCurrencyDirective'
import Infotip                     from '@/theme/default/components/common/Infotip'

export default {
  name      : 'AddAssetParametersDialog',
  components: {
    Infotip,
    VTextFieldCurrencyDirective
  },
  directives: {},
  mixins    : [dialogVisible, dialogData],
  props     : {
    componentLang: {
      type   : String,
      default: 'Hedging'
    },

    mode: {
      type   : String,
      default: 'Add'
    },

    carry: {
      type   : Array,
      default: undefined
    },

    strikeMoneynessOption: {
      type   : [Number, String],
      default: ''
    },

    strikeMoneynessRiskReversal: {
      type   : [Number, String],
      default: ''
    },

    step: {
      type   : Number,
      default: 1
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: HedgingAssetParamsModel,
  data () {
    return {}
  },
  computed: {},
  watch   : {
    isVisible (newVal) {
      if (newVal) {
        this.isFormSaving = false
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.initDynamicFieldsModel()
          })
        })
      } else {
        this.model = new HedgingAssetParamsModel()
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    /**
     * initDynamicFieldsModel
     *
     * Dynamically add fields and values to the model
     * and create corresponding field model validators
     */
    initDynamicFieldsModel () {
      this.carry.forEach(item => {
        this.$set(this.model, item.assetCode, item.carry || '')
        this.$set(this.model.validator.validatorRules, item.assetCode, {
          required  : true,
          type      : BaseValidator.Type.Number,
          betweenVal: (v) => parseFloat(v) >= -999 && parseFloat(v) <= 999
        })
      })

      this.$set(this.model, 'strikeMoneynessOption', this.strikeMoneynessOption || '')
      this.$set(this.model.validator.validatorRules, 'strikeMoneynessOption', {
        required  : true,
        type      : BaseValidator.Type.Number,
        betweenVal: (v) => parseFloat(v) >= -999 && parseFloat(v) <= 999
      })

      this.$set(this.model, 'strikeMoneynessRiskReversal', this.strikeMoneynessRiskReversal || '')
      this.$set(this.model.validator.validatorRules, 'strikeMoneynessRiskReversal', {
        required  : true,
        type      : BaseValidator.Type.Number,
        betweenVal: (v) => parseFloat(v) >= 0 && parseFloat(v) <= 999
      })

      this.$nextTick(() => {
        this.$forceUpdate()
      })

      setTimeout(() => {
        this.$forceUpdate()
      }, 1000)
    },

    /**
     * saveFromData
     *
     * Locally persist entered asset parameters
     */
    saveFromData () {
      if (this.isFormSaving) return

      this.isFormSaving = true
      const data = this.model.clone()

      const carry = []
      const objKeys = Object.keys(data).filter(v => v !== 'strikeMoneynessOption' && v !== 'strikeMoneynessRiskReversal')
      objKeys.forEach(key => {
        const obj = {
          assetCode: key,
          carry    : data[key]
        }
        carry.push(obj)
      })

      if (this.step === 3) {
        this.$root.$confirmDialog(
          this.$t('Hedging.AssetParameters.Confirm.Save.Title'),
          this.$t('Hedging.AssetParameters.Confirm.Save.Body'),
          {
            color : 'synthesis-ui-red-02',
            button: {
              no : { title: this.$t('Common.Button.No') },
              yes: { title: this.$t('Common.Button.Yes') }
            }
          }
        ).then(response => {
          if (response) {
            this.validateForm() && this.formSaveSuccess({
              carry                      : collect(carry, HedgingCarryInputModel),
              strikeMoneynessOption      : parseFloat(data.strikeMoneynessOption),
              strikeMoneynessRiskReversal: parseFloat(data.strikeMoneynessRiskReversal)
            })
          } else {
            this.closeDialog()
          }
        })
      } else {
        this.validateForm() && this.formSaveSuccess({
          carry                      : collect(carry, HedgingCarryInputModel),
          strikeMoneynessOption      : parseFloat(data.strikeMoneynessOption),
          strikeMoneynessRiskReversal: parseFloat(data.strikeMoneynessRiskReversal)
        })
      }
    },

    isOverPercentLimit (val) {
      return parseFloat(val) > 100 || parseFloat(val) < -100
    }
  }
}
</script>

<style scoped>
/* v-dialog overrides */
.v-dialog__content {
  align-items: start;
  justify-content: end;
  height: 100%;
}

/deep/ .v-dialog {
  border-radius: 4px;
  margin: 0px !important;
  max-height: 100% !important;
}
</style>
