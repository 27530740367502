import { API } from '@/api/Api'
import {
  LoginQuery,
  MyPreferencesQuery,
  MyUserQuery,
  ResetPasswordMutation,
  ResetPasswordQuery,
  UpdateMyUserPreferencesMutation,
  UpdateMyUserProfileMutation,
  UserQuery
}              from '@/api/query/User.graphql'

export default {

  async Get (userId = '') {
    const Data = { userId: userId }
    return await API.GQLRequest(UserQuery, Data)
  },

  async Login (username, password = {}) {
    const Data = {
      userName: username,
      password: password
    }
    return await API.GQLRequest(LoginQuery, Data)
  },

  async MyUser () {
    const Data = {}
    return await API.GQLRequest(MyUserQuery, Data)
  },

  async MyPreferences () {
    const Data = {}
    return await API.GQLRequest(MyPreferencesQuery, Data)
  },

  async UpdateMyUserProfile (data) {
    const Data = { ...data }

    return await API.GQLRequest(UpdateMyUserProfileMutation, Data)
  },

  async UpdateMyUserPreferences (data) {
    const Data = { ...data }

    return await API.GQLRequest(UpdateMyUserPreferencesMutation, Data)
  },

  async ForgotPassword (data = {}) {
    const Data = { ...data }
    return await API.GQLRequest(ResetPasswordQuery, Data)
  },

  async ResetPassword (data = {}) {
    const Data = { ...data }
    return await API.GQLRequest(ResetPasswordMutation, Data)
  }
}
