<template>
  <v-col cols="12">
    <block-item-card
      v-if="optimalFixedRatio"
      ref="optimalFixedRatioCard"
      :export-element="elementOptimalFixedRatio"
      :title="$t('Risks.Section.DebtProfile.Block.OptimalFixedRatio.Title')"
      :tooltip="$t('Risks.Section.DebtProfile.Block.OptimalFixedRatio.InfoTip')"
    >
      <v-row class="ma-0">
        <v-col
          class="ma-0"
          cols="12"
        >
          <v-row
            class="ma-0"
            justify="center"
            align="center"
          >
            <v-col
              cols="12"
              :md="appToolbarExtended ? 7 : 5"
              :lg="appToolbarExtended ? 5 : 4"
              xl="3"
              class="text-center"
            >
              <v-row>
                <v-col>
                  <asset-button-group
                    align-center
                    min-width="332"
                    :items="ratesViewAssets"
                    :selected.sync="ratesViewSelectedAsset"
                  >
                    <template #title>
                      <v-row class="flex pl-2 pb-1 text-left">
                        <v-col>
                          <span class="text-no-wrap text-size-14 font-weight-medium synthesis-ui-red-03--text">
                            {{ $t('Risks.Section.DebtProfile.Block.OptimalFixedRatio.Buttons.Labels.UserInput1.Prefix') }}
                            <span class="text-no-wrap text-size-14 font-weight-bold synthesis-brand-dark-blue--text">
                              {{ $t('Risks.Section.DebtProfile.Block.OptimalFixedRatio.Buttons.Labels.UserInput1.Suffix') }}
                            </span>
                          </span>
                        </v-col>
                      </v-row>
                    </template>
                  </asset-button-group>
                </v-col>
                <v-col>
                  <asset-button-group
                    align-center
                    min-width="332"
                    :items="riskAversionAssets"
                    :selected.sync="riskAversionSelectedAsset"
                  >
                    <template #title>
                      <v-row class="flex pl-2 pb-1 text-left">
                        <v-col>
                          <span class="text-no-wrap text-size-14 font-weight-medium synthesis-ui-red-03--text">
                            {{ $t('Risks.Section.DebtProfile.Block.OptimalFixedRatio.Buttons.Labels.UserInput2.Prefix') }}
                            <span class="text-no-wrap text-size-14 font-weight-bold synthesis-brand-dark-blue--text">
                              {{ $t('Risks.Section.DebtProfile.Block.OptimalFixedRatio.Buttons.Labels.UserInput2.Suffix') }}
                            </span>
                          </span>
                        </v-col>
                      </v-row>
                    </template>
                  </asset-button-group>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              :md="appToolbarExtended ? 5 : 7"
              :lg="appToolbarExtended ? 7 : 8"
              xl="9"
            >
              <v-lazy
                :options="{threshold: .5}"
                :value="disableLazy"
                transition="fade-transition"
              >
                <e-chart-debt-profile-gauge
                  axis-x-tick-suffix="%"
                  :tooltip-show="false"
                  :gauge-progress-show="false"
                  :chart-colors="gaugeColorValues"
                  :chart-data-options="gaugeValues"
                  :chart-animation="chartAnimation"
                  :chart-grid-margin="{left: 0, top: 50, right: 0, bottom: 0, containLabel: true}"
                  :chart-height="$vuetify.breakpoint.lgAndUp ? 340 : 260"
                  :title-show="true"
                  :title-value="$t('Risks.Section.DebtProfile.Block.OptimalFixedRatio.TitleChart')"
                />
              </v-lazy>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </block-item-card>
  </v-col>
</template>

<script>
import BlockItemCard          from '@/theme/default/components/common/BlockItemCard'
import riskAnalysisId         from '@/theme/default/views/risks/mixins/riskAnalysisId'
import riskBaseCurrency       from '@/theme/default/views/risks/mixins/riskBaseCurrency'
import useExportAdditional    from '@/mixins/useExportAdditional'
import AssetButtonGroup       from '@/theme/default/components/common/AssetButtonGroup.vue'
import i18n                   from '@/lang/lang'
import EChartDebtProfileGauge from '@/theme/default/components/charts/echarts/EChartDebtProfileGauge.vue'
import baseEChartColors       from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'

export default {
  name      : 'OptimalFixedRatioBlock',
  components: {
    EChartDebtProfileGauge,
    AssetButtonGroup,
    BlockItemCard
  },
  directives: {},
  mixins    : [riskBaseCurrency, baseEChartColors, riskAnalysisId, useExportAdditional],
  props     : {},
  dataStore : {
    riskDebtData        : 'Risks.Debt.Data',
    riskBaseCurrencyData: 'Risks.BaseCurrency.Data',
    appToolbarExtended  : 'App.Config.AppToolbarExtended'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      elementOptimalFixedRatio : null,
      ratesViewSelectedAsset   : i18n.t('Risks.Section.DebtProfile.Block.OptimalFixedRatio.Buttons.RatesView.SelectedAsset'),
      riskAversionSelectedAsset: i18n.t('Risks.Section.DebtProfile.Block.OptimalFixedRatio.Buttons.RiskAversion.SelectedAsset')
    }
  },
  computed: {
    debt () {
      return this.riskDebtData[this.id] || null
    },

    optimalFixedRatio () {
      return this.debt?.OptimalFixedRatio || null
    },

    ratesViewAssets () {
      return this.optimalFixedRatio?.RatesView || []
    },

    riskAversionAssets () {
      return this.optimalFixedRatio?.RiskAversions || []
    },

    itemValues () {
      return this.optimalFixedRatio?.Items || []
    },

    selectedValue () {
      const item = this.itemValues.find(item => (item.RatesView === this.ratesViewSelectedAsset && item.RiskAversion === this.riskAversionSelectedAsset)) || null

      return item?.Value || 0
    },

    gaugeValues () {
      return [{
        value: this.selectedValue
      }]
    },

    gaugeColorValues () {
      const v = this.gaugeColors

      return [
        [0.2, v[0]],
        [0.4, v[1]],
        [0.6, v[2]],
        [0.8, v[3]],
        [1, v[4]]
      ]
    }
  },
  watch: {
    debt: {
      handler () {
        this.setExportElements()
      },
      immediate: true
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    setExportElements () {
      this.$nextTick(() => {
        this.elementOptimalFixedRatio = this.$refs?.optimalFixedRatioCard?.$el
      })
    }
  }
}
</script>

<style scoped>

</style>
