export default {
  props: {
    btnActionVisible: {
      type   : Boolean,
      default: true
    },
    btnActionDisabled: {
      type   : Boolean,
      default: false
    },
    btnActionTitle: {
      type   : String,
      default: function () { return this.$t('Common.Button.Continue') }
    },
    btnActionColor: {
      type   : String,
      default: 'synthesis-ui-green-02'
    },
    btnActionTextColorClass: {
      type   : String,
      default: 'white--text'
    },

    btnBackVisible: {
      type   : Boolean,
      default: true
    },
    btnBackDisabled: {
      type   : Boolean,
      default: false
    },
    btnBackTitle: {
      type   : String,
      default: function () { return this.$t('Common.Button.Back') }
    },
    btnBackColor: {
      type   : String,
      default: 'synthesis-ui-grey-03'
    },
    btnBackTextColorClass: {
      type   : String,
      default: 'synthesis-brand-dark-blue--text'
    },

    btnCancelVisible: {
      type   : Boolean,
      default: true
    },
    btnCancelDisabled: {
      type   : Boolean,
      default: false
    },
    btnCancelTitle: {
      type   : String,
      default: function () { return this.$t('Common.Button.Cancel') }
    },
    btnCancelColor: {
      type   : String,
      default: 'synthesis-ui-green-02'
    },
    btnCancelTextColorClass: {
      type   : String,
      default: 'synthesis-ui-green-02--text'
    }
  },

  computed: {
    isStepValid () {
      if (!Array.isArray(this.stepObj?.Fields) || (Array.isArray(this.stepObj?.Fields) && !this.stepObj?.Fields?.length)) return true
      return this.model && this.stepObj.Fields.every(field => this.model.validateField(field)) || false
    },

    stepObj () {
      return this.steps[this.step - 1]
    },

    stepBtnAction () {
      return this.stepObj?.BtnAction || null
    },

    stepBtnBack () {
      return this.stepObj?.BtnBack || null
    }
  }
}
