import BaseResource from '@/lib/data/resource/BaseResource'

export default class SurveyResponseRateResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Response = payload?.response ?? ''
    this.Value = payload?.percentage ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
