<template>
  <view-data-container
    :loading="isLoadingData"
    :no-data-title="$t('Risks.Message.NoActiveData.Title')"
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-row>
          <table-header-gradient-blue
            :loading="loading"
            :title="title"
            has-skeleton
          >
            <template
              v-if="tableValues && tableValues.length"
              #toolbarActions
            >
              <v-spacer />

              <div class="d-flex fill-height align-end justify-end">
                <export-to-csv
                  v-if="tableValues"
                  :export-data="exportData"
                  :export-file-name="title"
                  :text="$t('Common.Button.Export')"
                  class="pr-4"
                  color="synthesis-ui-blue-04"
                  color-hover="synthesis-ui-blue-03"
                  dark
                  large
                  no-padding
                />
              </div>
            </template>

            <template #table>
              <v-data-table
                :headers="table.headers"
                :items="tableValues"
                class="row-pointer font-weight-medium synthesis-card-border"
                disable-pagination
                disable-sort
                hide-default-footer
                @click:row="showHistoricalMTMValueDialog"
              >
                <template #item.Actions="{item}">
                  <v-icon
                    color="synthesis-ui-red-05"
                    dense
                    @click.stop="onTradeDeleteClick(item)"
                  >
                    mdi-trash-can
                  </v-icon>
                </template>
              </v-data-table>
            </template>
          </table-header-gradient-blue>
        </v-row>
      </v-col>
    </v-row>

    <historical-mtm-value-dialog
      ref="HistoricalMtmValueDialog"
      :data="historicalMTMValueDialogData"
      :default-period="historicalMTMValueDialogSelectedPeriod"
      :visible.sync="historicalMTMValueDialogVisible"
      @selected-period-changed="handleTradeHistoricalPeriodChanged"
    />
  </view-data-container>
</template>

<script>

import positionId               from '@/theme/default/views/positions/mixins/positionId'
import TableHeaderGradientBlue  from '@/theme/default/components/common/TableHeaderGradientBlue'
import API                      from '@/api/Api'
import { clone }                from '@/lib/utils/helper'
import PositionsResource        from '@/api/resources/positions/PositionsResource'
import HistoricalMtmValueDialog from '@/theme/default/views/positions/HistoricalMtmValueDialog'
import ExportToCsv              from '@/theme/default/components/common/ExportToCsv'
import routerTabState           from '@/mixins/routerTabState'
import ViewDataContainer        from '@/theme/default/components/common/ViewDataContainer'
import viewHasData              from '@/mixins/viewHasData'

let chartInstance = null

export default {
  name      : 'PositionsShowMaturedTrades',
  components: {
    ViewDataContainer,
    ExportToCsv,
    HistoricalMtmValueDialog,
    TableHeaderGradientBlue
  },
  directives: {},
  mixins    : [positionId, routerTabState, viewHasData],
  props     : {},
  dataStore : {
    positionsSummaryMaturedTradesData: 'Positions.SummaryMaturedTrades.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      historicalMTMValueDialogData          : null,
      historicalMTMValueDialogVisible       : false,
      historicalMTMValueDialogSelectedPeriod: this.$dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
      table                                 : {
        headers: [
          {
            text     : this.$t('Positions.Table.Headers.Asset'),
            align    : 'left',
            value    : 'Asset',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.Label'),
            align    : 'left',
            value    : 'Label',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.Instrument'),
            align    : 'left',
            value    : 'Instrument',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.TradeDate'),
            align    : 'left',
            value    : 'TradeDate',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.MaturityDate'),
            align    : 'left',
            value    : 'MaturityDate',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.Rate'),
            align    : 'left',
            value    : 'Rate',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.Notional'),
            align    : 'left',
            value    : 'Notional',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.Direction'),
            align    : 'left',
            value    : 'Direction',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text', 'font-weight-medium']
          },
          {
            text     : this.$t('Positions.Table.Headers.Actions'),
            align    : 'center',
            value    : 'Actions',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          }
        ]
      }
    }
  },
  computed: {
    accountId () {
      return this.user?.selectedAccountId || null
    },

    loading () {
      return !this.position
    },

    position () {
      return this.positionsSummaryMaturedTradesData[this.id] || null
    },

    title () {
      return this.position?.Name || ''
    },

    createdAt () {
      return this.position?.CreatedAtFmt || ''
    },

    cardValues () {
      return this.position?.CardValues || []
    },

    tableValues () {
      return this.position?.TableValuesMatured || []
    },

    exportData () {
      return this.position?.ExportMaturedData || []
    },

    breadcrumb () {
      return [
        {
          text    : this.$t('Positions.Title'),
          disabled: false,
          exact   : true,
          to      : { name: 'Positions' }
        },
        {
          text    : this.$t('Positions.Summary.Title'),
          disabled: true
        }
      ]
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {
    chartInstance = null
  },
  methods: {
    getData () {
      API.Resource.Positions.SummaryMaturedTrades(this.id)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },

    handleResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        const p = data?.findPositionsMatured || null
        if (p) {
          const o = clone(p)
          const v = new PositionsResource(o)

          // Store data to DataStore
          this.$set(this.positionsSummaryMaturedTradesData, v.Id, v)
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    onTradeDeleteClick (item) {
      if (!item || !item.Id) return

      this.$root.$confirmDialog(
        this.$t('Positions.Tab.MaturedTrades.Confirm.Delete.Title'),
        this.$t('Positions.Tab.MaturedTrades.Confirm.Delete.Body', { Name: item.Asset }),
        {
          color : 'synthesis-ui-red-02',
          button: {
            no : { title: this.$t('Common.Button.Cancel') },
            yes: { title: this.$t('Common.Button.Delete') }
          }
        }
      ).then(response => {
        if (response) {
          API.Resource.Positions.DeletePositionTrades(this.id, [item.Id])
            .then(response => {
              this.handleDeleteTradeResponse(response)
            })
            .catch(e => {
              this.handleDeleteTradeResponse(e.response)
            })
            .finally(() => {})
        }
      })
    },
    handleDeleteTradeResponse (response) {
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        this.getData()
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    isBaseCurrencySymbolSingle (baseCurrency) {
      return baseCurrency.length === 1
    },

    baseCurrencySymbolSingle (baseCurrency) {
      return this.isBaseCurrencySymbolSingle(baseCurrency) ? baseCurrency : ''
    },

    getDailyChangeIcon (v) {
      if (v === 0 || v === '') {
        return ''
      } else {
        return (v > 0 ? 'mdi-arrow-top-right' : 'mdi-arrow-bottom-right')
      }
    },

    showHistoricalMTMValueDialog (item) {
      this.historicalMTMValueDialogData = null
      this.historicalMTMValueDialogVisible = true

      this.$nextTick(() => {
        chartInstance = this.$refs?.HistoricalMtmValueDialog?.$refs?.ChartHistorical?.$refs?.echartHistoricalChart?.chart

        this.$nextTick(() => {
          if (this.historicalMTMValueDialogData === null) {
            this.showChartLoader()
          }

          API.Resource.Positions.TradeHistoricalMtm(item.Id, this.historicalMTMValueDialogSelectedPeriod)
            .then(response => {
              this.handleTradeHistoricalMtmResponse(response)
            })
            .catch(e => {
              this.handleTradeHistoricalMtmResponse(e.response)
            })
            .finally(() => {
              this.hideChartLoader()
            })
        })
      })
    },

    handleTradeHistoricalMtmResponse (response) {
      const data = API.responseData(response)?.tradeHistoricalMtm || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.historicalMTMValueDialogData = data
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    handleTradeHistoricalPeriodChanged (tradeId, period) {
      this.historicalMTMValueDialogSelectedPeriod = period
      this.showChartLoader()

      API.Resource.Positions.TradeHistoricalMtm(tradeId, period)
        .then(response => {
          this.handleTradeHistoricalPeriodResponse(response)
        })
        .catch(e => {
          this.handleTradeHistoricalPeriodResponse(e.response)
        })
        .finally(() => {
          this.hideChartLoader()
        })
    },
    handleTradeHistoricalPeriodResponse (response) {
      const data = API.responseData(response)?.tradeHistoricalMtm || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.historicalMTMValueDialogData = data
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    showChartLoader () {
      chartInstance && chartInstance.showLoading('default', {
        text         : '',
        spinnerRadius: 20,
        color        : this.$vuetify.theme.themes.light['synthesis-ui-blue-04'],
        maskColor    : 'rgba(255,255,255,0.8)',
        lineWidth    : 4
      })
    },

    hideChartLoader () {
      chartInstance && chartInstance.hideLoading()
    }
  }
}
</script>

<style scoped>
/deep/ .v-toolbar__content, .v-toolbar__extension {
  padding: 4px 0 !important;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

</style>
