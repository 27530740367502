import baseEChartProps from '@/theme/default/components/charts/echarts/mixins/common/baseEChartProps'

export default {
  mixins: [baseEChartProps],
  props : {
    chartLineColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-ui-blue-04'] }
    },
    chartLineSmooth: {
      type   : Boolean,
      default: false
    },
    chartLineSymbol: {
      type   : String,
      default: 'image://data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxnIGZpbHRlcj0idXJsKCNmaWx0ZXIwX2RfMzY3XzI2MDYzKSI+DQo8Y2lyY2xlIGN4PSI5IiBjeT0iNSIgcj0iNSIgZmlsbD0iIzNENjhENSIvPg0KPGNpcmNsZSBjeD0iOSIgY3k9IjUiIHI9IjQuNSIgc3Ryb2tlPSJ3aGl0ZSIvPg0KPC9nPg0KPGRlZnM+DQo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2RfMzY3XzI2MDYzIiB4PSIwIiB5PSIwIiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIj4NCjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+DQo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIgcmVzdWx0PSJoYXJkQWxwaGEiLz4NCjxmZU9mZnNldCBkeT0iNCIvPg0KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMiIvPg0KPGZlQ29tcG9zaXRlIGluMj0iaGFyZEFscGhhIiBvcGVyYXRvcj0ib3V0Ii8+DQo8ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC4yNSAwIi8+DQo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9ImVmZmVjdDFfZHJvcFNoYWRvd18zNjdfMjYwNjMiLz4NCjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9kcm9wU2hhZG93XzM2N18yNjA2MyIgcmVzdWx0PSJzaGFwZSIvPg0KPC9maWx0ZXI+DQo8L2RlZnM+DQo8L3N2Zz4='
    },
    chartLineSymbolSize: {
      type   : Number,
      default: 20
    },
    chartLineSymbolOffset: {
      type   : Array,
      default: () => [0, 5]
    },
    chartLineShowSymbol: {
      type   : Boolean,
      default: false
    },
    chartLineWidth: {
      type   : Number,
      default: 2
    },
    /**
     * It specifies whether not to contain zero position of axis compulsively
     * When it is set to be true, the axis may not contain zero position,
     * which is useful in the scatter chart for both value axes.
     *
     * This configuration item is unavailable when the min and max are set.
     */
    chartScale: {
      type   : Boolean,
      default: false
    }
  }
}
