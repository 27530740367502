<template>
  <v-row>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="550px"
      persistent
      scrollable
      transition="slide-x-reverse-transition"
    >
      <v-card min-height="100vh">
        <div class="">
          <v-toolbar
            color="section-bg-gradient-yellow"
            flat
          >
            <v-spacer />

            <v-btn
              :disabled="isFormSaving"
              icon
              @click="closeDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <div class="px-5">
            <v-icon
              color="orange"
              size="24"
            >
              mdi-poll
            </v-icon>

            <div class="mt-4">
              <div class="synthesis-text text-size-24 synthesis-brand-dark-blue--text font-weight-medium">
                {{ $t(`Dashboard.TrackLivePrices.Title`) }}
              </div>
              <div class="synthesis-text text-size-14 synthesis-ui-grey-01--text">
                {{ $t(`Dashboard.TrackLivePrices.Subtitle`) }}
              </div>
            </div>

            <div class="mt-4">
              <v-text-field
                v-model="searchTerms"
                :placeholder="$t('Common.Search.Title')"
                append-icon="mdi-magnify"
                hide-details="auto"
                outlined
                single-line
              />

              <select-base-currency
                v-model="selectedBaseCurrency"
                use-user-base-currency
                show-select-only
                class="mt-4"
              />
            </div>

            <div class="my-4">
              <div class="d-inline-block">
                <asset-button-group
                  :height="27"
                  :items="categories"
                  :selected.sync="selectedCategory"
                  disable-dropdown-on-mobile
                />
              </div>
            </div>
          </div>

          <v-divider />
        </div>

        <v-card-text
          ref="formContainer"
          class="pt-0 pa-0"
          style="overflow-y: scroll;"
        >
          <v-form
            ref="form"
            v-model="isFormValid"
            @submit.prevent
          >
            <v-list
              flat
              two-line
            >
              <template v-for="(item, index) in prices">
                <v-lazy
                  :key="`lazy-live-prices-list-item-${index}`"
                  :options="{threshold: .5}"
                  transition="fade-transition"
                >
                  <div>
                    <v-list-item
                      class="px-6 py-1"
                      @click="onListItemClick(item)"
                    >
                      <v-list-item-action>
                        <v-checkbox :input-value="item.selected" />
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                          <v-list-item-subtitle />
                        </v-list-item-content>
                      </v-list-item-content>

                      <v-list-item-action-text class="text-right">
                        <div class="text-size-14 font-weight-medium synthesis-brand-dark-blue--text">
                          {{ item.priceFmt }}
                        </div>
                        <div
                          :class="[item.getDailyChangeColor]"
                          class="synthesis-inter-text text-size-10 font-weight-regular"
                        >
                          {{ item.dailyChangeFmt }}
                        </div>
                      </v-list-item-action-text>
                    </v-list-item>
                    <v-divider />
                  </div>
                </v-lazy>
              </template>
            </v-list>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="py-3">
          <v-btn
            :disabled="isFormSaving || !isModelValid"
            :loading="isFormSaving"
            block
            class="action-button white--text"
            color="synthesis-ui-green-02"
            depressed
            large
            @click="saveFromData"
          >
            {{ $t(`Dashboard.TrackLivePrices.Form.Button.Save.Title`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dialogData                                     from '@/mixins/dialog/dialogData'
import dialogVisible                                  from '@/mixins/dialog/dialogVisible'
import API                                            from '@/api/Api'
import { clone, toDecimalMark, toDecimalSignificant } from '@/lib/utils/helper'
import AssetEnum                                      from '@/api/enums/AssetEnum'
import AssetButtonGroup                               from '@/theme/default/components/common/AssetButtonGroup'
import TrackLivePricesResource                        from '@/api/resources/dashboard/TrackLivePricesResource'
import TrackLivePricesInputModel                      from '@/api/models/dashboard/TrackLivePricesInputModel'
import { collect }                                    from '@/lib/utils/array'
import TrackLivePricesPairResource                    from '@/api/resources/dashboard/TrackLivePricesPairResource'
import UserModel                                      from '@/api/models/user/UserModel'
import SelectBaseCurrency                             from '@/theme/default/components/form/SelectBaseCurrency'

export default {
  name      : 'TrackLivePricesDialog',
  components: {
    SelectBaseCurrency,
    AssetButtonGroup
  },
  directives: {},
  mixins    : [dialogVisible, dialogData],
  props     : {},
  dataStore : { livePrices: 'Dashboard.LivePrices.All' },
  enums     : { AssetEnum },
  dataModel : TrackLivePricesInputModel,
  data () {
    return {
      searchTerms          : '',
      searchResult         : [],
      selectedPriceItems   : [],
      searchDebounceTimeout: null,
      selectedCategory     : null,
      selectedBaseCurrency : this?.user?.selectedAccountBaseCurrency?.symbol || 'EUR'
    }
  },
  computed: {
    categories () {
      return this.livePrices?.categories || []
    },

    prices () {
      const prices = this.searchResult
      return this.selectedCategory ? prices.filter(price => price.category === this.selectedCategory) : prices
    },

    selectedItems () {
      // const prices = this.livePrices?.prices || []
      // const selectedPrices = prices.filter(price => price.selected)
      const subscribedAssetsLivePrices = this.livePrices?.subscribedAssetsLivePrices || []
      const selectedPrices = this.selectedPriceItems || []
      const mergedSelectedPrices = [...selectedPrices, ...subscribedAssetsLivePrices]

      return mergedSelectedPrices
    }
  },

  watch: {
    selectedBaseCurrency () {
      this.getData()
    },

    selectedCategory () {
      this.searchTerms = ''
      if (this.$refs.formContainer) this.$refs.formContainer.scrollTop = 0
    },

    searchTerms (searchTerm) {
      this.searchAssets(searchTerm)
    },

    isVisible (newVal) {
      if (newVal) {
        this.getData()
        this.isFormSaving = false
      } else {
        this.searchTerms = ''
        this.selectedCategory = null
        this.searchResult = []
        this.selectedPriceItems = []
      }
    }
  },
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    toDecimalMark,
    toDecimalSignificant,

    /**
     * getData
     *
     * Get latest quotes from api
     */
    getData () {
      this.getAllAssetsLivePrices()
    },

    /**
     * searchAssets
     *
     * Search in prices locally with 500ms debounce / delay
     *
     * @param searchTerm {String}
     */
    searchAssets (searchTerm = '') {
      const prices = this.livePrices?.prices || []
      clearTimeout(this.searchDebounceTimeout)
      searchTerm = searchTerm.trim().toLocaleLowerCase(this.$i18n.locale)
      if (!searchTerm) {
        this.searchResult = prices
        return
      }

      this.searchDebounceTimeout = setTimeout(() => {
        this.searchResult = prices.filter(price => price.name.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm) || price.pair.baseSymbol.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm) || price.pair.quoteSymbol.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm))
      }, 500)
    },

    /**
     * getAllAssetsLivePrices
     *
     * Request All Assets Live Prices
     */
    getAllAssetsLivePrices () {
      API.Resource.Dashboard.AllAssetsLivePrices(this.selectedBaseCurrency)
        .then(response => {
          this.handleAllAssetsLivePricesResponse(response)
        })
        .catch(e => {
          this.handleAllAssetsLivePricesResponse(e.response)
        })
        .finally(() => {})
    },

    /**
     * handleAllAssetsLivePricesResponse
     *
     * @param response <Object>
     */
    handleAllAssetsLivePricesResponse (response) {
      const data = API.responseData(response)?.listAllAssetsLivePrices || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.livePrices = new TrackLivePricesResource(data)
        this.searchResult = this.livePrices?.prices || []
        this.user.livePricesPairs = this.livePrices?.subscribedAssetsLivePrices || []
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    /**
     * onListItemClick
     *
     * @param item {Object}
     */
    onListItemClick (item) {
      item.selected = !item.selected

      if (item.selected) {
        this.selectedPriceItems.push(item)
      } else {
        const index = this.selectedItems.findIndex(obj => obj.symbol === item.symbol)
        if (index > -1) this.selectedItems.splice(index, 1)
      }
    },

    /**
     * saveFromData
     */
    saveFromData () {
      if (this.isFormSaving || !this.validateForm()) return
      this.isFormSaving = true
      const data = clone(this.model)
      data.livePricesPairs = collect(this.selectedItems, TrackLivePricesPairResource)

      API.Resource.User.UpdateMyUserPreferences(data)
        .then(response => {
          this.handleFormSaveResponse(response)
        })
        .catch(e => {
          this.handleFormSaveResponse(e.response)
        })
        .finally(() => {
          this.isFormSaving = false
        })
    },

    handleFormSaveResponse (response) {
      const data = API.responseData(response)?.updateMyPreferences || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.user = new UserModel(data)
        this.formSaveSuccess(data)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>
/* v-dialog overrides */
.v-dialog__content {
  align-items     : start;
  justify-content : end;
  height          : 100%;
}

/deep/ .v-dialog {
  border-radius : 4px;
  margin        : 0px !important;
  max-height    : 100% !important;
}
</style>
