<template>
  <v-row class="ma-0">
    <v-col
      class="pa-0 pb-2"
      cols="12"
    >
      <block-item-card

        :min-height="44"
        style="border: none;"
      >
        <v-container
          v-ripple="ripple"
          class="synthesis-ui-grey-06"
          fill-height
          pa-0
          style="height: 44px;border-radius: 8px !important;"
          :style="`${ripple ? 'cursor: pointer;' : ''}`"
        >
          <v-row class="ma-0 py-2">
            <v-col
              class="pa-0 px-3"
              cols="6"
            >
              <div
                class="d-inline-block text-no-wrap"
                style="padding: 5px 0"
              >
                <div class="text-size-14 font-weight-medium synthesis-brand-dark-blue--text">
                  {{ item.label }}
                </div>
              </div>
            </v-col>
            <v-col
              class="pa-0 px-3 text-right"
              cols="6"
            >
              <div
                class="d-inline-block text-no-wrap"
                style="padding: 5px 0"
              >
                <div class="text-size-14 font-weight-medium synthesis-brand-dark-blue--text">
                  {{ item.value }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </block-item-card>
    </v-col>
  </v-row>
</template>

<script>
import BlockItemCard from '@/theme/default/components/common/BlockItemCard.vue'

export default {
  name      : 'ForecastFlowRuglSummaryCardItem',
  components: { BlockItemCard },
  directives: {},
  mixins    : [],
  props     : {
    item: {
      type    : Object,
      required: true
    },
    ripple: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
