<template>
  <block-item-card
    :min-height="310"
    :title="$t('Dashboard.Block.LivePrices.Title')"
  >
    <template #actions>
      <v-spacer />
      <v-card-title class="pa-0">
        <v-btn
          class="px-2 button-text-underline synthesis-text text-size-12 font-weight-400"
          color="synthesis-ui-green-02"
          height="30"
          text
          @click="onButtonActionClick"
        >
          {{ $t('Dashboard.Block.LivePrices.Button.AddNewPrices.Title') }}
        </v-btn>
      </v-card-title>
    </template>
    <v-col
      class="pt-0 text-left"
      cols="12"
    >
      <div class="d-inline-flex">
        <asset-button-group
          :height="26"
          :items="categories"
          :selected.sync="selectedCategory"
          disable-dropdown-on-mobile
        />
      </div>
    </v-col>
    <v-card-text
      class="overflow-y-auto pt-0 pb-2"
      style="height: 218px"
    >
      <template
        v-for="(item, i) in prices"
      >
        <v-lazy
          :key="`lazy-live-prices-list-card-${i}`"
          :options="{threshold: .5}"
          transition="fade-transition"
        >
          <live-prices-list-card
            :key="`live-prices-list-card-${i}`"
            :item="item"
            @live-prices-list-card-click="onLivePricesListCardClick(item)"
          />
        </v-lazy>
      </template>
    </v-card-text>

    <track-live-prices-dialog
      :visible.sync="trackLivePricesDialogVisible"
      @form:save:success="onTrackLivePricesDialogSave"
    />

    <show-live-price-quote-dialog
      :quote="selectedQuote"
      :visible.sync="showLivePriceQuoteDialog"
    />
  </block-item-card>
</template>

<script>
import BlockItemCard               from '@/theme/default/components/common/BlockItemCard'
import LivePricesListCard          from '@/theme/default/views/dashboard/blocks/LivePricesListCard'
import API                         from '@/api/Api'
import { clone }                   from '@/lib/utils/helper'
import DashboardLivePricesResource from '@/api/resources/dashboard/DashboardLivePricesResource'
import AssetButtonGroup            from '@/theme/default/components/common/AssetButtonGroup'
import TrackLivePricesDialog       from '@/theme/default/views/dashboard/dialogs/TrackLivePricesDialog'
import ShowLivePriceQuoteDialog    from '@/theme/default/views/dashboard/dialogs/ShowLivePriceQuoteDialog'
import Socket                      from '@/theme/default/views/dashboard/socket/socket'

export default {
  name      : 'LivePricesBlock',
  components: {
    ShowLivePriceQuoteDialog,
    TrackLivePricesDialog,
    AssetButtonGroup,
    LivePricesListCard,
    BlockItemCard
  },
  directives: {},
  mixins    : [],
  props     : {
    height: {
      type   : [Number, String],
      default: 224
    }
  },
  dataStore: {
    livePricesData: 'Dashboard.LivePrices.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      trackLivePricesDialogVisible: false,
      showLivePriceQuoteDialog    : false,
      selectedCategory            : '',
      selectedQuote               : null
    }
  },
  computed: {
    livePrices () {
      return this.livePricesData || null
    },

    categories () {
      return this.livePrices?.Categories || []
    },

    prices () {
      const prices = this.livePrices?.Prices || []

      return prices.filter(item => item.Category === this.selectedCategory)
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {
    this.$bus.$on('ws-onmessage', this.wsOnMessage)
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off('ws-onmessage', this.wsOnMessage)
  },
  destroyed () {},
  methods: {
    onButtonActionClick () {
      this.trackLivePricesDialogVisible = true
    },

    onLivePricesListCardClick (quote) {
      this.selectedQuote = quote
      this.showLivePriceQuoteDialog = true
    },

    onTrackLivePricesDialogSave () {
      this.getData()
    },

    getData () {
      API.Resource.Dashboard.SubscribedAssetsLivePrices()
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },
    handleResponse (response) {
      const data = API.responseData(response)?.subscribedAssetsLivePrices || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        if (data) {
          const o = clone(data)
          this.livePricesData = new DashboardLivePricesResource(o)
          Socket.refresh()
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    setData (data) {
      const prices = this.livePrices?.Prices || []
      if (prices && prices.length) {
        const o = clone(prices)

        const index = o.findIndex(item => item.Symbol === data.symbol)
        if (index > -1) {
          this.livePrices.Prices[index].Price = data.price
          this.livePrices.Prices[index].DailyChange = data.dailyChange ?? 0
        }
      }
    },

    wsOnMessage (data) {
      this.setData(data)
    }
  }
}
</script>

<style scoped>

</style>
