<template>
  <v-container
    v-if="summary"
    fluid
  >
    <v-row>
      <section-header
        :breadcrumb-items="breadcrumb"
        :button="showHeaderButton"
        :button-title="$t('Risks.Button.RunHedgingStrategy.Title')"
        button-icon=""
        @click-button="onRunHedgingStrategies"
      />
    </v-row>

    <v-row>
      <v-col cols="12">
        <risks-analysis-show-base
          :risk-analysis-id="id"
          class="pa-0"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta         from '@/mixins/i18n/i18nRouteMeta'
import SectionHeader         from '@/theme/default/components/common/SectionHeader'
import riskAnalysisId        from '@/theme/default/views/risks/mixins/riskAnalysisId'
import RisksAnalysisShowBase from '@/theme/default/views/risks/RisksAnalysisShowBase'
import API                   from '@/api/Api'
import { clone }             from '@/lib/utils/helper'
import AssetModel            from '@/api/models/asset/AssetModel'
import RiskSummaryResource   from '@/api/resources/risks/RiskSummaryResource'
import routerTabState        from '@/mixins/routerTabState'

export default {
  name      : 'RisksAnalysisShow',
  components: {
    RisksAnalysisShowBase,
    SectionHeader
  },
  directives: {},
  mixins    : [i18nRouteMeta, routerTabState, riskAnalysisId],
  props     : {
    riskAnalysisName: {
      type   : String,
      default: ''
    }
  },
  dataStore: {
    riskSummaryData     : 'Risks.Summary.Data',
    riskBaseCurrencyData: 'Risks.BaseCurrency.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    showHeaderButton () {
      return [
        this.$t('Risks.Tab.Summary.Slug'),
        this.$t('Risks.Tab.Correlations.Slug'),
        this.$t('Risks.Tab.Seasonality.Slug'),
        this.$t('Risks.Tab.Forecasts.Slug')
      ].includes(this.activeTab)
    },

    summary () {
      return this.riskSummaryData[this.id] || null
    },

    title () {
      return this.summary?.Name || ''
    },

    breadcrumb () {
      return [{
        text    : this.$t('Risks.Title'),
        disabled: false,
        exact   : true,
        to      : { name: 'Risks' }
      }, {
        text    : this.title,
        disabled: true
      }]
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Risks.Summary(this.id)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },

    handleResponse (response) {
      const data = API.responseData(response)?.findAnalysis || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const o = clone(data)
        const b = new AssetModel(o.baseCurrency ?? null)
        const v = new RiskSummaryResource(o)

        // Store data to DataStore
        this.$set(this.riskSummaryData, this.id, v)
        this.$set(this.riskBaseCurrencyData, this.id, b)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    onRunHedgingStrategies () {
      if (this.riskSummaryData[this.id]?.Active) {
        this.$router.push({ name: 'HedgingStrategyNew' })
      } else {
        this.$root.$confirmDialog(
          this.$t('Risks.Button.RunHedgingStrategy.Confirm.Title'),
          this.$t('Risks.Button.RunHedgingStrategy.Confirm.Body'),
          {
            color : 'synthesis-ui-red-02',
            button: {
              no : { title: this.$t('Common.Button.No') },
              yes: { title: this.$t('Common.Button.Yes') }
            }
          }
        ).then(response => {
          if (response) {
            API.Resource.Risks.SetActiveAnalysis(this.id)
              .then(response => {
                this.handleSetActiveAnalysisResponse(response)
              })
              .catch(e => {
                this.handleSetActiveAnalysisResponse(e.response)
              })
              .finally(() => {})
          }
        })
      }
    },

    handleSetActiveAnalysisResponse (response) {
      const data = API.responseData(response)?.activeAnalysis || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.$router.push({ name: 'HedgingStrategyNew' })
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>

</style>
