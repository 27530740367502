import BaseResource from '@/lib/data/resource/BaseResource'

export default class HedgingStrategyInstrumentOptionResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Label = payload.displayLabel ?? ''
    this.Value = payload.instrumentValue ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
