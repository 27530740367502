<template>
  <block-item-card
    v-if="hasForecastFlow"
    ref="forecastFlowCard"
    :show-background="false"
    :show-border="false"
  >
    <forecast-flow-rugl-summary />
    <forecast-flow-rugl-table
      :items="items"
    />
  </block-item-card>
</template>

<script>

import BlockItemCard           from '@/theme/default/components/common/BlockItemCard.vue'
import ExportEnum              from '@/api/enums/ExportEnum'
import riskAnalysisId          from '@/theme/default/views/risks/mixins/riskAnalysisId'
import ForecastFlowRuglTable   from '@/theme/default/views/risks/blocks/forecastFlow/ForecastFlowRuglTable.vue'
import ForecastFlowRuglSummary from '@/theme/default/views/risks/blocks/forecastFlow/ForecastFlowRuglSummary.vue'

export default {
  name      : 'RiskForecastFlowShowBlock',
  components: {
    ForecastFlowRuglTable,
    ForecastFlowRuglSummary,
    BlockItemCard
  },
  directives: {},
  mixins    : [riskAnalysisId],
  props     : {},
  dataStore : {
    riskForecastFlowData: 'Risks.ForecastFlow.Data'
  },
  enums    : { ExportEnum },
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    forecastFlow () {
      return this.riskForecastFlowData[this.id]
    },

    hasForecastFlow () {
      return !!this.forecastFlow
    },

    loading () {
      return !this.hasForecastFlow
    },

    ruglTable () {
      return this.forecastFlow?.RuglTable || null
    },

    items () {
      return this.ruglTable || {
        headers: [],
        values : [],
        group  : {}
      }
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
/deep/ .v-toolbar .v-toolbar__content {
  padding-right: 0;
}
</style>
