import BaseResource             from '@/lib/data/resource/BaseResource'
import { clone, toDecimalMark } from '@/lib/utils/helper'
import { first }                from '@/lib/utils/array'
import utils                    from '@/lib/utils'
import i18n                     from '@/lang/lang'

export default class RiskCorrelationsResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const o = payload?.result?.correlations || null

    this.Frequency = o?.frequency || i18n.t('Risks.Section.CorrelationsAcrossExposures.Monthly')
    this.Assets = o?.matrix?.assets || []
    this.Columns = o?.matrix?.columns || []
    this.Rows = o?.matrix?.rows || []

    this.RangeStartDate = o?.range?.startDate || ''
    this.RangeEndDate = o?.range?.endDate || ''
    this.WindowStartDate = o?.window?.startDate || ''
    this.WindowEndDate = o?.window?.endDate || ''
  }

  /* PROPERTIES */
  get DateRange () {
    if (this.RangeStartDate && this.RangeEndDate) {
      return [this.RangeStartDate, this.RangeEndDate]
    }

    return []
  }

  get WindowDateRange () {
    if (this.WindowStartDate && this.WindowEndDate) {
      return [this.WindowStartDate, this.WindowEndDate]
    }

    return []
  }

  get Table () {
    return this.getTable()
  }

  /* METHODS */
  getTable () {
    const o = this.Rows

    if (o) {
      const data = []
      let row = []

      const css = 'd-inline-flex pa-3 synthesis-text text-size-14 font-weight-medium override--border '
      const firstRow = first(o)
      const len = firstRow?.row?.length || 5
      let width = 100 / (len + 1)
      const height = 44
      let rounded = 'tl-lg'

      const rows = []
      o.forEach((item, index, arr) => {
        const v = clone(item)

        if (index > 0) {
          v.row.push(arr[index - 1].asset)
        }

        rows.push(v)
      })

      /**
       * Add the last row
       */
      rows.push({
        asset: '&nbsp;',
        row  : [o[len - 1].asset]
      })

      rows.forEach((item, rowIndex, arr) => {
        if (item.row) {
          const borderCss = item.asset === '&nbsp;' ? 'override--border-none' : 'override--border'

          /**
           * First Column
           */
          if (rowIndex === (arr.length - 2)) {
            rounded = 'bl-lg'
          } else {
            rounded = (rowIndex === 0) ? 'tl-lg' : false
          }

          row.push({
            value  : item.asset,
            rounded: rounded,
            color  : window.Vue.$vuetify.theme.themes.light['synthesis-brand-white'],
            class  : css + borderCss + ' synthesis-brand-dark-blue--text',
            width  : width + '%',
            height : height
          })

          let borderLeft = 'override--border-left-none'
          item.row.forEach((value, valueIndex, valueArr) => {
            const v = this.getColor(value)

            if (rowIndex === 0 && valueIndex === (valueArr.length - 1)) {
              rounded = 'tr-lg'
            } else if (rowIndex > 0 && valueIndex === (valueArr.length - 1)) {
              rounded = 'br-lg'
            } else if (borderCss === 'override--border-none') {
              rounded = 'b-lg'
            } else {
              rounded = false
            }

            if (utils.type.isString(value)) {
              if (rowIndex === (arr.length - 1)) {
                borderLeft = 'override--border-left'
                rounded = 'b-lg'
                width = width + 0.1
              }

              row.push({
                value  : value,
                rounded: rounded,
                color  : window.Vue.$vuetify.theme.themes.light['synthesis-brand-white'],
                class  : css + borderLeft + ' synthesis-brand-dark-blue--text',
                width  : width + '%',
                height : height
              })
            } else {
              row.push({
                value  : toDecimalMark(value, 0) + '%',
                rounded: rounded,
                color  : v.bgColorClass,
                class  : css + 'override--border-left-none ' + v.textColor,
                width  : width + '%',
                height : height
              })
            }
          })
        }

        data.push(row)
        row = []
      })

      return data
    }

    return []
  }

  getColor (num) {
    if (num > 33 && num <= 66) {
      return {
        textColor   : 'synthesis-ui-green-01--text',
        bgColorClass: window.Vue.$vuetify.theme.themes.light['synthesis-ui-green-08']
      }
    }

    if (num > 66 && num <= 100) {
      return {
        textColor   : 'synthesis-ui-green-01--text',
        bgColorClass: window.Vue.$vuetify.theme.themes.light['synthesis-ui-green-07']
      }
    }

    if (num > -33 && num < 33) {
      return {
        textColor   : 'synthesis-brand-dark-blue--text',
        bgColorClass: window.Vue.$vuetify.theme.themes.light['synthesis-brand-white']
      }
    }

    if (num > -66 && num < -33) {
      return {
        textColor   : 'synthesis-ui-red-01--text',
        bgColorClass: window.Vue.$vuetify.theme.themes.light['synthesis-ui-red-07']
      }
    }

    if (num > -100 && num < -66) {
      return {
        textColor   : 'synthesis-ui-red-01--text',
        bgColorClass: window.Vue.$vuetify.theme.themes.light['synthesis-ui-red-06']
      }
    }

    return {
      textColor   : 'synthesis-brand-dark-blue--text',
      bgColorClass: window.Vue.$vuetify.theme.themes.light['synthesis-brand-white']
    }
  }
}
