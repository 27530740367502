const DataEventEnum = {
  EVENT          : 'data-event',
  LOGIN          : 'data-event:login',
  LOGOUT         : 'data-event:logout',
  SIGN_UP        : 'data-event:sign_up',
  SEARCH         : 'data-event:search',
  ACCOUNT_DELETE : 'data-event:account_delete',
  ACCOUNT_DISABLE: 'data-event:account_disable',
  ACCOUNT_ENABLE : 'data-event:account_enable',
  ACCOUNT_UPDATE : 'data-event:account_update'
}

export default DataEventEnum
