export const Hedging = {
  BaseCurrency: {
    Data: {}
  },

  Archive: {
    Data: {}
  },

  Summary: {
    Data: {}
  },

  Compare: {
    Data: {}
  },

  Instrument: {
    Forward: {
      Data   : {},
      Rolling: {
        Data: {}
      }
    },

    Option: {
      Data   : {},
      Rolling: {
        Data: {}
      }
    },

    Collar: {
      Data   : {},
      Rolling: {
        Data: {}
      }
    }
  },

  Costs: {
    Data: {}
  }
}
