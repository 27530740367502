<template>
  <e-chart
    :ref="`echart${chartRefId}`"
    :autoresize="true"
    :option="getChartOptions"
    :style="`height: ${getChartHeight}px;`"
    class="chart"
  />
</template>

<script>
import baseEChartBarProps from '@/theme/default/components/charts/echarts/mixins/bar/baseEChartBarProps'
import baseEChartBarData  from '@/theme/default/components/charts/echarts/mixins/bar/baseEChartBarData'
import baseEChartColors   from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'

import { first } from '@/lib/utils/array'

import { use }                 from 'echarts/core'
import { BarChart }            from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer }      from 'echarts/renderers'

import { GridComponent, LegendComponent, TitleComponent, TooltipComponent, TransformComponent } from 'echarts/components'
import { toDecimalSignificant, toNumberFormatNotationShort }                                    from '@/lib/utils/helper'
import dayjs                                                                                    from 'dayjs'
import chartsBaseCurrencySymbolSingle                                                           from '@/theme/default/components/charts/mixins/chartsBaseCurrencySymbolSingle'

use([
  BarChart,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
  CanvasRenderer,
  UniversalTransition
])

export default {
  name    : 'EChartSeasonalityBar',
  mixins  : [baseEChartColors, chartsBaseCurrencySymbolSingle, baseEChartBarProps, baseEChartBarData],
  computed: {
    setChartDataOptions () {
      return this.getChartDataOptions
    },

    getChartDataOptions () {
      const o = this.series
      const month = this.$tc('Common.Date.Month', 1)
      const tooltipValuePrefix = this.isBaseCurrencySymbolSingle ? '' : '<span class="pt-4 pr-1 text-size-12">' + this.axisYTickPrefix + '</span>'

      return {
        animation         : this.chartAnimation,
        animationThreshold: this.chartAnimationThreshold,
        animationDuration : this.chartAnimationDuration,
        animationEasing   : this.chartAnimationEasing,
        tooltip           : {
          trigger        : 'axis',
          backgroundColor: this.themeColorRGBA(this.tooltipBackgroundColor, 0.8),
          borderColor    : this.tooltipBorderColor,
          borderWidth    : this.tooltipBorderWidth,
          padding        : this.tooltipPadding,
          textStyle      : {
            color: this.tooltipLabelColor
          },
          formatter: params => {
            params = params[0]
            return `<span class="synthesis-text text-size-14 font-weight-regular">${ month } ${ params.name }</span><br/>${ tooltipValuePrefix }<span class="ml-0 synthesis-text text-size-18 font-weight-regular">${ this.baseCurrencySymbolSingle }${ toNumberFormatNotationShort(params.value) }</span>`
          },
          axisPointer: {
            type: 'shadow'
          }
        },
        legend   : { show: this.legendShow },
        textStyle: {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid : this.getChartGridMargin,
        xAxis: {
          type     : 'category',
          axisLabel: {
            show: true
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitNumber: this.axisXSplitNumber,
          data       : o.xAxis
        },
        yAxis: {
          type     : 'value',
          axisLabel: {
            show      : this.axisYLabelShow,
            inside    : this.axisYLabelInside,
            margin    : this.axisYLabelMargin,
            padding   : [0, 0, 0, 0], // [top, right, bottom, left]
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            color     : this.axisLabelFontColor,
            formatter : value => {
              if (this.axisYLabelValueFormat === 'number') {
                value = (value === 0 ? value : toNumberFormatNotationShort(parseFloat(value)))
              }
              if (this.axisYLabelValueFormat === 'percent') {
                value = (value === 0 ? value : toDecimalSignificant(parseFloat(value)))
              }
              if (this.axisYLabelValueFormat === 'date') {
                value = dayjs(value).format('MMM YY')
              }

              return (this.axisYTickPrefix + ' ' || '') + value + (this.axisYTickSuffix || '')
            },
            interval     : this.axisYLabelInterval,
            verticalAlign: this.axisYLabelVerticalAlign
          },
          splitLine: {
            show     : this.axisYSplitLineShow,
            lineStyle: {
              type   : this.axisYSplitLineType,
              color  : this.axisYSplitLineColor,
              opacity: this.axisYSplitLineOpacity,
              width  : this.axisYSplitLineWidth
            }
          },
          splitNumber: this.axisYSplitNumber,
          axisLine   : {
            show: false
          },
          axisTick: {
            show: false
          },
          silent: true
        },
        series: o.series
      }
    },

    series () {
      let o = {
        x: [],
        y: []
      }

      if (this.chartDataOptions.length) {
        o = first(this.chartDataOptions)
      }

      const firstValue = first(o.y)
      const color = firstValue > 0 ? this.themeColorRGBA('synthesis-ui-blue-04') : this.themeColorRGBA('synthesis-ui-red-02')

      const series = {
        name        : '',
        type        : 'bar',
        barMinHeight: 2,
        barGap      : 0,
        label       : {
          show: false
        },
        itemStyle: {
          // (clockwise upper left, upper right, bottom right and bottom left)
          borderRadius: [this.barBorderRadius, this.barBorderRadius, this.barBorderRadius, this.barBorderRadius],
          color       : color
        },
        showBackground : true,
        backgroundStyle: {
          color: this.barBackgroundColor
        },
        animationDelay: function (idx) {
          return idx * 10
        },
        cursor: this.cursor,
        data  : o.y
      }

      return {
        xAxis : o.x,
        series: series
      }
    }
  }
}
</script>

<style scoped>
.chart {
  width: 100%;
}
</style>
