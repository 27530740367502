<template>
  <div class="">
    <v-app-bar
      app
      clipped-left
      color="synthesis-ui-blue-01"
      dark
      dense
      flat
      height="60"
    >
      <div class="d-flex align-center fill-height">
        <img
          height="20"
          src="/img/logo/logo-light.png"
        >
      </div>

      <div class="flex-grow-1" />

      <v-app-bar-nav-icon @click.stop="navigationDrawerVisible = !navigationDrawerVisible" />
    </v-app-bar>
  </div>
</template>

<script>

export default {
  name      : 'AppToolbar',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    navigationDrawer: {
      type   : Boolean,
      default: undefined
    }
  },
  dataStore: {},
  data () {
    return {}
  },
  computed: {
    navigationDrawerVisible: {
      get () {
        return this.navigationDrawer
      },
      set (val) {
        this.$emit('update:navigationDrawer', val)
      }
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
/deep/ .v-toolbar__content {
  padding-top    : 0;
  padding-bottom : 0;
  height         : 100% !important;
}
</style>
