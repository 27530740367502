import Vue              from 'vue'
import Hotjar           from 'vue-hotjar'
import DataEventEnum    from '@/lib/services/enum/DataEventEnum'
import DataEventService from '@/lib/services/event/DataEventService'
import HotJarEventEnum  from '@/lib/services/enum/HotJarEventEnum'

export const setupHotJar = (HJConfig) => {
  const config = HJConfig?.key && HJConfig?.enabled ? {
    id          : HJConfig?.key ?? '',
    isProduction: HJConfig?.enabled ?? false
  } : null

  const environments = HJConfig?.environments || ['production']

  if (config && environments.includes(process.env.VUE_APP_ENV)) {
    Vue.use(Hotjar, config)

    DataEventService.On(DataEventEnum.EVENT, handleHJDataEvent)
  }
}

const handleHJDataEvent = ($data) => {
  const data = JSON.parse(JSON.stringify($data))
  const HJ = window?.hj
  if (!HJ) return

  switch (data.Event) {
  case DataEventEnum.LOGIN:
    data?.Payload?.User && HJ(HotJarEventEnum.LOGIN, data?.Payload?.User?.id || null, {
      email   : data?.Payload?.User?.email || '',
      userName: data?.Payload?.User?.userName || ''
    })
    break
  }
}
