<template>
  <v-container
    class="fill-height align-start"
    fluid
  >
    <v-row
      class="fill-height"
      dense
    >
      <v-col
        class=""
        cols="12"
        lg="8"
        md="7"
        sm="12"
        xl="10"
      >
        <v-row>
          <section-header :breadcrumb-items="breadcrumbItems" />

          <v-col
            v-if="$vuetify.breakpoint.sm"
            class="py-0"
            cols="12"
          >
            <form-stepper-steps
              :step.sync="step"
              :steps="steps"
            />
          </v-col>

          <v-col
            :class="[{'pa-0': !stepHasCard}]"
            :cols="stepCols"
          >
            <v-card
              :color="stepBgColor"
              class="synthesis-card-border"
              flat
            >
              <v-card-text
                :class="[stepHasCard ? 'pa-sm-4' : 'pa-sm-0']"
                class="pa-0"
              >
                <v-form
                  ref="form"
                  v-model="isFormValid"
                  @submit.prevent
                >
                  <v-window v-model="step">
                    <v-window-item :value="1">
                      <div class="mx-2 mr-sm-4">
                        <v-card
                          class="synthesis-card-border"
                          color="#FFFFF"
                          flat
                        >
                          <v-card-text class="pa-sm-4">
                            <v-container fluid>
                              <v-row>
                                <v-col
                                  class="pb-0"
                                  cols="12"
                                  lg="6"
                                >
                                  <label class="field-label">{{ $t('Hedging.Edit.Form.Fields.Name.Label') }}</label>
                                  <v-text-field
                                    v-model="model.name"
                                    :error-messages="serverErrorMessages['name']"
                                    :placeholder="$t('Hedging.Edit.Form.Fields.Name.Placeholder')"
                                    :rules="model.validator.vuetifyFormFieldRules('name')"
                                    outlined
                                  />
                                </v-col>
                                <v-col
                                  class="pt-0"
                                  cols="12"
                                  lg="6"
                                >
                                  <select-base-currency
                                    v-model="model.baseCurrencyCode"
                                    :disabled="model.exposures.length > 0"
                                    :error-messages="serverErrorMessages['baseCurrencyCode']"
                                    :rules="model.validator.vuetifyFormFieldRules('baseCurrencyCode')"
                                    :use-user-base-currency="false"
                                  />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                        </v-card>

                        <v-container
                          fluid
                          px-0
                          py-y
                        >
                          <v-row>
                            <v-col cols="12">
                              <v-item-group
                                v-model="model.activeAnalysisUsed"
                                mandatory
                              >
                                <v-container
                                  fluid
                                  pa-0
                                >
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      sm="6"
                                    >
                                      <v-item
                                        v-slot="{active, toggle}"
                                        :value="true"
                                      >
                                        <item-group-item-card
                                          :active="active"
                                          :disabled="!activeAnalysisExposures.length"
                                          :item="{Title: 'Current Risk Assets', InfoTip: 'Import all assets used in latest Active Risk Analysis'}"
                                          :toggle="toggle"
                                          large
                                        />
                                      </v-item>
                                    </v-col>
                                    <v-col
                                      cols="12"
                                      sm="6"
                                    >
                                      <v-item
                                        v-slot="{active, toggle}"
                                        :value="false"
                                      >
                                        <item-group-item-card
                                          :active="active"
                                          :item="{Title: 'Select New Assets', InfoTip: 'Choose up to 10 new assets '}"
                                          :toggle="toggle"
                                          large
                                        />
                                      </v-item>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-item-group>
                            </v-col>

                            <v-col cols="12">
                              <item-list-card
                                :actions="!model.activeAnalysisUsed"
                                :items="model.exposuresList"
                                :menu-items="$t('Hedging.Edit.Form.Fields.Exposures.MenuItems')"
                                @list-item-edit-button-click="onExposureListItemEditButtonClick"
                                @list-item-delete-button-click="onExposureListItemDeleteButtonClick"
                              />
                              <dashed-action-button
                                v-if="!model.activeAnalysisUsed"
                                :disabled="isAddExposureDisabled"
                                :title="$t('Hedging.Edit.Form.Fields.Exposures.Label')"
                                @click="onAddExposureClick"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </div>
                    </v-window-item>

                    <v-window-item :value="2">
                      <div class="mx-2 mr-sm-4">
                        <v-card
                          class="synthesis-card-border"
                          color="#FFFFF"
                          flat
                        >
                          <v-card-text class="pa-sm-4">
                            <v-container fluid>
                              <v-row>
                                <v-col
                                  cols="12"
                                  lg="9"
                                >
                                  <label class="field-label">
                                    {{ $t('Hedging.Edit.Form.Fields.MarketRatesUsed.Label') }}
                                    <infotip
                                      v-if="$t('Hedging.Edit.Form.Fields.MarketRatesUsed.InfoTip')"
                                      icon-style="margin-top: -12px"
                                    >
                                      {{ $t('Hedging.Edit.Form.Fields.MarketRatesUsed.InfoTip') }}
                                    </infotip>
                                  </label>
                                  <select-market-parameters
                                    v-model="model.marketRatesUsed"
                                    :asset-params-button="model.carry.length ? 'Edit' : 'Add'"
                                    :rules="model.validator.vuetifyFormFieldRules('marketRatesUsed')"
                                    mandatory
                                    @click:show-rates="onShowRatesClick"
                                    @click:add-asset-params="onAddAssetParamsClick"
                                    @click:edit-asset-params="onEditAssetParamsClick"
                                  />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                        </v-card>

                        <v-card
                          class="synthesis-card-border mt-5"
                          color="#FFFFF"
                          flat
                        >
                          <v-card-text class="pa-sm-4">
                            <v-container fluid>
                              <v-row>
                                <v-col
                                  cols="12"
                                  lg="9"
                                >
                                  <label class="field-label">{{ $t('Hedging.Edit.Form.Fields.HedgingInstrument.Label') }}</label>
                                  <select-hedging-instrument
                                    v-model="selectedHedgingInstrument"
                                    :items="$t('Hedging.Edit.Form.Fields.HedgingInstrument.Items')"
                                    asset-params-button="Add"
                                    mandatory
                                  />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                        </v-card>

                        <v-container
                          ma-0
                          pb-0
                          px-0
                        >
                          <v-row class="ma-0 pa-0">
                            <v-col class="ma-0 pa-0">
                              <v-btn
                                :disabled="!isStepValid"
                                class="synthesis-text text-size-14 white--text"
                                color="synthesis-ui-green-01"
                                depressed
                                height="42"
                                large
                                @click="createOrRerunAnalysis"
                              >
                                {{ $t('Hedging.Edit.Form.Button.RunAnalysis') }}
                              </v-btn>
                            </v-col>
                            <v-col class="ma-0 pa-0 text-right">
                              <export-to-image
                                v-if="elementHedgingInstrumentBlock"
                                key="ExportToImage_HedgingInstrumentBlock"
                                :export-element="elementHedgingInstrumentBlock"
                                :export-file-name="$t('Hedging.Edit.Form.Steps.Step2.Title')"
                                :text="$t('Common.Button.Export')"
                                col-padding="py-0 pr-0"
                                color="synthesis-ui-blue-04"
                                color-hover="synthesis-ui-blue-03"
                                dark
                                large
                                no-container-padding
                                no-margin
                                @export-to-image="exportToImage"
                                @export-to-image-completed="exportToImageCompleted"
                              />
                            </v-col>
                          </v-row>
                        </v-container>

                        <div
                          v-if="model.policyId && !loaderVisible"
                          class="mt-4"
                        >
                          <hedging-instrument-block
                            ref="hedgingInstrumentBlock"
                            :chart-animation="chartAnimation"
                            :disable-lazy="disableLazy"
                            :instrument-type="selectedHedgingInstrumentValue"
                            :policy-id="model.policyId"
                          />
                        </div>
                      </div>
                    </v-window-item>

                    <v-window-item :value="3">
                      <div class="mx-2 mr-sm-4">
                        <v-card
                          class="synthesis-card-border"
                          color="#FFFFF"
                          flat
                        >
                          <v-card-text class="pa-sm-4">
                            <v-container fluid>
                              <v-row>
                                <v-col
                                  cols="12"
                                  sm="8"
                                >
                                  <label class="field-label">
                                    {{ $t('Hedging.Edit.Form.Fields.MarketRatesUsed.Label') }}
                                    <infotip
                                      v-if="$t('Hedging.Edit.Form.Fields.MarketRatesUsed.InfoTip')"
                                      icon-style="margin-top: -12px"
                                    >
                                      {{ $t('Hedging.Edit.Form.Fields.MarketRatesUsed.InfoTip') }}
                                    </infotip>
                                  </label>
                                  <select-market-parameters
                                    v-model="model.marketRatesUsed"
                                    :asset-params-button="model.carry.length ? 'Edit' : 'Add'"
                                    :rules="model.validator.vuetifyFormFieldRules('marketRatesUsed')"
                                    mandatory
                                    @click:show-rates="onShowRatesClick"
                                    @click:add-asset-params="onAddAssetParamsClick"
                                    @click:edit-asset-params="onEditAssetParamsClick"
                                  />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                        </v-card>

                        <v-container
                          ma-0
                          pb-0
                          px-0
                        >
                          <v-row class="ma-0 pa-0">
                            <v-col class="ma-0 pa-0">
                              <v-btn
                                :disabled="!isStepValid"
                                class="synthesis-text text-size-14 white--text"
                                color="synthesis-ui-green-01"
                                depressed
                                height="42"
                                large
                                @click="createOrRerunAnalysis"
                              >
                                {{ $t('Hedging.Edit.Form.Button.RunAnalysis') }}
                              </v-btn>
                            </v-col>
                            <v-col class="ma-0 pa-0 text-right">
                              <export-to-image
                                v-if="elementHedgingInstrumentResultsBlock"
                                key="ExportToImage_HedgingInstrumentResultsBlock"
                                :export-element="elementHedgingInstrumentResultsBlock"
                                :export-file-name="$t('Hedging.Edit.Form.Steps.Step3.Title')"
                                :text="$t('Common.Button.Export')"
                                col-padding="py-0 pr-0"
                                color="synthesis-ui-blue-04"
                                color-hover="synthesis-ui-blue-03"
                                dark
                                large
                                no-container-padding
                                no-margin
                                @export-to-image="exportToImage"
                                @export-to-image-completed="exportToImageCompleted"
                              />
                            </v-col>
                          </v-row>
                        </v-container>

                        <div
                          v-if="model.policyId && !loaderVisible"
                          class="mt-4"
                        >
                          <hedging-instrument-results-block
                            v-if="step===3"
                            ref="hedgingInstrumentResultsBlock"
                            :chart-animation="chartAnimation"
                            :disable-lazy="disableLazy"
                            :policy-id="model.policyId"
                          />
                        </div>
                      </div>
                    </v-window-item>

                    <v-window-item :value="4">
                      <div class="mx-2 mr-sm-4">
                        <div
                          v-if="model.policyId && !loaderVisible"
                          class="mt-4"
                        >
                          <hedging-costs-tables-block :policy-id="model.policyId" />
                        </div>
                      </div>
                    </v-window-item>

                    <v-window-item :value="5">
                      <div class="mx-2 mr-sm-4">
                        <div
                          v-if="model.policyId && !loaderVisible"
                          class="mt-4"
                        >
                          <v-row>
                            <v-col cols="12">
                              <select-natural-hedging v-model="model.includeNaturalHedging" />
                            </v-col>
                          </v-row>
                          <hedging-summary-list-block
                            :policy-id="model.policyId"
                            show-toolbar-legend
                            show-toolbar-title
                          />
                        </div>
                      </div>
                    </v-window-item>
                  </v-window>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        class="text-right"
        cols="12"
        lg="4"
        md="5"
        sm="12"
        xl="2"
      >
        <form-stepper
          :step.sync="step"
          :steps="steps"
          class="d-inline-flex"
          @click-btn-action="onStepperBtnActionClick"
          @click-btn-cancel="onStepperBtnCancelClick"
          @click-btn-back="onStepperBtnBackClick"
        />
      </v-col>
    </v-row>

    <add-hedging-exposure-dialog
      :base-currency-code="model.baseCurrencyCode"
      :exposures-list="model.exposures"
      :item="selectedEditItem"
      :visible.sync="exposuresDialogVisible"
      component-lang="Hedging"
      @form:save:success="onExposureSave"
      @dialog:close="onExposureDialogClose"
    />

    <add-asset-parameters-dialog
      :carry="addAssetParamsDialogModel"
      :component-lang="`Hedging.AssetParameters.${addAssetParamsDialogMode}`"
      :mode="addAssetParamsDialogMode"
      :step="step"
      :strike-moneyness-option="model.strikeMoneynessOption"
      :strike-moneyness-risk-reversal="model.strikeMoneynessRiskReversal"
      :visible.sync="addAssetParamsDialogVisible"
      @form:save:success="onAssetsParamsSave"
    />

    <overlay-loader :value="loaderVisible" />
  </v-container>
</template>

<script>

import i18nRouteMeta                    from '@/mixins/i18n/i18nRouteMeta'
import SectionHeader                    from '@/theme/default/components/common/SectionHeader'
import OverlayLoader                    from '@/theme/default/components/common/OverlayLoader'
import HedgingPolicyModel               from '@/api/models/hedging/HedgingPolicyModel'
import formData                         from '@/mixins/form/formData'
import assetExposureCommon              from '@/mixins/assetExposureCommon'
import formStepper                      from '@/theme/default/components/form/formStepper/mixins/formStepper'
import FormStepperSteps                 from '@/theme/default/components/form/formStepper/components/FormStepperSteps'
import FormStepper                      from '@/theme/default/components/form/formStepper/FormStepper'
import SelectBaseCurrency               from '@/theme/default/components/form/SelectBaseCurrency'
import ItemListCard                     from '@/theme/default/components/common/ItemListCard'
import DashedActionButton               from '@/theme/default/components/common/DashedActionButton'
import ItemGroupItemCard                from '@/theme/default/components/common/ItemGroupItemCard'
import API                              from '@/api/Api'
import { collect }                      from '@/lib/utils/array'
import SelectMarketParameters           from '@/theme/default/views/hedging/edit/SelectMarketParameters'
import SelectHedgingInstrument          from '@/theme/default/views/hedging/edit/SelectHedgingInstrument'
import AddAssetParametersDialog         from '@/theme/default/views/hedging/edit/AddAssetParametersDialog'
import { clone }                        from '@/lib/utils/helper'
import HedgingCarryPerAssetResource     from '@/api/resources/hedging/HedgingCarryPerAssetResource'
import HedgingSummaryResource           from '@/api/resources/hedging/HedgingSummaryResource'
import HedgingInstrumentResource        from '@/api/resources/hedging/HedgingInstrumentResource'
import HedgingSummaryListBlock          from '@/theme/default/views/hedging/blocks/HedgingSummaryListBlock'
import HedgingInstrumentBlock           from '@/theme/default/views/hedging/blocks/HedgingInstrumentBlock'
import HedgingInstrumentResultsBlock    from '@/theme/default/views/hedging/blocks/HedgingInstrumentResultsBlock'
import HedgingCostsTablesBlock          from '@/theme/default/views/hedging/blocks/HedgingCostsTablesBlock'
import HedgingCostsResource             from '@/api/resources/hedging/HedgingCostsResource'
import InstrumentTypeEnum               from '@/api/enums/InstrumentTypeEnum'
import CurrentRiskAssetExposureResource from '@/api/resources/asset/CurrentRiskAssetExposureResource'
import AddHedgingExposureDialog         from '@/theme/default/views/hedging/AddHedgingExposureDialog'
import ExportToImage                    from '@/theme/default/components/common/ExportToImage'
import Infotip                          from '@/theme/default/components/common/Infotip'
import SelectNaturalHedging             from '@/theme/default/views/hedging/edit/SelectNaturalHedging'

export default {
  name      : 'HedgingAnalysisNew',
  components: {
    SelectNaturalHedging,
    Infotip,
    ExportToImage,
    AddHedgingExposureDialog,
    HedgingCostsTablesBlock,
    HedgingInstrumentResultsBlock,
    HedgingInstrumentBlock,
    HedgingSummaryListBlock,
    AddAssetParametersDialog,
    SelectHedgingInstrument,
    SelectMarketParameters,
    ItemGroupItemCard,
    DashedActionButton,
    ItemListCard,
    SelectBaseCurrency,
    FormStepper,
    FormStepperSteps,
    OverlayLoader,
    SectionHeader
  },
  directives: {},
  mixins    : [i18nRouteMeta, assetExposureCommon, formData, formStepper],
  props     : {},
  dataStore : {
    hedgingSummaryData: 'Hedging.Summary.Data',
    hedgingCostsData  : 'Hedging.Costs.Data',
    hedgingInstrument : 'Hedging.Instrument'
  },
  enums    : {},
  dataModel: HedgingPolicyModel,
  data () {
    return {
      isFormDataDirty                 : false,
      loaderVisible                   : false,
      addAssetParamsDialogVisible     : false,
      selectedEditItem                : null,
      addAssetParamsDialogModel       : [],
      addAssetParamsDialogMode        : 'Add',
      showCurrentAssets               : null,
      activeAnalysisExposures         : [],
      activeAnalysisBaseCurrencySymbol: null,
      selectedHedgingInstrument       : null,
      breadcrumbItems                 : [
        {
          text    : this.$t('Hedging.Title'),
          disabled: false,
          exact   : true,
          to      : { name: 'Hedging' }
        },
        {
          text    : this.$t('Hedging.New.Title'),
          disabled: true
        }
      ],
      elementHedgingInstrumentBlock       : null,
      elementHedgingInstrumentResultsBlock: null,
      chartAnimation                      : true,
      disableLazy                         : false
    }
  },
  computed: {
    steps () {
      return [
        {
          Title    : this.$t('Hedging.Edit.Form.Steps.Step1.Title'),
          Complete : this.step > 1,
          HasCard  : false,
          Cols     : 11,
          BgColor  : 'transparent',
          Fields   : ['name', 'baseCurrencyCode', 'mode', 'exposures'],
          BtnAction: {
            Title         : this.$t('Common.Button.Continue'),
            CallBack      : this.handleStep1,
            PreventDefault: true
          }
        },
        {
          Title   : this.$t('Hedging.Edit.Form.Steps.Step2.Title'),
          Complete: this.step > 2,
          HasCard : false,
          BgColor : 'transparent',
          Fields  : ['carry']
        },
        {
          Title    : this.$t('Hedging.Edit.Form.Steps.Step3.Title'),
          Complete : this.step > 3,
          HasCard  : false,
          BgColor  : 'transparent',
          Fields   : ['carry'],
          BtnAction: {
            Title         : this.$t('Common.Button.Continue'),
            CallBack      : this.createOrRerunAnalysis,
            PreventDefault: false
          }
        },
        {
          Title   : this.$t('Hedging.Edit.Form.Steps.Step4.Title'),
          Complete: this.step > 4,
          HasCard : false,
          BgColor : 'transparent',
          Fields  : []
        },
        {
          Title    : this.$t('Hedging.Edit.Form.Steps.Step5.Title'),
          Complete : this.step > 5,
          HasCard  : false,
          BgColor  : 'transparent',
          Fields   : [],
          BtnAction: {
            Title         : this.$t('Hedging.Edit.Form.Steps.Step5.Button.Action'),
            CallBack      : this.persistAnalysis,
            PreventDefault: true
          }
        }
      ]
    },

    selectedHedgingInstrumentValue () {
      if (this.selectedHedgingInstrument === 'activeOptions') return InstrumentTypeEnum.OPTION
      if (this.selectedHedgingInstrument === 'activeRiskReversal') return InstrumentTypeEnum.COLLAR
      return InstrumentTypeEnum.FORWARD
    }
  },
  watch: {
    selectedHedgingInstrument (newVal) {
      if (!newVal) return

      this.model.activeForwards = true
      this.model.activeOptions = true
      this.model.activeRiskReversal = true
      this.model[newVal] = true
    },

    model: {
      handler (newVal) {
        this.isFormDataDirty = true
      },
      deep: true
    },

    'model.includeNaturalHedging': function (newVal) {
      this.createOrRerunAnalysis()
    },

    'model.marketRatesUsed': function (newVal) {
      if (newVal) {
        this.model.carry = []
        this.model.strikeMoneynessOption = ''
        this.model.strikeMoneynessRiskReversal = ''
      }
    },

    'model.activeAnalysisUsed': function (newVal) {
      if (newVal) {
        if (this.activeAnalysisExposures && this.activeAnalysisExposures.length) {
          this.model.exposures = collect(this.activeAnalysisExposures, CurrentRiskAssetExposureResource)
          this.model.baseCurrencyCode = this.activeAnalysisBaseCurrencySymbol
        }
      } else {
        this.model.exposures = []
        this.model.baseCurrencyCode = this.user?.selectedAccountBaseCurrency?.symbol || ''
      }
    }
  },
  beforeCreate () {},
  created () {
    this.model.accountId = this.user.selectedAccountId

    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.deleteStorageDataById()
  },
  destroyed () {},
  methods: {
    setExportElements () {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.elementHedgingInstrumentBlock = this.$refs?.hedgingInstrumentBlock?.$el
            this.elementHedgingInstrumentResultsBlock = this.$refs?.hedgingInstrumentResultsBlock?.$el
          })
        })
      })
    },

    onStepperBtnActionClick (step) {
      if (step === 2 || step === 3) {
        this.setExportElements()
      }
    },

    onStepperBtnBackClick (from, to) {
      if (to < 2) this.deleteStorageDataById()
    },

    onStepperBtnCancelClick () {
      this.$router.push({ name: 'Hedging' })
    },

    onShowRatesClick () {
      if (!this.showCurrentAssets) return
      this.addAssetParamsDialogModel = collect(this.showCurrentAssets.carryPerAsset, HedgingCarryPerAssetResource)
      this.model.strikeMoneynessOption = this.showCurrentAssets.strikeMoneynessOption
      this.model.strikeMoneynessRiskReversal = this.showCurrentAssets.strikeMoneynessRiskReversal
      this.addAssetParamsDialogMode = 'Show'
      this.addAssetParamsDialogVisible = true
    },

    onAddAssetParamsClick () {
      this.addAssetParamsDialogModel = collect(this.showCurrentAssets.carryPerAsset, HedgingCarryPerAssetResource)
      this.model.strikeMoneynessOption = this.showCurrentAssets.strikeMoneynessOption
      this.model.strikeMoneynessRiskReversal = this.showCurrentAssets.strikeMoneynessRiskReversal
      this.addAssetParamsDialogMode = 'Add'
      this.addAssetParamsDialogVisible = true
    },

    onEditAssetParamsClick () {
      let exposures = collect(this.model.exposures, HedgingCarryPerAssetResource)
      exposures = exposures.map(exposure => {
        exposure.carry = this.model.carry.find(c => c.assetCode === exposure.assetCode)?.carry || ''
        return exposure
      })

      this.addAssetParamsDialogModel = collect(exposures, HedgingCarryPerAssetResource)
      this.addAssetParamsDialogMode = 'Edit'
      this.addAssetParamsDialogVisible = true
    },

    onAssetsParamsSave (data) {
      this.model.carry = data.carry
      this.model.strikeMoneynessOption = data.strikeMoneynessOption
      this.model.strikeMoneynessRiskReversal = data.strikeMoneynessRiskReversal
    },

    getData () {
      API.Resource.Risks.CurrentAssets(this.model.accountId)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },
    handleResponse (response) {
      const data = API.responseData(response)?.activeAnalysis || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        if (data.exposures && data.baseCurrency) {
          data.exposures = data.exposures.map(exposure => {
            exposure.baseCurrency = data.baseCurrency
            return exposure
          })
          this.model.baseCurrencyCode = this.activeAnalysisBaseCurrencySymbol = data.baseCurrency.symbol
          this.activeAnalysisExposures = data.exposures
          this.model.exposures = collect(data.exposures, CurrentRiskAssetExposureResource)
        } else {
          this.model.activeAnalysisUsed = false
          this.activeAnalysisExposures = []
          this.activeAnalysisBaseCurrencySymbol = null
          this.model.exposures = []
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    handleStep1 () {
      /*
      if (this.model.activeAnalysisUsed && this.model.hasExcludedExposureAssets()) {
        this.$bus.$emit('app:error', [
          { custom: { message: 'Zero net exposure: no Hedging Analysis for this asset.' } }
        ])
      } else {
        this.getRates()
        this.step = 2
      }
       */

      this.getRates()
      this.step = 2
    },

    getRates () {
      API.Resource.Hedging.Rates({
        accountId       : this.model.accountId,
        baseCurrencyCode: this.model.baseCurrencyCode,
        assets          : clone(this.model.exposures).map(exposure => exposure.assetCode)
      })
        .then(response => {
          this.handleRatesResponse(response)
        })
        .catch(e => {
          this.handleRatesResponse(e.response)
        })
        .finally(() => {})
    },
    handleRatesResponse (response) {
      const data = API.responseData(response)?.showRates || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.showCurrentAssets = data
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    createOrRerunAnalysis () {
      if (this.model.policyId && !this.isFormDataDirty) return

      this.loaderVisible = true

      this.model.CreateAnalysis()
        .then(response => {
          this.handleCreateOrRerunAnalysisResponse(response)
        })
        .catch(e => {
          this.handleCreateOrRerunAnalysisResponse(e.response)
        })
        .finally(() => {
          this.loaderVisible = false
        })
    },
    handleCreateOrRerunAnalysisResponse (response) {
      const data = API.responseData(response)?.createPolicy || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const o = clone(data)
        const v = new HedgingSummaryResource(o || null)

        // Store data to DataStore
        this.$set(this.hedgingSummaryData, o.id, v)

        // Store instrument types to DataStore
        o?.result?.forwards && this.$set(this.hedgingInstrument.Forward.Data, o.id, new HedgingInstrumentResource(o?.result?.forwards))
        o?.result?.options && this.$set(this.hedgingInstrument.Option.Data, o.id, new HedgingInstrumentResource(o?.result?.options))
        o?.result?.riskReversal && this.$set(this.hedgingInstrument.Collar.Data, o.id, new HedgingInstrumentResource(o?.result?.riskReversal))

        // Hedging Costs
        o?.result?.costs && this.$set(this.hedgingCostsData, o.id, new HedgingCostsResource(o?.result?.costs))

        this.model.policyId = data.id
        this.$nextTick(() => {
          this.isFormDataDirty = false

          this.setExportElements()
        })
      } else {
        this.step--
        this.$bus.$emit('app:error', errors)
      }
    },

    persistAnalysis () {
      this.isFormSaving = true

      this.model.PersistAnalysis()
        .then(response => {
          this.handlePersistAnalysisResponse(response)
        })
        .catch(e => {
          this.handlePersistAnalysisResponse(e.response)
        })
        .finally(() => {
          this.isFormSaving = false
        })
    },
    handlePersistAnalysisResponse (response) {
      const data = API.responseData(response)?.persistAnalysis || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.$router.push({ name: 'Hedging' })
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    deleteStorageDataById () {
      this.$delete(this.hedgingSummaryData, this.model.policyId)
      this.$delete(this.hedgingInstrument.Forward.Data, this.model.policyId)
      this.$delete(this.hedgingInstrument.Option.Data, this.model.policyId)
      this.$delete(this.hedgingInstrument.Collar.Data, this.model.policyId)
      this.$delete(this.hedgingCostsData, this.model.policyId)

      this.model.policyId = null
    },

    exportToImage () {
      this.chartAnimation = false
      this.disableLazy = true
    },

    exportToImageCompleted () {
      this.chartAnimation = true
    }
  }
}
</script>

<style scoped>

</style>
