import Vue              from 'vue'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import Bugsnag          from '@bugsnag/js'
import { EventBus }     from '@/lib/events/eventBus'

export const setupBugsnag = (BSConfig, options = {}) => {
  const BugsnagEnabled = BSConfig?.enabled ?? false
  const BugsnagKey = BSConfig?.key ?? ''
  const BugsnagEnv = BSConfig?.environments || ['production', 'staging']

  const defaultOptions = {
    plugins          : [new BugsnagPluginVue(Vue)],
    apiKey           : BugsnagKey,
    appType          : 'browser',
    appVersion       : process.env.VUE_APP_VERSION || '1.0.0',
    autoDetectErrors : true,
    autoTrackSessions: true,
    collectUserIp    : true,
    enabledErrorTypes: {
      unhandledExceptions: true,
      unhandledRejections: true
    },
    enabledReleaseStages: BugsnagEnv,
    releaseStage        : process.env.VUE_APP_ENV,
    generateAnonymousId : true,
    metaData            : {
      project: {
        name: process.env.VUE_APP_NAME || ''
      }
    },
    onError: function (event) {
      // Adjust event here
    },
    onSession: function (session) {
      // session.setUser(userId)
    },
    redactedKeys: [
      'access_token', // exact match: "access_token"
      /^password$/i, // case-insensitive: "password", "PASSWORD", "PaSsWoRd"
      /^cc_/ // prefix match: "cc_number" "cc_cvv" "cc_expiry"
    ],
    trackInlineScripts: true
  }

  const initOptions = { ...defaultOptions, ...options }

  if (BugsnagEnabled && initOptions && initOptions.apiKey) {
    Bugsnag.start(initOptions)
    EventBus.$on('Auth:Login:Success', onUserLogin)
    // Bugsnag.notify(new Error('Test error'))
  }
}

const onUserLogin = (user) => {
  user?.id && Bugsnag.setUser(user.id, user.email, user.userName)
}
