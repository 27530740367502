import BaseResource           from '@/lib/data/resource/BaseResource'
import AssetModel             from '@/api/models/asset/AssetModel'
import AddressModel           from '@/api/models/user/AddressModel'
import CountryModel           from '@/api/models/user/CountryModel'
import IndustryModel          from '@/api/models/user/IndustryModel'
import CorporateGroupResource from '@/api/resources/user/CorporateGroupResource'

export default class CompanyResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.id = payload.id ?? null
    this.fullName = payload.fullName ?? ''
    this.shortName = payload.shortName ?? ''
    this.registeredAt = new CountryModel(payload.registeredAt ?? null)
    this.baseCurrency = new AssetModel(payload.baseCurrency ?? null)
    this.industry = new IndustryModel(payload.industry ?? null)
    this.address = new AddressModel(payload.address ?? null)
    this.corporateGroup = new CorporateGroupResource(payload.corporateGroup ?? null)
  }

  /* PROPERTIES */

  /* METHODS */
}
