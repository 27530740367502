export default {
  props: {
    comparisonId: {
      type   : String,
      default: ''
    }
  },
  computed: {
    id () {
      return this.comparisonId || this.$route.params.id
    }
  }
}
