import BaseResource    from '@/lib/data/resource/BaseResource'
import { collect }     from '@/lib/utils/array'
import ArticleResource from '@/api/resources/knowledgeCenter/ArticleResource'

export default class ArticleListResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Items = payload.articleSummaries ? collect(payload.articleSummaries, ArticleResource) : []
    this.Total = payload.total ?? 0
    this.Page = payload.page ?? 1
    this.ItemsPerPage = payload.itemsPerPage ?? 8
  }

  /* PROPERTIES */

  /* METHODS */
}
