import BaseResource                    from '@/lib/data/resource/BaseResource'
import i18n                            from '@/lang/lang'
import { toNumberFormatNotationShort } from '@/lib/utils/helper'

export default class RiskDebtFixedFloatingMixItemsResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.AssetValue = payload?.year ?? ''
    this.AfterHedgingFixed = payload?.afterHedgingFixed ?? 0
    this.AfterHedgingFloating = payload?.afterHedgingFloating ?? 0
    this.BeforeHedgingFixed = payload?.beforeHedgingFixed ?? 0
    this.BeforeHedgingFloating = payload?.beforeHedgingFloating ?? 0
    this.TotalDebtOutstanding = payload?.totalDebtOutstanding ?? 0
  }

  /* PROPERTIES */
  get Data () {
    return {
      BeforeHedging: [
        {
          name : i18n.t('Risks.Section.DebtProfile.Block.FixedVsFloating.Fixed.Title'),
          value: this.BeforeHedgingFixed,
          type : 'number'
        },
        {
          name : i18n.t('Risks.Section.DebtProfile.Block.FixedVsFloating.Floating.Title'),
          value: this.BeforeHedgingFloating,
          type : 'number'
        }
      ],
      AfterHedging: [
        {
          name : i18n.t('Risks.Section.DebtProfile.Block.FixedVsFloating.Fixed.Title'),
          value: this.AfterHedgingFixed,
          type : 'number'
        },
        {
          name : i18n.t('Risks.Section.DebtProfile.Block.FixedVsFloating.Floating.Title'),
          value: this.AfterHedgingFloating,
          type : 'number'
        }
      ],
      TotalDebtOutstandingValueFmt: toNumberFormatNotationShort(this.TotalDebtOutstanding)
    }
  }

  /* METHODS */
}
