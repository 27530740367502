import BaseResource from '@/lib/data/resource/BaseResource'

export default class AssetResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.name = payload.name ?? ''
    this.symbol = payload.symbol ?? ''
    this.displaySymbol = payload.displaySymbol ?? ''
    this.displayName = payload.displayName ?? ''
    this.type = payload.type ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
