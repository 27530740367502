export default {
  name: 'RouterTabState',
  data () {
    return {
      routerTabStateActiveTab   : null,
      routerTabStateActiveSubTab: null
    }
  },
  computed: {
    activeTab: {
      set (tab) {
        this.routerTabStateActiveTab = tab
        this.routerTabStateActiveSubTab = null

        if (this.$route.params.tab) {
          this.$router.push({
            params: {
              ...this.$route.params,
              tab   : tab || null,
              subTab: null
            }
          })
        } else {
          this.$router.replace({
            params: {
              ...this.$route.params,
              tab   : tab || null,
              subTab: null
            }
          })
        }
      },
      get () {
        return this.$route.params.tab || this.routerTabStateActiveTab
      }
    },

    activeSubTab: {
      set (tab) {
        this.routerTabStateActiveSubTab = tab
        if (this.$route.params.subTab) {
          this.$router.push({
            params: {
              ...this.$route.params,
              subTab: tab || null
            }
          })
        } else {
          this.$router.replace({
            params: {
              ...this.$route.params,
              subTab: tab || null
            }
          })
        }
      },
      get () {
        return this.$route.params.subTab || this.routerTabStateActiveSubTab
      }
    }
  }
}
