<template>
  <v-text-field
    v-model="cmpValue"
    :background-color="backgroundColor"
    :clearable="clearable"
    :dense="dense"
    :disabled="disabled"
    :error="error"
    :error-messages="errorMessages"
    :hide-details="hideDetails"
    :label="label"
    :outlined="outlined"
    :placeholder="placeholder"
    :prefix="options.prefix"
    :readonly="readonly"
    :rules="rules"
    :suffix="options.suffix"
    v-bind="properties"
    @blur="onBlur"
    @keypress="keyPress"
  />
</template>

<script>
/**
 * Sample Usage
 *
 *    <v-text-field-currency
 *        v-model="model.notional"
 *        :options="{
 *                           locale: $i18n.code,
 *                           prefix: model.selectedItem.pair.base.type === 'Currency' ? model.selectedItem.quotation.displayUnit : '',
 *                           suffix: model.selectedItem.pair.base.type === 'Commodity' ? model.selectedItem.quotation.displayUnit : '',
 *                           length: 11,
 *                           precision: 0
 *                         }"
 *        :properties="{
 *                           flat: true,
 *                           solo: true,
 *                           height: '70',
 *                         }"
 *        :readonly="model.userProvidedSeasonality"
 *        :rules="model.validator.vuetifyFormFieldRules('notional')"
 *        class="annual-exposure"
 *        hide-details="auto"
 *        placeholder="0"
 *    />
 */
import i18n from '@/lang/lang'

export default {
  name : 'VTextFieldCurrency',
  model: {
    prop : 'value',
    event: 'input'
  },
  props: {
    value: {
      type   : [String, Number],
      default: '0'
    },
    label: {
      type   : String,
      default: ''
    },
    placeholder: {
      type   : String,
      default: undefined
    },
    readonly: {
      type   : Boolean,
      default: false
    },
    dense: {
      type   : Boolean,
      default: false
    },
    error: {
      type   : Boolean,
      default: false
    },
    hideDetails: {
      type   : [Boolean, String],
      default: false
    },
    errorMessages: {
      type   : [Array, String],
      default: () => []
    },
    rules: {
      type   : [Array, String],
      default: () => []
    },
    disabled: {
      type   : Boolean,
      default: false
    },
    outlined: {
      type   : Boolean,
      default: false
    },
    clearable: {
      type   : Boolean,
      default: false
    },
    backgroundColor: {
      type   : String,
      default: 'white'
    },
    valueWhenIsEmpty: {
      type   : String,
      default: '' // "0" or "" or null
    },
    valueOptions: {
      type   : Object,
      default: function () {
        return {
          min     : 0,
          minEvent: 'SetValueMin'
        }
      }
    },
    options: {
      type   : Object,
      default: function () {
        return {
          locale   : i18n.code,
          prefix   : '',
          suffix   : '',
          length   : 11,
          precision: 2
        }
      }
    },
    // Other v-text-field properties
    properties: {
      type   : Object,
      default: function () {
        return {}
      }
    }
  },
  data    : () => ({}),
  computed: {
    cmpValue: {
      get: function () {
        return this.value !== null && this.value !== ''
          ? this.humanFormat(this.value.toString())
          : this.valueWhenIsEmpty
      },
      set: function (newValue) {
        this.$emit('input', this.machineFormat(newValue))
      }
    }
  },
  methods: {
    humanFormat: function (number) {
      if (isNaN(number)) {
        number = ''
      } else {
        number = Number(number).toLocaleString(this.options.locale, {
          maximumFractionDigits: this.options.precision,
          minimumFractionDigits: this.options.precision
        })
      }
      return number
    },

    machineFormat (number) {
      if (number) {
        number = this.cleanNumber(number)
        number = number.padStart(parseInt(this.options.precision) + 1, '0')
        number = number.substring(0, number.length - parseInt(this.options.precision)) + '.' + number.substring(number.length - parseInt(this.options.precision), number.length)
        if (isNaN(number)) {
          number = this.valueWhenIsEmpty
        }
      } else {
        number = this.valueWhenIsEmpty
      }
      if (this.options.precision === 0) {
        number = this.cleanNumber(number)
      }
      return number
    },

    keyPress ($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault()
      }
      if (this.targetLength()) {
        $event.preventDefault()
      }
    },

    cleanNumber: function (value) {
      let result = ''
      if (value) {
        let flag = false
        const arrayValue = value.toString().split('')
        for (let i = 0; i < arrayValue.length; i++) {
          if (this.isInteger(arrayValue[i])) {
            if (!flag) {
              if (arrayValue[i] !== '0') {
                result = result + arrayValue[i]
                flag = true
              }
            } else {
              result = result + arrayValue[i]
            }
          }
        }
      }
      return result
    },

    isInteger (value) {
      let result = false
      if (Number.isInteger(parseInt(value))) {
        result = true
      }
      return result
    },

    targetLength () {
      if (Number(this.cleanNumber(this.value).length) >= Number(this.options.length)) {
        return true
      } else {
        return false
      }
    },

    onBlur () {
      if (this.value.length === 0 || parseFloat(this.value) <= this.valueOptions.min) {
        this.$emit(this.valueOptions.minEvent || 'SetValueMin', this.valueOptions.min)
      }

      if (this.valueOptions.max && parseFloat(this.value) >= this.valueOptions.max) {
        this.$emit(this.valueOptions.maxEvent || 'SetValueMax', this.valueOptions.max)
      }
    }
  }
}
</script>
