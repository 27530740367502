import BaseResource                    from '@/lib/data/resource/BaseResource'
import AssetModel                      from '@/api/models/asset/AssetModel'
import { toNumberFormatNotationShort } from '@/lib/utils/helper'
import AssetEnum                       from '@/api/enums/AssetEnum'
import i18n                            from '@/lang/lang'

export default class DashboardRiskSummaryItemResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Asset = payload.asset ?? ''
    this.Label = payload.label ?? ''
    this.Notional = payload.notional ?? ''
    this.HedgedRisk = payload.hedgedRisk ?? ''
    this.UnhedgedRisk = payload.unhedgedRisk ?? ''
    this.DetailedAsset = new AssetModel(payload.detailedAsset ?? null)
    this.BaseCurrency = new AssetModel(payload.baseCurrency ?? null)
    this.DisplayUnit = payload.quotation?.displayUnit || null
  }

  /* PROPERTIES */
  get BaseCurrencyTitle () {
    return this.baseCurrencySymbolSingle() ? this.getBaseCurrencySymbolSingle() : this.getBaseCurrencySymbolSingle() + ' '
  }

  get BaseCurrencyDisplayName () {
    return this.BaseCurrency?.displayName || ''
  }

  get BaseCurrencyDisplaySymbol () {
    return this.BaseCurrency?.displaySymbol || ''
  }

  get DetailedAssetType () {
    return this.DetailedAsset?.type || ''
  }

  get NotionalFmt () {
    return this.Notional ? `${ toNumberFormatNotationShort(this.Notional) }${ this.getNotionalSuffix() }` : ''
  }

  get HedgedRiskFmt () {
    return this.HedgedRisk ? this.BaseCurrencyTitle + toNumberFormatNotationShort(this.HedgedRisk) : '-'
  }

  get UnhedgedRiskFmt () {
    return this.UnhedgedRisk ? this.BaseCurrencyTitle + toNumberFormatNotationShort(this.UnhedgedRisk) : '-'
  }

  /* METHODS */
  getNotionalSuffix () {
    return this.DetailedAssetType === AssetEnum.COMMODITY ? ` ${ this.DisplayUnit.toLocaleLowerCase(i18n.code) }` : ''
  }

  baseCurrencySymbolSingle () {
    return this.BaseCurrencyDisplaySymbol.length === 1
  }

  getBaseCurrencySymbolSingle () {
    return this.baseCurrencySymbolSingle() ? this.BaseCurrencyDisplaySymbol : this.BaseCurrencyDisplayName
  }
}
