<template>
  <v-container fluid>
    <v-row>
      <section-header :breadcrumb-items="breadcrumb" />
    </v-row>
    <v-row>
      <v-col cols="12">
        <comparison-table-summary
          :comparison-id="comparisonId"
          :expand-all-fields="expandAllFields"
        >
          <template #toolbarActions>
            <v-spacer />
            <div class="d-flex fill-height align-end justify-end">
              <export-to-csv
                v-if="summary"
                :export-data="exportData"
                :export-file-name="title"
                :text="$t('Common.Button.Export')"
                class="pr-6"
                color="synthesis-ui-blue-04"
                color-hover="synthesis-ui-blue-03"
                dark
                large
                no-padding
              />
              <v-btn
                class="synthesis-text text-size-14 white--text"
                color="synthesis-ui-green-01"
                depressed
                height="42"
                large
                @click="toggleExpandAllFields"
              >
                {{ $t(`Industry.Button.${ expandAllFields ? 'Retract' : 'Expand' }.Title`) }}
              </v-btn>
            </div>
          </template>
        </comparison-table-summary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta             from '@/mixins/i18n/i18nRouteMeta'
import SectionHeader             from '@/theme/default/components/common/SectionHeader'
import comparisonId              from '@/theme/default/views/industry/mixins/comparisonId'
import API                       from '@/api/Api'
import { clone }                 from '@/lib/utils/helper'
import ComparisonTableSummary    from '@/theme/default/views/industry/blocks/ComparisonTableSummary'
import ComparisonSummaryResource from '@/api/resources/industry/ComparisonSummaryResource'
import ExportToCsv               from '@/theme/default/components/common/ExportToCsv'

export default {
  name      : 'IndustryComparisonShow',
  components: {
    ExportToCsv,
    ComparisonTableSummary,
    SectionHeader
  },
  directives: {},
  mixins    : [i18nRouteMeta, comparisonId],
  props     : {},
  dataStore : {
    comparisonSummaryData: 'Industry.Summary.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      expandAllFields: false
    }
  },
  computed: {
    summary () {
      return this.comparisonSummaryData[this.id] || null
    },

    exportData () {
      return this.summary?.ExportData || []
    },

    title () {
      return this.summary?.Name || ''
    },

    breadcrumb () {
      return [{
        text    : this.$t('Industry.Title'),
        disabled: false,
        exact   : true,
        to      : { name: 'Industry' }
      }, {
        text    : this.title,
        disabled: true
      }]
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    toggleExpandAllFields () {
      this.expandAllFields = !this.expandAllFields
    },

    getData () {
      API.Resource.Industry.Summary({
        comparisonId   : this.id,
        sortAttributeId: null,
        sortDirection  : null
      })
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },

    handleResponse (response) {
      const data = API.responseData(response)?.findCompanyComparison || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const o = clone(data)
        const v = new ComparisonSummaryResource(o)

        // Store data to DataStore
        this.$set(this.comparisonSummaryData, this.id, v)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>

</style>
