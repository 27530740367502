<script>
import BaseEChartPie     from '@/theme/default/components/charts/echarts/BaseEChartPie'
import { toDecimalMark } from '@/lib/utils/helper'

export default {
  name    : 'EChartSurveyPie',
  extends : BaseEChartPie,
  computed: {
    series () {
      let values = []

      if (this.chartDataOptions.length) {
        values = this.chartDataOptions
      }

      return {
        type     : 'pie',
        // roseType : 'radius',
        top      : this.pieTop,
        left     : this.pieLeft,
        right    : this.pieRight,
        bottom   : this.pieBottom,
        width    : this.pieWidth,
        height   : this.pieHeight,
        center   : this.pieCenter,
        radius   : this.pieRadius,
        label    : { show: false },
        labelLine: { show: false },
        tooltip  : {
          formatter: params => {
            const value = toDecimalMark(parseFloat(params.value), 0)

            return `<div class="pr-4"><span class="mr-2">${ params.marker } ${ params.name }</span><br /><span class="ml-0 synthesis-text text-size-18 font-weight-regular">${ value }%</span></div>`
          }
        },
        itemStyle: {
          borderColor : this.pieBorderColor,
          borderWidth : this.pieBorderWidth,
          borderRadius: this.pieBorderRadius
        },
        cursor: this.cursor,
        data  : values
      }
    }
  }
}
</script>

<style scoped>
.chart {
  width: 100%;
}
</style>
