import BaseResource from '@/lib/data/resource/BaseResource'

export default class RiskDebtInterestExpenseVariabilityAssetResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Title = payload || ''
    this.Value = payload || ''
  }

  /* PROPERTIES */

  /* METHODS */
}
