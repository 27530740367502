<template>
  <v-container fluid>
    <v-row>
      <section-header :breadcrumb-items="breadcrumb" />
    </v-row>
    <v-row class="mt-0">
      <alert-details />
      <alert-triggers />
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta  from '@/mixins/i18n/i18nRouteMeta'
import SectionHeader  from '@/theme/default/components/common/SectionHeader'
import API            from '@/api/Api'
import { clone }      from '@/lib/utils/helper'
import AlertResource  from '@/api/resources/alerts/AlertResource'
import AlertDetails   from '@/theme/default/views/alerts/blocks/AlertDetails'
import AlertTriggers  from '@/theme/default/views/alerts/blocks/AlertTriggers'
import routerTabState from '@/mixins/routerTabState'
import alertId        from '@/theme/default/views/alerts/mixins/alertId'

export default {
  name      : 'AlertShow',
  components: {
    AlertTriggers,
    AlertDetails,
    SectionHeader
  },
  directives: {},
  mixins    : [i18nRouteMeta, alertId, routerTabState],
  props     : {},
  dataStore : {
    alertsSummaryData: 'Alerts.Summary.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    alert () {
      return this.alertsSummaryData[this.id] || null
    },

    title () {
      return this.alert?.Name || ''
    },

    breadcrumb () {
      return [
        {
          text    : this.$t('Alerts.Title'),
          disabled: false,
          exact   : true,
          to      : { name: 'Alerts' }
        },
        {
          text    : this.title,
          disabled: true
        }
      ]
    }
  },
  watch: {
    id: {
      handler () {
        this.getData()
      },
      immediate: true
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Alerts.Summary(this.id)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },

    handleResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        const p = data?.findAlert || null
        if (p) {
          const o = clone(p)
          const v = new AlertResource(o)

          // Store data to DataStore
          this.$set(this.alertsSummaryData, v.Id, v)
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>
/deep/ .v-toolbar__content, .v-toolbar__extension {
  padding : 4px 0 !important;
}
</style>
