<template>
  <div>
    <v-row>
      <v-col
        v-if="totalRiskPerAsset && totalRiskPerAsset.length"
        cols="12"
        lg="4"
        md="4"
      >
        <block-item-card
          ref="totalRisk"
          :base-currency-symbol="riskBaseCurrencyDisplayName"
          :export-element="elementTotalRisk"
          :title="$t('Risks.Section.RiskSummary.TotalRisk.Title')"
          :title-prefix="$t('Dashboard.Block.LiveRisk.Label.Risk')"
          :title-prefix-tooltip="$t('Dashboard.Block.LiveRisk.InfoTip.Risk')"
          :title-value="annualRisk"
          :title-value-color-red="false"
        >
          <v-card-text
            class="pb-2 overflow-y-auto"
            style="height: 215px"
          >
            <template
              v-for="(item, i) in totalRiskPerAsset"
            >
              <item-chart-legend
                :key="`total-risk-per-asset-legend-${i}`"
                :background-color="getColorBg(i % colors.length, .1)"
                :base-currency-symbol="riskBaseCurrencyDisplayName"
                :icon-color="getColor(i % colors.length)"
                :title="item.Asset"
                :title-value="item.AnnualRisk"
                :expanded="item.AggregateItem"
                :icon="expandedTotalRiskPerAsset[item.Asset] ? item.IconExpanded : item.Icon"
                @item-chart-legend-click="onTotalRiskPerAssetClick(item)"
              />

              <div
                v-if="item.AggregateItem && expandedTotalRiskPerAsset[item.Asset]"
                :key="`total-risk-per-asset-legend-expanded-${i}`"
                class="pl-4"
              >
                <template
                  v-for="(aggregateItem, i2) in item.PerCashflowItems"
                >
                  <item-chart-legend
                    :key="`total-risk-per-asset-legend-expanded-item-${i2}`"
                    :background-color="getColorBg(i % colors.length, .1)"
                    :base-currency-symbol="riskBaseCurrencyDisplayName"
                    :icon-color="getColor(i % colors.length)"
                    :title="aggregateItem.Asset"
                    :title-value="aggregateItem.AnnualRisk"
                    icon="mdi-circle-outline"
                  />
                </template>
              </div>
            </template>
          </v-card-text>
        </block-item-card>
      </v-col>
      <v-col
        v-if="totalRiskPerAssetPieChart && totalRiskPerAssetPieChart.length"
        cols="12"
        lg="4"
        md="4"
      >
        <block-item-card
          ref="totalRiskPerAsset"
          :export-element="elementTotalRiskPerAsset"
          :title="$t('Risks.Section.RiskSummary.PercentageTotalRisk.Title')"
          :tooltip="$t('Risks.Section.RiskSummary.PercentageTotalRisk.InfoTip')"
          @export-to-image="exportTotalRiskPerAssetToImage"
          @export-to-image-completed="exportTotalRiskPerAssetToImageCompleted"
        >
          <v-lazy
            :options="{threshold: .5}"
            transition="fade-transition"
          >
            <base-e-chart-pie
              :axis-y-tick-prefix="riskBaseCurrencyDisplayName"
              :chart-animation="totalRiskPerAssetPieChartAnimation"
              :chart-data-options="totalRiskPerAssetPieChart"
              :chart-height="totalRiskPerAssetPieChartSize"
              :class="{'pt-6': totalRiskPerAssetPieChartSize===240}"
              :label-line-show="totalRiskPerAssetPieLabelShow"
              :label-show="totalRiskPerAssetPieLabelShow"
              chart-animation-easing="elasticOut"
            />
          </v-lazy>
        </block-item-card>
      </v-col>
      <v-col
        v-if="methodologyContribution && methodologyContribution.length"
        cols="12"
        lg="4"
        md="4"
      >
        <block-item-card
          ref="methodologyContribution"
          :export-element="elementMethodologyContribution"
          :title="$t('Risks.Section.RiskSummary.MethodologyBreakdown.Title')"
          :tooltip="$t('Risks.Section.RiskSummary.MethodologyBreakdown.InfoTip')"
        >
          <v-lazy
            :options="{threshold: .5}"
            transition="fade-transition"
          >
            <base-e-chart-bar-stack
              :axis-label-font-color="themeColorRGBA('synthesis-ui-grey-01')"
              :axis-pointer-line-style-opacity="0"
              :axis-y-label-inside="false"
              :axis-y-label-interval="1"
              :axis-y-label-margin="0"
              :axis-y-label-show="false"
              :axis-y-split-line-dash-offset="4"
              :axis-y-split-line-opacity="1"
              :axis-y-split-line-show="false"
              :axis-y-split-line-type="[8, 8]"
              axis-x-label-value-format="number"
              :axis-x-tick-prefix="riskBaseCurrencyDisplayName"
              :axis-y-tick-prefix="riskBaseCurrencyDisplayName"
              :bar-background-color="themeColorRGBA('synthesis-ui-grey-06', 0.1)"
              :chart-data-options="methodologyContribution"
              :chart-grid-margin="{left: 15,top: 20,right: 15,bottom: 0,containLabel: true}"
              :chart-height="280"
              chart-animation-easing="quinticOut"
            />
          </v-lazy>
        </block-item-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import BlockItemCard      from '@/theme/default/components/common/BlockItemCard'
import BaseEChartPie      from '@/theme/default/components/charts/echarts/BaseEChartPie'
import baseEChartColors   from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'
import BaseEChartBarStack from '@/theme/default/components/charts/echarts/BaseEChartBarStack'
import ItemChartLegend    from '@/theme/default/components/common/ItemChartLegend'
import riskAnalysisId     from '@/theme/default/views/risks/mixins/riskAnalysisId'
import riskBaseCurrency   from '@/theme/default/views/risks/mixins/riskBaseCurrency'

export default {
  name      : 'RiskSummaryBaseBlock',
  components: {
    ItemChartLegend,
    BaseEChartBarStack,
    BaseEChartPie,
    BlockItemCard
  },
  directives: {},
  mixins    : [riskBaseCurrency, baseEChartColors, riskAnalysisId],
  props     : {},
  dataStore : {
    riskSummaryData     : 'Risks.Summary.Data',
    riskBaseCurrencyData: 'Risks.BaseCurrency.Data',
    appToolbarExtended  : 'App.Config.AppToolbarExtended'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      activeTab                         : null,
      elementTotalRisk                  : null,
      elementTotalRiskPerAsset          : null,
      elementMethodologyContribution    : null,
      totalRiskPerAssetPieLabelShow     : false,
      totalRiskPerAssetPieChartAnimation: true,
      totalRiskPerAssetPieChartSize     : 280,
      expandedTotalRiskPerAsset         : []
    }
  },
  computed: {
    summary () {
      return this.riskSummaryData[this.id] || null
    },

    annualRisk () {
      return this.summary?.AnnualRisk || ''
    },

    totalRiskPerAsset () {
      return this.summary?.TotalRiskPerAsset || []
    },

    totalRiskPerAssetPieChart () {
      return this.summary?.PieValues || []
    },

    methodologyContribution () {
      return this.summary?.MethodologyContribution || []
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.elementTotalRisk = this.$refs?.totalRisk?.$el
      this.elementTotalRiskPerAsset = this.$refs?.totalRiskPerAsset?.$el
      this.elementMethodologyContribution = this.$refs?.methodologyContribution?.$el
    })
  },
  methods: {
    exportTotalRiskPerAssetToImage () {
      this.totalRiskPerAssetPieChartSize = 240
      this.totalRiskPerAssetPieChartAnimation = false
      this.totalRiskPerAssetPieLabelShow = true
    },

    exportTotalRiskPerAssetToImageCompleted () {
      this.totalRiskPerAssetPieChartSize = 280
      this.totalRiskPerAssetPieChartAnimation = true
      this.totalRiskPerAssetPieLabelShow = false
    },

    onTotalRiskPerAssetClick (item) {
      for (const key in this.expandedTotalRiskPerAsset) {
        if (key !== item.Asset) {
          this.expandedTotalRiskPerAsset[key] = false
        }
      }

      this.$set(this.expandedTotalRiskPerAsset, item.Asset, !this.expandedTotalRiskPerAsset[item.Asset])
    }
  }
}
</script>

<style scoped>

</style>
