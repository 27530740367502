import { AES, HmacMD5 }     from '@/lib/crypto/crypto'
import UserModel            from '@/api/models/user/UserModel'
import AuthGQLApi           from '@/mixins/auth/authGQLApi'
import RoleEnum             from '@/api/enums/RoleEnum'
import ApiResponseEnum      from '@/api/enums/ApiResponseEnum'
import SubscriptionTierEnum from '@/api/enums/SubscriptionTierEnum'

export default {
  name: 'Auth',
  data () {
    return {
      authError        : false,
      isAuthLoading    : false,
      isMaintenanceMode: false
    }
  },
  mixins: [AuthGQLApi],
  enums : {
    RoleEnum,
    ApiResponseEnum
  },
  methods: {
    /**
     * Check if a user has a specific role
     *
     * @param role {string || array}
     * @returns {boolean}
     */
    userHasRole (role) {
      return Array.isArray(role)
        ? this.userRoles.some(userRole => role.some(rolesRole => rolesRole.toUpperCase() === userRole.toUpperCase()))
        : this.userRoles.some(userRole => userRole.toUpperCase() === role.toUpperCase())
    },

    /**
     * Check if a user has permission to route / component based on his role
     *
     * @param component {string}
     * @param role {string || array}
     * @returns {boolean}
     */
    userCan (component, role) {
      let hasPermission = false
      if (!component || !role) return false
      if (!this.$router.currentRoute.meta.requiresAuth) return true

      component = component.toUpperCase()

      if (this.userIsAuthenticated && this.userHasRole(role)) {
        hasPermission = true
      } else {
        if (this.$router.currentRoute.name !== 'Login') {
          this.$router.replace({
            name : 'Login',
            query: {}
          })
        }
      }
      return hasPermission
    }
  },
  computed: {
    authErrorMessage () {
      return this.$t('Users.Login.Error.Auth')
    },

    userKey () {
      return HmacMD5.hash('user')
    },

    authorizationTokenKey () {
      return HmacMD5.hash('authBearerToken')
    },

    authorizationToken: {
      get () {
        return AES.decrypt(this.$sessionStorage.get(this.authorizationTokenKey))
      },
      set (val) {
        this.$sessionStorage.set(this.authorizationTokenKey, AES.encrypt(val))
      }
    },

    user: {
      get () {
        const sessionUser = this.$sessionStorage.get(this.userKey)
        if (this.$dataStore.User) {
          return new UserModel(AES.decrypt(this.$dataStore.User))
        } else if (sessionUser) {
          this.$dataStore.User = sessionUser
          return new UserModel(AES.decrypt(sessionUser))
        }
        return null
      },
      set (val) {
        this.$dataStore.User = AES.encrypt(val)
        this.$sessionStorage.set(this.userKey, this.$dataStore.User)
        if (val === null) {
          this.$dataStore.User = null
          this.$sessionStorage.remove(this.userKey)
          this.$sessionStorage.remove(this.authorizationTokenKey)
        }
      }
    },

    userIsAuthenticated () {
      return !!this.user
    },

    userRoles () {
      return this.user?.roles || []
    },

    userCanAccess () {
      return this.userCan(this.$router.currentRoute.name, this.userRoles)
    },

    userIsAdmin () {
      return this.userHasRole(RoleEnum.ADMIN)
    },

    userIsCFO () {
      return this.userHasRole(RoleEnum.CFO)
    },

    userIsGroupCFO () {
      return this.userHasRole(RoleEnum.GROUPCFO) && this.user.selectedAccountSubsidiaryIds.length
    },

    userSubscriptionTier () {
      return this.user.subscriptionTier
    },

    userHasStandardSubscriptionTier () {
      return this.user.subscriptionTier === SubscriptionTierEnum.STANDARD
    },

    userHasPremiumSubscriptionTier () {
      return this.user.subscriptionTier === SubscriptionTierEnum.PREMIUM
    },

    userHasDebtProfile () {
      return this.user?.hasDebtProfile ?? false
    },

    userSelectedAccountId () {
      return this.user?.selectedAccountId || null
    },

    hasForecastFlow () {
      return this.user.hasForecastFlow
    }
  }

}
