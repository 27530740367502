<template>
  <v-col cols="12">
    <block-item-card
      v-if="fixedAndFloating"
      ref="fixedVsFloatingCard"
      :export-element="elementFixedAndFloating"
      :title="$t('Risks.Section.DebtProfile.Block.FixedVsFloating.Title')"
      :tooltip="$t('Risks.Section.DebtProfile.Block.FixedVsFloating.InfoTip')"
      :title-value-margin-top="titleValueMarginTop"
      :title-value-padding-left="5"
      :title-value-prefix="$t('Risks.Section.DebtProfile.Block.FixedVsFloating.TitleValue.Prefix')"
      :title-value="totalDebtOutstandingValueFmt"
      :title-value-color="totalDebtOutstandingValueColor"
      :title-value-color-red="false"
      :title-large="false"
    >
      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        #actions
      >
        <v-spacer />
        <asset-button-group
          :items="assets"
          :selected.sync="fixedAndFloatingSelectedAsset"
          class="mt-4"
        />
      </template>
      <template
        v-else
        #actions-row
      >
        <v-card-title class="mx-3 mb-4 pa-0">
          <asset-button-group
            :items="assets"
            :selected.sync="fixedAndFloatingSelectedAsset"
          />
        </v-card-title>
      </template>

      <v-row class="ma-0">
        <v-col
          class="ma-0 pa-1"
          cols="12"
        >
          <v-row
            class="ma-0"
          >
            <v-col
              cols="12"
              md="6"
            >
              <item-chart-legend
                :background-color="themeColorRGBA('synthesis-ui-red-08')"
                :title="$t('Risks.Section.DebtProfile.Block.FixedVsFloating.BeforeHedging.Title')"
                :title-size="16"
                class="pa-5 pt-4"
              />
              <v-lazy
                :options="{threshold: .5}"
                :value="disableLazy"
                transition="fade-transition"
              >
                <base-e-chart-pie
                  axis-y-tick-prefix=""
                  axis-y-tick-suffix="%"
                  :chart-data-options="beforeHedgingPieValues"
                  :pie-radius="150"
                  :chart-height="360"
                  :tooltip-show="true"
                  chart-animation-easing="elasticOut"
                  tooltip-confine
                  :label-line-show="false"
                  :label-show="true"
                  :chart-animation="chartAnimation"
                  legend-show
                  label-position="inside"
                  :font-color="themeColorRGBA('synthesis-brand-white')"
                  :chart-grid-margin="{left: 5, right: 0, top: 0, bottom: 0, containLabel: false}"
                  :label-pie-asset="false"
                  :pie-asset-value-font-color="themeColorRGBA('synthesis-brand-white')"
                  :pie-asset-value-font-size="28"
                  :pie-asset-value-font-weight="300"
                  :pie-legend-font-size="18"
                  :pie-legend-font-weight="300"
                  pie-asset-value-align="center"
                  legend-left="center"
                  legend-top="bottom"
                  :legend-data="beforeHedgingPieLegendValues"
                  :default-colors="pieColors"
                />
              </v-lazy>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <item-chart-legend
                :background-color="themeColorRGBA('synthesis-ui-green-08')"
                :title="$t('Risks.Section.DebtProfile.Block.FixedVsFloating.AfterHedging.Title')"
                :title-size="16"
                class="pa-5 pt-4"
              />
              <v-lazy
                :options="{threshold: .5}"
                :value="disableLazy"
                transition="fade-transition"
              >
                <base-e-chart-pie
                  axis-y-tick-prefix=""
                  axis-y-tick-suffix="%"
                  :chart-data-options="afterHedgingPieValues"
                  :pie-radius="150"
                  :chart-height="360"
                  :tooltip-show="true"
                  chart-animation-easing="elasticOut"
                  tooltip-confine
                  :label-line-show="false"
                  :label-show="true"
                  :chart-animation="chartAnimation"
                  legend-show
                  label-position="inside"
                  :chart-grid-margin="{left: 5, right: 0, top: 0, bottom: 0, containLabel: false}"
                  :label-pie-asset="false"
                  :pie-asset-value-font-color="themeColorRGBA('synthesis-brand-white')"
                  :pie-asset-value-font-size="28"
                  :pie-asset-value-font-weight="300"
                  :pie-legend-font-size="18"
                  :pie-legend-font-weight="300"
                  pie-asset-value-align="center"
                  legend-left="center"
                  legend-top="bottom"
                  :legend-data="afterHedgingPieLegendValues"
                  :default-colors="pieColors"
                />
              </v-lazy>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </block-item-card>
  </v-col>
</template>

<script>
import chartsColorMixin    from '@/theme/default/components/charts/mixins/chartsColorMixin'
import BlockItemCard       from '@/theme/default/components/common/BlockItemCard'
import riskAnalysisId      from '@/theme/default/views/risks/mixins/riskAnalysisId'
import riskBaseCurrency    from '@/theme/default/views/risks/mixins/riskBaseCurrency'
import BaseEChartPie       from '@/theme/default/components/charts/echarts/BaseEChartPie.vue'
import AssetButtonGroup    from '@/theme/default/components/common/AssetButtonGroup.vue'
import ItemChartLegend     from '@/theme/default/components/common/ItemChartLegend.vue'
import { first }           from '@/lib/utils/array'
import useExportAdditional from '@/mixins/useExportAdditional'
import { clone }           from '@/lib/utils/helper'

export default {
  name      : 'FixedVsFloatingBlock',
  components: {
    ItemChartLegend,
    AssetButtonGroup,
    BaseEChartPie,
    BlockItemCard
  },
  directives: {},
  mixins    : [riskBaseCurrency, chartsColorMixin, riskAnalysisId, useExportAdditional],
  props     : {},
  dataStore : {
    riskDebtData        : 'Risks.Debt.Data',
    riskBaseCurrencyData: 'Risks.BaseCurrency.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      elementFixedAndFloating      : null,
      fixedAndFloatingSelectedAsset: null
    }
  },
  computed: {
    debt () {
      return this.riskDebtData[this.id] || null
    },

    fixedAndFloating () {
      return this.debt?.FixedFloatingMix || null
    },

    assets () {
      return this.fixedAndFloating?.Assets || []
    },

    selectedAsset () {
      const v = this.fixedAndFloating?.Items || null

      if (v) {
        return v.find(item => item.AssetValue === this.fixedAndFloatingSelectedAsset) || null
      }

      return null
    },

    pieColors () {
      return [
        this.themeColorRGBA('synthesis-ui-red-02'),
        this.themeColorRGBA('synthesis-ui-green-02')
      ]
    },

    totalDebtOutstandingValueFmt () {
      return this.selectedAsset?.Data?.TotalDebtOutstandingValueFmt || ''
    },

    totalDebtOutstandingValueColor () {
      return this.themeColorRGBA('synthesis-brand-dark-blue')
    },

    titleValueMarginTop () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return -10
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        return 15
      } else {
        return -5
      }
    },

    beforeHedgingPieValues () {
      return this.selectedAsset?.Data?.BeforeHedging || []
    },

    beforeHedgingPieLegendValues () {
      return clone(this.beforeHedgingPieValues).reverse()
    },

    afterHedgingPieValues () {
      return this.selectedAsset?.Data?.AfterHedging || []
    },

    afterHedgingPieLegendValues () {
      return clone(this.afterHedgingPieValues).reverse()
    }
  },
  watch: {
    debt: {
      handler () {
        this.setExportElements()
        this.setSelectedAssets()
      },
      immediate: true
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    setExportElements () {
      this.$nextTick(() => {
        this.elementFixedAndFloating = this.$refs?.fixedVsFloatingCard?.$el
      })
    },

    setSelectedAssets () {
      this.$nextTick(() => {
        // Fix for dropdown
        if (this.assets && !!this.assets.length) {
          this.fixedAndFloatingSelectedAsset = first(this.assets).Value
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
