<template>
  <span
    :class="{'snotifyToast__input--filled': isPromptFocused}"
    class="snotifyToast__input"
  >
    <input
      :id="id"
      class="snotifyToast__input__field"
      type="text"
      @blur="isPromptFocused = !!length"
      @focus="isPromptFocused = true"
      @input="valueChanged"
    >
    <label
      :for="id"
      class="snotifyToast__input__label"
    >
      <span class="snotifyToast__input__labelContent">
        {{ placeholder | truncate }}
      </span>
    </label>
  </span>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['placeholder'],
  data () {
    return {
      id             : 545,
      isPromptFocused: false,
      length         : 0
    }
  },
  methods: {
    valueChanged (e) {
      const value = e.target.value.trim()
      this.length = value.length
      this.$emit('valueChanged', value)
    }
  }
}
</script>
