<template>
  <v-stepper
    v-model="stepModel"
    :class="[{transparent: $vuetify.breakpoint.xs}]"
    :non-linear="nonLinear"
    class="fill-height pa-0 pa-sm-5"
    flat
    touchless
    vertical
  >
    <slot
      :steps="steps"
      name="steps"
    >
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <template v-for="(item, index) in steps">
          <v-stepper-step
            :key="`stepper-step-${index + 1}`"
            :complete="item.Complete || false"
            :complete-icon="item.CompleteIcon"
            :edit-icon="item.EditIcon"
            :editable="item.Editable || false"
            :error-icon="item.ErrorIcon"
            :rules="item.Rules || []"
            :step="index + 1"
            touchless
            v-on="item.Editable ? {click: onStepperStepClick(index + 1, item)} : {}"
          >
            {{ item.Title }}
          </v-stepper-step>
        </template>
      </template>

      <template v-else>
        <span
          v-if="!showFullStep"
          class="text-size-18 font-weight-bold synthesis-brand-dark-blue--text"
        >
          Step {{ step }}/{{ totalSteps }}: {{ stepObj.Title }}
        </span>

        <v-stepper-step
          v-else
          :complete="stepObj.Complete || false"
          :complete-icon="stepObj.CompleteIcon"
          :edit-icon="stepObj.EditIcon"
          :editable="stepObj.Editable || false"
          :error-icon="stepObj.ErrorIcon"
          :rules="stepObj.Rules || []"
          :step="step"
          class="mb-0"
          v-on="stepObj.Editable ? {click: onStepperStepClick(step, stepObj)} : {}"
        >
          {{ stepObj.Title }}
        </v-stepper-step>
      </template>
    </slot>
  </v-stepper>
</template>

<script>
import formStepperSteps from '@/theme/default/components/form/formStepper/mixins/formStepperSteps'

export default {
  name      : 'FormStepperSteps',
  components: {},
  directives: {},
  mixins    : [formStepperSteps],
  props     : {},
  dataStore : {},
  enums     : {},
  dataModel : null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>
/* Stepper Override */
/deep/ .v-stepper__step {
  border           : 1px solid #000000;
  padding          : 18px;
  border-radius    : 5px;
  background-color : #dddddd;
  margin-bottom    : 12px;
  font-weight      : bold;
}

/* Step */
/deep/ .v-stepper__step {
  border           : 1px solid var(--v-synthesis-ui-grey-02-base);
  background-color : transparent;
}

/deep/ .v-stepper__step .v-stepper__label {
  font-weight : normal;
  color       : var(--v-synthesis-ui-grey-01-base) !important;
}

/deep/ .v-stepper__step .v-stepper__step__step {
  color            : var(--v-synthesis-ui-grey-01-base);
  background-color : var(--v-synthesis-ui-grey-02-base) !important;
  border-color     : var(--v-synthesis-ui-grey-02-base) !important;
  height           : 28px;
  min-width        : 28px;
  width            : 28px;
}

/* Step Active */
/deep/ .v-stepper__step--active {
  border           : 1px solid var(--v-synthesis-ui-blue-04-base);
  background-color : var(--v-synthesis-brand-lightest-blue-base);
}

/deep/ .v-stepper__step--active .v-stepper__label {
  color : var(--v-synthesis-brand-dark-blue-base) !important;
}

/deep/ .v-stepper__step--active .v-stepper__step__step {
  color            : var(--v-synthesis-brand-dark-blue-base);
  background-color : var(--v-synthesis-ui-blue-07-base) !important;
  border-color     : var(--v-synthesis-ui-blue-07-base) !important;
}

/* Step Complete */
/deep/ .v-stepper__step--complete {
  border           : 1px solid var(--v-synthesis-ui-green-01-base);
  background-color : transparent;
}

/deep/ .v-stepper__step--complete .v-stepper__step__step {
  background-color : var(--v-synthesis-ui-green-03-base) !important;
  border-color     : var(--v-synthesis-ui-green-03-base) !important;
}

/deep/ .v-stepper__step--complete .v-stepper__label {
  color : var(--v-synthesis-ui-green-01-base) !important;
}
</style>
