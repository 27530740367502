import BaseResource                            from '@/lib/data/resource/BaseResource'
import AssetModel                              from '@/api/models/asset/AssetModel'
import ChartsStyleEnum                         from '@/api/enums/ChartsEnum'
import { collect }                             from '@/lib/utils/array'
import HedgingStrategyInstrumentOptionResource from '@/api/resources/hedging/HedgingStrategyInstrumentOptionResource'

export default class HedgingCompareAssetStrategyResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const currentStrategyProvided = payload.currentStrategyProvided ?? false

    this.Name = payload.asset ?? ''
    this.Asset = new AssetModel(payload.detailedAsset ?? {})
    this.BaseCurrency = new AssetModel(payload.baseCurrency ?? null)
    this.Current = currentStrategyProvided ? (payload.displayCurrentPreferredInstrument ?? '') : '-' // currentStrategyProvided ? (payload.currentPreferredInstrument ?? '') : '-'
    this.Optimal = payload.displayPreferredInstrument ?? '' // payload.preferredInstrument
    this.Industry = payload.displayIndustryPreferredInstrument ?? '' // payload.industryPreferredInstrument
    this.CurrentValue = payload?.currentHedgeRatio
    this.OptimalValue = payload?.optimalHedgeRatio
    this.IndustryValue = payload?.industryHedgeRatio
    this.CurrentStrategyProvided = currentStrategyProvided
    this.PreferredInstrument = payload.preferredInstrument
    this.InstrumentOptions = collect(payload?.instrumentOptions || [], HedgingStrategyInstrumentOptionResource)
  }

  /* PROPERTIES */
  get BarValues () {
    return [{
      y: [this.Industry, this.Optimal, this.Current],
      x: [this.IndustryValue, this.OptimalValue, this.CurrentValue],
      c: [
        ChartsStyleEnum.Colors.HedgingCompareInstrumentBar.Industry,
        ChartsStyleEnum.Colors.HedgingCompareInstrumentBar.Optimal,
        ChartsStyleEnum.Colors.HedgingCompareInstrumentBar.Current
      ]
    }]
  }

  /* METHODS */
}
