<template>
  <v-menu
    ref="dateRangeMenu"
    v-model="dateRangeMenu"
    :close-on-content-click="false"
    :disabled="disabled"
    :return-value.sync="selectedDate"
    nudge-bottom="44"
    transition="scale-transition"
  >
    <template #activator="{on, attrs}">
      <v-text-field
        v-model="selectedDateFormattedText"
        :disabled="disabled"
        :height="height"
        :style="`width: ${width}px;`"
        class="d-inline-block date-range-text-field"
        dense
        hide-details="auto"
        outlined
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>

    <v-card flat>
      <div class="pa-6 pb-2 text-center">
        <asset-button-group
          :height="24"
          :items="[
            {Title: 'Preset', Value: 'preset'},
            {Title: 'Range', Value: 'range'},
          ]"
          :selected.sync="selectedType"
          class="d-inline-flex"
        />
      </div>

      <v-date-picker
        v-if="selectedType === 'range'"
        v-model="selectedDate"
        :allowed-dates="allowedDatesWithMinRange"
        :disabled="disabled"
        :locale="$i18n.locale"
        :max="max"
        :min="min"
        color="primary"
        no-title
        range
        scrollable
        type="month"
        width="220"
      />

      <v-list
        v-else-if="selectedType === 'preset'"
        class="pa-0"
        dense
      >
        <v-list-item-group
          v-model="selectedPreset"
          color="primary"
        >
          <template v-for="(preset, index) in presetsList">
            <v-list-item
              :key="`month-date-picker-preset-${index}`"
              @click="onPresetClick(preset)"
            >
              <v-list-item-content>
                <v-list-item-title> {{ preset.Title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="onDateDialogCancel"
        >
          {{ $t('Common.Button.Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="onDateDialogOk"
        >
          {{ $t('Common.Button.Ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import AssetButtonGroup from '@/theme/default/components/common/AssetButtonGroup'
import { isFunction }   from '@/lib/utils/type'

export default {
  name      : 'SelectDateRange',
  components: { AssetButtonGroup },
  directives: {},
  mixins    : [],
  props     : {
    value: {
      type   : Array,
      default: () => []
    },

    disabled: {
      type   : Boolean,
      default: false
    },

    minRange: {
      type   : Number,
      default: 0
    },

    allowedDates: {
      type   : Function,
      default: null
    },

    min: {
      type   : String,
      default: undefined
    },

    max: {
      type   : String,
      default: undefined
    },

    presetsList: {
      type   : Array,
      default: function () {
        return this.$t('Common.Date.Preset')
      }
    },

    presetsCalcFromDate: {
      type   : String,
      default: new Date().toISOString()
    },

    width: {
      type   : [String, Number],
      default: 222
    },

    height: {
      type   : [String, Number],
      default: 42
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {
      dateRangeMenu : false,
      selectedPreset: null,
      selectedDate  : [],
      selectedType  : 'preset'
    }
  },
  computed: {
    selectedDateFormattedText () {
      const dates = this.selectedDate.map(date => this.formatDisplayMonthDate(date))
      return dates.join(' - ')
    },

    model: {
      get () {
        return this.value.map(v => this.formatValueMonthDate(v))
      },
      set (val) {
        this.$emit('input', this.returnModelValueFormatted(val))
      }
    }
  },
  watch: {
    model (newVal) {
      this.selectedDate = newVal
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    allowedDatesWithMinRange (val) {
      const isDateAllowed = isFunction(this.allowedDates) ? this.allowedDates(val) : true
      const selectedStartDate = this.selectedDate.length === 1 && this.selectedDate[0] || undefined
      const selectedEndDateAdd = selectedStartDate && this.minRange && this.$dayjs(selectedStartDate).add(this.minRange, 'month') || undefined
      const selectedEndDateSubtract = selectedStartDate && this.minRange && this.$dayjs(selectedStartDate).subtract(this.minRange, 'month') || undefined
      const isDateOutOfMinRange = selectedEndDateAdd && selectedEndDateSubtract ? this.$dayjs(val).isSameOrAfter(selectedEndDateAdd, 'month') || this.$dayjs(val).isSameOrBefore(selectedEndDateSubtract, 'month') || this.$dayjs(val).isSame(selectedStartDate, 'month') : true

      return selectedStartDate && selectedEndDateAdd && selectedEndDateSubtract ? isDateAllowed && isDateOutOfMinRange : isDateAllowed
    },

    onDateDialogCancel () {
      this.dateRangeMenu = false
      this.selectedPreset = null
    },

    onDateDialogOk () {
      this.$refs.dateRangeMenu.save(this.selectedDate)
      this.model = this.selectedDate
      this.selectedPreset = null
    },

    onPresetClick (preset) {
      this.selectedDate = this.dateRangeFromPreset(preset)
    },

    dateRangeFromPreset (preset) {
      const now = this.$dayjs(this.presetsCalcFromDate)

      return preset.Value > 0
        ? [this.formatValueMonthDate(now), this.formatValueMonthDate(now.add(Math.abs(preset.Value), preset.ValueType))]
        : [this.formatValueMonthDate(now.subtract(Math.abs(preset.Value), preset.ValueType)), this.formatValueMonthDate(now)]
    },

    returnModelValueFormatted (val) {
      const [startDate, endDate] = this.sortModelDates(val)

      return [this.formatModelMonthDate(this.$dayjs(startDate).startOf('month')), this.formatModelMonthDate(this.$dayjs(endDate).endOf('month'))]
    },

    sortModelDates (val) {
      return val.sort((d1, d2) => {
        d1 = this.$dayjs(d1)
        d2 = this.$dayjs(d2)

        if (d1.isBefore(d2)) {
          return -1
        } else if (d1.isAfter(d2)) {
          return 1
        } else {
          return 0
        }
      })
    },

    formatDisplayMonthDate (date) {
      if (!date) return ''

      return this.$dayjs(date).format('MMM YYYY')
    },

    formatValueMonthDate (date) {
      if (!date) return ''

      return this.$dayjs(date).format('YYYY-MM')
    },

    formatModelMonthDate (date) {
      if (!date) return ''

      return this.$dayjs(date).format('YYYY-MM-DD')
    }
  }
}
</script>

<style scoped>
/* month date picker */
/deep/ .v-date-picker-table--month td .v-btn {
  min-width  : 50px;
  min-height : 50px;
  padding    : 0 8px;
  width      : auto;
  border     : 1px solid var(--v-synthesis-ui-grey-02-base);
}

/deep/ .date-range-text-field .v-input__slot {
  min-height : 42px;
}

/deep/ .date-range-text-field input {
  text-align : center;
}

/deep/ .v-text-field__slot input {
  text-align : center;
}
</style>
