import BaseModel                        from '@/lib/data/model/BaseModel'
import RiskAnalysisValidator            from '@/api/validations/risks/RiskAnalysisValidator'
import RiskAnalysisResource             from '@/api/resources/risks/RiskAnalysisResource'
import API                              from '@/api/Api'
import { collect }                      from '@/lib/utils/array'
import ActiveAnalysesRiskSummariesModel from '@/api/models/risks/ActiveAnalysesRiskSummariesModel'
import GroupHedgeRatioInputResource     from '@/api/resources/risks/GroupHedgeRatioInputResource'

export default class RiskAnalysisModel extends BaseModel {
  constructor (payload = {}, resource = RiskAnalysisResource, validator = RiskAnalysisValidator) {
    super(payload, resource, validator)
  }

  /* PROPERTIES */

  /* METHODS */
  boot () {
    super.boot()

    this.guarded = ['exposuresList', 'riskAnalysesList', 'id', 'multipleExposuresPerAsset']
  }

  clone (includeGuardedFields = false, Resource = null) {
    const data = super.clone(includeGuardedFields, Resource)
    if (data.riskAnalyses.length) {
      delete data.exposures
      data.riskAnalyses = collect(data.riskAnalyses, ActiveAnalysesRiskSummariesModel).map(item => {
        const analysis = item.clone()
        analysis.hedgeRatios = collect(analysis.hedgeRatios, GroupHedgeRatioInputResource)
        return analysis
      })
    } else {
      delete data.riskAnalyses
      data.exposures = data.exposures.map(exposure => {
        delete exposure.selectedItem
        delete exposure.userProvidedSeasonality
        delete exposure.asset
        return exposure
      })
    }
    return data
  }

  async CreateAnalysis () {
    if (this.riskAnalyses.length) {
      return await API.Resource.Risks.CreateGroupAnalysis(this.clone())
    } else {
      return await API.Resource.Risks.CreateAnalysis(this.clone())
    }
  }

  async RerunAnalysis () {
    const data = {
      riskAnalysisId  : this.id,
      activeVar       : this.activeVar,
      activeHistorical: this.activeHistorical,
      activeEvents    : this.activeEvents,
      activeSimulation: this.activeSimulation
    }
    return await API.Resource.Risks.RerunAnalysis(data)
  }

  async PersistAnalysis () {
    return await API.Resource.Risks.PersistAnalysis(this.id)
  }

  async SetActiveAnalysis () {
    return await API.Resource.Risks.SetActiveAnalysis(this.id)
  }
}
