import BaseResource from '@/lib/data/resource/BaseResource'

export default class RiskForecastFlowRuglSummaryTableResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Title = payload.title || ''
    this.Values = payload.rows ?? []
  }

  /* PROPERTIES */

  /* METHODS */
}
