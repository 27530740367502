<template>
  <v-row v-if="barChart">
    <v-col>
      <block-item-card
        :sub-title="$t('Hedging.Section.SuggestedStrategy.OptimalHedgeRatio.Subtitle', {Type: instrument})"
        :title="$t('Hedging.Section.SuggestedStrategy.OptimalHedgeRatio.Title')"
        :tooltip="$t('Hedging.Section.SuggestedStrategy.OptimalHedgeRatio.InfoTip')"
      >
        <v-row class="ma-0">
          <v-col
            class="ma-0"
            cols="12"
          >
            <v-row
              class="ma-0 flex-md-nowrap"
            >
              <v-col
                class="pa-0 pr-4 flex-shrink-1"
                cols="12"
                lg="9"
                md="8"
                xl="10"
              >
                <v-lazy
                  :options="{threshold: .5}"
                  transition="fade-transition"
                >
                  <e-chart-hedging-optimal-hedge-ratio-bar
                    :axis-label-font-color="themeColorRGBA('synthesis-ui-grey-01')"
                    :axis-x-label-margin="15"
                    :axis-x-label-show="true"
                    :axis-y-label-inside="false"
                    :axis-y-label-margin="0"
                    :axis-y-label-show="true"
                    :axis-y-split-line-dash-offset="4"
                    :axis-y-split-line-opacity="1"
                    :axis-y-split-line-type="[8, 8]"
                    :axis-y-split-number="5"
                    :bar-background-color="themeColorRGBA('synthesis-ui-grey-05', 0.1)"
                    :chart-data-options="barChart"
                    :chart-grid-margin="{left: 5,top: 20,right: 5,bottom: gridMarginBottom,containLabel: true}"
                    :chart-height="280"
                    :tooltip-show="tooltipShow"
                    :tooltip-show-content="true"
                    axis-x-label-value-format="title"
                    axis-y-label-value-format="percent"
                    axis-y-tick-suffix="%"
                    chart-animation-easing="quinticInOut"
                  />
                </v-lazy>
              </v-col>
              <v-col
                class="pa-0 pt-4 pt-md-0"
                cols="12"
                lg="3"
                md="4"
                style="min-width: 270px"
                xl="2"
              >
                <v-row
                  class="ma-0"
                >
                  <v-col
                    class="ma-0 pa-0"
                    cols="12"
                  >
                    <block-item-card
                      min-height="264"
                    >
                      <v-card-title
                        class="pt-4 pl-4 pb-2 text-size-14 font-weight-medium synthesis-brand-dark-blue--text"
                      >
                        {{ $t('Hedging.Section.SuggestedStrategy.HedgeAmount.Title') }}
                        <infotip
                          v-if="$t('Hedging.Section.SuggestedStrategy.HedgeAmount.InfoTip')"
                          icon-class="ml-1"
                          icon-style="margin-top: -6px"
                        >
                          {{ $t('Hedging.Section.SuggestedStrategy.HedgeAmount.InfoTip') }}
                        </infotip>
                      </v-card-title>
                      <v-card-text
                        v-if="instruments && instruments.length"
                        class="pb-2 overflow-y-auto"
                        style="height: 210px"
                      >
                        <template
                          v-for="(item, i) in instruments"
                        >
                          <item-chart-legend
                            :key="`hedging-optimal-hedge-ratio-legend-${i}`"
                            :background-color="getColorBg(i % colors.length)"
                            :base-currency-symbol="item.BaseCurrency"
                            :icon-color="getColor(i % colors.length)"
                            :title="item.Name"
                            :title-value="item.HedgeAmount"
                          />
                        </template>
                      </v-card-text>
                    </block-item-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </block-item-card>
    </v-col>
  </v-row>
</template>

<script>

import BlockItemCard                     from '@/theme/default/components/common/BlockItemCard'
import ItemChartLegend                   from '@/theme/default/components/common/ItemChartLegend'
import EChartHedgingOptimalHedgeRatioBar from '@/theme/default/components/charts/echarts/EChartHedgingOptimalHedgeRatioBar'
import chartsColorMixin                  from '@/theme/default/components/charts/mixins/chartsColorMixin'
import baseEChartColors                  from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'
import policyId                          from '@/theme/default/views/hedging/mixins/policyId'
import Infotip                           from '@/theme/default/components/common/Infotip'

export default {
  name      : 'InstrumentOptimalHedgeRatioBlock',
  components: {
    Infotip,
    EChartHedgingOptimalHedgeRatioBar,
    ItemChartLegend,
    BlockItemCard
  },
  directives: {},
  mixins    : [chartsColorMixin, baseEChartColors, policyId],
  props     : {
    instrumentData: {
      type   : Object,
      default: () => {}
    },
    instrumentType: {
      type   : String,
      default: ''
    },
    tooltipShow: {
      type   : Boolean,
      default: false
    },
    gridMarginBottom: {
      type   : Number,
      default: 20
    }
  },
  dataStore: {
    hedgingSummaryData: 'Hedging.Summary.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    instrumentTypeData () {
      if (this.instrumentData) {
        return this.instrumentData
      }

      return this.hedgingSummaryData[this.id] || null
    },

    instrument () {
      return this.instrumentType
    },

    barChart () {
      return this.instrumentTypeData?.ChartValues || []
    },

    instruments () {
      return this.instrumentTypeData?.Instruments || []
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
