import BaseResource from '@/lib/data/resource/BaseResource'

export default class RiskDebtAmortisationInputResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.amount = payload.amount ?? null
    this.key = payload.key ?? ''
    this.label = payload.label ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
