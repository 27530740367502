<template>
  <v-card
    :max-width="maxWidth"
    :min-width="minWidth"
    :width="$vuetify.breakpoint.mdAndUp ? width : '100%'"
    class="synthesis-card-border fill-height d-flex align-content-end flex-column"
    flat
  >
    <form-stepper-steps
      v-if="$vuetify.breakpoint.mdAndUp"
      :non-linear="nonLinear"
      :step.sync="stepModel"
      :steps="steps"
      @click-step="onStepperStepClick"
    />

    <v-card-actions class="d-block pa-3 pa-sm-5">
      <form-stepper-actions
        :step.sync="stepModel"
        :steps="steps"
        @click-btn-action="onStepperBtnActionClick"
        @click-btn-back="onStepperBtnBackClick"
        @click-btn-cancel="onStepperBtnCancelClick"
      />
    </v-card-actions>
  </v-card>
</template>

<script>

import formStepperCommon  from '@/theme/default/components/form/formStepper/mixins/formStepperCommon'
import formStepperSteps   from '@/theme/default/components/form/formStepper/mixins/formStepperSteps'
import formStepperActions from '@/theme/default/components/form/formStepper/mixins/formStepperActions'
import FormStepperActions from '@/theme/default/components/form/formStepper/components/FormStepperActions'
import FormStepperSteps   from '@/theme/default/components/form/formStepper/components/FormStepperSteps'

export default {
  name      : 'FormStepper',
  components: {
    FormStepperSteps,
    FormStepperActions
  },
  directives: {},
  mixins    : [formStepperCommon, formStepperSteps, formStepperActions],
  props     : {
    width: {
      type   : [Number, String],
      default: 265
    },

    minWidth: {
      type   : [Number, String],
      default: undefined
    },

    maxWidth: {
      type   : [Number, String],
      default: undefined
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {
    step (newVal, oldVal) {
      this.$emit('change', newVal, oldVal)
      if (window) window.scrollTo(0, 0)
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onStepperBtnActionClick (...args) {
      this.$emit('click-btn-action', ...args)
    },

    onStepperBtnBackClick (...args) {
      this.$emit('click-btn-back', ...args)
    },

    onStepperBtnCancelClick (...args) {
      this.$emit('click-btn-cancel', ...args)
    }
  }
}
</script>

<style scoped>

</style>
