import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class RiskDeptFloatingInputValidator extends BaseValidator {
  validatorRules = {
    debtProfileId: {
      required: false,
      type    : BaseValidator.Type.String
    },
    id: {
      required: false,
      type    : BaseValidator.Type.String
    },
    name: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 3
    },
    maturity: {
      required: true,
      type    : BaseValidator.Type.String
    },
    notional: {
      required                    : true,
      NotEmptyZeroOrPositiveNumber: (v) => parseFloat(v) >= 0 && v !== ''
    },
    spread: {
      required                    : true,
      NotEmptyZeroOrPositiveNumber: (v) => parseFloat(v) >= 0 && v !== '',
      betweenVal                  : (v) => parseFloat(v) >= 0 && parseFloat(v) <= 100
    }
  }

  validatorMessages = {
    el: {
      NotEmptyZeroOrPositiveNumber: 'Enter zero or a positive value',
      betweenVal                  : 'Enter a value between 0 and 100'
    },
    en: {
      NotEmptyZeroOrPositiveNumber: 'Enter zero or a positive value',
      betweenVal                  : 'Enter a value between 0 and 100'
    }
  }

  constructor (data = {}) {
    super(data)
  }
}
