export default {
  computed: {
    isBaseCurrencySymbolSingle () {
      return this.axisYTickPrefix.length === 1
    },

    baseCurrencySymbolSingle () {
      return this.isBaseCurrencySymbolSingle ? this.axisYTickPrefix : ''
    }
  }
}
