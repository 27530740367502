import BaseResource             from '@/lib/data/resource/BaseResource'
import { collect }              from '@/lib/utils/array'
import AssetNameResource        from '@/api/resources/asset/AssetNameResource'
import HedgingAssetCostResource from '@/api/resources/hedging/HedgingAssetCostResource'

export default class HedgingCostsResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Assets = collect(payload.assets ?? [], AssetNameResource)
    this.Costs = collect(payload.assetCost ?? [], HedgingAssetCostResource)
  }

  /* PROPERTIES */

  /* METHODS */
}
