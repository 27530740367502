import BaseResource             from '@/lib/data/resource/BaseResource'
import AlertPriorityEnum        from '@/api/enums/AlertPriorityEnum'
import AlertTypeEnum            from '@/api/enums/AlertTypeEnum'
import { collect }              from '@/lib/utils/array'
import i18n                     from '@/lang/lang'
import TriggerEditInputResource from '@/api/resources/alerts/TriggerEditInputResource'

export default class AlertEditInputResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.alertDefinitionId = payload.Id ?? ''
    this.name = payload.Name ?? ''
    this.ignoreFiring = payload.ignoreFiring ?? false
    this.priority = payload.Priority ?? AlertPriorityEnum.Low
    this.baseCurrencyCode = payload.BaseCurrency?.symbol ?? ''
    this.description = payload.Description ?? ''
    this.enableEmailNotifications = payload.EmailNotifications ?? false
    this.expirationDate = payload.ExpiryDate || null
    this.type = payload.AlertType ?? AlertTypeEnum.AssetPrice
    payload.AssetPriceTriggers = payload.AssetPriceTriggers && payload.AssetPriceTriggers.map(trigger => {
      trigger.AlertType = AlertTypeEnum.AssetPrice
      trigger.BaseCurrency = payload.BaseCurrency
      return trigger
    })
    payload.PositionMtmTriggers = payload.PositionMtmTriggers && payload.PositionMtmTriggers.map(trigger => {
      trigger.AlertType = AlertTypeEnum.PositionMTM
      trigger.BaseCurrency = payload.BaseCurrency
      return trigger
    })
    payload.PortfolioMtmTriggers = payload.PortfolioMtmTriggers && payload.PortfolioMtmTriggers.map(trigger => {
      trigger.AlertType = AlertTypeEnum.PortfolioMTM
      trigger.BaseCurrency = payload.BaseCurrency
      return trigger
    })
    this.assetPriceTriggers = []
    this.positionMTMTriggers = []
    this.portfolioMTMTriggers = []
    this.triggers = collect(payload.AssetPriceTriggers ?? payload.PositionMtmTriggers ?? payload.PortfolioMtmTriggers ?? [], TriggerEditInputResource)
  }

  /* PROPERTIES */
  get alertType () {
    return this.type
  }

  get triggersList () {
    const retVal = []

    this.triggers.forEach(trigger => {
      const asset = trigger?.selectedItem?.pair?.base?.displayName || trigger?.selectedItem?.title || ''
      const triggerLevel = trigger?.displayThreshold || '0'
      const items = [
        {
          Title: (this.type === AlertTypeEnum.PortfolioMTM) ? i18n.t('Alerts.AddTrigger.Form.Fields.Portfolio.Label') : i18n.t('Alerts.AddTrigger.Form.Fields.Asset.Label'),
          Value: asset,
          Tags : null
        },
        {
          Title: i18n.t('Alerts.AddTrigger.Form.Fields.Threshold.Label'),
          Value: triggerLevel,
          Tags : null
        }
      ]

      if (this.type === AlertTypeEnum.PortfolioMTM) {
        items.splice(1, 0,
          {
            Title: i18n.t('Alerts.AddTrigger.Form.Fields.Assets.Label'),
            Value: trigger?.selectedItem?.assets || '',
            Tags : trigger?.selectedItem?.tags || null
          }
        )
      }

      retVal.push({
        Trigger: trigger,
        Items  : items
      })
    })

    return retVal
  }

  /* METHODS */
}
