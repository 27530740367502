<template>
  <v-container
    class="align-content-start"
    fill-height
    fluid
  >
    <v-row>
      <section-header
        :breadcrumb-items="breadcrumb"
        :button="viewHasData"
        :button-title="$t('Positions.Button.New.Title')"
        button-to="PositionsNew"
      />
    </v-row>

    <v-row>
      <v-col cols="12">
        <view-data-container
          :button-title="$t('Positions.Button.New.Title')"
          :loading="isLoadingData"
          :no-data-title="$t('Positions.Message.NoData.Title')"
          button
          button-to="PositionsNew"
          class="pa-0"
        >
          <template v-if="viewHasData">
            <v-row>
              <v-col cols="12">
                <v-tabs
                  v-model="activeTab"
                  active-class="active-tab"
                  background-color="transparent"
                  height="30"
                  hide-slider
                  show-arrows
                >
                  <v-tab :href="`#${$t('Positions.Tab.Archive.Slug')}`">
                    {{ $t('Positions.Tab.Archive.Title') }}
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="pa-0"
                cols="12"
              >
                <v-tabs-items v-model="activeTab">
                  <v-tab-item :value="$t('Positions.Tab.Archive.Slug')">
                    <v-container fluid>
                      <v-row>
                        <template v-for="(item, index) in items">
                          <v-col
                            :key="`tab-${activeTab}-items-${index}`"
                            cols="12"
                            lg="4"
                            md="6"
                            style="padding: 10px"
                          >
                            <v-lazy
                              :key="`lazy-positions-archive-tab-${activeTab}-items-${index}`"
                              :options="{threshold: .5}"
                              transition="fade-transition"
                            >
                              <archive-item-card
                                :active-card="item.VisibleOnDashboard"
                                :card-event="true"
                                :date-created="item.CreatedAtFmt"
                                :menu-items="$t('Positions.Tab.Archive.Card.MenuItems')"
                                :tags="item.Tags"
                                :title="item.Name"
                                @card-click="onItemCardClick(item)"
                                @archive-item-visibility-button-click="onItemCardVisibilityClick(item)"
                                @archive-item-delete-button-click="onItemCardDeleteClick(item)"
                                @archive-item-edit-button-click="onItemCardEditClick(item)"
                              />
                            </v-lazy>
                          </v-col>
                        </template>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </template>
        </view-data-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta        from '@/mixins/i18n/i18nRouteMeta'
import viewHasData          from '@/mixins/viewHasData'
import routerTabState       from '@/mixins/routerTabState'
import SectionHeader        from '@/theme/default/components/common/SectionHeader'
import ViewDataContainer    from '@/theme/default/components/common/ViewDataContainer'
import ArchiveItemCard      from '@/theme/default/components/common/ArchiveItemCard'
import API                  from '@/api/Api'
import { clone }            from '@/lib/utils/helper'
import { collect }          from '@/lib/utils/array'
import PositionsResource    from '@/api/resources/positions/PositionsResource'
import PositionEditResource from '@/api/resources/positions/PositionEditResource'

export default {
  name      : 'Positions',
  components: {
    ArchiveItemCard,
    ViewDataContainer,
    SectionHeader
  },
  directives: {},
  mixins    : [i18nRouteMeta, viewHasData, routerTabState],
  props     : {},
  dataStore : {
    items               : 'Positions.Archive.Data',
    positionsSummaryData: 'Positions.Summary.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    breadcrumb () {
      return [{
        text    : this.$t('Positions.Title'),
        tooltip : this.$t('Positions.InfoTip'),
        disabled: true
      }]
    },

    accountId () {
      return this.user?.selectedAccountId || null
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      this.isLoadingData = true

      API.Resource.Positions.Archive(this.accountId)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {
          this.isLoadingData = false
        })
    },
    handleResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        const list = data?.listPositions || null
        if (list) {
          const o = clone(list)

          this.items = collect(o, PositionsResource)
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    onItemCardVisibilityClick (item) {
      if (!item || !item.Id) return

      this.$root.$confirmDialog(
        this.$t('Positions.Tab.Archive.Confirm.Visibility.Title', {
          Value: item.VisibleOnDashboard ? this.$t('Positions.Tab.Archive.Confirm.Visibility.HideFrom') : this.$t('Positions.Tab.Archive.Confirm.Visibility.ShowOn')
        }),
        this.$t('Positions.Tab.Archive.Confirm.Visibility.Body', {
          Name: item.Name,
          Show: item.VisibleOnDashboard ? this.$t('Common.Button.Hide') : this.$t('Common.Button.Show')
        }),
        {
          color : 'synthesis-ui-blue-02',
          button: {
            no : { title: this.$t('Common.Button.Cancel') },
            yes: { title: item.VisibleOnDashboard ? this.$t('Common.Button.Hide') : this.$t('Common.Button.Show') }
          }
        }
      ).then(response => {
        if (response) {
          API.Resource.Positions.PositionVisibility(item.Id, !item.VisibleOnDashboard)
            .then(response => {
              this.handleVisibilityPositionResponse(response)
            })
            .catch(e => {
              this.handleVisibilityPositionResponse(e.response)
            })
            .finally(() => {})
        }
      })
    },
    handleVisibilityPositionResponse (response) {
      const visible = API.responseData(response)?.positionVisibility || ''
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && visible) {
        this.getData()
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    onItemCardEditClick (item) {
      if (!item || !item.Id) return

      this.$router.push({
        name  : 'PositionsEdit',
        params: {
          id  : item.Id,
          item: new PositionEditResource(item)
        }
      })
    },

    onItemCardDeleteClick (item) {
      if (!item || !item.Id) return

      this.$root.$confirmDialog(
        this.$t('Positions.Tab.Archive.Confirm.Delete.Title'),
        this.$t('Positions.Tab.Archive.Confirm.Delete.Body', { Name: item.Name }),
        {
          color : 'synthesis-ui-red-02',
          button: {
            no : { title: this.$t('Common.Button.Cancel') },
            yes: { title: this.$t('Common.Button.Delete') }
          }
        }
      ).then(response => {
        if (response) {
          API.Resource.Positions.DeletePosition(item.Id)
            .then(response => {
              this.handleDeletePositionResponse(response)
            })
            .catch(e => {
              this.handleDeletePositionResponse(e.response)
            })
            .finally(() => {})
        }
      })
    },
    handleDeletePositionResponse (response) {
      const positionId = API.responseData(response)?.deletePositions
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && positionId) {
        this.deleteStorageDataById(positionId)
        this.getData()
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },
    deleteStorageDataById (positionId) {
      if (this.positionId === positionId) {
        this.positionId = null
      }

      this.$delete(this.positionsSummaryData, positionId)
      this.$delete(this.$dataStore.Positions.Summary.Data, positionId)
      this.$delete(this.$dataStore.Positions.SummaryMaturedTrades.Data, positionId)

      this.items = null
    },

    getItemData (item) {
      API.Resource.Positions.Summary(item.Id)
        .then(response => {
          this.handleItemResponse(response)
        })
        .catch(e => {
          this.handleItemResponse(e.response)
        })
        .finally(() => {})
    },
    handleItemResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        const p = data?.findPositions || null
        if (p) {
          const o = clone(p)
          const v = new PositionsResource(o)

          // Store data to DataStore
          this.$set(this.positionsSummaryData, v.Id, v)
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    onItemCardClick (item) {
      this.getItemData(item)

      this.$router.push({
        name  : 'PositionsShow',
        params: {
          id          : item.Id,
          positionId  : item.Id,
          positionName: item.Name
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
