<template>
  <v-col cols="12">
    <block-item-card
      ref="historicalSpectrum"
      :base-currency-symbol="riskBaseCurrencyDisplayName"
      :export-element="elementHistoricalSpectrum"
      :title="$t('Risks.Section.HistoricalSpectrum.Title')"
      :title-large="false"
      :title-value="portfolioRisk"
      :title-value-color-red="true"
      :tooltip="$t('Risks.Section.HistoricalSpectrum.InfoTip')"
    >
      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        #actions
      >
        <v-spacer />
        <asset-button-group
          :items="assets"
          :selected.sync="selectedAsset"
          class="mt-4"
        />
      </template>
      <template
        v-else
        #actions-row
      >
        <v-card-title
          class="mx-3 pa-0"
          :class="`${aggregateItem ? 'mb-2' : 'mb-4'}`"
        >
          <asset-button-group
            :items="assets"
            :selected.sync="selectedAsset"
          />
        </v-card-title>
        <v-card-title class="mx-3 mb-4 pa-0">
          <asset-button-group
            v-if="aggregateItem"
            :items="aggregateAssets"
            :selected.sync="selectedAggregateAsset"
          />
        </v-card-title>
      </template>

      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        #avatar
      >
        <v-spacer />
        <asset-button-group-orange
          v-if="aggregateItem"
          :items="aggregateAssets"
          :selected.sync="selectedAggregateAsset"
          :mandatory="false"
          class="mt-3"
        />
      </template>

      <v-row class="mb-5">
        <v-col
          cols="12"
        >
          <v-card
            class="pa-2"
            flat
          >
            <v-lazy
              :options="{threshold: .5}"
              transition="fade-transition"
            >
              <e-chart-historical-spectrum
                :axis-pointer-line-style-color="themeColorRGBA('synthesis-ui-blue-06')"
                :axis-pointer-line-style-width="2"
                :axis-x-label-interval="30"
                :axis-x-pointer-label-show="false"
                :axis-x-pointer-show="true"
                :axis-y-label-inside="false"
                :axis-y-label-margin="5"
                :axis-y-tick-prefix="riskBaseCurrencyDisplayName"
                :chart-data-options="chartDataOptions"
                :chart-data-options-updated="chartDataOptionsUpdated"
                :chart-grid-margin="{left: 10,top: 10,right: 20,bottom: 20,containLabel: true}"
                :chart-height="300"
                :chart-line-smooth="false"
                :chart-scale="true"
                :graph-line-show="true"
              />
            </v-lazy>
          </v-card>

          <seasonality-switch :selected.sync="selectedSeasonality" />
        </v-col>
      </v-row>
    </block-item-card>
  </v-col>
</template>

<script>
import BlockItemCard                  from '@/theme/default/components/common/BlockItemCard'
import AssetButtonGroup               from '@/theme/default/components/common/AssetButtonGroup'
import RiskHistoricalSpectrumResource from '@/api/resources/risks/RiskHistoricalSpectrumResource'
import API                            from '@/api/Api'
import { clone }                      from '@/lib/utils/helper'
import SeasonalitySwitch              from '@/theme/default/components/common/SeasonalitySwitch'
import chartsColorMixin               from '@/theme/default/components/charts/mixins/chartsColorMixin'
import riskAnalysisId                 from '@/theme/default/views/risks/mixins/riskAnalysisId'
import { first }                      from '@/lib/utils/array'
import riskBaseCurrency               from '@/theme/default/views/risks/mixins/riskBaseCurrency'
import EChartHistoricalSpectrum       from '@/theme/default/components/charts/echarts/EChartHistoricalSpectrum'
import AssetButtonGroupOrange         from '@/theme/default/components/common/AssetButtonGroupOrange.vue'

export default {
  name      : 'MethodTwoBlock',
  components: {
    AssetButtonGroupOrange,
    EChartHistoricalSpectrum,
    SeasonalitySwitch,
    BlockItemCard,
    AssetButtonGroup
  },
  directives: {},
  mixins    : [riskBaseCurrency, chartsColorMixin, riskAnalysisId],
  props     : {},
  dataStore : {
    riskHistoricalSpectrumData: 'Risks.HistoricalSpectrum.Data',
    riskBaseCurrencyData      : 'Risks.BaseCurrency.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      selectedAsset             : 'portfolio',
      selectedAggregateAsset    : null,
      selectedSeasonality       : ['user'],
      selectedSeasonalityColor  : 'synthesis-ui-purple-04',
      selectedAssetSeasonalities: [],
      elementHistoricalSpectrum : null
    }
  },
  computed: {
    riskHistoricalSpectrum () {
      return this.riskHistoricalSpectrumData[this.id] || null
    },

    assets () {
      return this.riskHistoricalSpectrum?.Assets || []
    },

    aggregateItem () {
      const v = this.riskHistoricalSpectrum?.Data || []

      return v.find(item => item.AssetValue === this.selectedAsset)?.AggregateItem || false
    },

    aggregateAssets () {
      const v = this.riskHistoricalSpectrum?.Data || []

      return v.find(item => item.AssetValue === this.selectedAsset)?.PerCashflowCodeNames || []
    },

    chartXAxisData () {
      return this.riskHistoricalSpectrum?.Dates || []
    },

    chartYAxisData () {
      const v = this.riskHistoricalSpectrum?.Data || []
      if (v) {
        const selectedItem = v.find(item => item.AssetValue === this.selectedAsset) || null

        let seasonality = null

        if (this.selectedAggregateAsset && selectedItem.AggregateItem) {
          seasonality = selectedItem.PerCashflowItems.find(item => item.AssetValue === this.selectedAggregateAsset)?.Seasonality || null
        } else {
          seasonality = selectedItem?.Seasonality || null
        }

        if (seasonality) {
          const data = []

          this.selectedSeasonality.forEach(item => {
            if (seasonality.hasOwnProperty(item)) {
              data.push(seasonality[item])
            }
          })

          return clone(data)
        }
      }

      return []
    },

    portfolioRisk () {
      const v = this.riskHistoricalSpectrum?.Data || []

      if (v) {
        const selectedItem = v.find(item => item.AssetValue === this.selectedAsset) || null

        let seasonality = null

        if (this.selectedAggregateAsset && selectedItem.AggregateItem) {
          seasonality = selectedItem.PerCashflowItems.find(item => item.AssetValue === this.selectedAggregateAsset)?.Seasonality || null
        } else {
          seasonality = selectedItem?.Seasonality || null
        }

        if (seasonality) {
          if (this.selectedSeasonality?.length > 1) {
            return seasonality?.user?.risk || ''
          } else {
            return seasonality[first(this.selectedSeasonality)]?.risk || ''
          }
        }
      }

      return ''
    },

    chartDataOptions () {
      return [{
        x: clone(this.chartXAxisData),
        y: clone(this.chartYAxisData)
      }]
    },

    chartDataOptionsUpdated () {
      const v = this.chartYAxisData
      this.$nextTick(() => { return v })

      return []
    }

  },
  watch: {
    selectedAsset () {
      this.setSelectedAssetAggregate()
    }
  },
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {
    this.elementHistoricalSpectrum = this.$refs?.historicalSpectrum?.$el
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Risks.HistoricalExposure(this.id).then(response => {
        this.handleResponse(response)
      }).catch(e => {
        this.handleResponse(e.response)
      })
    },
    handleResponse (response) {
      const data = API.responseData(response)
      const errors = API.responseErrors(response) || []
      const result = data?.findAnalysis || null

      if (API.isResponseSuccess(response) && result) {
        const o = clone(result)

        if (o) {
          const v = new RiskHistoricalSpectrumResource(o)

          this.$set(this.riskHistoricalSpectrumData, this.id, v)
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },
    setSelectedAssetAggregate () {
      this.selectedAggregateAsset = ''
    }
  }
}
</script>

<style scoped>

</style>
