<template>
  <v-col
    cols="12"
    style="min-height: 66px"
  >
    <slot name="breadcrumbs">
      <v-breadcrumbs
        :items="breadcrumbItems"
        class="pa-0 d-inline-block"
      >
        <template #item="{item}">
          <v-breadcrumbs-item
            :active-class="(item.disabled && item.tooltip ? 'v-breadcrumbs__item--disabled v-breadcrumbs__item--tooltip' : 'v-breadcrumbs__item--disabled')"
            :disabled="item.disabled"
            :exact="item.exact"
            :to="item.to"
          >
            {{ item.text }}
            <infotip
              v-if="item.tooltip"
              icon-style="margin-top: -16px"
              position="bottom"
            >
              {{ item.tooltip }}
            </infotip>
          </v-breadcrumbs-item>
        </template>
        <template #divider>
          <v-icon size="20">
            mdi-menu-right
          </v-icon>
        </template>
      </v-breadcrumbs>
    </slot>

    <div
      v-if="button"
      class="d-inline-block float-right"
    >
      <slot name="button">
        <v-btn
          :color="buttonColor"
          :disabled="buttonDisabled"
          :to="buttonTo ? {name: buttonTo} : {}"
          class="synthesis-text text-size-14 white--text"
          depressed
          height="42"
          large
          @click="$emit('click-button')"
        >
          <v-icon
            v-if="buttonIcon"
            left
          >
            {{ buttonIcon }}
          </v-icon>
          {{ buttonTitle }}
        </v-btn>
      </slot>
    </div>
  </v-col>
</template>

<script>
import Infotip from '@/theme/default/components/common/Infotip'

export default {
  name      : 'SectionHeader',
  components: { Infotip },
  directives: {},
  mixins    : [],
  props     : {
    breadcrumbItems: {
      type    : Array,
      required: true
    },
    button: {
      type   : Boolean,
      default: false
    },
    buttonTitle: {
      type   : String,
      default: ''
    },
    buttonIcon: {
      type   : String,
      default: 'mdi-plus-circle-outline'
    },
    buttonColor: {
      type   : String,
      default: 'synthesis-ui-green-01'
    },
    buttonTo: {
      type   : String,
      default: ''
    },
    buttonDisabled: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>
/* Breadcrumbs Override */
/deep/ .v-breadcrumbs li {
  color: var(--v-synthesis-ui-grey-01-base);
  font-weight: 500;
  font-size: 24px;
}

/deep/ .v-breadcrumbs li a, /deep/ .v-breadcrumbs__item--disabled li a {
  color: var(--v-synthesis-ui-grey-01-base) !important;
}

/deep/ .v-breadcrumbs__item--disabled {
  color: var(--v-synthesis-brand-dark-blue-base) !important;
}

/deep/ .v-breadcrumbs__item--tooltip {
  pointer-events: auto;
  cursor: default;
}

/deep/ .v-breadcrumbs li:nth-child(even) {
  padding: 0 8px;
}
</style>
