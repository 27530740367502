<template>
  <div>
    <slot name="card-actions">
      <slot name="card-actions-prepend" />

      <slot
        :btnActionColor="btnActionColor"
        :btnActionDisabled="btnActionDisabled"
        :btnActionTextColorClass="btnActionTextColorClass"
        name="btn-action"
      >
        <v-btn
          :class="[btnActionTextColorClass]"
          :color="btnActionColor"
          :disabled="!isStepValid || btnActionDisabled || stepModel > steps.length"
          block
          class="mb-2 action-button"
          depressed
          large
          @click="onStepperBtnActionClick"
        >
          {{ stepBtnAction && stepBtnAction.Title || btnActionTitle }}
        </v-btn>
      </slot>

      <slot
        :btnCancelColor="btnBackColor"
        :btnCancelDisabled="btnBackDisabled"
        :btnCancelTextColorClass="btnBackTextColorClass"
        name="btn-back"
      >
        <v-btn
          :class="[btnBackTextColorClass]"
          :color="btnBackColor"
          :disabled="btnBackDisabled || stepModel === 1"
          block
          class="mb-2 back-button"
          depressed
          large
          @click="onStepperBtnBackClick"
        >
          {{ btnBackTitle }}
        </v-btn>
      </slot>

      <slot
        :btnCancelColor="btnCancelColor"
        :btnCancelDisabled="btnCancelDisabled"
        :btnCancelTextColorClass="btnCancelTextColorClass"
        name="btn-cancel"
      >
        <v-btn
          :class="[btnCancelTextColorClass]"
          :color="btnCancelColor"
          :disabled="btnCancelDisabled"
          block
          class="cancel-button"
          depressed
          large
          text
          @click="onStepperBtnCancelClick"
        >
          {{ btnCancelTitle }}
        </v-btn>
      </slot>

      <slot name="card-actions-append" />
    </slot>
  </div>
</template>

<script>
import { isFunction }     from '@/lib/utils/type'
import formStepperCommon  from '@/theme/default/components/form/formStepper/mixins/formStepperCommon'
import formStepperActions from '@/theme/default/components/form/formStepper/mixins/formStepperActions'
import formStepperSteps   from '@/theme/default/components/form/formStepper/mixins/formStepperSteps'

export default {
  name      : 'FormStepperActions',
  components: {},
  directives: {},
  mixins    : [formStepperCommon, formStepperSteps, formStepperActions],
  props     : {},
  dataStore : {},
  enums     : {},
  dataModel : null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    onStepperBtnActionClick () {
      const from = parseInt(this.stepModel)
      if (isFunction(this.stepBtnAction?.CallBack)) this.stepBtnAction.CallBack(this.stepObj)
      if (!this.stepBtnAction?.PreventDefault) this.stepModel < this.steps.length && this.stepModel++

      this.$nextTick(() => {
        this.$emit('click-btn-action', from, this.stepModel, this.stepObj)
      })
    },

    onStepperBtnBackClick () {
      const from = parseInt(this.stepModel)
      if (isFunction(this.stepBtnBack?.CallBack)) this.stepBtnBack.CallBack(this.stepObj)
      if (!this.stepBtnBack?.PreventDefault) this.stepModel > 1 && this.stepModel--

      this.$nextTick(() => {
        this.$emit('click-btn-back', from, this.stepModel, this.stepObj)
      })
    },

    onStepperBtnCancelClick () {
      this.$emit('click-btn-cancel')
    }
  }
}
</script>

<style scoped>
.cancel-button {
  text-decoration       : underline;
  text-underline-offset : 3px;
}
</style>
