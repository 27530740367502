export default {
  Title: 'Terms & Privacy',

  TermsOfService: {
    Title: 'Terms and Conditions of Use',
    Text : `<p>Please read these Terms and Conditions of Use (the “<strong>Terms of Use</strong>”) carefully. These Terms of Use as amended, varied, or supplemented from time to time govern the use of Maven Synthesis and its contents (the “<strong>Services</strong>”) by any organisation approved by Maven Synthesis Ltd or any of its affiliates to register for, access, and use the Services (“<strong>you</strong>” or “<strong>your</strong>”). Provision of products and services outside the scope of the Services (including without limitation advice we may provide to you based on or in connection with the output of the Services) will be subject to agreement of specific terms.</p>
            <p>These Terms of Use contain important terms and will be legally binding on you if you accept them. By using the Services, you are agreeing to these Terms of Use. If you do not agree with these Terms of Use, please do not use the Services. We recommend that you save a copy of these Terms of Use for future reference.</p>
            <p class="font-weight-bold">These Terms of Use are in addition, and are without prejudice, to any other agreements between you and Maven Synthesis Ltd and/or its affiliates.</p>
            <p class="synthesis-text text-size-18 font-weight-bold">INFORMATION ABOUT US</p>
            <p>The Services are operated by Maven Synthesis Ltd (“<strong>we</strong>”, “<strong>our</strong>” and “<strong>us</strong>”). We are registered in England and Wales under company number 13611194 and our registered office is at 17 Grosvenor Street, London, W1K 4QG, United Kingdom.</p>
            <p>If you have any questions about these Terms of Use, or would otherwise like to contact us, please:</p>
            <p>
            <ul>
              <li>call +44(0) 203 325 2200; or</li>
              <li>email <a mailto="info@mavensynthesis.com">info@mavensynthesis.com</a></li>
            </ul>
            </p>
            <p>If you have any technical questions about the Services, or are facing technical issues, you can also contact us by <a mailto="info@mavensynthesis.com">support@mavensynthesis.com</a>.</p>
            <p class="synthesis-text text-size-18 font-weight-bold">ACCESSING THE SERVICES</p>
            <p>The Services are licensed to you on a personal, non-exclusive, non-assignable, non-sublicensable, and non-transferable basis for your own internal business use only and are not to be used by you for any other purpose or with or for any third party, whether in whole or in part.</p>
            <p>The Services are made available subject to payment of subscription charges and other terms as set out in the relevant subscription order form (“<strong>Order</strong>”) between you and us.</p>
            <p>We do not guarantee that the Services or any their content will always be available or be uninterrupted at any given time. We may suspend, withdraw, discontinue, or change all or any part of the Services (or suspend or withdraw your access to the Services) for business, legal, operational or security reasons. We will try to give you reasonable notice of any suspension or withdrawal, but will not be liable to you if for any reason the Services are unavailable at any time or for any period.</p>
            <p>We may also decide to make changes to the Services (including by adding new features). Depending on the update, you may not be able to use the Services until you have updated your browser or software or updated your browser settings. You may also need to accept an updated version of these Terms of Use when accessing any updated version of the Services. We will try to notify you in advance of any changes we make to the Services. If you do not want to accept the changes, please stop using the Services.</p>
            <p class="synthesis-text text-size-18 font-weight-bold">CHANGES TO THESE TERMS OF USE</p>
            <p>We reserve the right, without notice, to amend or revise these Terms of Use at any time, including where we change the features of the Services or make new services available through the Services, without any obligation or liability whatsoever towards anyone. We will upload the most recent version of the Terms of Use to the Services and you are under an obligation to ensure that you have read, understood, and accepted the Terms of Use each time you wish to access or use the Services.</p>
            <p class="synthesis-text text-size-18 font-weight-bold">NO ADVICE OR OFFER</p>
            <p>The content of the Services shall not constitute an offer, solicitation, or recommendation of any nature whatsoever. You should not rely solely on any information on the Services to make or refrain from making any decision of whatsoever nature (each a “<strong>Transaction</strong>”) or in substitution for the exercise of independent judgment. Nothing within the Services shall be construed as us providing any legal, financial, accounting, investment, or any other form of professional advice to you for any purpose whatsoever. We assume no responsibility to assess or advise on, and we make no representation as to, the appropriateness of any Transaction for any purpose. Where any opinions are expressed on the Services, they are provided by us for personal use and for informational purposes only.</p>
            <p>You acknowledge and agree that these Terms of Use apply notwithstanding the use of language such as or similar to the following within the content of the Services in relation to positions, strategies, or otherwise: “optimal”, “favourable”, “ideal”, “recommended”, “suggested”, “nominated”, “proposed”, “preferred”.</p>
            <p>Nothing in these Terms of Use or within the Services shall oblige us to enter into any Transaction with you.</p>
            <p class="synthesis-text text-size-18 font-weight-bold">LINKS TO THIRD PARTY WEBSITES</p>
            <p>The Services may include links to <span style="border-bottom-style: double;">third party</span> websites, research, and resources, including annual reports for corporates, that are controlled and maintained by others. Any link to a <span style="border-bottom-style: double;">third party</span> website, research, or resource is not an endorsement of any such website, research, or resource and these links are provided for your information only. You acknowledge and agree that we have no control over, and are not responsible for, the content or availability of any third party website, research, or resource. We will not be liable for any loss or damage that may arise from your use of such <span style="border-bottom-style: double;">third party</span> websites, research, or resources.</p>
            <p class="synthesis-text text-size-18 font-weight-bold">INTELLECTUAL PROPERTY RIGHTS</p>
            <p>All copyright, trademarks, and all other intellectual property rights in the Services and all the content, data, and information within the Services, including without limitation their design, logos, text, graphics and all software and source codes connected with the Services, are either owned by or licensed to us or otherwise used by us as permitted by the owner(s) thereof. The works on the Services are protected by copyright laws and treaties around the world and all such rights are reserved. You may only use any of such intellectual property rights to the extent permitted under these Terms of Use or required to be permitted by applicable law.</p>
            <p>Each of the following is a breach of these Terms of Use: (a) framing the Services on any other website or resource; (b) creating a link to any part of the Services on any other website or resource; (c) suggesting (in any way) any form of association, approval, or endorsement on our part; or (d) collecting any data from the Services through automated means, such as through harvesting bots, robots, spiders, or scrapers.</p>
            <p>You will indemnify us from and against any losses, damages, costs, charges, expenses and other liabilities (including legal fees) incurred by or awarded against us or our affiliates as a result of, or in connection with, your use of the intellectual property rights in the Services otherwise than in accordance with these Terms of Use.</p>
            <p>You grant to us a worldwide, non-exclusive, perpetual, irrevocable, and royalty-free licence to use any data you provide in the course of, or in relation to, your use of the Services for the purpose of providing the Services and for internal business purposes.</p>
            <p class="synthesis-text text-size-18 font-weight-bold">YOUR OBLIGATIONS</p>
            <p>You must not use the Services for any purpose that is unlawful under any applicable law or as prohibited by these Terms of Use. You must also not copy, reproduce, modify, or tamper with the Services or any of their content in any way, or use the Services in a way that could damage or affect our systems or security or interfere with other users, or restrict anyone else’s use of the Services.</p>
            <p>You must not use the Services to violate copyright, trademark, proprietary or other intellectual property rights of any member of the Maven group or any third party or to transmit confidential or proprietary information, except your own and then solely at your own risk.</p>
            <p>You must not misuse the Services by knowingly introducing viruses, trojans, worms, logic bombs or other material which is either malicious or technologically harmful. You must not attempt or facilitate or engage another to gain unauthorised access to the Services, the server on which the Services are stored or hosted, or any server, computer or database connected to the Services. You must not attack the Services via a denial-of-service attack or a distributed denial-of-service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use the Services will cease immediately.</p>
            <p>We reserve the right, in our absolute discretion, to provide or not to provide access to the Services to any of your employees or other personnel (“<strong>Users</strong>”) and, if access is declined or withdrawn for any reason, you will take all steps necessary to prevent affected Users from using the Services.  You will:</p>
            <p>
            <ul>
              <li>comply with all of our requirements from time to time relating to authorisation, set-up, and authentication of your Users;</li>
              <li>not allow persons other than Users authorised by us to access the Services;</li>
              <li>be responsible for all communications submitted using access credentials we provide to you, whether by Users or any other person; and</li>
              <li>notify us as soon as possible if you wish to revoke or change the access credentials or permissions of any User.</li>
            </ul>
            </p>
            <p>If someone else tries to access, or has accessed, your instance of the Services, or your access to the Services is otherwise compromised, please contact us as soon as possible to allow us to investigate. You will indemnify us from and against any losses, damages, costs, charges, expenses, and other liabilities (including legal fees) incurred by or awarded against us or our affiliates resulting from the disclosure of any credentials to a third party and/or a failure by you to keep any credentials secure and safe, whether such disclosure or failure was committed by any of your employees or otherwise.</p>
            <p class="synthesis-text text-size-18 font-weight-bold">CONFIDENTIALITY</p>
            <p>In respect of the Services, you undertake that you will not make any copies of or disclose at any time to any person any information, in whatever form (including, without limitation, in written, oral, visual or electronic form or on any magnetic or optical disk or memory and wherever located) and regardless of obligations of confidentiality applicable to the recipient of the information, relating to the Services (save for their existence), which will include without limitation (a) any technical data or other information that would be regarded as confidential by a reasonable business person relating to our operations, processes, know-how, designs, trade secrets or software, and (b) any technical data or other information developed by us in the course of providing the Services (“<strong>Confidential Information</strong>”), except as set out below:</p>
            <p>
            <ul>
              <li>your employees, officers, auditors, or legal advisers who need to know such information for the purposes of exercising your rights, provided that you:
               <ul>
               <li>inform such parties of the confidential nature of the Confidential Information before disclosure; and</li>
               <li>procure that any such party shall, in relation to any Confidential Information disclosed to them, comply with the obligations set out in this clause as if they were a party to these Terms of Use,</li>
               </ul>
               and at all times, you will be liable for the failure of any such party to comply with the confidentiality obligations set out above; and
              </li>
              <li>as you may be required by law, a court of competent jurisdiction or any governmental or regulatory authority having jurisdiction in relation to you.</li>
            </ul>
            </p>
            <p class="synthesis-text text-size-18 font-weight-bold">DATA PROTECTION AND PRIVACY</p>
            <p>We will collect and use your and your Users’ personal data when you use the Services in accordance with our Privacy Notice.</p>
            <p class="synthesis-text text-size-18 font-weight-bold">DISCLAIMERS</p>
            <p>The Services are and will be made available by us from time to time at our sole discretion on an “<strong>AS IS</strong>” and “<strong>AS AVAILABLE</strong>” basis without any representation or endorsement of any kind and without any warranty of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security, or accuracy or of any other kind whatsoever, whether express or implied.</p>
            <p>We make no warranty that the Services will operate or be available error free, that defects will be corrected or that the Services or the server that makes it available are free of viruses or anything else which may be harmful in any manner. You are responsible, at your own cost and expense, for configuring your network connection, devices, and other information technology, in order to access the Services. Some features of the Services may not be available depending on your computer, mobile device, or operating system. You should use your own virus protection software (if you think this is necessary). We will not be responsible for any <span style="border-bottom-style: double;">third party</span> services or products that you use to access the Services.</p>
            <p>We do not guarantee your access to the Services, which may from time to time be unavailable, delayed, limited or slow due to any technical reasons and we will not be liable in any way for such delay or disruption in the operation of the Services, or any part of them, or for any failure of any connection or communication related thereto or the non-availability of any information in them.</p>
            <p>We will not be liable to you or any third party for the correctness, quality, accuracy, security, completeness, or reliability of the Services or any information within them or for any decision made or action taken by you in reliance upon such information. Although we take reasonable steps to update the content on the Services, the content may be out of date at any given time, and we are under no obligation to update it and make no representations, warranties or guarantees that the content of the Services is accurate or complete.</p>
            <p>We accept no liability (including without limitation) in either contract, tort (including negligence), statutory duty or otherwise (to the maximum extent permitted by applicable law) arising out of or in connection with the use of or access to the Services and we shall not be liable for any direct or indirect:</p>
            <p>
            <ul>
              <li>economic losses (including without limitation loss of revenues, data, profits, contracts, use, opportunity, business, or anticipated savings);</li>
              <li>loss of goodwill or reputation;</li>
              <li>special, incidental, or consequential loss or damage,</li>
              <li>losses arising out of or in connection with any claim or demand from any third party arising out of your use of the Services; or</li>
              <li>losses suffered or incurred arising out of or in connection with your use of the Services or these Terms of Use.</li>
            </ul>
            </p>
            <p>Neither you nor we exclude liability for death or injury caused by negligence, or for any other liability that cannot be limited under law. Nothing in these Terms of Use shall exclude or limit or restrict our duties to you under the Financial Services and Markets Act 2000 and any rules made under it or any conduct of business rules which we are bound to comply with.</p>
            <p>If you are dissatisfied with any part of the Services or with these Terms of Use, your sole and exclusive remedy is to discontinue using the Services.</p>
            <p class="synthesis-text text-size-18 font-weight-bold">ENDING THESE TERMS OF USE</p>
            <p>If you do not agree to these Terms of Use (or any future versions of them), you should stop using the Services. Your right to use the Services will end automatically, but you will still be responsible for your use of them before you stopped using them.</p>
            <p>In addition to our other rights set out in these Terms of Use or other terms applicable to you, we can terminate your access to the Services immediately and without notice if:</p>
            <p>
            <ul>
              <li>you seriously or repeatedly breach any of these Terms of Use; or</li>
              <li>we suspect that your network connection, devices, or other intellectual property are not secure or contain, or are vulnerable to, viruses or malicious software.</li>
            </ul>
            </p>
            <p>Although we will try to inform you of our intention to terminate in advance, this may not always be possible (for example, we may be prevented from doing this under law).</p>
            <p class="synthesis-text text-size-18 font-weight-bold">OTHER TERMS</p>
            <p>These Terms of Use and the relevant Order shall constitute the entire agreement between the parties relating to the use of the Services and supersede and extinguish all previous representations, arrangements and agreements between the parties relating to its subject matter. Each party acknowledges and agrees that no representations were made prior to entering into these Terms of Use and that, in entering into these Terms of Use, it does not rely on, and shall have no remedy in respect of, any statement, representation, warranty or understanding (whether negligently or innocently made) of any person (whether party to these Terms of Use or not) other than as expressly set out or referred to in these Terms of Use.</p>
            <p>If any part of these Terms of Use is determined to be invalid, illegal, or unenforceable for any reason by any court of competent jurisdiction, those part(s) will be severed and the remaining parts in these Terms of Use shall survive and remain in full force and effect and continue to be binding and enforceable.</p>
            <p>A waiver by either party of any term or condition of these Terms of Use shall not be deemed or construed to be a waiver of any other term or condition of these Terms of Use or a waiver of such term or condition in the future. No delay or omission by either party to exercise any right or remedy provided for by law or under these Terms of Use will operate to waive, limit, or otherwise affect that right or remedy. A party seeking to waive any right or remedy shall give to the other party notice of waiver in writing signed by its duly authorised representative.</p>
            <p>These Terms of Use may not be assigned or otherwise transferred by you (in whole or in part) without first obtaining our written consent. We may, by notice to you, assign or novate these Terms of Use and its rights and benefits under them (in whole or in part) to any of our affiliates or any entity which succeeds to all or substantially all of our assets and business.</p>
            <p>These Terms of Use do not give rise to any rights for a third party to enforce any of its terms, except that our affiliates may enforce any term of which they have the benefit under these Terms of Use.</p>
            <p class="synthesis-text text-size-18 font-weight-bold">GOVERNING LAW</p>
            <p>These Terms of Use shall be governed by and construed in accordance with English law. You agree that the English courts have exclusive jurisdiction to settle any dispute arising out of or in connection with these Terms of Use (including a dispute or claim relating to any non-contractual obligation arising out of or in connection with these Terms of Use). You further agree to waive any objection to the English courts, whether on the grounds of venue or that the forum is not appropriate.</p>
            `
  }
}
