<template>
  <table-header-gradient-blue
    v-if="tableValues"
    :export-data="exportData"
    :export-file-name="exportFileName"
    :title="title"
    :tooltip="$t('Hedging.Table.Costs.Titles.ForwardsInterbankRates.InfoTip')"
  >
    <template #table>
      <v-data-table
        :headers="table.headers"
        :items="tableValues"
        class="font-weight-medium synthesis-card-border"
        disable-pagination
        disable-sort
        hide-default-footer
      >
        <template #item.Maturity="{item}">
          {{ item.Maturity }}
        </template>

        <template #item.ForwardRate="{item}">
          {{ item.ForwardRate }}
        </template>

        <template #item.Carry="{item}">
          <v-chip
            :color="item.CarryColor"
            :text-color="item.CarryTextColor"
          >
            {{ item.CarryFmt }}
          </v-chip>
        </template>
      </v-data-table>
    </template>
  </table-header-gradient-blue>
</template>

<script>

import policyId                from '@/theme/default/views/hedging/mixins/policyId'
import TableHeaderGradientBlue from '@/theme/default/components/common/TableHeaderGradientBlue'
import { toPascalCase }        from '@/lib/utils/string'

export default {
  name      : 'HedgingCostsForwardsInterbankRatesTableBlock',
  components: {
    TableHeaderGradientBlue
  },
  mixins: [policyId],
  props : {
    selectedAsset: {
      type   : String,
      default: ''
    }
  },
  dataStore: {
    hedgingCostsData: 'Hedging.Costs.Data'
  },
  data () {
    return {
      table: {
        headers: [
          {
            text : this.$t('Hedging.Table.Costs.Headers.Maturity'),
            align: 'start',
            value: 'Maturity',
            class: 'grey lighten-5'
          },
          {
            text : this.$t('Hedging.Table.Costs.Headers.ForwardRate'),
            value: 'ForwardRate',
            class: 'grey lighten-5'
          },
          {
            text : this.$t('Hedging.Table.Costs.Headers.Carry'),
            value: 'Carry',
            class: 'grey lighten-5'
          }
        ]
      }
    }
  },
  computed: {
    title () {
      return this.$t('Hedging.Table.Costs.Titles.ForwardsInterbankRates.Title')
    },

    asset () {
      return this.selectedAsset || ''
    },

    costs () {
      return this.hedgingCostsData[this.id] ?? null
    },

    assets () {
      return this.costs?.Assets || []
    },

    assetName () {
      const asset = this.assets.find(item => item.Value === this.asset) || null

      return asset?.Title || ''
    },

    assetCost () {
      const v = this.costs?.Costs

      if (v && !!v.length) {
        return v.find(item => item.AssetValue === this.asset) || null
      }
      return null
    },

    tableValues () {
      return this.assetCost?.ForwardsInterbankRates || []
    },

    exportData () {
      return this.assetCost?.ExportForwardsInterbankRatesData || []
    },

    exportFileName () {
      const asset = this.assetName.toLocaleUpperCase(this.$i18n.locale)
      const title = toPascalCase(this.title)

      return `${ asset }-${ title }`
    }
  }
}
</script>
