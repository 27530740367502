import BaseResource                       from '@/lib/data/resource/BaseResource'
import { collect }                        from '@/lib/utils/array'
import TrackLivePriceCategoryItemResource from '@/api/resources/dashboard/TrackLivePriceCategoryItemResource'
import TrackLivePriceItemResource         from '@/api/resources/dashboard/TrackLivePriceItemResource'

export default class TrackLivePricesResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.categoryItems = collect(payload.categories, TrackLivePriceCategoryItemResource)
    this.prices = collect(payload.prices, TrackLivePriceItemResource)
    this.subscribedAssetsLivePrices = collect(payload.subscribedAssetsLivePrices, TrackLivePriceItemResource)
  }

  /* PROPERTIES */
  get categories () {
    return this.categoryItems.filter(item => item.display).map(item => ({
      Title: item.categoryName,
      Value: item.category
    }))
  }

  /* METHODS */
}
