<template>
  <v-container
    class="fill-height align-start"
    fluid
  >
    <v-row
      class="fill-height"
      dense
    >
      <v-col
        class=""
        cols="12"
        lg="8"
        md="7"
        sm="12"
        xl="10"
      >
        <v-row>
          <section-header :breadcrumb-items="breadcrumbItems" />

          <v-col
            v-if="$vuetify.breakpoint.sm"
            class="py-0"
            cols="12"
          >
            <form-stepper-steps
              :step.sync="step"
              :steps="steps"
            />
          </v-col>

          <v-col
            :class="[{'pa-0': !stepHasCard}]"
            :cols="stepCols"
          >
            <v-card
              :color="stepBgColor"
              class="synthesis-card-border"
              flat
            >
              <v-card-text
                :class="[stepHasCard ? 'pa-sm-4' : 'pa-sm-0']"
                class="pa-0"
              >
                <v-form
                  ref="form"
                  v-model="isFormValid"
                  @submit.prevent
                >
                  <v-window v-model="step">
                    <v-window-item :value="1">
                      <comparison-table v-model="model.companyIds" />
                    </v-window-item>

                    <v-window-item :value="2">
                      <comparison-attributes v-model="model.comparisonAttributeIds" />
                    </v-window-item>

                    <v-window-item :value="3">
                      <comparison-table-summary
                        :comparison-id="model.id"
                        :expand-all-fields="expandAllFields"
                        :remove-container-col-padding="false"
                        :remove-container-padding="false"
                        :remove-container-row-margin="false"
                        hide-title
                      >
                        <template #toolbar>
                          <v-toolbar
                            :height="100"
                            class="fill-height"
                            color="transparent"
                            flat
                          >
                            <div class="fill-height justify-start">
                              <label class="field-label">{{ $t('Industry.New.Form.Fields.Name.Label') }}</label>
                              <v-text-field
                                v-model="model.name"
                                :error-messages="serverErrorMessages['name']"
                                :hide-details="$vuetify.breakpoint.xs ? 'auto' : false"
                                :placeholder="$t('Industry.New.Form.Fields.Name.Placeholder')"
                                :rules="model.validator.vuetifyFormFieldRules('name')"
                                background-color="white"
                                dense
                                height="42"
                                outlined
                              />
                            </div>
                            <v-spacer />
                            <div class="d-flex fill-height align-center justify-end mt-1 mt-sm-4">
                              <v-btn
                                class="synthesis-text text-size-14 white--text mb-sm-2"
                                color="synthesis-ui-green-01"
                                depressed
                                height="42"
                                large
                                @click="toggleExpandAllFields"
                              >
                                {{ $t(`Industry.Button.${ expandAllFields ? 'Retract' : 'Expand' }.Title`) }}
                              </v-btn>
                            </div>
                          </v-toolbar>
                        </template>
                      </comparison-table-summary>
                    </v-window-item>
                  </v-window>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        class="text-right"
        cols="12"
        lg="4"
        md="5"
        sm="12"
        xl="2"
      >
        <form-stepper
          :step.sync="step"
          :steps="steps"
          class="d-inline-flex"
          @click-btn-cancel="onStepperBtnCancelClick"
          @click-btn-back="onStepperBtnBackClick"
        />
      </v-col>
    </v-row>

    <overlay-loader :value="loaderVisible" />
  </v-container>
</template>

<script>

import i18nRouteMeta                from '@/mixins/i18n/i18nRouteMeta'
import SectionHeader                from '@/theme/default/components/common/SectionHeader'
import OverlayLoader                from '@/theme/default/components/common/OverlayLoader'
import FormStepper                  from '@/theme/default/components/form/formStepper/FormStepper'
import FormStepperSteps             from '@/theme/default/components/form/formStepper/components/FormStepperSteps'
import assetExposureCommon          from '@/mixins/assetExposureCommon'
import formData                     from '@/mixins/form/formData'
import formStepper                  from '@/theme/default/components/form/formStepper/mixins/formStepper'
import API                          from '@/api/Api'
import { clone }                    from '@/lib/utils/helper'
import ComparisonTable              from '@/theme/default/views/industry/blocks/ComparisonTable'
import ComparisonAttributes         from '@/theme/default/views/industry/blocks/ComparisonAttributes'
import ComparisonTableSummary       from '@/theme/default/views/industry/blocks/ComparisonTableSummary'
import IndustryComparisonInputModel from '@/api/models/industry/IndustryComparisonInputModel'
import ComparisonSummaryResource    from '@/api/resources/industry/ComparisonSummaryResource'
import { UUIDGeneratorBrowser }     from '@/lib/utils/browser'

export default {
  name      : 'IndustryComparisonNew',
  components: {
    ComparisonTableSummary,
    ComparisonAttributes,
    ComparisonTable,
    FormStepperSteps,
    FormStepper,
    OverlayLoader,
    SectionHeader
  },
  directives: {},
  mixins    : [i18nRouteMeta, assetExposureCommon, formData, formStepper],
  props     : {},
  dataStore : {
    comparisonSummaryData: 'Industry.Summary.Data'
  },
  enums    : {},
  dataModel: IndustryComparisonInputModel,
  data () {
    return {
      loaderVisible  : false,
      expandAllFields: false,
      breadcrumbItems: [
        {
          text    : this.$t('Industry.Title'),
          disabled: false,
          exact   : true,
          to      : { name: 'Industry' }
        },
        {
          text    : this.$t('Industry.New.Title'),
          disabled: true
        }
      ]
    }
  },
  computed: {
    steps () {
      return [
        {
          Title   : this.$t('Industry.New.Form.Steps.Step1.Title'),
          Complete: this.step > 1,
          HasCard : false,
          BgColor : 'transparent',
          Fields  : ['companyIds']
        },
        {
          Title    : this.$t('Industry.New.Form.Steps.Step2.Title'),
          Complete : this.step > 2,
          HasCard  : false,
          BgColor  : 'transparent',
          Fields   : ['comparisonAttributeIds'],
          BtnAction: {
            Title         : this.$t('Common.Button.Continue'),
            CallBack      : this.createComparison,
            PreventDefault: false
          }
        },
        {
          Title    : this.$t('Industry.New.Form.Steps.Step3.Title'),
          Complete : this.step > 3,
          HasCard  : false,
          BgColor  : 'transparent',
          Fields   : ['name'],
          BtnAction: {
            Title         : this.$t('Industry.New.Form.Steps.Step3.Button.Action'),
            CallBack      : this.saveComparison,
            PreventDefault: true
          }
        }
      ]
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onStepperBtnBackClick (from, to) {},

    onStepperBtnCancelClick () {
      this.$router.push({ name: 'Industry' })
    },

    toggleExpandAllFields () {
      this.expandAllFields = !this.expandAllFields
    },

    createComparison () {
      this.model.accountId = this.user.selectedAccountId
      this.loaderVisible = true

      this.model.CreateComparison()
        .then(response => {
          this.handleCreateComparisonResponse(response)
        })
        .catch(e => {
          this.handleCreateComparisonResponse(e.response)
        })
        .finally(() => {
          this.loaderVisible = false
        })
    },
    handleCreateComparisonResponse (response) {
      const data = API.responseData(response)?.newComparison || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.setData(data)
      } else {
        this.step--
        this.$bus.$emit('app:error', errors)
      }
    },

    saveComparison () {
      this.model.accountId = this.user.selectedAccountId
      this.loaderVisible = true

      this.model.SaveComparison()
        .then(response => {
          this.handleSaveComparisonResponse(response)
        })
        .catch(e => {
          this.handleSaveComparisonResponse(e.response)
        })
        .finally(() => {
          this.loaderVisible = false
        })
    },
    handleSaveComparisonResponse (response) {
      const data = API.responseData(response)?.saveComparison || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.setData(data)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    setData (data) {
      const persistent = data.persistent ?? false
      const id = persistent ? data.id : UUIDGeneratorBrowser()

      // Comparison id
      this.comparisonId = id

      if (data) {
        const o = clone(data)

        o.id = id
        const v = new ComparisonSummaryResource(o)

        // Store data to DataStore
        this.$set(this.comparisonSummaryData, v.Id, v)
      }

      this.model.id = id

      if (persistent) {
        this.$router.push({ name: 'Industry' })
      }
    }
  }
}
</script>

<style scoped>
/deep/ .v-toolbar__content {
  padding-left  : 4px;
  padding-right : 4px;
}
</style>
