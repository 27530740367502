<script>

import AssetButtonGroup from '@/theme/default/components/common/AssetButtonGroup.vue'

export default {
  name   : 'AssetButtonGroupOrange',
  extends: AssetButtonGroup
}
</script>

<style scoped>
/deep/ .v-item-group.theme--light.v-btn-toggle.v-btn-toggle--borderless.v-btn-toggle--dense.v-btn-toggle--group {
  background-color: var(--v-synthesis-ui-orange-07-base) !important;
  border-color: var(--v-synthesis-ui-orange-06-base) !important;
  color: var(--v-synthesis-ui-blue-04-base) !important;
}

/deep/ .theme--light.toggle-active.v-btn--active::before,
.theme--light.toggle-active.v-btn--active:hover::before,
.theme--light.toggle-active.v-btn--active {
  opacity: 1 !important;
  background-color: #FFFFFF !important;
}

/deep/ .theme--light.v-btn::before {
  background-color: #FFFFFF !important;
  opacity: 0;
}

/deep/ .theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before {
  color: var(--v-synthesis-ui-blue-04-base);
}

/deep/ .v-btn-toggle .v-btn > span.v-btn__content {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: var(--v-synthesis-ui-grey-01-base) !important;
}

/deep/ .theme--light.toggle-active.v-btn--active > span.v-btn__content {
  font-weight: 600 !important;
  color: var(--v-synthesis-ui-blue-04-base) !important;
}

</style>
