export default {
  Title: 'Risk',

  Message: {
    NoActiveData: {
      Title: 'No active Risk Analysis currently setup'
    },
    NoArchiveData: {
      Title: 'No Risk Analyses currently setup'
    },
    NoForecastsData: {
      Title: 'No Forecasts data available'
    }
  },

  Section: {
    RiskSummary: {
      Title    : 'Risk Summary',
      TotalRisk: {
        Title: 'Total Risk'
      },
      PercentageTotalRisk: {
        Title  : 'Total Risk per Asset',
        InfoTip: 'Contribution of each asset in Total Risk.'
      },
      MethodologyBreakdown: {
        Title   : 'Methodology Contribution',
        InfoTip : 'Results of 4 independent methodologies on risk. Note that the total risk per methodology tends to be lower than the sum of asset risks due to correlation effects (except VaR methodology).',
        BarChart: {
          XAxis: ['VAR', 'HIST', 'FWD', 'EX EV']
        }
      }
    },
    ValueAtRisk: {
      Caption: 'METHOD ONE',
      Title  : 'Value at Risk',
      Block  : {
        FinancialExposure: {
          Title  : 'Financial Exposure',
          InfoTip: 'Breakdown of annual exposures (user input in local currency) expressed in base currency using the most recent spot level.'
        },
        ValueAtRisk: {
          Title  : 'Value at Risk',
          InfoTip: 'Value at Risk methodology is widely used by both corporates and financial institutions. Here, each asset is stressed by a 2σ event, namely two times its historical volatility. The methodology assumes twelve monthly cashflows with seasonality input by the user. In this methodology, correlation does not enter the picture and risk tends to be higher.'
        },
        Volatility: {
          Title  : 'Volatility',
          InfoTip: 'Historical volatility of each asset, calculated as the standard deviation of monthly returns since 2000.'
        }
      }
    },
    HistoricalSpectrum: {
      Caption: 'METHOD TWO',
      Title  : 'Historical Spectrum',
      InfoTip: 'This methodology utilises Maven’s proprietary models and assumes constant local exposures with spot prices changing as we go back in time. The methodology assumes twelve monthly cashflows with seasonality input by the user, kept constant historically.'
    },
    MonteCarloSimulation: {
      Caption: 'METHOD THREE',
      Title  : 'Forward Simulations',
      InfoTip: 'This methodology utilises Maven’s proprietary models using 10,000 – 100,000 forward simulations per asset. The underlying processes are based on General Brownian Motion and Jump Diffusion models. The bell shaped distribution depicts total cashflow scenarios in base currency over a year. The green bar shows the average cashflow while the orange bar depicts the unfavourable 1st percentile of the distribution. The difference between the two represents the potential risk over a one year period.',
      Block  : {
        SimulatedPaths: {
          Title  : 'Simulated Paths',
          InfoTip: 'A small subset of the paths that were created to estimate the risk per asset is demonstrated here.'
        },
        Distribution: {
          Title     : 'Risk Distribution',
          VaR_Pct_1 : '1% VAR',
          VaR_Pct_5 : '5% VAR',
          Mean      : 'Mean',
          Risk_Pct_1: {
            Title  : '1% Risk',
            InfoTip: 'Using 12-step Monte Carlo processes, a high number of yearly paths are created by adding the monthly cash flows in base currency. The number here represents the 1st percentile of  the annual cashflows distribution.'
          },
          AverageCashFlow: {
            Title  : 'Av. Cashflow',
            InfoTip: 'Using 12-step Monte Carlo processes, a high number of yearly paths are created by adding the monthly cash flows in base currency. The number here represents the mean of  the annual cashflows distribution.'
          }
        }
      }
    },
    ExtremeEvents: {
      Caption: 'METHOD FOUR',
      Title  : 'Extreme Events',
      InfoTip: 'This proprietary methodology looks at how the exposures portfolio would have behaved in crises of the past. The four worst periods for the particular portfolio are presented here with a breakdown of each component.'
    },
    CorrelationsAcrossExposures: {
      Title    : 'Correlations across exposures',
      DateRange: 'Date Range',
      Weekly   : 'Weekly',
      Monthly  : 'Monthly',
      Preset   : [
        {
          Title    : 'Last 6 months',
          Value    : -7,
          ValueType: 'month'
        },
        {
          Title    : 'Last year',
          Value    : -1,
          ValueType: 'year'
        },
        {
          Title    : 'Last 3 years',
          Value    : -3,
          ValueType: 'year'
        },
        {
          Title    : 'Last 5 years',
          Value    : -5,
          ValueType: 'year'
        },
        {
          Title    : 'Last 10 years',
          Value    : -10,
          ValueType: 'year'
        },
        {
          Title    : 'Full Sample',
          Value    : -(parseInt(new Date().getFullYear()) - 2000),
          ValueType: 'year'
        }
      ]
    },
    SeasonalityAnalysis: {
      Title  : 'Seasonality Profile',
      InfoTip: 'A “Low” Seasonality denotes monthly flow that are closer to a uniform monthly profile, while a “High” Seasonality denotes highly seasonal monthly cash flows.',
      Block  : {
        CashFlowProfile: {
          Title  : 'Monthly Cashflow Profile',
          InfoTip: 'Local exposures as input by the user. Blue font indicates revenues/cash inflows while red font indicates expenses/cash outflows.'
        },
        AssetSeasonality: {
          Title: 'Asset Seasonality'
        },
        Impact: {
          Title: 'Impact',
          Chart: {
            High: 'High',
            Low : 'Low',
            Med : 'Med',
            Min : 'Min',
            Max : 'Max'
          }
        }
      }
    },
    ForecastsForExposures: {
      Title       : 'Forecasts for exposures',
      InfoTip     : 'Forecasts for exposures ... Lorem ipsum dolor sit amet, nobis dicam per id, choro graece cu sea. Solum iuvaret erroribus eam at',
      MeanForecast: 'Mean Forecast'
    },
    DebtProfile: {
      Title  : 'Debt Profile',
      InfoTip: 'Debt Profile...',
      Edit   : {
        Title      : 'View / Edit Debt Inputs',
        TitleExport: '@:Risks.Section.DebtProfile.Title Values - {name}',
        Form       : {
          Section: {
            OtherInputs: {
              Title : 'Inputs',
              Fields: {
                Name: {
                  Label      : 'Debt Profile Scenario',
                  Placeholder: 'Enter debt profile scenario'
                },
                Equity: {
                  Label      : 'Equity',
                  Placeholder: 'Enter equity'
                },
                Ebitda: {
                  Label      : 'EBITDA',
                  Placeholder: 'Enter EBITDA'
                }
              }
            },
            FixedDebt: {
              Title : 'Fixed Debt',
              Fields: {
                Name: {
                  Label      : 'Name',
                  Placeholder: 'Enter fixed debt name'
                },
                Maturity: {
                  Label      : 'Maturity',
                  Placeholder: 'Select maturity date'
                },
                Notional: {
                  Label      : 'Outstanding Notional',
                  Placeholder: 'Enter notional'
                },
                Coupon: {
                  Label      : 'Coupon (%)',
                  Placeholder: 'Enter coupon (%)'
                },
                Actions: {
                  Label      : 'Actions',
                  Placeholder: 'Actions'
                }
              }
            },
            FloatingDebt: {
              Title : 'Floating Debt',
              Fields: {
                Name: {
                  Label      : 'Name',
                  Placeholder: 'Enter floating debt name'
                },
                Maturity: {
                  Label      : 'Maturity',
                  Placeholder: 'Select maturity date'
                },
                Notional: {
                  Label      : 'Outstanding Notional',
                  Placeholder: 'Enter notional'
                },
                Spread: {
                  Label      : 'Spread (%)',
                  Placeholder: 'Enter spread (%)'
                },
                Actions: {
                  Label      : 'Actions',
                  Placeholder: 'Actions'
                }
              }
            },
            Derivatives: {
              Title : 'Derivatives',
              Fields: {
                Name: {
                  Label      : 'Name',
                  Placeholder: 'Enter derivative name'
                },
                Maturity: {
                  Label      : 'Maturity',
                  Placeholder: 'Select maturity date'
                },
                Notional: {
                  Label      : 'Outstanding Notional',
                  Placeholder: 'Enter notional'
                },
                FixedRate: {
                  Label      : 'Agreed Fixed Rate (%)',
                  Placeholder: 'Enter agreed fixed rate (%)'
                },
                Indication: {
                  Label      : 'Current Indication (%)',
                  Placeholder: 'Current Indication (%)'
                },
                Actions: {
                  Label      : 'Actions',
                  Placeholder: 'Actions'
                }
              }
            }
          },
          Button: {
            AddNew: {
              Title: '@:Common.Button.Add @:Common.Button.New'
            },
            AddAmortisation: {
              Title: '@:Common.Button.Add Amortisation'
            },
            EditAmortisation: {
              Title: '@:Common.Button.Edit Amortisation'
            },
            SaveAmortisation: {
              Title: '@:Common.Button.Save Amortisation'
            },
            DeleteRow: {
              Title: '@:Common.Button.Delete Row'
            },
            ClearAmortisation: {
              Title: '@:Common.Button.Clear Amortisation'
            },
            Save: {
              Title: '@:Common.Button.Save @:Risks.Section.DebtProfile.Title'
            },
            Edit: {
              Title: '@:Common.Button.Edit @:Risks.Section.DebtProfile.Title'
            },
            Update: {
              Title: '@:Common.Button.Update @:Risks.Section.DebtProfile.Title'
            },
            Cancel: {
              Title: '@:Common.Button.Cancel'
            }
          }
        }
      },
      Block: {
        Scenario: {
          Title  : 'Scenario: {name}',
          InfoTip: 'The Debt Profile tab allows users to see the impact of new debt issuance, debt repayment and derivatives to fixed/floating ratios, interest expense ranges and credit ratios. All sections are exportable. In this input field, users can input the name of the scenario they wish to investigate.'
        },
        Maturities: {
          Title   : 'Debt Maturities',
          InfoTip : '',
          Floating: {
            Title: 'Floating'
          },
          Fixed: {
            Title: 'Fixed'
          },
          Total: {
            Title: 'Total'
          },
          Items: [
            {
              Title: 'Yearly',
              Value: 'Yearly'
            },
            {
              Title: 'Quarterly',
              Value: 'Quarterly'
            }
          ]
        },
        FixedVsFloating: {
          Title     : 'Fixed vs Floating Mix',
          TitleValue: {
            Prefix: 'Outstanding Debt:'
          },
          InfoTip      : 'In the “Unhedged” scenario, current debt outstanding is used split between liabilities that have a floating leg versus those pays a fixed coupon/rate (no derivatives included). In the “After Hedging” scenario, all outstanding interest rate derivatives (Interest Rate Swaps, Caps etc.) are now taken into the calculation of the fixed/floating mix.',
          BeforeHedging: {
            Title: 'Unhedged'
          },
          AfterHedging: {
            Title: 'After Hedging'
          },
          Floating: {
            Title: 'Floating'
          },
          Fixed: {
            Title: 'Fixed'
          }
        },
        InterestExpense: {
          Title     : 'Interest Expense Variability',
          TitleValue: {
            Prefix: 'Current Rate:'
          },
          InfoTip      : 'In the “Unhedged” scenario, the midpoint is the mean interest expense (per annum) assuming current Libor levels. All debt is included but the variation is only due to the fluctuation of the floating debt components. In addition to the mean, the 1% and 99% intervals are shown based on current Libor distributions. Orange dotted line denotes market expectation for LIBOR of that year. Current LIBOR assumes 3-months US Libor.',
          BeforeHedging: {
            Title: 'Unhedged'
          },
          AfterHedging: {
            Title: 'After Hedging'
          },
          Items: [
            {
              Title: '-2%',
              Value: '-2'
            },
            {
              Title: '-1%',
              Value: '-1'
            },
            {
              Title: 'Current',
              Value: '0'
            },
            {
              Title: '+1%',
              Value: '1'
            },
            {
              Title: '+2%',
              Value: '2'
            }
          ]
        },
        CreditMetrics: {
          Title  : 'Credit Metrics',
          InfoTip: 'The Debt-to-Equity ratio is based on (i) all current debt outstanding and (ii) Equity as a user input. This allows the user to input different equity levels based on various share price scenarios. Debt-to-EBITDA is based on this and next year’s EBITDA user inputs. Similarly, EBITDA-to-Interest looks at this and next year’s EBITDA/interest expense multiple using current Libor as the base assumption. Having EBITDA as a user input allows for testing credit ratio scenarios under different economic conditions.',
          Pie    : {
            Equity: {
              Title: 'Equity'
            },
            Debt: {
              Title: 'Debt'
            },
            DebtToEquityRatio: {
              Title: 'Debt To Equity Ratio'
            }
          },
          Table: {
            DebtToEbitda: {
              Title: 'Debt To EBITDA'
            },
            EbitdaToInterest: {
              Title: 'EBITDA To Interest'
            }
          }
        },
        OptimalFixedRatio: {
          Title     : 'Optimal Fixed Ratio',
          TitleChart: 'Optimal Ratio',
          InfoTip   : 'Optimal Fixed Ratio denotes % of fixed debt over total debt for the next 12-18 months, as new information will be available as time passes.',
          Buttons   : {
            Labels: {
              UserInput1: {
                Prefix: 'User Input 1:',
                Suffix: 'View on Interest Rates'
              },
              UserInput2: {
                Prefix: 'User Input 2:',
                Suffix: 'Risk Aversion'
              }
            },
            RatesView: {
              Label        : 'Rates View',
              SelectedAsset: 'NONE'
            },
            RiskAversion: {
              Label        : 'Risk Aversion',
              SelectedAsset: 'NEUTRAL'
            }
          }
        }
      }
    },
    ForecastFlow: {
      Block: {
        Title  : 'CLVT Outputs',
        InfoTip: 'CLVT Outputs ... Lorem ipsum dolor sit amet, nobis dicam per id, choro graece cu sea. Solum iuvaret erroribus eam at'
      }
    }
  },

  Tab: {
    Active: {
      Title: 'Active Analysis',
      Slug : 'active-analysis',
      Card : {
        MenuItems: [
          {
            Event : 'archive-item-delete-button-click',
            Title : 'Delete',
            Icon  : 'mdi-trash-can',
            Color : 'synthesis-ui-red-05',
            Active: true
          }
        ]
      }
    },
    Archive: {
      Title  : 'All Analyses',
      Slug   : 'all-analyses',
      Confirm: {
        Delete: {
          Title: 'Delete Analysis?',
          Body : 'Are you sure you want to delete <span class="grey--text">"{Name}"</span> analysis?'
        },
        Active: {
          Title    : 'Set as Active?',
          Body     : 'Are you sure you want to set <span class="grey--text">"{Name}"</span> as your active analysis?',
          ButtonYes: 'Set as Active'
        }
      },
      Card: {
        MenuItems: [
          {
            Event         : 'archive-item-active-button-click',
            Title         : 'Set as active',
            Icon          : 'mdi-bookmark',
            Color         : 'synthesis-ui-green-06',
            ActiveAnalysis: false,
            Active        : true
          },
          {
            Event         : 'archive-item-delete-button-click',
            Title         : 'Delete',
            Icon          : 'mdi-trash-can',
            Color         : 'synthesis-ui-red-05',
            ActiveAnalysis: false,
            Active        : true
          },
          {
            Event         : 'archive-item-new-button-click',
            Title         : 'New',
            Icon          : 'mdi-plus-circle-outline',
            Color         : 'synthesis-ui-green-04',
            ActiveAnalysis: true,
            Active        : true
          }
        ]
      }
    },
    Summary: {
      Title: 'Summary',
      Slug : 'summary',
      Tab  : {
        ValueAtRisk: {
          Title: 'Value at Risk',
          Slug : 'value-at-risk'
        },
        HistoricalSpectrum: {
          Title: 'Historical Spectrum',
          Slug : 'historical-spectrum'
        },
        ForwardSimulations: {
          Title: 'Forward Simulations',
          Slug : 'forward-simulations'
        },
        ExtremeEvent: {
          Title: 'Extreme Event',
          Slug : 'extreme-events'
        }
      }
    },
    Correlations: {
      Title: 'Correlations',
      Slug : 'correlations'
    },
    Seasonality: {
      Title: 'Seasonality',
      Slug : 'seasonality'
    },
    Forecasts: {
      Title  : 'Forecasts',
      Slug   : 'forecasts',
      InfoTip: 'Please upgrade to Premium tier.'
    },
    Debt: {
      Title  : 'Debt Profile',
      Slug   : 'debt-profile',
      InfoTip: 'Please upgrade to Premium tier.'
    },
    ForecastFlow: {
      Title  : 'CLVT Outputs',
      Slug   : 'forecast-flow',
      InfoTip: 'Please upgrade to Premium tier.'
    }
  },

  Button: {
    RunHedgingStrategy: {
      Title  : 'Run Hedging Strategies',
      Confirm: {
        Title: 'Run Hedging Strategies',
        Body : 'In order to Run Hedging Strategies for this analysis it will be set as your active analysis. Are you sure you want to continue?'
      }
    },
    New: {
      Title: '@:Common.Button.New Analysis'
    },
    View: {
      Title: '@:Common.Button.View Analysis'
    },
    Save: {
      Title: '@:Common.Button.Save Analysis'
    }
  },

  Table: {
    RiskMethodologies: {
      Headers: {
        Asset       : 'ASSET',
        Exposure    : 'EXPOSURE',
        ValueAtRisk : 'VALUE AT RISK',
        Historical  : 'HISTORICAL',
        ExtremeEvent: 'EXTREME EVENT',
        Simulation  : 'SIMULATION',
        AnnualRisk  : 'ANNUAL RISK',
        Volatility  : 'VOLATILITY'
      }
    },

    Seasonality: {
      Headers: {
        Method          : 'Seasonality',
        MethodInfoTip   : 'Zero Seasonality assumes the total annual exposure is equally distributed in each month. Maximum Seasonality assumes the full annual exposure is allocated in a single month. User Input Seasonality is based on the actual user inputs per asset.',
        VaR_Pct_1       : '1% VaR',
        VaR_Pct_1InfoTip: '1% VaR risk of the annual cash flows under the Forward Simulations methodology.',
        VaR_Pct_5       : '5% VaR',
        Median          : 'Median',
        Impact          : 'Impact of Seasonality',
        ImpactInfoTip   : 'By subtracting the 1% VaR risk of the zero seasonality scenario, we can see how much extra risk is created simply by the monthly variation of the user cash flows.'
      },
      Data: {
        UserSeasonality: 'User Input',
        MaxSeasonality : 'Maximum',
        NoSeasonality  : 'Zero'
      }
    }

  },

  Edit: {
    Title: '@:Common.Button.Edit Analysis',
    Form : {
      Steps: {
        Step1: {
          Title: 'Inputs'
        },
        Step2: {
          Title: 'Exposures'
        },
        Step3: {
          Title : 'Risk Methodologies',
          Button: {
            Action: 'Run Analysis'
          }
        },
        Step4: {
          Title : 'Risk Summary',
          Button: {
            Action: 'Save Analysis'
          }
        }
      },

      Fields: {
        Name: {
          Label      : 'New Analysis Name',
          Placeholder: 'Enter analysis name'
        },
        BaseCurrency: {
          Label      : 'Base Currency',
          SelectLabel: 'Select @:Risks.Edit.Form.Fields.BaseCurrency.Label',
          Placeholder: 'Please Select'
        },
        Methodologies: {
          Label      : 'Methodologies',
          Placeholder: 'Select your Risk Methodologies',
          Items      : [
            {
              Value  : 'activeVar',
              Title  : 'Value at Risk',
              Image  : '/img/risks/methodologies/m1.png',
              InfoTip: 'VaR calculates the risk of the exposure portfolio by stress testing each component on a “2-sigma” basis'
            },
            {
              Value  : 'activeHistorical',
              Title  : 'Historical Spectrum',
              Image  : '/img/risks/methodologies/m2.png',
              InfoTip: 'This methodology looks into how current exposures would have performed with historical asset prices'
            },
            {
              Value  : 'activeSimulation',
              Title  : 'Forward Simulations',
              Image  : '/img/risks/methodologies/m4.png',
              InfoTip: '100,000 multi-step simulations are run to approximate future scenarios per asset and for the overall portfolio'
            },
            {
              Value  : 'activeEvents',
              Title  : 'Extreme Event',
              Image  : '/img/risks/methodologies/m3.png',
              InfoTip: 'The algorithm identifies the 4 worst periods for the exposure portfolio since 2000 and simulates risk under these extreme events'
            }
          ]
        },
        Exposures: {
          Label                       : 'Add exposure',
          Placeholder                 : 'Select currency, commodity or interest rate',
          Asset                       : 'Asset',
          InterbankRate               : 'Floating Rate Debt (Base)',
          AnnualInterest              : 'Annual Interest Expense',
          AnnualExposure              : 'Annual Exposure (Local)',
          AnnualExposureInBaseCurrency: 'Annual Exposure (Base)',
          Seasonality                 : 'Seasonality',
          Analysis                    : 'Analysis',
          TotalRisk                   : 'Total Risk',
          HedgeRatio                  : 'Hedge Ratio',
          MenuItems                   : [
            {
              Event : 'list-item-edit-button-click',
              Title : 'Edit',
              Icon  : 'mdi-pencil',
              Color : 'synthesis-ui-grey-01',
              Active: true
            },
            {
              Event : 'list-item-delete-button-click',
              Title : 'Delete',
              Icon  : 'mdi-trash-can',
              Color : 'synthesis-ui-red-05',
              Active: true
            }
          ]
        }
      }
    }
  },

  AddExposure: {
    Title       : '@:Common.Button.Add Exposure',
    Subtitle    : 'Select currency, commodity or interest rate',
    SubtitleDebt: 'Select currency or commodity',
    Tab         : {
      Exposures    : 'Exposures',
      RiskSummaries: 'Risk Summaries'
    },
    Form: {
      Fields: {
        TotalRisk: {
          Label      : 'Total Risk',
          Placeholder: ''
        },

        HedgeRatio: {
          Label      : 'Hedge Ratio',
          Placeholder: 'Enter ratio'
        },

        Label: {
          Label      : 'Source name',
          Placeholder: 'Enter asset source name e.g. exports receivables'
        },

        MultipleExposuresPerAsset: {
          Label      : 'Multiple Exposures per Asset',
          Placeholder: 'Multiple Exposures per Asset'
        },

        Code: {
          Label      : 'Exposure description',
          Placeholder: 'Enter asset exposure description'
        },

        Notional: {
          Label      : 'Annual Exposure',
          InfoTip    : 'Annual exposure in local units. Positive numbers in black denote revenues/inflows and negative numbers in red denote expenses/outflows. If this field is populated, each month will be allocated the total exposure divided by twelve (no seasonality). If different exposures per month, please click “Add Seasonality” link below.',
          Placeholder: '0'
        },

        InterbankRate: {
          Label      : 'Floating Rate Debt',
          InfoTip    : '',
          Placeholder: '0'
        }
      },
      Confirm: {
        Delete: {
          Title: 'Delete Exposure?',
          Body : 'Are you sure you want to delete this exposure?'
        }
      },
      Button: {
        SaveExposure: {
          Title: '@:Common.Button.Save Exposure'
        },
        UpdateExposure: {
          Title: '@:Common.Button.Update Exposure'
        },

        SaveSummary: {
          Title: '@:Common.Button.Save Summary'
        },
        UpdateSummary: {
          Title: '@:Common.Button.Update Summary'
        }
      }
    }
  },

  New: {
    Title: '@:Common.Button.New Analysis'
  }
}
