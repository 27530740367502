import BaseModel                        from '@/lib/data/model/BaseModel'
import IndustryComparisonInputValidator from '@/api/validations/industry/IndustryComparisonInputValidator'
import IndustryComparisonInputResource  from '@/api/resources/industry/IndustryComparisonInputResource'
import API                              from '@/api/Api'

export default class IndustryComparisonInputModel extends BaseModel {
  constructor (payload = {}, resource = IndustryComparisonInputResource, validator = IndustryComparisonInputValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
  async CreateComparison () {
    return await API.Resource.Industry.CreateComparison(this.clone())
  }

  async SaveComparison () {
    return await API.Resource.Industry.SaveComparison(this.clone())
  }
}
