import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class ActiveAnalysesRiskSummariesValidator extends BaseValidator {
  validatorRules = {
    hedgeRatios: {
      required             : true,
      NotEmptyBetweenNumber: (v, model = this.model) => Object.values(v).every(val => parseFloat(val.ratio) >= 0 && parseFloat(val.ratio) <= 100 && val.ratio !== '')
      // AllPositiveWithZeros : (v, model = this.model) => Object.values(v).some(val => parseFloat(val.ratio) > 0)
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
