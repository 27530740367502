import BaseResource             from '@/lib/data/resource/BaseResource'
import dayjs                    from 'dayjs'
import { collect, first, take } from '@/lib/utils/array'
import AssetNameResource        from '@/api/resources/asset/AssetNameResource'
import HedgingModeEnum          from '@/api/enums/HedgingModeEnum'
import i18n                     from '@/lang/lang'

export default class HedgingArchiveResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const o = payload?.result?.hedgeAmountsSummary || null
    const p = payload?.parameters || null

    this.Id = payload.id ?? ''
    this.Name = payload.name ?? ''
    this.Type = i18n.t(`Hedging.Mode.${ p?.mode ?? HedgingModeEnum.Historical }`)
    this.CreatedAt = payload.createdAt ?? ''
    this.CreatedAtFmt = this.getDateFmt(payload.createdAt ?? '')
    this.Assets = collect(o?.assets || [], AssetNameResource)
    this.Items = o?.hedgeAmounts || []
    this.Tags = this.getTags()
  }

  /* PROPERTIES */
  get BaseCurrencyDisplayName () {
    if (this.Items) {
      const item = first(this.Items)

      return item?.baseCurrency?.displaySymbol || ''
    }

    return ''
  }

  get PieValues () {
    if (this.Items) {
      return this.Items.map((asset, i) => {
        return {
          name : this.Assets[i].Title,
          value: asset,
          type : 'number'
        }
      })
    }

    return []
  }

  get TagsTrimmed () {
    const showTotal = 4
    const addMore = this.Tags.length > showTotal
    const showTags = take(this.Tags, showTotal)
    addMore && showTags.push('...')

    return showTags
  }

  /* METHODS */
  getTags () {
    if (this.Assets) {
      return this.Assets.map(item => item.Title)
    }

    return []
  }

  getDateFmt (date) {
    return date ? dayjs(date).format('DD MMM YYYY') : ''
  }
}
