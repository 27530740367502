import BaseResource from '@/lib/data/resource/BaseResource'

export default class SubsidiaryResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.companyId = payload.companyId ?? ''
    this.companyName = payload.companyName ?? ''
    this.accountId = payload.accountId ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
