<script>
import dayjs                                                                from 'dayjs'
import { first, last }                                                      from '@/lib/utils/array'
import { toDecimalMark, toDecimalSignificant, toNumberFormatNotationShort } from '@/lib/utils/helper'
import BaseEChartLine                                                       from '@/theme/default/components/charts/echarts/BaseEChartLine'
import baseEChartLineAreaProps                                              from '@/theme/default/components/charts/echarts/mixins/lineArea/baseEChartLineAreaProps'
import baseEChartLineAreaData                                               from '@/theme/default/components/charts/echarts/mixins/lineArea/baseEChartLineAreaData'
import baseEChartColors                                                     from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'
import chartsColorMixin                                                     from '@/theme/default/components/charts/mixins/chartsColorMixin'
import baseEChartBarProps                                                   from '@/theme/default/components/charts/echarts/mixins/bar/baseEChartBarProps'
import baseEChartBarData                                                    from '@/theme/default/components/charts/echarts/mixins/bar/baseEChartBarData'

import { use }      from 'echarts/core'
import { BarChart } from 'echarts/charts'

use([
  BarChart
])

export default {
  name   : 'EChartHedgingInstrumentsHistogram',
  extends: BaseEChartLine,
  mixins : [baseEChartColors, chartsColorMixin, baseEChartLineAreaProps, baseEChartLineAreaData, baseEChartBarProps, baseEChartBarData],
  props  : {},
  data () {
    return {
      debounceTimeout: null
    }
  },
  computed: {
    getChartGridMargin () {
      return this.chartGridMargin || {
        left        : 0,
        top         : 0,
        right       : 0,
        bottom      : 0,
        containLabel: true
      }
    },

    setChartDataOptions () {
      return this.getChartDataOptions
    },

    getChartDataOptions () {
      const series = []
      const xAxis = []
      const yAxis = []

      if (this.chartDataOptions.length) {
        const data = first(this.chartDataOptions)

        Object.keys(data).forEach((item, index) => {
          const values = data[item].data ?? null
          const color = data[item].color ?? this.themeColorRGBA('synthesis-ui-purple-02')

          if (values && !!values.length) {
            const o1 = first(values)
            const o2 = last(values)

            if (o1) {
              series.push({
                type      : 'line',
                smooth    : 0.6,
                showSymbol: false,
                lineStyle : { opacity: 0 },
                areaStyle : {
                  color: this.themeColorRGBA('synthesis-ui-grey-02', 0.8)
                },
                xAxisIndex: index,
                yAxisIndex: index,
                cursor    : this.cursor,
                silent    : this.silent,
                data      : o1.y
              })
            }

            if (o2) {
              series.push({
                z             : 10,
                type          : 'bar',
                barCategoryGap: '90%',
                label         : { show: false },
                itemStyle     : {
                  // (clockwise upper left, upper right, bottom right and bottom left)
                  borderRadius: [this.barBorderRadius, this.barBorderRadius, this.barBorderRadius, this.barBorderRadius],
                  color       : color
                },
                animationDelay: function (idx) {
                  return idx * 10
                },
                xAxisIndex: index,
                yAxisIndex: index,
                cursor    : this.cursor,
                silent    : this.silent,
                data      : o2.y
              })
            }

            xAxis.push({
              data     : o1.x,
              type     : 'category',
              gridIndex: index,
              axisLabel: {
                visible   : true,
                fontFamily: this.fontFamily,
                fontSize  : this.axisLabelFontSize,
                color     : this.axisLabelFontColor,
                formatter : value => {
                  if (this.axisXLabelValueFormat === 'number') {
                    value = toNumberFormatNotationShort(parseFloat(value))
                  }
                  if (this.axisXLabelValueFormat === 'percent') {
                    value = toDecimalMark(parseFloat(value))
                  }
                  if (this.axisXLabelValueFormat === 'date') {
                    value = dayjs(value).format(this.axisXLabelValueDateFormat)
                  }

                  return (this.axisXTickPrefix + ' ' || '') + value + (this.axisXTickSuffix || '')
                },
                align      : 'left',
                inside     : this.axisXLabelInside,
                margin     : this.axisXLabelMargin,
                padding    : [0, 0, 0, 0], // [top, right, bottom, left]
                interval   : this.axisXLabelInterval,
                hideOverlap: this.axisXLabelHideOverlap
              },
              axisLine: {
                show     : this.axisXGridLineShow,
                lineStyle: {
                  color: this.axisXGridLineStyleColor
                }
              },
              axisTick : { show: false },
              splitLine: { show: false }
            })

            yAxis.push({
              type     : 'value',
              gridIndex: index,
              scale    : this.chartScale,
              axisLabel: {
                visible   : true,
                inside    : this.axisYLabelInside,
                margin    : this.axisYLabelMargin,
                padding   : [0, 0, 0, 0], // [top, right, bottom, left]
                fontFamily: this.fontFamily,
                fontSize  : this.axisLabelFontSize,
                color     : this.axisLabelFontColor,
                formatter : value => {
                  if (this.axisYLabelValueFormat === 'number') {
                    value = (value === 0 ? value : toNumberFormatNotationShort(parseFloat(value)))
                  }
                  if (this.axisYLabelValueFormat === 'percent') {
                    value = (value === 0 ? value : toDecimalSignificant(parseFloat(value)))
                  }
                  if (this.axisYLabelValueFormat === 'date') {
                    value = dayjs(value).format(this.axisYLabelValueDateFormat)
                  }

                  return (this.axisYTickPrefix + ' ' || '') + value + (this.axisYTickSuffix || '')
                },
                interval     : this.axisYLabelInterval,
                hideOverlap  : this.axisYLabelHideOverlap,
                verticalAlign: this.axisYLabelVerticalAlign
              },
              axisLine: {
                onZero   : false,
                show     : this.axisYGridLineShow,
                lineStyle: {
                  color: this.axisYGridLineStyleColor
                }
              },
              axisTick : { show: false },
              splitLine: {
                show     : this.axisYSplitLineShow,
                lineStyle: {
                  type   : this.axisYSplitLineType,
                  color  : this.axisYSplitLineColor,
                  opacity: this.axisYSplitLineOpacity,
                  width  : this.axisYSplitLineWidth
                }
              },
              splitNumber: this.axisYSplitNumber
            })
          }
        })
      }

      return {
        animation              : this.chartAnimation,
        animationThreshold     : this.chartAnimationThreshold,
        animationDuration      : this.chartAnimationDuration,
        animationEasing        : this.chartAnimationEasing,
        animationDurationUpdate: this.chartAnimationDuration,
        animationEasingUpdate  : this.chartAnimationEasing,
        tooltip                : { show: false },
        legend                 : { show: false },
        textStyle              : {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid  : this.getChartGridMargin,
        xAxis : xAxis,
        yAxis : yAxis,
        series: series,
        media : [{
          query : { maxWidth: this.$vuetify.breakpoint.thresholds.xs },
          option: {
            grid: [
              {
                left  : 15,
                top   : 30,
                right : 15,
                bottom: '68%'
              },
              {
                left  : 15,
                top   : '34%',
                right : 15,
                bottom: '35%'
              },
              {
                left  : 15,
                top   : '67%',
                right : 15,
                bottom: 0
              }
            ]
          }
        }]
      }
    }
  }
}
</script>
