import BaseModel        from '@/lib/data/model/BaseModel'
import AccountValidator from '@/api/validations/user/AccountValidator'
import AccountResource  from '@/api/resources/user/AccountResource'

export default class AccountModel extends BaseModel {
  constructor (payload = {}, resource = AccountResource, validator = AccountValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}
