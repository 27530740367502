import BaseResource from '@/lib/data/resource/BaseResource'

export default class RiskHistoricalPricesResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Dates = payload.dates ?? []
    this.Prices = payload.prices ?? []
  }

  /* PROPERTIES */
  get Values () {
    return [{
      x: this.Dates,
      y: this.Prices
    }]
  }

  /* METHODS */
}
