<template>
  <view-data-container
    :loading="isLoadingData"
    :no-data-title="$t('Risks.Message.NoActiveData.Title')"
  >
    <v-row v-if="riskActiveData">
      <v-col
        cols="12"
      >
        <block-item-card
          class="pa-5 pt-0"
        >
          <v-toolbar flat>
            <v-toolbar-title
              class="synthesis-text text-size-28 font-weight-medium synthesis-brand-dark-blue--text"
              v-text="riskActiveData.Name"
            />
          </v-toolbar>

          <risk-summary-base-block :risk-analysis-id="riskActiveData.Id" />

          <v-row class="pa-3 justify-end">
            <v-btn
              :to="{name: 'HedgingStrategyNew'}"
              class="mr-1 button-text-underline synthesis-text text-size-14 font-weight-400"
              color="synthesis-ui-green-02"
              height="42"
              text
            >
              {{ $t('Risks.Button.RunHedgingStrategy.Title') }}
            </v-btn>
            <v-btn
              class="synthesis-text text-size-14 font-weight-400 white--text"
              color="synthesis-ui-green-02"
              depressed
              height="42"
              large
              @click="onItemCardClick"
            >
              {{ $t('Risks.Button.View.Title') }}
            </v-btn>
          </v-row>
        </block-item-card>
      </v-col>
    </v-row>
  </view-data-container>
</template>

<script>

import RiskSummaryBaseBlock       from '@/theme/default/views/risks/blocks/RiskSummaryBaseBlock'
import BlockItemCard              from '@/theme/default/components/common/BlockItemCard'
import API                        from '@/api/Api'
import { clone }                  from '@/lib/utils/helper'
import RiskActiveAnalysisResource from '@/api/resources/risks/RiskActiveAnalysisResource'
import RiskSummaryResource        from '@/api/resources/risks/RiskSummaryResource'
import riskAnalysisId             from '@/theme/default/views/risks/mixins/riskAnalysisId'
import AssetModel                 from '@/api/models/asset/AssetModel'
import ViewDataContainer          from '@/theme/default/components/common/ViewDataContainer'
import viewHasData                from '@/mixins/viewHasData'

export default {
  name      : 'RisksAnalysisActive',
  components: {
    ViewDataContainer,
    BlockItemCard,
    RiskSummaryBaseBlock
  },
  directives: {},
  mixins    : [riskAnalysisId, viewHasData],
  props     : {},
  dataStore : {
    riskActiveData      : 'Risks.Active.Data',
    riskSummaryData     : 'Risks.Summary.Data',
    riskBaseCurrencyData: 'Risks.BaseCurrency.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      breadcrumbItems: [{
        text    : this.$t('Risks.Title'),
        disabled: false
      }]
    }
  },
  computed: {
    accountId () {
      return this.user?.selectedAccountId || null
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      this.isLoadingData = true

      API.Resource.Risks.Active(this.accountId)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {
          this.isLoadingData = false
        })
    },

    handleResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        const active = data?.activeAnalysis || null

        if (active) {
          const o = clone(active)
          const b = new AssetModel(o.baseCurrency ?? null)
          const v = new RiskActiveAnalysisResource(o)

          // Clear Active analysis
          this.riskActiveData = null

          this.$nextTick(() => {
            // Store Active analysis
            this.riskActiveData = v

            // Store data to DataStore
            this.$set(this.riskSummaryData, v.Id, v)
            this.$set(this.riskBaseCurrencyData, v.Id, b)
          })
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    getItemData (item) {
      API.Resource.Risks.Summary(item.Id)
        .then(response => {
          this.handleItemResponse(response, item)
        })
        .catch(e => {
          this.handleItemResponse(e.response, item)
        })
        .finally(() => {})
    },

    handleItemResponse (response, item) {
      const data = API.responseData(response)?.findAnalysis || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const o = clone(data)
        const b = new AssetModel(o.baseCurrency ?? null)
        const v = new RiskSummaryResource(o)

        // Store data to DataStore
        this.$set(this.riskSummaryData, item.Id, v)
        this.$set(this.riskBaseCurrencyData, item.Id, b)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    onItemCardClick () {
      this.getItemData(this.riskActiveData)

      this.$router.push({
        name  : 'RisksAnalysisShow',
        params: {
          id              : this.riskActiveData.Id,
          riskAnalysisId  : this.riskActiveData.Id,
          riskAnalysisName: this.riskActiveData.Name
        }
      })
    }
  }
}
</script>

<style scoped>
/deep/ .v-toolbar__content {
  padding: 20px 0 20px 0;
}
</style>
