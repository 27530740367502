import BaseModel                   from '@/lib/data/model/BaseModel'
import MavenAdvisoryInputValidator from '@/api/validations/help/MavenAdvisoryInputValidator'
import MavenAdvisoryInputResource  from '@/api/resources/help/MavenAdvisoryInputResource'
import API                         from '@/api/Api'

export default class MavenAdvisoryInputModel extends BaseModel {
  constructor (payload = {}, resource = MavenAdvisoryInputResource, validator = MavenAdvisoryInputValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
  async SendRequest () {
    return await API.Resource.Help.MavenAdvisoryRequest(this.clone())
  }
}
