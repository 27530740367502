<template>
  <v-card
    :class="[colorClass]"
    class="pa-2"
    flat
    v-on="enableCardClick ? {click: onCardClick} : {}"
  >
    <v-toolbar
      color="transparent"
      flat
    >
      <v-toolbar-title class="text-size-20 synthesis-text synthesis-brand-dark-blue--text">
        <div
          v-if="subTitle"
          class="text-size-10 synthesis-text synthesis-brand-dark-blue--text font-weight-600"
        >
          {{ subTitle }}
        </div>
        {{ title }}
      </v-toolbar-title>

      <v-spacer />

      <span
        v-if="titleValue"
        class="text-size-24 synthesis-text synthesis-brand-dark-blue--text font-weight-bold"
      >
        {{ titleValue }}
      </span>

      <v-avatar
        v-if="avatar"
        :color="`synthesis-ui-${color}-01 lighten-5`"
        size="36"
      >
        <v-icon :color="`synthesis-ui-${color}-01`">
          mdi-bell
        </v-icon>
      </v-avatar>
    </v-toolbar>

    <v-card-text>
      <div :style="`min-height: ${subTitle ? 90 : 112}px;`">
        <template v-for="(tag, index) in tags">
          <v-chip
            :key="`tag-${index}`"
            :color="$vuetify.theme.themes.light['synthesis-ui-grey-05']"
            :text-color="$vuetify.theme.themes.light['synthesis-ui-grey-01']"
            class="mr-1 mb-1 text-size-10 text-uppercase"
            label
          >
            {{ tag }}
          </v-chip>
        </template>
      </div>
    </v-card-text>

    <v-card-actions class="pa-4">
      <span
        class="text-size-10"
        style="color: #63747d"
      >
        {{ actionText }}
      </span>

      <v-spacer />

      <slot />
    </v-card-actions>
  </v-card>
</template>

<script>
import i18n from '@/lang/lang'

export default {
  name      : 'ItemCard',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    title: {
      type   : String,
      default: ''
    },
    subTitle: {
      type   : String,
      default: ''
    },
    titleValue: {
      type   : String,
      default: ''
    },
    actionText: {
      type   : String,
      default: ''
    },
    avatar: {
      type   : Boolean,
      default: false
    },
    color: {
      type     : String,
      validator: (val) => ['blue', 'red', 'yellow', 'green', ''].find(item => item !== val.toLocaleLowerCase(i18n.locale)),
      default  : 'red'
    },
    tags: {
      type   : Array,
      default: () => []
    },
    cardEvent: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    colorClass () {
      return this.color ? `section-bg-gradient-${ this.color }` : ''
    },

    enableCardClick () {
      return this.cardEvent
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onCardClick (item) {
      this.$emit('card-click', item)
    }
  }
}
</script>

<style scoped>

</style>
